import client from "Api";
import { accountsUrl, propertyUrl } from "helpers/urls";
import { Dispatch } from "redux";
import { AlertActions, IAlertPayload } from "store/alerts/AlertInterface";
import { AlertType } from "store/alerts/AlertTypes";
import { IUserAuthRequestData } from "store/authentication/AuthenticationInterface";
import { IResponseData } from "store/common/CommonInterface";

import {
  AccountActions,
  ICheckEmailData,
  IGetActivitiesResponse,
} from "./AccountInterface";
import AccountTypes from "./AccountTypes";

export const getActivities = () => {
  return async (dispatch: Dispatch<AccountActions>) => {
    try {
      dispatch({ type: AccountTypes.GET_ACTIVITIES_REQUEST });
      const response: IGetActivitiesResponse = await client.get(
        `${propertyUrl}owner/allActivities`,
      );
      dispatch({
        type: AccountTypes.GET_ACTIVITIES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AccountTypes.GET_ACTIVITIES_ERROR });
      return error;
    }
  };
};

export const checkEmail = (email: string) => {
  return async (
    dispatch: Dispatch<AccountActions | AlertActions>,
  ): Promise<IResponseData<ICheckEmailData>> => {
    try {
      dispatch({ type: AccountTypes.CHECK_EMAIL_REQUEST });
      const response: IResponseData<ICheckEmailData> = await client.get(
        `${accountsUrl}users/exists?email=${encodeURIComponent(email)}`,
      );
      dispatch({
        type: AccountTypes.CHECK_EMAIL_SUCCESS,
        payload: { ...(response.data as ICheckEmailData) },
      });
      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
      dispatch({ type: AccountTypes.CHECK_EMAIL_ERROR });
      return error;
    }
  };
};

export const updateAccountsInfo = (payload: IUserAuthRequestData) => {
  return async (dispatch: Dispatch<AccountActions>) => {
    try {
      dispatch({ type: AccountTypes.UPDATE_USER_ACCOUNT_INFO_REQUEST });
      const response: IResponseData = await client.patch(
        `${accountsUrl}users`,
        payload,
      );
      dispatch({ type: AccountTypes.UPDATE_USER_ACCOUNT_INFO_SUCCESS });
      return response;
    } catch (error) {
      dispatch({ type: AccountTypes.UPDATE_USER_ACCOUNT_INFO_ERROR });
      return error;
    }
  };
};

/** Commenting as we are not using the below API */
// export const checkPhone = (phone: string) => {
//   return async (dispatch: Dispatch<AccountActions>) => {
//     try {
//       dispatch({ type: AccountTypes.CHECK_PHONE_REQUEST });
//       const response: IResponseData = await client.get(
//         `${accountsUrl}phone/exists?phone=${encodeURIComponent(phone)}`,
//       );
//       dispatch({
//         type: AccountTypes.CHECK_PHONE_SUCCESS,
//         payload: { ...(response.data as ICheckPhoneData) },
//       });
//       return response;
//     } catch (error: unknown) {
//       dispatch({ type: AccountTypes.CHECK_PHONE_ERROR });
//       return error;
//     }
//   };
// };
