import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import NativeSelect, { NativeSelectProps } from "@mui/material/NativeSelect";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DownArrow } from "helpers/images";
import theme from "theme";

const CustomInputSelect = styled(InputBase)(({ error, theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "6px",
    border: `1px solid ${
      error ? theme.palette.error.main : theme.palette.divider
    }`,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    position: "relative",
    padding: "13px 10px 13px 16px",
    "&:focus": {
      borderColor: !error ? theme.palette.text.primary : "",
    },
  },
  "& .MuiNativeSelect-icon": {
    right: "16px",
    top: "calc(50% - 0.2em)",
  },
}));

interface ICustomSelectFieldProps extends NativeSelectProps {
  children: React.ReactNode | JSX.Element;
  label: string;
  errormessage?: string;
}

function CustomSelectField(props: ICustomSelectFieldProps) {
  const { children, label, error, errormessage } = props;
  return (
    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
      <Typography
        color={error ? theme.palette.error.main : ""}
        component="span"
        variant="h5"
      >
        {label}
      </Typography>
      <FormControl fullWidth>
        <NativeSelect
          IconComponent={DownArrow}
          input={<CustomInputSelect error={error} />}
          {...props}
        >
          {children}
        </NativeSelect>
      </FormControl>
      {error ? (
        <Typography color={theme.palette.error.main} variant="caption">
          {errormessage}
        </Typography>
      ) : null}
    </Stack>
  );
}

export default CustomSelectField;
