import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import { BackIcon, CloseIcon, WingItLogo } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AuthFlowTypes,
  resetAuthState,
  setMobileViewAuthSession,
  showHideAuthModal,
  updateAuthStep,
  updateEmailValidationFlags,
  updateVerifyCodeActive,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

function AuthHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    authStep,
    showAuthModal,
    verifyCodeComponentActive,
    emailValidationFlags,
  } = useAppSelector(s => s.auth);
  const { sendEmailForSignUp, sendEmailForVerify } = emailValidationFlags;

  const stepsWithoutBackButton = [1, 5];
  const [hideCloseButton, setHideCloseButton] = useState<boolean>(false);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (location.pathname.includes("/login") && !isMobile) {
      setHideCloseButton(true);
    } else if (
      (authStep >= 5 && !emailValidationFlags.sendEmailForVerify) ||
      emailValidationFlags.resetPasswordActive ||
      verifyCodeComponentActive
    ) {
      setHideCloseButton(true);
    } else {
      setHideCloseButton(false);
    }
  }, [
    authStep,
    emailValidationFlags.resetPasswordActive,
    emailValidationFlags.sendEmailForVerify,
    isMobile,
    location.pathname,
    verifyCodeComponentActive,
  ]);

  const toggleAuthModal = useCallback(
    (resetAuthStep = false) => {
      if (isMobile) {
        if (showAuthModal) dispatch(showHideAuthModal(!showAuthModal));
        dispatch(setMobileViewAuthSession(false));
        navigate("/");
      } else {
        dispatch(showHideAuthModal(!showAuthModal));
      }
      if (resetAuthStep) {
        dispatch(resetAuthState());
        if (authStep !== 1) {
          setTimeout(() => {
            dispatch(updateAuthStep(AuthFlowTypes.EMAIL));
          }, 100);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, authStep, showAuthModal],
  );

  const handleBack = () => {
    if (verifyCodeComponentActive) {
      dispatch(updateAuthStep(AuthFlowTypes.ACCOUNT_DETAILS));
      dispatch(updateVerifyCodeActive(false));
      return;
    }
    if (sendEmailForSignUp) {
      dispatch(
        updateEmailValidationFlags({
          ...emailValidationFlags,
          sendEmailForSignUp: false,
        }),
      );
      return;
    }
    if (sendEmailForVerify) {
      dispatch(
        updateEmailValidationFlags({
          ...emailValidationFlags,
          sendEmailForVerify: false,
        }),
      );
      return;
    }
    switch (authStep) {
      case 3:
        dispatch(updateAuthStep(authStep - 2));
        break;
      default:
        dispatch(updateAuthStep(authStep - 1));
    }
  };

  const showBackButton = () => {
    return (
      !stepsWithoutBackButton.includes(authStep) &&
      !emailValidationFlags.resetPasswordActive &&
      !(emailValidationFlags.sendEmailForVerify && authStep === 9)
    );
  };

  return (
    <Box
      px={theme.spacing(3)}
      sx={{
        height: "60px",
        borderBottom: `1px solid ${theme.palette.text.secondary}`,
        position: "relative",
      }}
    >
      {!hideCloseButton && (
        <Box sx={{ position: "absolute", right: "24px", top: "12px" }}>
          <IconButton onClick={() => toggleAuthModal(true)}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {showBackButton() && (
        <Box sx={{ position: "absolute", left: "24px", top: "10px" }}>
          <IconButton
            aria-label="Back Button"
            size="large"
            sx={{ mb: 1, mt: 1 }}
            onClick={handleBack}
          >
            <BackIcon />
          </IconButton>
        </Box>
      )}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Box mx={theme.spacing(3)}>
          <SvgIcon
            inheritViewBox
            component={WingItLogo}
            sx={{ height: "24px", width: "90px" }}
          />
        </Box>
      </Stack>
    </Box>
  );
}

export default AuthHeader;
