import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Container, Grid, IconButton, Stack } from "@mui/material";
import NoImage from "assets/no_image.png";
import ImageBadgeComponent from "components/image/ImageBadgeComponent";
import Modal from "components/Modal/ModalContainer";
import PropertyCarousel from "components/PropertyCarousel/PropertyCarousel";
import RenderEdit from "components/PropertyDetails/components/RenderEdit";
import { ImageIcon } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { useState } from "react";
import { ImageType, PropertySteps, setEditingProperty } from "store";
import { useAppDispatch } from "store/hooks";
import theme from "theme";

const ImageGrid = (props: any) => {
  const { activityMediaArr, isEdit } = props;
  const { navigateTo } = useNavigateStep();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile("midsm");

  const { ACTIVITY_IMAGE } = ImageType;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const updatePropertyStep = (step: PropertySteps) => {
    dispatch(setEditingProperty(true));
    navigateTo(step);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        {isMobile ? (
          <Box className="grid-container" sx={{ position: "relative" }}>
            {activityMediaArr !== null && activityMediaArr.length >= 0 ? (
              <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
                <Grid item md={12} xs={12}>
                  <ImageBadgeComponent
                    borderRadius="20px"
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "500px"}
                    imageSrc={activityMediaArr[0]?.croppedImagePath}
                    imageType={ACTIVITY_IMAGE}
                    showDelete={false}
                    showEdit={false}
                  />
                  <Button
                    sx={{
                      background: theme.palette.common.white,
                      color: theme.palette.common.black,
                      borderRadius: "10px",
                      fontSize: "12px",
                      padding: "12px",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      "&:hover": {
                        background: theme.palette.common.white,
                      },
                    }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    <ImageIcon style={{ marginRight: "10px" }} />
                    Photos
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
                <Grid item md={12} xs={12}>
                  <ImageBadgeComponent
                    borderRadius="20px"
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "500px"}
                    imageSrc={NoImage}
                    imageType={ACTIVITY_IMAGE}
                    showDelete={false}
                    showEdit={false}
                  />
                  {isEdit && (
                    <Button
                      sx={{
                        background: theme.palette.common.white,
                        color: theme.palette.common.black,
                        borderRadius: "10px",
                        fontSize: "12px",
                        padding: "12px",
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        "&:hover": {
                          background: theme.palette.common.white,
                        },
                      }}
                      variant="contained"
                      onClick={() =>
                        updatePropertyStep(PropertySteps.ACTIVITY_PHOTOS)
                      }
                    >
                      <ImageIcon style={{ marginRight: "10px" }} />
                      Add Photos
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        ) : (
          <Box
            className="grid-container"
            sx={{ position: "relative", width: "100%" }}
          >
            {activityMediaArr !== null && activityMediaArr.length === 1 ? (
              <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
                <Grid item md={12} xs={12}>
                  <ImageBadgeComponent
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "500px"}
                    imageSrc={activityMediaArr[0]?.croppedImagePath}
                    imageType={ACTIVITY_IMAGE}
                    showDelete={false}
                    showEdit={false}
                  />
                  <Button
                    sx={{
                      background: theme.palette.common.white,
                      color: theme.palette.common.black,
                      borderRadius: "10px",
                      fontSize: "12px",
                      padding: "12px",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      "&:hover": {
                        background: theme.palette.common.white,
                      },
                    }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    <ImageIcon style={{ marginRight: "10px" }} />
                    Photos
                  </Button>
                </Grid>
              </Grid>
            ) : activityMediaArr !== null && activityMediaArr.length === 2 ? (
              <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
                <Grid item md={6} xs={12}>
                  <ImageBadgeComponent
                    borderRadius={isMobile ? "20px" : "20px 0 0 20px"}
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "500px"}
                    imageSrc={activityMediaArr[0]?.croppedImagePath}
                    imageType={ACTIVITY_IMAGE}
                    showDelete={false}
                    showEdit={false}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ImageBadgeComponent
                    borderRadius={isMobile ? "20px" : "0 20px 20px 0"}
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "500px"}
                    imageSrc={activityMediaArr[1]?.croppedImagePath}
                    imageType={ACTIVITY_IMAGE}
                    marginTop={isMobile ? "20px" : "0"}
                    showDelete={false}
                    showEdit={false}
                  />
                  <Button
                    sx={{
                      background: theme.palette.common.white,
                      color: theme.palette.common.black,
                      borderRadius: "10px",
                      fontSize: "12px",
                      padding: "12px",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      "&:hover": {
                        background: theme.palette.common.white,
                      },
                    }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    <ImageIcon style={{ marginRight: "10px" }} />
                    Photos
                  </Button>
                </Grid>
              </Grid>
            ) : activityMediaArr !== null && activityMediaArr.length > 2 ? (
              <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
                <Grid item md={8} xs={12}>
                  <ImageBadgeComponent
                    borderRadius={isMobile ? "20px" : "20px 0 0 20px"}
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "500px"}
                    imageSrc={activityMediaArr[0]?.croppedImagePath}
                    imageType={ACTIVITY_IMAGE}
                    showDelete={false}
                    showEdit={false}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <ImageBadgeComponent
                    borderRadius={isMobile ? "20px" : "0 20px 20px 0"}
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "238px"}
                    imageSrc={activityMediaArr[1]?.croppedImagePath}
                    imageType={ACTIVITY_IMAGE}
                    showDelete={false}
                    showEdit={false}
                  />
                  <ImageBadgeComponent
                    borderRadius={isMobile ? "20px" : "0 20px 20px 0"}
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "238px"}
                    imageSrc={activityMediaArr[2]?.croppedImagePath}
                    imageType={ACTIVITY_IMAGE}
                    marginTop={"24px"}
                    showDelete={false}
                    showEdit={false}
                  />
                  <Button
                    sx={{
                      background: theme.palette.common.white,
                      color: theme.palette.common.black,
                      borderRadius: "10px",
                      fontSize: "12px",
                      padding: "12px",
                      position: "absolute",
                      bottom: "20px",
                      right: "20px",
                      "&:hover": {
                        background: theme.palette.common.white,
                      },
                    }}
                    variant="contained"
                    onClick={handleOpen}
                  >
                    <ImageIcon style={{ marginRight: "10px" }} />
                    Photos
                  </Button>
                </Grid>
              </Grid>
            ) : (
              <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
                <Grid item md={12} xs={12}>
                  <ImageBadgeComponent
                    borderRadius="20px"
                    handleEditImage={() => {}}
                    height={isMobile ? "300px" : "500px"}
                    imageSrc={NoImage}
                    imageType={ACTIVITY_IMAGE}
                    showDelete={false}
                    showEdit={false}
                  />
                  {isEdit && (
                    <Button
                      sx={{
                        background: theme.palette.common.white,
                        color: theme.palette.common.black,
                        borderRadius: "10px",
                        fontSize: "12px",
                        padding: "12px",
                        position: "absolute",
                        bottom: "20px",
                        right: "20px",
                        "&:hover": {
                          background: theme.palette.common.white,
                        },
                      }}
                      variant="contained"
                      onClick={() =>
                        updatePropertyStep(PropertySteps.ACTIVITY_PHOTOS)
                      }
                    >
                      <ImageIcon style={{ marginRight: "10px" }} />
                      Add Photos
                    </Button>
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        {/* {!isMobile && 
        <Box className="grid-container" sx={{ position: "relative" }}>
          {activityMediaArr !== null && activityMediaArr.length === 1 ? (
            <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
              <Grid item md={12} xs={12}>
                <ImageBadgeComponent
                  handleEditImage={() => {}}
                  height={isMobile ? "300px" : "500px"}
                  imageSrc={activityMediaArr[0]?.croppedImagePath}
                  imageType={ACTIVITY_IMAGE}
                  showDelete={false}
                  showEdit={false}
                />
                <Button
                  sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.common.black,
                    borderRadius: "10px",
                    fontSize: "12px",
                    padding: "12px",
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    "&:hover": {
                      background: theme.palette.common.white,
                    },
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <ImageIcon style={{ marginRight: "10px" }} />
                  Photos
                </Button>
              </Grid>
            </Grid>
          ) : activityMediaArr !== null && activityMediaArr.length === 2 ? (
            <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
              <Grid item md={6} xs={12}>
                <ImageBadgeComponent
                  borderRadius={isMobile ? "20px" : "20px 0 0 20px"}
                  handleEditImage={() => {}}
                  height={isMobile ? "300px" : "500px"}
                  imageSrc={activityMediaArr[0]?.croppedImagePath}
                  imageType={ACTIVITY_IMAGE}
                  showDelete={false}
                  showEdit={false}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <ImageBadgeComponent
                  borderRadius={isMobile ? "20px" : "0 20px 20px 0"}
                  handleEditImage={() => {}}
                  height={isMobile ? "300px" : "500px"}
                  imageSrc={activityMediaArr[1]?.croppedImagePath}
                  imageType={ACTIVITY_IMAGE}
                  marginTop={isMobile ? "20px" : "0"}
                  showDelete={false}
                  showEdit={false}
                />
                <Button
                  sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.common.black,
                    borderRadius: "10px",
                    fontSize: "12px",
                    padding: "12px",
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    "&:hover": {
                      background: theme.palette.common.white,
                    },
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <ImageIcon style={{ marginRight: "10px" }} />
                  Photos
                </Button>
              </Grid>
            </Grid>
          ) : activityMediaArr !== null && activityMediaArr.length > 2 ? (
            <Grid container pt={2} spacing={{ xs: 0, md: 2 }}>
              <Grid item md={8} xs={12}>
                <ImageBadgeComponent
                  borderRadius={isMobile ? "20px" : "20px 0 0 20px"}
                  handleEditImage={() => {}}
                  height={isMobile ? "300px" : "500px"}
                  imageSrc={activityMediaArr[0]?.croppedImagePath}
                  imageType={ACTIVITY_IMAGE}
                  showDelete={false}
                  showEdit={false}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <ImageBadgeComponent
                  borderRadius={isMobile ? "20px" : "0 20px 20px 0"}
                  handleEditImage={() => {}}
                  height={isMobile ? "300px" : "238px"}
                  imageSrc={activityMediaArr[1]?.croppedImagePath}
                  imageType={ACTIVITY_IMAGE}
                  showDelete={false}
                  showEdit={false}
                />
                <ImageBadgeComponent
                  borderRadius={isMobile ? "20px" : "0 20px 20px 0"}
                  handleEditImage={() => {}}
                  height={isMobile ? "300px" : "238px"}
                  imageSrc={activityMediaArr[2]?.croppedImagePath}
                  imageType={ACTIVITY_IMAGE}
                  marginTop={"24px"}
                  showDelete={false}
                  showEdit={false}
                />
                <Button
                  sx={{
                    background: theme.palette.common.white,
                    color: theme.palette.common.black,
                    borderRadius: "10px",
                    fontSize: "12px",
                    padding: "12px",
                    position: "absolute",
                    bottom: "20px",
                    right: "20px",
                    "&:hover": {
                      background: theme.palette.common.white,
                    },
                  }}
                  variant="contained"
                  onClick={handleOpen}
                >
                  <ImageIcon style={{ marginRight: "10px" }} />
                  Photos
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Box>} */}
        {isEdit ? (
          <RenderEdit
            onClick={() => updatePropertyStep(PropertySteps.ACTIVITY_PHOTOS)}
          />
        ) : null}
      </Stack>

      <Modal fullWidth={true} maxWidth="lg" open={open}>
        <Box>
          <Container>
            <Box>
              <IconButton
                aria-label="delete"
                sx={{
                  marginTop: "20px",
                  backgroundColor: "#eaeaea",
                  height: "32px",
                  width: "32px",
                  display: "flex",
                  marginLeft: "auto",
                }}
                onClick={handleClose}
              >
                <ClearIcon />
              </IconButton>
            </Box>
            <Box sx={{ padding: isMobile ? "0 15px" : "10px 60px" }}>
              <PropertyCarousel imagesArray={activityMediaArr} />
            </Box>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export default ImageGrid;
