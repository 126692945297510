import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomDatePicker from "components/TextField/CustomDatePicker";
import CustomInputField from "components/TextField/CustomInputField";
import CustomInputHeader from "components/TextField/CustomInputHeader";
import CustomMobileInputField from "components/TextField/CustomMobileInputField";
import moment from "moment";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  updateUserEmergencyContactInfo,
  updateUserProfileInfo,
} from "store/profile/ProfileAction";
import {
  IProfileEditableData,
  IUserEmergencyContactData,
} from "store/profile/ProfileInterface";
import { unmaskUSNumber } from "utils/utils";
import {
  getAgeInYears,
  validateEmail,
  validateName,
  validatePhoneNumber,
} from "utils/validator";

function Profile() {
  const dispatch = useAppDispatch();

  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDOB, setIsEditingDOB] = useState(false);
  const [isEditingEmergencyContact, setIsEditingEmergencyContact] =
    useState(false);

  const [emergencyContact, setEmergencyContact] =
    useState<IUserEmergencyContactData>();

  const [errorMsgs, setErrorMsgs] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    emergencyContactName: "",
    emergencyContactEmail: "",
    emergencyContactPhone: "",
    emergencyContactRelationship: "",
  });

  const { userProfileData } = useAppSelector(s => s.profile);
  const { dob, firstName, lastName, emergencyContacts } = userProfileData;

  useEffect(() => {
    if (emergencyContacts && emergencyContacts.length > 0) {
      setEmergencyContact(emergencyContacts[0]);
    } else {
      setEmergencyContact(undefined);
    }
  }, [emergencyContacts]);

  const [profileEditableData, setProfileEditableData] =
    useState<IProfileEditableData>({
      firstName: firstName || "",
      lastName: lastName || "",
      dob: dob || "",
      emergencyContactName: emergencyContact?.fullName || "",
      emergencyContactEmail: emergencyContact?.email || "",
      emergencyContactPhone: emergencyContact?.phone || "",
      emergencyContactRelationship: emergencyContact?.relationship || "",
    });
  useEffect(() => {
    setProfileEditableData({
      firstName: firstName || "",
      lastName: lastName || "",
      dob: dob || "",
      emergencyContactName: emergencyContact?.fullName || "",
      emergencyContactEmail: emergencyContact?.email || "",
      emergencyContactPhone: emergencyContact?.phone || "",
      emergencyContactRelationship: emergencyContact?.relationship || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData, emergencyContact]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const tempValue = value;
    const trimValue: string = tempValue !== " " ? tempValue : tempValue.trim();
    setProfileEditableData({ ...profileEditableData, [name]: trimValue });
    setErrorMsgs({ ...errorMsgs, [name]: "" });
  };

  const handleDOBValidation = (dob: string) => {
    const age = getAgeInYears(dob);
    let errMsg = "";
    if (Number.isNaN(age)) {
      errMsg = "Please enter a valid date";
    } else if (age < 18) {
      errMsg = "Age should be above 18 years";
    } else if (age > 100) {
      errMsg = "Date of Birth cannot be more than 100 years";
    }
    return errMsg;
  };

  const handleInputDateChange = (newValue: Date | null) => {
    const dob: string = moment(newValue).format("MM/DD/YYYY");

    setProfileEditableData({ ...profileEditableData, dob: dob });
    const errMsg = handleDOBValidation(dob);
    setErrorMsgs({ ...errorMsgs, dob: errMsg });
  };

  const handleInputValidations = (inputValue: string, fieldName: string) => {
    const value = inputValue.trim();
    let minLength = 1;
    let maxLength = 35;
    if (fieldName === "emergencyContactName") {
      minLength = 2;
      maxLength = 70;
    }
    let errorMsg = "";
    if (
      fieldName === "firstName" ||
      fieldName === "lastName" ||
      fieldName === "emergencyContactName"
    ) {
      if (
        value.length === 0 ||
        !(value.length >= minLength && value.length <= maxLength) ||
        !validateName(value)
      ) {
        errorMsg = `Name must be between ${minLength}-${maxLength} characters, contain ${minLength} ${
          minLength === 1 ? "letter" : "letters"
        }, and may contain no special characters except hyphen (-) or apostrophe.`;
      }
    }
    if (fieldName === "emergencyContactRelationship") {
      if (
        value.length === 0 ||
        !(value.length >= minLength && value.length <= maxLength)
      ) {
        errorMsg = "Relationship must be between 1-35 characters";
      }
    }
    return errorMsg;
  };

  const handleEmailValidation = (email: string) => {
    let emailError = "";
    if (email && email.trim()) {
      const isEmailValid = validateEmail(email);
      if (!isEmailValid) {
        emailError = "Please enter valid email address";
      }
    } else {
      emailError = "Email is required";
    }
    return emailError;
  };
  const handlePhoneNumberValidation = (phoneNumber: string) => {
    let phoneError = "";
    if (phoneNumber && phoneNumber.trim()) {
      const isPhoneValid = validatePhoneNumber(phoneNumber);
      if (!isPhoneValid) {
        phoneError = "Please enter valid phone number";
      }
    } else {
      phoneError = "Phone number is required";
    }
    return phoneError;
  };

  const updateProfileInfo = async (section: string) => {
    switch (section) {
      case "name": {
        const firstName = profileEditableData.firstName?.trim() || "";
        const lastName = profileEditableData.lastName?.trim() || "";

        const fNameError = handleInputValidations(firstName, "firstName");
        const lNameError = handleInputValidations(lastName, "lastName");

        setErrorMsgs({
          ...errorMsgs,
          firstName: fNameError,
          lastName: lNameError,
        });

        if (!fNameError && !lNameError) {
          dispatch(
            updateUserProfileInfo({
              firstName,
              lastName,
            }),
          );
          setIsEditingName(false);
        }
        break;
      }
      case "dob": {
        if (profileEditableData.dob) {
          const errMsg = handleDOBValidation(profileEditableData.dob);
          setErrorMsgs({ ...errorMsgs, dob: errMsg });
          if (!errMsg) {
            dispatch(
              updateUserProfileInfo({
                dob: profileEditableData.dob,
              }),
            );
            setIsEditingDOB(false);
          }
        } else {
          setErrorMsgs({
            ...errorMsgs,
            dob: "Please enter Date of Birth",
          });
        }
        break;
      }
      case "emergencyContact": {
        const fullName = profileEditableData.emergencyContactName.trim();
        const relationship =
          profileEditableData.emergencyContactRelationship.trim();

        const nameError = handleInputValidations(
          fullName,
          "emergencyContactName",
        );
        const relationshipError = handleInputValidations(
          relationship,
          "emergencyContactRelationship",
        );

        const emailError = handleEmailValidation(
          profileEditableData.emergencyContactEmail,
        );

        const phoneError = handlePhoneNumberValidation(
          unmaskUSNumber(profileEditableData.emergencyContactPhone),
        );

        const phoneNumber = unmaskUSNumber(
          profileEditableData.emergencyContactPhone,
        );
        setErrorMsgs({
          ...errorMsgs,
          emergencyContactName: nameError,
          emergencyContactEmail: emailError,
          emergencyContactPhone: phoneError,
          emergencyContactRelationship: relationshipError,
        });

        if (!nameError && !relationshipError && !emailError && !phoneError) {
          const payload = {
            fullName: profileEditableData.emergencyContactName?.trim(),
            relationship:
              profileEditableData.emergencyContactRelationship?.trim(),
            phone: `${phoneNumber}`,
            email: profileEditableData.emergencyContactEmail?.trim(),
          };
          dispatch(updateUserEmergencyContactInfo(payload));
          setIsEditingEmergencyContact(false);
        }
        break;
      }
      default:
      // code block
    }
  };

  const handleOnChange = (value: any) => {
    setProfileEditableData({
      ...profileEditableData,
      emergencyContactPhone: value,
    });
    setErrorMsgs({ ...errorMsgs, emergencyContactPhone: "" });
  };

  return (
    <>
      <Stack alignItems="start" justifyContent="center" spacing={2}>
        <Typography alignItems="center" component="div" variant="h4">
          Profile
        </Typography>
        <Divider sx={{ width: "100%" }} />
        <CustomInputHeader
          closeEditing={() => {
            setProfileEditableData({
              ...profileEditableData,
              firstName,
              lastName,
            });
            setIsEditingName(false);
            setErrorMsgs({ ...errorMsgs, firstName: "", lastName: "" });
          }}
          isEditing={isEditingName}
          label="Name"
          saveEditValue={() => {
            // setIsEditingName(false);
            updateProfileInfo("name");
          }}
          toEdit={setIsEditingName}
        />

        {isEditingName ? (
          <>
            <CustomInputField
              autoComplete="off"
              error={errorMsgs.firstName.length > 0}
              errormessage={errorMsgs.firstName}
              inputProps={{ maxLength: 35 }}
              label="First Name"
              name="firstName"
              type="text"
              value={profileEditableData.firstName}
              onBlur={() => {
                const errMsg = handleInputValidations(
                  profileEditableData.firstName ?? "",
                  "firstName",
                );
                setErrorMsgs({ ...errorMsgs, firstName: errMsg });
              }}
              onChange={handleInputChange}
            />
            <CustomInputField
              autoComplete="off"
              error={errorMsgs.lastName.length > 0}
              errormessage={errorMsgs.lastName}
              inputProps={{ maxLength: 35 }}
              label="Last Name"
              name="lastName"
              type="text"
              value={profileEditableData.lastName}
              onBlur={() => {
                const errMsg = handleInputValidations(
                  profileEditableData.lastName ?? "",
                  "lastName",
                );
                setErrorMsgs({ ...errorMsgs, lastName: errMsg });
              }}
              onChange={handleInputChange}
            />
          </>
        ) : (
          <>
            <Typography component="span" variant="h5">
              {`${firstName ?? ""} ${lastName ?? ""}`}
            </Typography>
          </>
        )}
        <Divider sx={{ width: "100%" }} />
        <CustomInputHeader
          closeEditing={() => {
            setProfileEditableData({ ...profileEditableData, dob });
            setIsEditingDOB(false);
            setErrorMsgs({ ...errorMsgs, dob: "" });
          }}
          isEditing={isEditingDOB}
          label="Date of Birth (MM/DD/YYYY)"
          saveEditValue={() => {
            updateProfileInfo("dob");
          }}
          toEdit={setIsEditingDOB}
        />
        {isEditingDOB ? (
          <CustomDatePicker
            disableFuture
            disabled={!isEditingDOB}
            DOB={
              profileEditableData.dob
                ? new Date(profileEditableData.dob)
                : new Date()
            }
            errorMessage={errorMsgs.dob}
            label=""
            onChange={handleInputDateChange}
          />
        ) : (
          <>
            <Typography component="span" variant="h5">
              {dob ? moment(dob).format("MM/DD/YYYY") : ""}
            </Typography>
          </>
        )}

        <Divider sx={{ width: "100%" }} />

        {/* Emergency Contact */}
        <CustomInputHeader
          closeEditing={() => {
            setProfileEditableData({
              ...profileEditableData,
              emergencyContactName: emergencyContact?.fullName || "",
              emergencyContactEmail: emergencyContact?.email || "",
              emergencyContactPhone: emergencyContact?.phone || "",
              emergencyContactRelationship:
                emergencyContact?.relationship || "",
            });
            setIsEditingEmergencyContact(false);
            setErrorMsgs({
              ...errorMsgs,
              emergencyContactName: "",
              emergencyContactEmail: "",
              emergencyContactPhone: "",
              emergencyContactRelationship: "",
            });
          }}
          isEditing={isEditingEmergencyContact}
          label="Emergency Contact"
          saveEditValue={() => {
            updateProfileInfo("emergencyContact");
          }}
          toEdit={setIsEditingEmergencyContact}
        />
        {isEditingEmergencyContact ? (
          <>
            <CustomInputField
              autoComplete="off"
              error={errorMsgs.emergencyContactName.length > 0}
              errormessage={errorMsgs.emergencyContactName}
              inputProps={{ maxLength: 70 }}
              label="Full Name"
              name="emergencyContactName"
              type="text"
              value={profileEditableData.emergencyContactName}
              onBlur={() => {
                const errMsg = handleInputValidations(
                  profileEditableData.emergencyContactName ?? "",
                  "emergencyContactName",
                );
                setErrorMsgs({ ...errorMsgs, emergencyContactName: errMsg });
              }}
              onChange={handleInputChange}
            />
            <CustomInputField
              autoComplete="off"
              error={errorMsgs.emergencyContactRelationship.length > 0}
              errormessage={errorMsgs.emergencyContactRelationship}
              inputProps={{ maxLength: 35 }}
              label="Relationship"
              name="emergencyContactRelationship"
              type="text"
              value={profileEditableData.emergencyContactRelationship}
              onBlur={() => {
                const errMsg = handleInputValidations(
                  profileEditableData.emergencyContactRelationship ?? "",
                  "emergencyContactRelationship",
                );
                setErrorMsgs({
                  ...errorMsgs,
                  emergencyContactRelationship: errMsg,
                });
              }}
              onChange={handleInputChange}
            />
            <CustomMobileInputField
              autoComplete="off"
              error={errorMsgs.emergencyContactPhone.length > 0}
              errormessage={errorMsgs.emergencyContactPhone}
              inputProps={{ maxLength: 14 }}
              label="Phone"
              name="emergencyContactPhone"
              placeholder="(xxx) xxx-xxxx"
              value={profileEditableData.emergencyContactPhone}
              onBlur={() => {
                const errMsg = handlePhoneNumberValidation(
                  unmaskUSNumber(profileEditableData.emergencyContactPhone),
                );
                setErrorMsgs({ ...errorMsgs, emergencyContactPhone: errMsg });
              }}
              onChange={handleOnChange}
            />
            <CustomInputField
              autoComplete="off"
              error={errorMsgs.emergencyContactEmail.length > 0}
              errormessage={errorMsgs.emergencyContactEmail}
              label="Email"
              name="emergencyContactEmail"
              type="email"
              value={profileEditableData.emergencyContactEmail}
              onBlur={() => {
                const errMsg = handleEmailValidation(
                  profileEditableData.emergencyContactEmail,
                );
                setErrorMsgs({ ...errorMsgs, emergencyContactEmail: errMsg });
              }}
              onChange={event => handleInputChange(event)}
            />
          </>
        ) : (
          <>
            <Typography component="span" variant="h5">
              {emergencyContact?.fullName}
            </Typography>
          </>
        )}
        <Divider sx={{ width: "100%" }} />
      </Stack>
    </>
  );
}

export default Profile;
