import { DialogTitle } from "@mui/material";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/material/styles";
import { useIsMobile } from "hooks/useIsMobile";
import React from "react";

import ModalHeader, { IModalHeaderProps } from "./ModalHeader";

interface IModalProps extends IModalHeaderProps, DialogProps {
  open: boolean;
  fullWidth?: boolean;
  children: React.ReactNode | JSX.Element;
  showHeader?: boolean;
}

interface ICustomDialogProps extends DialogProps {
  ismobile: number;
}

const CustomDialog = styled(Dialog)<ICustomDialogProps>(({ ismobile }) => ({
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      borderRadius: ismobile ? "0px" : "20px",
    },
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
  "& .MuiDialogContent-root": {
    padding: 0,
  },
  "& .MuiDialogActions-root": {
    padding: 0,
  },
}));

function Modal(props: IModalProps) {
  const {
    children,
    handleBack,
    handleClose,
    showBackIcon = false,
    showCloseIcon = false,
    showHeader = false,
    title = "",
    ...rest
  } = props;

  const isMobile = useIsMobile();

  return (
    <CustomDialog
      disableEnforceFocus
      fullScreen={!!isMobile}
      ismobile={+isMobile}
      sx={{ zIndex: isMobile ? 1306 : 1300 }}
      onClose={() => {}}
      {...rest}
    >
      {showHeader && (
        <DialogTitle id="scroll-dialog-title">
          <ModalHeader
            handleBack={handleBack}
            handleClose={handleClose}
            showBackIcon={showBackIcon}
            showCloseIcon={showCloseIcon}
            title={title}
          />
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </CustomDialog>
  );
}

export default Modal;
