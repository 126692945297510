import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import { WingItWhiteLogo } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { useNavigate } from "react-router-dom";
import theme from "theme";

function LeftHeader() {
  const { goToHome } = useNavigateStep();
  const isMobile = useIsMobile("sm");
  const navigate = useNavigate();

  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      justifyContent="space-between"
      sx={{ px: 2, py: isMobile ? 1 : 2 }}
    >
      <Box>
        <IconButton sx={{ p: 0 }} onClick={() => navigate(-1)}>
          <CloseIcon sx={{ color: theme.palette.common.white }} />
        </IconButton>
      </Box>
      <Box onClick={goToHome}>
        <SvgIcon
          inheritViewBox
          component={WingItWhiteLogo}
          sx={{
            cursor: "pointer",
            // height: { sm: "36px", xs: "20px" },
            // width: { sm: "135px", xs: "80px" },
          }}
        />
      </Box>
    </Stack>
  );
}

export default LeftHeader;
