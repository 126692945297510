import { Range } from "react-date-range";

import { PropertyType } from "./PropertyTypes";

interface IUpdatePropertyStep {
  type: PropertyType.UPDATE_PROPERTY_STEP;
  payload: number;
}

interface ICreatePropertyRequest {
  type: PropertyType.CREATE_PROPERTY_REQUEST;
}
interface ICreatePropertySuccess {
  type: PropertyType.CREATE_PROPERTY_SUCCESS;
  payload: IProperty;
}
interface ICreatePropertyError {
  type: PropertyType.CREATE_PROPERTY_ERROR;
}

interface IClearPropertyData {
  type: PropertyType.CLEAR_PROPERTY_DATA;
}

interface IClearFoundPropertyData {
  type: PropertyType.CLEAR_FOUND_PROPERTY_DATA;
}

interface IClaimPropertyRequest {
  type: PropertyType.CLAIM_PROPERTY_REQUEST;
}
interface IClaimPropertySuccess {
  type: PropertyType.CLAIM_PROPERTY_SUCCESS;
}
interface IClaimPropertyError {
  type: PropertyType.CLAIM_PROPERTY_ERROR;
}

interface IUpdatePropertyRequest {
  type: PropertyType.UPDATE_PROPERTY_REQUEST;
}

interface IUpdatePropertySuccess {
  type: PropertyType.UPDATE_PROPERTY_SUCCESS;
  payload: IProperty;
}

interface IUpdatePropertyError {
  type: PropertyType.UPDATE_PROPERTY_ERROR;
}

interface IManageMarkersRequest {
  type: PropertyType.MANAGE_MARKERS_REQUEST;
}

interface IManageMarkersSuccess {
  type: PropertyType.MANAGE_MARKERS_SUCCESS;
  payload: IProperty;
}

interface IManageMarkersError {
  type: PropertyType.MANAGE_MARKERS_ERROR;
}
interface IGetAllPropertiesRequest {
  type: PropertyType.GET_ALL_PROPERTIES_REQUEST;
}

interface IGetAllPropertiesSuccess {
  type: PropertyType.GET_ALL_PROPERTIES_SUCCESS;
  payload: IProperty[];
}

interface IGetAllPropertiesError {
  type: PropertyType.GET_ALL_PROPERTIES_ERROR;
}

interface IGetPropertyDetailsRequest {
  type: PropertyType.GET_PROPERTY_DETAILS_REQUEST;
}

interface IGetPropertyDetailsSuccess {
  type: PropertyType.GET_PROPERTY_DETAILS_SUCCESS;
  payload: IProperty;
}

interface IGetPropertyDetailsError {
  type: PropertyType.GET_PROPERTY_DETAILS_ERROR;
}

interface ISetActivityType {
  type: PropertyType.SET_ACTIVITY_TYPE;
  payload: string;
}

interface ISetActivityDetail {
  type: PropertyType.SET_ACTIVITY_DETAIL;
  payload: string;
}

interface IShowDateRangeCalender {
  type: PropertyType.SHOW_DATE_RANGE_CALENDER;
  payload: boolean;
}

interface IAddActivityDateRequest {
  type: PropertyType.ADD_ACTIVITY_DATE_REQUEST;
}
interface IAddActivityDateSuccess {
  type: PropertyType.ADD_ACTIVITY_DATE_SUCCESS;
  payload: IActivityDate;
}
interface IAddActivityDateError {
  type: PropertyType.ADD_ACTIVITY_DATE_ERROR;
}

interface ISetActiveEditIndex {
  type: PropertyType.SET_EDIT_ACTIVE_INDEX;
  payload: { index: number | undefined; editActive: boolean };
}

interface IEditActivityDateRequest {
  type: PropertyType.EDIT_ACTIVITY_DATE_REQUEST;
}
interface IEditActivityDateSuccess {
  type: PropertyType.EDIT_ACTIVITY_DATE_SUCCESS;
  payload: IActivityDate;
}
interface IEditActivityDateError {
  type: PropertyType.EDIT_ACTIVITY_DATE_ERROR;
}

interface IDeleteActivityDateRequest {
  type: PropertyType.DELETE_ACTIVITY_DATE_REQUEST;
}
interface IDeleteActivityDateSuccess {
  type: PropertyType.DELETE_ACTIVITY_DATE_SUCCESS;
  payload: string;
}
interface IDeleteActivityDateError {
  type: PropertyType.DELETE_ACTIVITY_DATE_ERROR;
}

interface IGetActivitiesRequest {
  type: PropertyType.GET_ACTIVITIES_REQUEST;
}

interface IGetActivitiesSuccess {
  type: PropertyType.GET_ACTIVITIES_SUCCESS;
  payload: IActivity[];
}

interface IGetActivitiesError {
  type: PropertyType.GET_ACTIVITIES_ERROR;
}

interface IGetActivityDetailsRequest {
  type: PropertyType.GET_ACTIVITY_DETAILS_REQUEST;
}

interface IGetActivityDetailsSuccess {
  type: PropertyType.GET_ACTIVITY_DETAILS_SUCCESS;
  payload: IActivity;
}

interface IGetActivityDetailsError {
  type: PropertyType.GET_ACTIVITY_DETAILS_ERROR;
}

interface ICreatePropertyActivityRequest {
  type: PropertyType.CREATE_PROPERTY_ACTIVITY_REQUEST;
}

interface ICreatePropertyActivitySuccess {
  type: PropertyType.CREATE_PROPERTY_ACTIVITY_SUCCESS;
  payload: any;
}

interface ICreatePropertyActivityError {
  type: PropertyType.CREATE_PROPERTY_ACTIVITY_ERROR;
}

interface IAddActivityImageRequest {
  type: PropertyType.ADD_ACTIVITY_IMAGE_REQUEST;
}
interface IAddActivityImageSuccess {
  type: PropertyType.ADD_ACTIVITY_IMAGE_SUCCESS;
  payload: any;
}
interface IAddActivityImageError {
  type: PropertyType.ADD_ACTIVITY_IMAGE_ERROR;
}

interface IEditActivityImageRequest {
  type: PropertyType.EDIT_ACTIVITY_IMAGE_REQUEST;
}
interface IEditActivityImageSuccess {
  type: PropertyType.EDIT_ACTIVITY_IMAGE_SUCCESS;
  payload: any;
}
interface IEditActivityImageError {
  type: PropertyType.EDIT_ACTIVITY_IMAGE_ERROR;
}

interface IDeleteActivityImageRequest {
  type: PropertyType.DELETE_ACTIVITY_IMAGE_REQUEST;
}
interface IDeleteActivityImageSuccess {
  type: PropertyType.DELETE_ACTIVITY_IMAGE_SUCCESS;
  payload: string;
}
interface IDeleteActivityImageError {
  type: PropertyType.DELETE_ACTIVITY_IMAGE_ERROR;
}

interface IUpdateAreaActivityRequest {
  type: PropertyType.UPDATE_AREA_ACTIVITY_REQUEST;
}

interface IUpdateAreaActivitySuccess {
  type: PropertyType.UPDATE_AREA_ACTIVITY_SUCCESS;
  payload: IAreaActivity;
}

interface IUpdateAreaActivityError {
  type: PropertyType.UPDATE_AREA_ACTIVITY_ERROR;
}

interface ISetEditingProperty {
  type: PropertyType.SET_EDITING_PROPERTY;
  payload: boolean;
}

interface IUpdatePropertyFieldsRequest {
  type: PropertyType.UPDATE_PROPERTY_FIELDS_REQUEST;
}

interface IUpdatePropertyFieldsSuccess {
  type: PropertyType.UPDATE_PROPERTY_FIELDS_SUCCESS;
  payload: any;
}

interface IUpdatePropertyFieldsError {
  type: PropertyType.UPDATE_PROPERTY_FIELDS_ERROR;
}

interface IGetPropertyAvailableDatesRequest {
  type: PropertyType.GET_PROPERTY_AVAILABLE_DATES_REQUEST;
}

interface IGetPropertyAvailableDatesSuccess {
  type: PropertyType.GET_PROPERTY_AVAILABLE_DATES_SUCCESS;
  payload: any;
}

interface IGetPropertyAvailableDatesError {
  type: PropertyType.GET_PROPERTY_AVAILABLE_DATES_ERROR;
}

interface IUpdatePropertyStatusRequest {
  type: PropertyType.UPDATE_PROPERTY_STATUS_REQUEST;
}

interface IUpdatePropertyStatusSuccess {
  type: PropertyType.UPDATE_PROPERTY_STATUS_SUCCESS;
  payload: IProperty;
}

interface IUpdatePropertyStatusError {
  type: PropertyType.UPDATE_PROPERTY_STATUS_ERROR;
}

export type PropertyActions =
  | IUpdatePropertyStep
  | ICreatePropertyRequest
  | ICreatePropertySuccess
  | ICreatePropertyError
  | IClearPropertyData
  | IClearFoundPropertyData
  | IClaimPropertyRequest
  | IClaimPropertySuccess
  | IClaimPropertyError
  | IUpdatePropertyRequest
  | IUpdatePropertySuccess
  | IUpdatePropertyError
  | IManageMarkersRequest
  | IManageMarkersSuccess
  | IManageMarkersError
  | IGetAllPropertiesRequest
  | IGetAllPropertiesSuccess
  | IGetAllPropertiesError
  | IGetPropertyDetailsRequest
  | IGetPropertyDetailsSuccess
  | IGetPropertyDetailsError
  | IGetActivitiesRequest
  | IGetActivitiesSuccess
  | IGetActivitiesError
  | IGetActivityDetailsRequest
  | IGetActivityDetailsSuccess
  | IGetActivityDetailsError
  | ISetActivityType
  | ISetActivityDetail
  | IShowDateRangeCalender
  | IAddActivityDateRequest
  | IAddActivityDateSuccess
  | IAddActivityDateError
  | ISetActiveEditIndex
  | IEditActivityDateRequest
  | IEditActivityDateSuccess
  | IEditActivityDateError
  | IDeleteActivityDateRequest
  | IDeleteActivityDateSuccess
  | IDeleteActivityDateError
  | ICreatePropertyActivityRequest
  | ICreatePropertyActivitySuccess
  | ICreatePropertyActivityError
  | IAddActivityImageRequest
  | IAddActivityImageSuccess
  | IAddActivityImageError
  | IEditActivityImageRequest
  | IEditActivityImageSuccess
  | IEditActivityImageError
  | IDeleteActivityImageRequest
  | IDeleteActivityImageSuccess
  | IDeleteActivityImageError
  | IUpdateAreaActivityRequest
  | IUpdateAreaActivitySuccess
  | IUpdateAreaActivityError
  | ISetEditingProperty
  | IUpdatePropertyFieldsRequest
  | IUpdatePropertyFieldsSuccess
  | IUpdatePropertyFieldsError
  | IGetPropertyAvailableDatesRequest
  | IGetPropertyAvailableDatesSuccess
  | IGetPropertyAvailableDatesError
  | IUpdatePropertyStatusRequest
  | IUpdatePropertyStatusSuccess
  | IUpdatePropertyStatusError;

export type IPropertyState = {
  propertyStep: number;
  propertyInfo: IProperty;
  isLoading: boolean;
  isUpdating: boolean;
  allProperties: IProperty[];
  selectedArea: IArea;
  selectedAreaActivity: IAreaActivity | undefined;

  activities: IActivity[];
  selectedActivity: string;
  selectedActivityDetail: string;
  showRangeCalender: boolean;
  activityDates: IActivityDate[];
  activeEditRangeIndex: number | undefined;
  editActive: boolean;
  loading: boolean;
  activityMediaArr: IActivityMedia[];
  isPropertyEditing: boolean;
  availableDates: Date[];
};

export interface IAddPhoto {
  title: string;
  description: string;
  file: File | null;
}

export interface IAddress {
  name: string;
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface IMailingAddress {
  mailingAddress: IAddress;
}

export interface IPropertyDetailsState {
  propertyName: string;
  description: string;
  contactOwnerName: string;
  propertyAddress: IAddress;
}

export interface IPropertyRulesState {
  rules: string[];
}

export interface IUpdatePropertyState {
  name?: string;
  contactOwnerName?: string;
  description?: string;
  someoneLiving?: boolean;
  isPropertyMailingAddressSame?: boolean;
  lastStep?: string;
  propertyStatus?: string;
  mailingAddress?: IAddress;
  address1?: string;
  bookingDetails?: string;
  refund?: boolean;

  city?: string;
  state?: string;
  zip?: string;
}

/* Map Interfaces */
export interface ISearchAPIResponse {
  attribution: string;
  features: IPlace[];
  query: string[];
  type: string;
}
export interface IPlace {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: IPlaceProperty;
  text_en: string;
  language_en: string;
  place_name_en: string;
  text: string;
  language: string;
  place_name: string;
  bbox: number[];
  center: number[];
  geometry: IGeometry;
  context: IContext[];
}
interface IPlaceProperty {
  short_code: string;
  wikidata: string;
}
interface IContext {
  id: string;
  wikidata: string;
  short_code: string;
  text_en: string;
  language_en: string;
  text: string;
  language: string;
}

interface IGeometry {
  type: string;
  coordinates: number[];
}

export interface IPropertyRequest {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  lat: string;
  lon: string;
  ip?: string;
}
export interface IContextAddress {
  id: string;
  text: string;
}

export interface IProperty {
  documentId: string;
  name: string;
  contactOwnerName: string;
  description: string;
  remoteIp: string;
  outline: string;
  claimed: boolean;
  bookingDetails: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  parcelCount: number;
  totalAcres: number;
  calcAcres: number;
  owner: string;
  someoneLiving: boolean;
  isPropertyAndMailingAddressSame: boolean;
  mailingAddress: IAddress;
  area: IArea[];
  markers: IMarker[] | null;
  lastStep: string;
  propertyStatus: string;
  lat: string;
  lng: string;
  activityNames: string;
  endDate: string;
  startDate: string;
  rules: string;
  createdAt: string;
  publishedAt: string;
}

export interface IMarker {
  latitude: number;
  longitude: number;
  label: string;
  notes: string;
  point: string;
  type: string;
  overlay: boolean;
  gateCode?: string;
  parkingSpots?: number;
}

export interface IArea {
  name: string;
  description: string;
  outline: string;
  type: string;
  overlay: boolean;
  documentId?: string;
  propertyId: string;
  activities: IAreaActivity[];
}

export interface IAreaActivity {
  activityId: string;
  areaActivityDate: IActivityDate[];
  areaActivityMedia: IActivityMedia[];
  areaId: string;
  description: string;
  documentId: string;
  maxParticipants: number;
  name: string;
  price: number;
  pricePerUser: number;
  propertyID: string;
  bookingNotes: string;
  originalPrice: number;
  markUpPrice: number;
}

export enum ImageType {
  ACTIVITY_IMAGE = "activity_image",
}

export type IActivityState = {
  loading: boolean;
  activities: IActivity[];
  selectedActivity: string;
  selectedActivityDetail: string;
  showRangeCalender: boolean;
  activityDates: IActivityDate[];
  activeEditRangeIndex: number | undefined;
  editActive: boolean;
};

export interface IActivity {
  activityName: string;
  exclusive?: boolean;
  activityId: string;
  details?: IActivityDetails[];
}

export interface IActivityDetails {
  name: string;
  description: string;
  activityId: string;
  detailId: string;
}

export interface IActivityDate extends Range {
  name: string;
  documentId?: string;
  impNotes?: string;
}

export interface IActivityMedia {
  areaActivityId: string;
  croppedImageDetails: any;
  croppedImagePath: string;
  documentId: string;
  originalImagePath: string;
}
