import RoomIcon from "@mui/icons-material/Room";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomInputField from "components/TextField/CustomInputField";
import { useState } from "react";
import theme from "theme";

interface IExclusionAreaProps {
  handleSave: (marker: any) => void;
  handleDelete: () => void;
  area: any;
}

function ExclusionArea({
  handleSave,
  handleDelete,
  area,
}: IExclusionAreaProps) {
  const [exclusionAreaData, setExclusionAreaData] = useState({
    name: area.name,
    description: area.description,
    type: area.type,
    overlay: true,
    documentId: area.documentId,
    outline: area.outline,
  });

  const [labelErrorMsg, setLabelErrorMsg] = useState("");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const tempValue = value;
    const trimValue: string = tempValue !== " " ? tempValue : tempValue.trim();
    setExclusionAreaData({ ...exclusionAreaData, [name]: trimValue });
  };

  const handleOnSave = () => {
    if (exclusionAreaData.name) {
      handleSave(exclusionAreaData);
    } else {
      setLabelErrorMsg("Name is required");
    }
  };

  return (
    <Box>
      <Stack alignItems="flex-start" justifyContent="flex-start" spacing={2}>
        <Stack alignItems="center" direction="row" sx={{ width: "100%" }}>
          <RoomIcon
            style={{ fontSize: "30px", color: theme.palette.text.primary }}
          />
          <Typography style={{ fontSize: "24px", fontWeight: 700 }}>
            Exclusion Area
          </Typography>
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <CustomInputField
          autoComplete="off"
          error={labelErrorMsg.length > 0}
          errormessage={labelErrorMsg}
          inputProps={{ maxLength: 35 }}
          label="Choose a name for exclusion area"
          name="name"
          type="text"
          value={exclusionAreaData.name}
          onChange={handleInputChange}
          onFocus={() => {
            setLabelErrorMsg("");
          }}
        />

        <CustomInputField
          multiline
          autoComplete="off"
          label="Write a description (optional)"
          minRows={5}
          name="description"
          type="textarea"
          value={exclusionAreaData.description}
          onChange={handleInputChange}
        />
        <Divider sx={{ width: "100%" }} />
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: theme.spacing(2), width: "100%" }}
        >
          <Button
            sx={{ width: "50%" }}
            variant="outlined"
            onClick={handleDelete}
          >
            Delete
          </Button>
          <Button
            color="secondary"
            sx={{ width: "50%" }}
            variant="contained"
            onClick={handleOnSave}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ExclusionArea;
