import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import ToggleDrawerDialog from "components/customDrawer/ToggleDrawerDialog";
import ImageBadgeComponent from "components/image/ImageBadgeComponent";
import UploadImageComponent from "components/image/UploadImageComponent";
import ImageCropper from "components/imageCropper/ImageCropper";
import Loader from "components/loader";
import ModalHeader from "components/Modal/ModalHeader";
import { propertyTitles } from "constants/constants";
import useNavigateStep from "hooks/useNavigateStep";
import { useState } from "react";
import {
  addActivityImage,
  alertError,
  deleteActivityImage,
  ImageType,
  IResponseData,
  PropertySteps,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { dataURItoBlob } from "utils/utils";
import {
  validateFileFormat,
  validateFileResolution,
  validateFileSize,
} from "utils/validator";

import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";
import UploadPhoto from "./UploadPhoto";

const validImageWidth = 640;
const validImageHeight = 480;

const { ACTIVITY_IMAGE } = ImageType;

export default function ActivityPhotos() {
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigateStep();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const activityStepTitles = propertyTitles[PropertySteps.ACTIVITY_PHOTOS];
  const { selectedAreaActivity, activityMediaArr, propertyInfo, loading } =
    useAppSelector(s => s.property);

  const toggleShowModal = () => setShowModal(prev => !prev);

  const [upload, setUpload] = useState<any>({
    editImage: false,
    file: null,
    previewImage: "",
  });
  const [selectedImage, setSelectedImage] = useState<any>({
    croppedImageReadUrl: "",
    documentId: "",
    originalImageReadUrl: "",
    croppedImageDetails: "",
  });

  const handleEdit = () => {
    setSelectedImage({});
    setUpload({ ...upload, editImage: true });
  };

  const selectFile =
    () => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];
      const isImage: boolean = file.type.includes("image");
      if (target?.files?.length === 1 && isImage) {
        const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;
        if (isImage && !validateFileFormat(file.name, allowedExtensions)) {
          dispatch(
            alertError({
              message:
                "File format is not supported. Please select one of the following file types: jpg, jpeg, png or webp.",
            }),
          );
          return;
        }
        const maxSize = 100;
        const isFileSizeValid: boolean = validateFileSize(file.size, maxSize);

        const isFileResolutionValid = await validateFileResolution(
          file,
          validImageWidth,
          validImageHeight,
        );
        if (isFileSizeValid && isFileResolutionValid) {
          setUpload({
            ...upload,
            previewImage: URL.createObjectURL(file),
            file,
            editImage: true,
          });
        } else {
          const errorMessage = !isFileSizeValid
            ? `The image file must be ${maxSize} MB or less.`
            : `The image resolution must be ${validImageWidth} * ${validImageHeight} or higher.`;
          dispatch(
            alertError({
              message: errorMessage,
            }),
          );
        }
      } else {
        dispatch(
          alertError({
            message:
              "File format is not supported. Please select an image and try again.",
          }),
        );
      }
    };

  const handleApply = async (cropper: any, minZoom: number, zoom: number) => {
    setUpload({ ...upload, editImage: false });
    const croppedCanvas = cropper.getCroppedCanvas().toDataURL("image/jpeg");
    const blob = dataURItoBlob(croppedCanvas);
    const croppedFile = new File([blob], "croppedImage.jpeg");
    let newFile: Blob | null = null;
    const createFile = async () => {
      try {
        const existingImage = selectedImage?.originalImageReadUrl ?? "";
        const response = await fetch(existingImage);
        const data = await response.blob();
        const metadata = {
          type: "image/jpeg",
        };
        const file = new File([data], "activityImage.jpeg", metadata);
        // ... do something with the file or return it
        return file;
      } catch (error) {
        dispatch(
          alertError({
            message: `Error while uploading activity image, Please try again later`,
          }),
        );
        return null;
      }
    };
    if (upload.file === null) {
      newFile = await createFile();
      if (newFile === null) return;
    }
    const uploadImageData = {
      image: upload.file || newFile,
      croppedFile,
      data: cropper.getData(false),
      canvasData: cropper.getCanvasData(),
      cropBoxData: cropper.getCropBoxData(),
      minZoom,
      zoom,
    };
    if (selectedImage.documentId) {
      // dispatch(
      //   updateBusinessImage(uploadImageData, selectedImage.documentId),
      // ).then((response: IResponseData) => {
      //   if (!response.error) {
      //     handleCancelUploadImage();
      //   }
      // });
    } else {
      dispatch(
        addActivityImage(
          uploadImageData,
          selectedAreaActivity?.documentId || "",
        ),
      ).then((response: IResponseData) => {
        if (!response.error) {
          handleCancelUploadImage();
        }
      });
    }
  };

  const handleCancelUploadImage = () => {
    setUpload({
      ...upload,
      previewImage: "",
      file: null,
      editImage: false,
    });
    setSelectedImage({});
  };

  const handleDelete = async (imageDocumentId: string) => {
    await dispatch(deleteActivityImage(imageDocumentId));
  };

  const handleNext = async () => {
    await dispatch(
      updatePropertyDetails(
        {
          lastStep:
            parseInt(propertyInfo.lastStep, 10) > PropertySteps.ACTIVITY_PHOTOS
              ? propertyInfo.lastStep
              : PropertySteps.ACTIVITY_PHOTOS.toString(),
        },
        propertyInfo?.documentId,
      ),
    );
    navigateTo(PropertySteps.REVIEW);
  };

  return (
    <>
      {loading && (
        <>
          <Box
            sx={{
              position: "fixed",
              height: "100%",
              width: "75%",
              left: "25%",
              zIndex: 99,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            }}
          >
            <Loader />
          </Box>
        </>
      )}
      <RightMainComponentWrapper>
        {upload.editImage && (
          <ImageCropper
            enableZoom
            footer
            header
            aspectRatio={NaN}
            croppedImageData={
              selectedImage?.croppedImageDetails
                ? JSON.parse(selectedImage.croppedImageDetails)
                : {}
            }
            handleApply={handleApply}
            handleCancelUploadImage={handleCancelUploadImage}
            image={selectedImage.originalImageReadUrl}
            imageType={"rectangle"}
            initialAspectRatio={NaN}
            // loading={loading}
            previewImage={upload.previewImage}
            selectFile={selectFile()}
            styles={{ minHeight: 400, maxHeight: 600, width: "100%" }}
            title={`Upload Activity Image`}
          />
        )}
        <Stack alignItems="flex-start" justifyContent="center" width="100%">
          <Typography
            component="div"
            sx={{
              fontWeight: 700,
              lineHeight: "36px",
              fontSize: "28px",
            }}
            variant="h6"
          >
            {activityStepTitles.title}
          </Typography>
          <Typography component="div" my={theme.spacing(2)} variant="h6">
            {activityStepTitles.subTitle}
          </Typography>
          <Stack spacing={theme.spacing(2)} width="inherit">
            <Divider />
            {/* <Stack direction="row" spacing={theme.spacing(2)} width="inherit">
              <AddIcon />
              <Typography
                className="text-ul-offset_4_hover"
                variant="subtitle3"
                onClick={toggleShowModal}
              >
                Upload photos
              </Typography>
            </Stack> */}
            <Stack alignItems="start">
              <Stack direction="row" sx={{ marginBottom: "20px" }}>
                {activityMediaArr !== null && activityMediaArr.length > 0
                  ? activityMediaArr.map((image, index) => {
                      return (
                        <ImageBadgeComponent
                          key={index}
                          handleDeleteImage={() =>
                            handleDelete(image.documentId)
                          }
                          handleEditImage={() => handleEdit()}
                          height={"200px"}
                          imageSrc={image?.croppedImagePath}
                          imageType={ACTIVITY_IMAGE}
                          marginRight={"20px"}
                          showDelete={true}
                          showEdit={false}
                          width={"200px"}
                        />
                      );
                    })
                  : null}
              </Stack>
              <UploadImageComponent
                fileType={ACTIVITY_IMAGE}
                selectFile={selectFile()}
              />
              <Typography
                component="div"
                my={theme.spacing(1)}
                textAlign="center"
                variant="h6"
                width="100%"
              >
                For best quality, upload a photo up to 100MB with a resolution
                of 640*480 or higher.
              </Typography>
            </Stack>
            <Divider />
          </Stack>
          {/* <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              py: theme.spacing(10),
              border: `1px dashed ${theme.palette.text.secondary}`,
              borderRadius: "10px",
              marginTop: theme.spacing(1.5),
              width: "100%",
            }}
          >
            <DefaultImage />
          </Stack> */}
        </Stack>
      </RightMainComponentWrapper>
      <Footer
        disabled={false}
        handleNext={handleNext}
        loading={false}
        primaryText="Next"
        secondaryText="Back"
      />
      {showModal && (
        <ToggleDrawerDialog
          breakpoint="midsm"
          header={<ModalHeader showCloseIcon handleClose={toggleShowModal} />}
          openDrawer={openDrawer}
          openModal={showModal}
          setDrawer={setOpenDrawer}
          setModal={toggleShowModal}
        >
          <UploadPhoto handleClose={toggleShowModal} />
        </ToggleDrawerDialog>
      )}
    </>
  );
}
