import AuthenticationType from "./AuthenticationTypes";

interface IResetAuthState {
  type: AuthenticationType.RESET_AUTH_STATE;
}

interface IMobileViewAuthSessionActive {
  type: AuthenticationType.MOBILE_VIEW_AUTH_SESSION_ACTIVE;
  payload: boolean;
}

interface IShowAuthModal {
  type: AuthenticationType.SHOW_AUTH_MODAL;
  payload: boolean;
}

interface IUpdateAuthState {
  type: AuthenticationType.UPDATE_AUTH_STEP;
  payload: number;
}

interface IUpdateUserAuthInfo {
  type: AuthenticationType.UPDATE_USER_AUTH_INFO;
  payload: IUserAuthRequestData;
}

interface IUpdateVerifyEmailInfo {
  type: AuthenticationType.UPDATE_VERIFY_EMAIL_INFO;
  payload: ISignUpEmailData;
}

interface IMobileAuthGetCodeRequest {
  type: AuthenticationType.MOBILE_AUTH_GET_CODE_REQUEST;
}

interface IMobileAuthGetCodeSuccess {
  type: AuthenticationType.MOBILE_AUTH_GET_CODE_SUCCESS;
  payload: IGetCodeResponseData;
}

interface IMobileAuthGetCodeError {
  type: AuthenticationType.MOBILE_AUTH_GET_CODE_ERROR;
}

interface IMobileAuthVerifyCodeRequest {
  type: AuthenticationType.MOBILE_AUTH_VERIFY_CODE_REQUEST;
}

interface IMobileAuthVerifyCodeSuccess {
  type: AuthenticationType.MOBILE_AUTH_VERIFY_CODE_SUCCESS;
  payload: IVerifyCodeData;
}

interface IMobileAuthVerifyCodeError {
  type: AuthenticationType.MOBILE_AUTH_VERIFY_CODE_ERROR;
}

interface IMobileAuthResendCodeRequest {
  type: AuthenticationType.MOBILE_AUTH_RESEND_CODE_REQUEST;
}

interface IMobileAuthResendCodeSuccess {
  type: AuthenticationType.MOBILE_AUTH_RESEND_CODE_SUCCESS;
}

interface IMobileAuthResendCodeError {
  type: AuthenticationType.MOBILE_AUTH_RESEND_CODE_ERROR;
}

interface ICheckEmailRequest {
  type: AuthenticationType.CHECK_EMAIL_REQUEST;
}

interface ICheckEmailSuccess {
  type: AuthenticationType.CHECK_EMAIL_SUCCESS;
  payload: IEmailExistsPayload;
}

interface ICheckEmailError {
  type: AuthenticationType.CHECK_EMAIL_ERROR;
}

interface IUserLogout {
  type: AuthenticationType.USER_LOGOUT;
}

interface IResetPasswordRequest {
  type: AuthenticationType.RESET_PASSWORD_REQUEST;
}

interface IResetPasswordSuccess {
  type: AuthenticationType.RESET_PASSWORD_SUCCESS;
}

interface IResetPasswordError {
  type: AuthenticationType.RESET_PASSWORD_ERROR;
}

interface IUpdatePasswordRequest {
  type: AuthenticationType.UPDATE_PASSWORD_REQUEST;
}

interface IUpdatePasswordSuccess {
  type: AuthenticationType.UPDATE_PASSWORD_SUCCESS;
}

interface IUpdatePasswordError {
  type: AuthenticationType.UPDATE_PASSWORD_ERROR;
}

interface ISignInEmailRequest {
  type: AuthenticationType.SIGNIN_EMAIL_REQUEST;
}

interface ISignInEmailSuccess {
  type: AuthenticationType.SIGNIN_EMAIL_SUCCESS;
  payload: IUserData;
}

interface ISignInEmailError {
  type: AuthenticationType.SIGNIN_EMAIL_ERROR;
}

interface ISignUpEmailRequest {
  type: AuthenticationType.SIGNUP_EMAIL_REQUEST;
}

interface ISignUpEmailSuccess {
  type: AuthenticationType.SIGNUP_EMAIL_SUCCESS;
  payload: IUserData;
}

interface ISignUpEmailError {
  type: AuthenticationType.SIGNUP_EMAIL_ERROR;
}

interface IUserAuthStatus {
  type: AuthenticationType.USER_AUTH_STATUS;
  payload: boolean;
}

interface ICheckUserSession {
  type: AuthenticationType.CHECK_USER_SESSION;
  payload: boolean;
}

interface IUpdatePhoneValidationFlags {
  type: AuthenticationType.UPDATE_PHONE_VALIDATION_FLAGS;
  payload: IPhoneValidationFlags;
}

interface IUpdateEmailValidationFlags {
  type: AuthenticationType.UPDATE_EMAIL_VALIDATION_FLAGS;
  payload: IEmailValidationFlags;
}

interface IUpdateVerifyCodeActive {
  type: AuthenticationType.UPDATE_VERIFY_CODE_ACTIVE;
  payload: boolean;
}

interface ICheckMailExpirationRequest {
  type: AuthenticationType.CHECK_MAIL_EXPIRATION_REQUEST;
}

interface ICheckMailExpirationSuccess {
  type: AuthenticationType.CHECK_MAIL_EXPIRATION_SUCCESS;
}

interface ICheckMailExpirationError {
  type: AuthenticationType.CHECK_MAIL_EXPIRATION_ERROR;
}

export type AuthActions =
  | IResetAuthState
  | IMobileViewAuthSessionActive
  | IShowAuthModal
  | IUpdateAuthState
  | IUpdateVerifyEmailInfo
  | IMobileAuthGetCodeRequest
  | IMobileAuthGetCodeSuccess
  | IMobileAuthGetCodeError
  | IMobileAuthVerifyCodeRequest
  | IMobileAuthVerifyCodeSuccess
  | IMobileAuthVerifyCodeError
  | IMobileAuthResendCodeRequest
  | IMobileAuthResendCodeSuccess
  | IMobileAuthResendCodeError
  | ICheckEmailRequest
  | ICheckEmailSuccess
  | ICheckEmailError
  | IResetPasswordRequest
  | IResetPasswordSuccess
  | IResetPasswordError
  | IUpdatePasswordRequest
  | IUpdatePasswordSuccess
  | IUpdatePasswordError
  | IUpdateUserAuthInfo
  | ISignInEmailRequest
  | ISignInEmailSuccess
  | ISignInEmailError
  | ISignUpEmailRequest
  | ISignUpEmailSuccess
  | ISignUpEmailError
  | IUserAuthStatus
  | ICheckUserSession
  | IUpdatePhoneValidationFlags
  | IUpdateEmailValidationFlags
  | IUpdatePhoneValidationFlags
  | IUpdateVerifyCodeActive
  | ICheckMailExpirationRequest
  | ICheckMailExpirationSuccess
  | ICheckMailExpirationError
  | IUserLogout;

export interface IVerifiedUser {
  isVerified: boolean;
  status: string;
}

export type IAuthState = {
  loading: boolean;
  isResendCodeLoading: boolean;
  isResetPasswordLoading: boolean;
  showAuthModal: boolean;
  authStep: number;
  codeData: IGetCodeResponseData;
  isNewUserCreated: boolean;
  userData: IUserData | IUserData[];
  userAuth: boolean | null;
  emailExists: boolean;
  userAuthData: IUserAuthRequestData;
  delayVerifyUserSession: boolean;
  signUpEmailData: ISignUpEmailData;
  verifyCodeComponentActive: boolean;
  phoneValidationFlags: IPhoneValidationFlags;
  emailValidationFlags: IEmailValidationFlags;
  isMobileViewAuthSessionActive: boolean;
};

export interface IGetCodeResponseData {
  DeviceID: string;
  FlowType: string;
  PreAuthSessionID: string;
}
export interface IGetCodeResponse {
  status: number;
  message?: string;
  error?: boolean;
  data: IGetCodeResponseData;
}

export interface IVerifyCodeData {
  createdNewUser: boolean;
  user: IUserData;
}

export interface IUserData {
  email?: string;
  id: string;
  phoneNumber?: string;
  timejoined: number;
  thirdParty?: string | null;
}
export interface ISocialSignupPayload {
  code: string;
  redirectURI: string;
  thirdPartyId: string;
}

export interface IEmailExistsPayload {
  exists: boolean;
  users: IUserData[];
}

export interface IUserAuthRequestData {
  firstName: string;
  lastName: string;
  dob: string;
  phone: string;
  email: string;
  privacyPolicyAccepted: boolean;
  tncAccepted: boolean;
  hearAboutUs: string;
  interestedActivity: string;
  isSignupFinished: boolean;
}

export interface ISignUpEmailData {
  token: string;
  email: string;
}

export interface IPhoneValidationFlags {
  previousPhone: string;
  phoneExistsValidationCompleted: boolean;
  verifiedPhonePresent: boolean;
}

export interface IEmailValidationFlags {
  socialEmail: string;
  previousEmail: string;
  emailExistsValidationCompleted: boolean;
  sendEmailForSignUp: boolean;
  sendEmailForVerify: boolean;
  verifiedEmailPresent: boolean;
  resetPasswordActive: boolean;
  sendVerifyEmailForPasswordSetup: boolean;
}

export enum FlowTypes {
  MOBILE = "phone",
  EMAIL = "email_pwd",
  SOCIAL = "social",
}

export enum AuthFlowTypes {
  EMAIL = 1,
  PASSWORD,
  ACCOUNT_DETAILS,
  TNC,
  GUIDELINES,
  HEAR_ABOUT_US,
  ACTIVITY,
}

export enum VerificationType {
  UPDATE_EMAIL = "updateEmail",
  SIGNUP = "signup",
  FINISH_EMAIL_VERIFICATION = "finishEmailVerification",
}
