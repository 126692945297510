import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { useMemo, useState } from "react";
import {
  IAddress,
  IMailingAddress,
  IResponseData,
  IUpdatePropertyState,
  PropertySteps,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import {
  defaultSchema,
  mailingAddressValidations,
} from "utils/validationSchema";

import Address from "../Address";
import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";
import { useMailingForm } from "../usePropertyForm";

function MailingAddress() {
  const { navigateTo } = useNavigateStep();
  const isMobile = useIsMobile("midsm");
  const dispatch = useAppDispatch();
  const { isUpdating, propertyInfo } = useAppSelector(s => s.property);
  const [useDefaultAddress, setDefaultAddress] = useState<boolean>(true);

  const initialState: IMailingAddress = {
    mailingAddress: {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
    },
  };

  const handleMailingAddress = () => {
    setDefaultAddress(prev => !prev);
  };

  const onSubmit = async () => {
    const { address, city, name, state, zip } = handleTrimObjectValues(
      values.mailingAddress,
    );
    const propertyDetails: IUpdatePropertyState = {
      isPropertyMailingAddressSame: useDefaultAddress,
      lastStep:
        parseInt(propertyInfo.lastStep, 10) > PropertySteps.MAILING_ADDRESS
          ? propertyInfo.lastStep
          : PropertySteps.MAILING_ADDRESS.toString(),
      ...(!useDefaultAddress && {
        mailingAddress: {
          address,
          city,
          name,
          state,
          zip,
        },
      }),
    };
    const response: IResponseData = (await dispatch(
      updatePropertyDetails(propertyDetails, propertyInfo?.documentId),
    )) as IResponseData;
    if (!response.error) {
      navigateTo(PropertySteps.ACTIVITY_TYPE);
    }
  };

  const handleTrimObjectValues = (object: IAddress) => {
    type T = keyof IAddress;
    const values = object;
    Object.keys(values).forEach((key: string) => {
      if (typeof values[key as T] === "string") {
        (values[key as T] as string) = (values[key as T] as string).trim();
      }
    });
    return values;
  };

  const formik = useMailingForm(
    onSubmit,
    initialState,
    useDefaultAddress ? defaultSchema : mailingAddressValidations,
  );
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
  } = formik;

  const disabled = useMemo(() => {
    if (useDefaultAddress) return false;
    return !isValid;
  }, [useDefaultAddress, isValid]);

  return (
    <>
      <RightMainComponentWrapper center={!isMobile}>
        <RadioGroup
          aria-labelledby="mailingAddress"
          id="mailingAddress"
          name="mailingAddress"
          value={useDefaultAddress}
          onChange={handleMailingAddress}
        >
          <FormControlLabel
            value
            className="radio-button-label"
            control={<Radio color="secondary" />}
            label="Same as property address"
          />
          {/* {useDefaultAddress && ( */}
          <FormLabel sx={{ paddingLeft: theme.spacing(4) }}>
            {[
              propertyInfo?.address1,
              propertyInfo?.city,
              propertyInfo?.state,
              propertyInfo?.zip,
            ]
              .filter(Boolean)
              .join(", ")}
          </FormLabel>
          {/* )} */}
          <FormControlLabel
            className="radio-button-label"
            control={<Radio color="secondary" />}
            label="Use a different address"
            value={false}
          />
        </RadioGroup>
        {!useDefaultAddress && (
          <Stack
            my={theme.spacing(3)}
            sx={{
              border: `1px solid ${theme.palette.text.primary}`,
              borderRadius: "5px",
              p: theme.spacing(1.5),
            }}
          >
            {/* <Box noValidate component="form" onSubmit={handleSubmit}> */}
            <Address
              errors={errors.mailingAddress}
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched.mailingAddress}
              values={values.mailingAddress}
            />
          </Stack>
        )}
      </RightMainComponentWrapper>
      <Footer
        disabled={disabled}
        handleNext={handleSubmit}
        loading={isUpdating}
      />
    </>
  );
}

export default MailingAddress;
