import BackIcon from "@mui/icons-material/ArrowBack";
import { Container, Grid, IconButton, Stack } from "@mui/material";
import Loader from "components/loader";
import PropertyDetails from "components/PropertyDetails";
import PriceDetailsBox from "components/PropertyDetails/components/PriceDetailsBox";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPropertyDetails, PropertySteps } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";

import ImageGrid from "../edit/components/ImageGrid";

type ReviewParams = {
  propertyId: string;
};

function PropertyReview() {
  const { propertyId } = useParams<ReviewParams>();

  const isMobile = useIsMobile("midsm");
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigateStep();

  const { isLoading, propertyInfo, activityMediaArr } = useAppSelector(
    s => s.property,
  );

  const [showRules, setShowRules] = useState<boolean>(true);

  useEffect(() => {
    if (propertyId) {
      dispatch(getPropertyDetails(propertyId)).then(response => {
        if (response && !response.error) {
          if (parseInt(response.data.lastStep, 10) === PropertySteps.REVIEW) {
            navigateTo(PropertySteps.REVIEW);
          } else {
            navigateTo(parseInt(response.data.lastStep, 10) + 1);
          }
        }
      });
    }
  }, [dispatch, navigateTo, propertyId]);

  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/host/listings");
  };

  useEffect(() => {
    if (propertyInfo.rules !== undefined) {
      const propInfoRules = JSON.parse(propertyInfo.rules);
      if (propInfoRules.length < 1) {
        setShowRules(false);
      } else if (propInfoRules.length == 1 && propInfoRules[0].length == 0) {
        setShowRules(false);
      }
    }
  }, [propertyInfo]);

  if (isLoading)
    return (
      <Stack alignItems="center" height={"100vh"} justifyContent="center">
        <Loader />
      </Stack>
    );

  return (
    <Container>
      <IconButton
        aria-label="Back Button"
        size="large"
        sx={{ mb: 2 }}
        onClick={() => handleBack()}
      >
        <BackIcon />
      </IconButton>
      <ImageGrid activityMediaArr={activityMediaArr} />
      <Grid container pt={2} spacing={{ xs: 0, md: 5 }}>
        <Grid item md={8} xs={12}>
          <PropertyDetails
            property={propertyInfo}
            showLocation={true}
            showRules={showRules}
          />
        </Grid>
        <Grid item md={4} pt={isMobile ? 3 : 0} xs={12}>
          <PriceDetailsBox hostReview property={propertyInfo} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PropertyReview;
