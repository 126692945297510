import { useIsMobile } from "hooks/useIsMobile";
import Slider from "react-slick";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

interface ISliderSettings {
  dots?: boolean;
  infinite?: boolean;
  speed?: number;
  slidesToShow?: number;
  slidesToScroll?: number;
  arrows?: boolean;
}

interface IPropertyImage {
  croppedImagePath: string;
}
interface IPropertyCarouselProps {
  imagesArray: IPropertyImage[];
}

const PropertyCarousel = ({ imagesArray }: IPropertyCarouselProps) => {
  const sliderSettings: ISliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  const isMobile = useIsMobile("midsm");

  return (
    <Slider {...sliderSettings} className="property-carousel">
      {imagesArray.map((image: IPropertyImage, index: number) => {
        return (
          <div key={index}>
            <TransformWrapper
              initialPositionX={0}
              initialPositionY={0}
              initialScale={1}
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <div className="controlPanel">
                    <button className="controlBtn" onClick={() => zoomIn()}>
                      Zoom In
                    </button>
                    <button className="controlBtn" onClick={() => zoomOut()}>
                      Zoom Out
                    </button>
                    <button
                      className="controlBtn"
                      onClick={() => resetTransform()}
                    >
                      Reset Zoom
                    </button>
                  </div>
                  <TransformComponent>
                    <img
                      height="500px"
                      src={image.croppedImagePath}
                      style={{
                        objectFit: "cover",
                        display: "block",
                        margin: "auto",
                      }}
                      width={isMobile ? "100%" : "unset"}
                    />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
        );
      })}
    </Slider>
  );
};

export default PropertyCarousel;
