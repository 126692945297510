import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HRLine from "components/hrline/HRLine";
import ListPropertyActivitiesSkeleton from "components/skeleton/ListPropertyActivitiesSkeleton";
import CustomSearchInput from "components/TextField/CustomSearchInput";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import React, { useEffect, useState } from "react";
import { getPropertyActivityDetails, setSelectedActivityDetail } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { PropertySteps } from "store/property/PropertyTypes";
import theme from "theme";

import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";

function ActivityDetails() {
  const { navigateTo } = useNavigateStep();
  const dispatch = useAppDispatch();
  const { loading, activities, selectedActivity, selectedActivityDetail } =
    useAppSelector(s => s.property);
  const [value, setValue] = useState("" || selectedActivityDetail);
  const [searchInput, setSearchInput] = useState("");
  const index = activities.findIndex(
    activity => activity.activityName === selectedActivity,
  );
  const activity = activities[index];

  const isMobile = useIsMobile("midsm");

  useEffect(() => {
    dispatch(getPropertyActivityDetails(activity?.activityId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target as HTMLInputElement;
    setSearchInput(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleNext = () => {
    dispatch(setSelectedActivityDetail(value));
    navigateTo(PropertySteps.ENTRY_POINT);
  };

  return (
    <>
      <RightMainComponentWrapper center={false}>
        <Stack
          spacing={theme.spacing(isMobile ? 3 : 4)}
          sx={{
            boxSizing: "border-box",
            mt: theme.spacing(isMobile ? 0 : 5),
            px: theme.spacing(isMobile ? 0 : 9),
          }}
          width="100%"
        >
          <Typography component="div" variant={isMobile ? "h6" : "h7"}>
            Each Activity Type is allowed 1 Detail and is a single listing. You
            can come back and create as many Activity Types as you like - if you
            have an Activity Type Detail that is not listed please contact
            Customer Service.
          </Typography>
          <Box width="100%">
            <CustomSearchInput
              handleInputChange={handleSearchInputChange}
              placeholder="Search for activity"
              searchText={searchInput}
            />
          </Box>
          <HRLine />
          <Divider sx={{ width: "100%" }} />
          <Stack>
            <Typography variant="subtitle3">{selectedActivity}</Typography>
          </Stack>
          <RadioGroup
            aria-labelledby="activityType"
            id="activityType"
            name="activityType"
            sx={{ marginTop: `${theme.spacing(2)} !important` }}
            value={value}
            onChange={handleChange}
          >
            {loading ? (
              <ListPropertyActivitiesSkeleton withTopDivider={false} />
            ) : activity?.details && activity?.details?.length > 0 ? (
              activity?.details
                ?.filter((detail, arr) => {
                  if (searchInput.length >= 3) {
                    return detail.name
                      .toLocaleLowerCase()
                      .includes(searchInput.toLocaleLowerCase());
                  }
                  return arr;
                })
                ?.map(detail => (
                  <Stack key={detail.detailId}>
                    <FormControlLabel
                      className="radio-button-label"
                      control={<Radio color="secondary" />}
                      label={
                        <Box my={theme.spacing(2)}>
                          <Typography variant="subtitle3">
                            {detail.name}
                          </Typography>
                        </Box>
                      }
                      value={detail.name}
                    />
                    <Divider />
                  </Stack>
                ))
            ) : null}
          </RadioGroup>
        </Stack>
      </RightMainComponentWrapper>
      <Footer handleNext={handleNext} primaryText="Next" secondaryText="Back" />
    </>
  );
}

export default ActivityDetails;
