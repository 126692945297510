import { Reducer } from "redux";

import {
  IActivity,
  IActivityDate,
  IActivityMedia,
  IArea,
  IAreaActivity,
  IProperty,
  IPropertyState,
  PropertyActions,
} from "./PropertyInterface";
import { PropertyType } from "./PropertyTypes";

const initialState: IPropertyState = {
  propertyStep: 0,
  propertyInfo: {} as IProperty,
  isLoading: false,
  isUpdating: false,
  allProperties: [] as IProperty[],
  selectedArea: {} as IArea,
  selectedAreaActivity: {} as IAreaActivity,
  activities: [] as IActivity[],
  selectedActivity: "",
  selectedActivityDetail: "",
  showRangeCalender: false,
  activityDates: [] as IActivityDate[],
  activityMediaArr: [] as IActivityMedia[],
  activeEditRangeIndex: undefined,
  editActive: false,
  loading: false,
  isPropertyEditing: false,
  availableDates: [],
};

const PropertyReducer: Reducer<IPropertyState, PropertyActions> = (
  state = initialState,
  action = {} as PropertyActions,
) => {
  switch (action.type) {
    case PropertyType.UPDATE_PROPERTY_STEP: {
      return {
        ...state,
        propertyStep: action.payload,
      };
    }
    case PropertyType.CREATE_PROPERTY_REQUEST: {
      return {
        ...state,
        isUpdating: true,
        propertyInfo: {} as IProperty,
      };
    }
    case PropertyType.CREATE_PROPERTY_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        propertyInfo: action.payload,
      };
    }
    case PropertyType.CREATE_PROPERTY_ERROR: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case PropertyType.CLEAR_PROPERTY_DATA: {
      return {
        ...state,
        propertyStep: 0,
        propertyInfo: {} as IProperty,
        selectedArea: {} as IArea,
        selectedAreaActivity: {} as IAreaActivity,
        selectedActivity: "",
        selectedActivityDetail: "",
        activityDates: [] as IActivityDate[],
        activityMediaArr: [] as IActivityMedia[],
        isPropertyEditing: false,
      };
    }
    case PropertyType.CLAIM_PROPERTY_REQUEST:
    case PropertyType.UPDATE_PROPERTY_STATUS_REQUEST:
    case PropertyType.UPDATE_PROPERTY_REQUEST: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case PropertyType.CLAIM_PROPERTY_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case PropertyType.CLAIM_PROPERTY_ERROR:
    case PropertyType.UPDATE_PROPERTY_STATUS_ERROR:
    case PropertyType.UPDATE_PROPERTY_ERROR: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case PropertyType.UPDATE_PROPERTY_STATUS_SUCCESS:
    case PropertyType.UPDATE_PROPERTY_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        propertyInfo: { ...state.propertyInfo, ...action.payload },
      };
    }
    case PropertyType.GET_ALL_PROPERTIES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PropertyType.GET_ALL_PROPERTIES_SUCCESS: {
      return {
        ...state,
        allProperties: action.payload,
        isLoading: false,
      };
    }
    case PropertyType.GET_ALL_PROPERTIES_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case PropertyType.GET_PROPERTY_DETAILS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case PropertyType.GET_PROPERTY_DETAILS_SUCCESS: {
      const selectedArea = action.payload.area?.[0];
      let selectedAreaActivity;
      let activityDates = [] as IActivityDate[];
      let activityMediaArr = [] as IActivityMedia[];
      if (selectedArea) {
        selectedAreaActivity = selectedArea?.activities?.[0];
        if (selectedAreaActivity) {
          activityDates = selectedAreaActivity.areaActivityDate
            ? selectedAreaActivity.areaActivityDate
            : [];
          activityMediaArr = selectedAreaActivity.areaActivityMedia;
        }
      }
      return {
        ...state,
        propertyInfo: action.payload,
        isLoading: false,
        selectedArea,
        selectedAreaActivity,
        activityDates,
        activityMediaArr,
      };
    }
    case PropertyType.GET_PROPERTY_DETAILS_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case PropertyType.SET_ACTIVITY_TYPE: {
      return {
        ...state,
        selectedActivity: action.payload,
      };
    }
    case PropertyType.SET_ACTIVITY_DETAIL: {
      return {
        ...state,
        selectedActivityDetail: action.payload,
      };
    }
    case PropertyType.SHOW_DATE_RANGE_CALENDER: {
      return {
        ...state,
        showRangeCalender: action.payload,
      };
    }
    case PropertyType.ADD_ACTIVITY_DATE_SUCCESS: {
      const activityDates = [...state.activityDates, action.payload];
      return {
        ...state,
        activityDates,
      };
    }
    case PropertyType.SET_EDIT_ACTIVE_INDEX: {
      return {
        ...state,
        activeEditRangeIndex: action.payload.index,
        editActive: action.payload.editActive,
      };
    }
    case PropertyType.DELETE_ACTIVITY_DATE_SUCCESS: {
      const activityDates = [...state.activityDates];
      const selectedIndex = state.activityDates.findIndex(
        range => range.documentId === action.payload,
      );
      if (selectedIndex !== -1) {
        activityDates.splice(selectedIndex, 1);
      }
      return {
        ...state,
        activityDates,
      };
    }
    case PropertyType.EDIT_ACTIVITY_DATE_SUCCESS: {
      const activityDates = [...state.activityDates];
      activityDates[state.activeEditRangeIndex as number].name =
        action.payload.name;
      activityDates[state.activeEditRangeIndex as number].startDate =
        action.payload.startDate;
      activityDates[state.activeEditRangeIndex as number].endDate =
        action.payload.endDate;
      return {
        ...state,
        activityDates,
      };
    }
    case PropertyType.GET_ACTIVITIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PropertyType.GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        activities: action.payload,
      };
    }
    case PropertyType.GET_ACTIVITIES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case PropertyType.GET_ACTIVITY_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PropertyType.GET_ACTIVITY_DETAILS_SUCCESS: {
      const { activities } = state;
      const selectedIndex = state.activities.findIndex(
        activity => activity.activityId === action.payload.activityId,
      );
      if (selectedIndex !== -1) {
        activities[selectedIndex].details = action.payload.details;
      }
      return {
        ...state,
        loading: false,
        activities,
      };
    }
    case PropertyType.GET_ACTIVITY_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case PropertyType.ADD_ACTIVITY_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PropertyType.ADD_ACTIVITY_IMAGE_SUCCESS: {
      const activityMediaArr = state.activityMediaArr
        ? [...state.activityMediaArr, action.payload]
        : [action.payload];
      return {
        ...state,
        loading: false,
        activityMediaArr,
      };
    }
    case PropertyType.ADD_ACTIVITY_IMAGE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case PropertyType.DELETE_ACTIVITY_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case PropertyType.DELETE_ACTIVITY_IMAGE_SUCCESS: {
      const imagesArr = [...state.activityMediaArr];
      const filteredItems = imagesArr.filter(
        item => item.documentId !== action.payload,
      );
      const activityMediaArr = filteredItems;
      return {
        ...state,
        loading: false,
        activityMediaArr,
      };
    }
    case PropertyType.DELETE_ACTIVITY_IMAGE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    case PropertyType.CREATE_PROPERTY_ACTIVITY_REQUEST: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case PropertyType.CREATE_PROPERTY_ACTIVITY_SUCCESS: {
      return {
        ...state,
        selectedAreaActivity: action.payload,
        isUpdating: false,
      };
    }
    case PropertyType.CREATE_PROPERTY_ACTIVITY_ERROR: {
      return {
        ...state,
        isUpdating: false,
      };
    }

    case PropertyType.UPDATE_AREA_ACTIVITY_REQUEST: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case PropertyType.UPDATE_AREA_ACTIVITY_SUCCESS: {
      const propertyDetails = { ...state.propertyInfo };
      propertyDetails.area[0].activities[0] = { ...action.payload };
      return {
        ...state,
        isUpdating: false,
        selectedAreaActivity: {
          ...state.selectedAreaActivity,
          ...action.payload,
        },
        propertyInfo: { ...propertyDetails },
      };
    }
    case PropertyType.UPDATE_AREA_ACTIVITY_ERROR: {
      return {
        ...state,
        isUpdating: false,
      };
    }

    case PropertyType.MANAGE_MARKERS_REQUEST: {
      return {
        ...state,
        isUpdating: true,
      };
    }
    case PropertyType.MANAGE_MARKERS_SUCCESS: {
      const propertyDetails = { ...state.propertyInfo };
      if (action.payload.markers) {
        propertyDetails.markers = [...action.payload.markers];
      }
      return {
        ...state,
        isUpdating: false,
        propertyInfo: propertyDetails,
      };
    }
    case PropertyType.MANAGE_MARKERS_ERROR: {
      return {
        ...state,
        isUpdating: false,
      };
    }

    case PropertyType.SET_EDITING_PROPERTY: {
      return {
        ...state,
        isPropertyEditing: action.payload,
      };
    }

    case PropertyType.UPDATE_PROPERTY_FIELDS_REQUEST: {
      return {
        ...state,
        isUpdating: true,
      };
    }

    case PropertyType.UPDATE_PROPERTY_FIELDS_ERROR: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case PropertyType.UPDATE_PROPERTY_FIELDS_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        propertyInfo: { ...state.propertyInfo, ...action.payload },
      };
    }
    case PropertyType.GET_PROPERTY_AVAILABLE_DATES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case PropertyType.GET_PROPERTY_AVAILABLE_DATES_SUCCESS: {
      return {
        ...state,
        loading: false,
        availableDates: action.payload,
      };
    }
    case PropertyType.GET_PROPERTY_AVAILABLE_DATES_ERROR: {
      return {
        ...state,
        isUpdating: false,
      };
    }
    case PropertyType.CLEAR_FOUND_PROPERTY_DATA: {
      return {
        ...state,
        propertyInfo: {} as IProperty,
        selectedArea: {} as IArea,
        selectedAreaActivity: {} as IAreaActivity,
        selectedActivity: "",
        selectedActivityDetail: "",
        activityDates: [] as IActivityDate[],
        activityMediaArr: [] as IActivityMedia[],
        isPropertyEditing: false,
      };
    }

    default:
      return state;
  }
};

export default PropertyReducer;
