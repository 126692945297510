import { getStateCode } from "helpers/getStateCode";
import { Dispatch } from "redux";
import { IResponseData } from "store";

import client from "../../Api";
import { propertyUrl } from "../../helpers/urls";
import { ISearchPropertyPayload, SearchActions } from "./SearchInterface";
import { SearchTypes } from "./SearchTypes";

export const getAllActivities = () => {
  return async (dispatch: Dispatch<SearchActions>) => {
    try {
      dispatch({ type: SearchTypes.FETCH_ACTIVITIES_REQUEST });
      const response: IResponseData = await client.get(
        `${propertyUrl}owner/allActivities`,
      );
      dispatch({
        type: SearchTypes.FETCH_ACTIVITIES_REQUEST_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: SearchTypes.FETCH_ACTIVITIES_REQUEST_ERROR });
      return error;
    }
  };
};

export const getAllLocationList = () => {
  return async (dispatch: Dispatch<SearchActions>) => {
    try {
      dispatch({ type: SearchTypes.FETCH_PROPERTIES_REQUEST });
      const response: IResponseData = await client.get(
        `${propertyUrl}owner/get/locations`,
      );
      dispatch({
        type: SearchTypes.FETCH_PROPERTIES_REQUEST_SUCCESS,
        payload: response.data.map((property: string) => {
          const parts = property.split(" ");
          const city = parts[0];
          const state = parts[1];
          return `${city}, ${getStateCode(state)}`;
        }),
      });
      return response;
    } catch (error) {
      dispatch({ type: SearchTypes.FETCH_PROPERTIES_REQUEST_ERROR });
      return error;
    }
  };
};

export const makeSearchRequest = (payload: ISearchPropertyPayload) => {
  return async (dispatch: Dispatch<SearchActions>) => {
    try {
      dispatch({ type: SearchTypes.MAKE_SEARCH_REQUEST });
      const response: IResponseData = await client.post(
        `${propertyUrl}/owner/property/search`,
        payload,
      );
      dispatch({
        type: SearchTypes.MAKE_SEARCH_REQUEST_SUCCESS,
        payload: response.data || [],
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: SearchTypes.MAKE_SEARCH_REQUEST_ERROR });
      return error;
    }
  };
};

export const fetchSearchResults = (payload: ISearchPropertyPayload) => {
  return async (dispatch: Dispatch<SearchActions>) => {
    try {
      dispatch({ type: SearchTypes.MAKE_SEARCH_REQUEST });
      const response: IResponseData = await client.post(
        `${propertyUrl}/owner/property/mapFilterProperty`,
        payload,
      );
      dispatch({
        type: SearchTypes.MAKE_SEARCH_REQUEST_SUCCESS,
        payload: response.data || [],
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: SearchTypes.MAKE_SEARCH_REQUEST_ERROR });
      return error;
    }
  };
};

export const fetchMapProperties = (payload: ISearchPropertyPayload) => {
  return async (dispatch: Dispatch<SearchActions>) => {
    try {
      dispatch({ type: SearchTypes.FETCH_MAP_PROPERTIES_REQUEST });
      const response: IResponseData = await client.post(
        `${propertyUrl}/owner/property/mapFilterProperty`,
        payload,
      );
      dispatch({
        type: SearchTypes.FETCH_MAP_PROPERTIES_SUCCESS,
        payload: response.data || [],
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: SearchTypes.FETCH_MAP_PROPERTIES_ERROR });
      return error;
    }
  };
};
