enum AuthenticationType {
  RESET_AUTH_STATE = "RESET_AUTH_STATE",

  MOBILE_VIEW_AUTH_SESSION_ACTIVE = "MOBILE_VIEW_AUTH_SESSION_ACTIVE",

  USER_LOGOUT = "USER_LOGOUT",

  USER_AUTH_STATUS = "USER_AUTH_STATUS",
  CHECK_USER_SESSION = "CHECK_USER_SESSION",

  SHOW_AUTH_MODAL = "SHOW_AUTH_MODAL",

  UPDATE_AUTH_STEP = "UPDATE_AUTH_STEP",

  UPDATE_USER_AUTH_INFO = "UPDATE_USER_AUTH_INFO",

  UPDATE_VERIFY_EMAIL_INFO = "UPDATE_VERIFY_EMAIL_INFO",

  UPDATE_PHONE_VALIDATION_FLAGS = "UPDATE_PHONE_VALIDATION_FLAGS",
  UPDATE_EMAIL_VALIDATION_FLAGS = "UPDATE_EMAIL_VALIDATION_FLAGS",
  UPDATE_VERIFY_CODE_ACTIVE = "UPDATE_VERIFY_CODE_ACTIVE",

  MOBILE_AUTH_GET_CODE_REQUEST = "MOBILE_AUTH_GET_CODE_REQUEST",
  MOBILE_AUTH_GET_CODE_SUCCESS = "MOBILE_AUTH_GET_CODE_SUCCESS",
  MOBILE_AUTH_GET_CODE_ERROR = "MOBILE_AUTH_GET_CODE_ERROR",

  MOBILE_AUTH_VERIFY_CODE_REQUEST = "MOBILE_AUTH_VERIFY_CODE_REQUEST",
  MOBILE_AUTH_VERIFY_CODE_SUCCESS = "MOBILE_AUTH_VERIFY_CODE_SUCCESS",
  MOBILE_AUTH_VERIFY_CODE_ERROR = "MOBILE_AUTH_VERIFY_CODE_ERROR",

  MOBILE_AUTH_RESEND_CODE_REQUEST = "MOBILE_AUTH_RESEND_CODE_REQUEST",
  MOBILE_AUTH_RESEND_CODE_SUCCESS = "MOBILE_AUTH_RESEND_CODE_SUCCESS",
  MOBILE_AUTH_RESEND_CODE_ERROR = "MOBILE_AUTH_RESEND_CODE_ERROR",

  CHECK_EMAIL_REQUEST = "CHECK_EMAIL_REQUEST",
  CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS",
  CHECK_EMAIL_ERROR = "CHECK_EMAIL_ERROR",

  RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR",

  UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR",

  SIGNIN_EMAIL_REQUEST = "SIGNIN_EMAIL_REQUEST",
  SIGNIN_EMAIL_SUCCESS = "SIGNIN_EMAIL_SUCCESS",
  SIGNIN_EMAIL_ERROR = "SIGNIN_EMAIL_ERROR",

  SIGNUP_EMAIL_REQUEST = "SIGNUP_EMAIL_REQUEST",
  SIGNUP_EMAIL_SUCCESS = "SIGNUP_EMAIL_SUCCESS",
  SIGNUP_EMAIL_ERROR = "SIGNUP_EMAIL_ERROR",

  CHECK_MAIL_EXPIRATION_REQUEST = "CHECK_MAIL_EXPIRATION_REQUEST",
  CHECK_MAIL_EXPIRATION_SUCCESS = "CHECK_MAIL_EXPIRATION_SUCCESS",
  CHECK_MAIL_EXPIRATION_ERROR = "CHECK_MAIL_EXPIRATION_ERROR",
}

export default AuthenticationType;
