import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIsMobile } from "hooks/useIsMobile";
import { useCallback, useEffect } from "react";
import { clearAlert } from "store/alerts/AlertActions";
import { useAppDispatch, useAppSelector } from "store/hooks";

export default function Toast() {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  const { delay, message, open, status } = useAppSelector(s => s.alert);

  const handleClose = useCallback(() => {
    dispatch(clearAlert());
  }, [dispatch]);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, delay);
    }
  }, [open, handleClose, delay]);

  return (
    <>
      {open && (
        <Box
          sx={{
            position: "fixed",
            bottom: isMobile ? "80px" : 0,
            right: 0,
            padding: isMobile ? 0 : "30px",
            maxWidth: "400px",
            width: "100%",
            zIndex: 1310,
            marginRight: isMobile ? "10px" : "auto",
          }}
        >
          <Alert severity={status}>
            <Typography component="span" variant="h4">
              {message}
            </Typography>
          </Alert>
        </Box>
      )}
    </>
  );
}
