import { Skeleton, Stack } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import theme from "theme";

export default function ListCardSkeleton() {
  return (
    <Stack direction="row">
      <Stack direction="row" flexGrow={1} spacing={theme.spacing(3)}>
        <Skeleton animation="wave" height={24} width={24} />
        <Stack spacing={theme.spacing(0.5)}>
          <Skeleton animation="wave" width={100} />
          <Skeleton animation="wave" width={100} />
          <Skeleton animation="wave" width={100} />
        </Stack>
      </Stack>
      <Box>
        <Skeleton animation="wave" width={50} />
      </Box>
    </Stack>
  );
}
