import BackIcon from "@mui/icons-material/ArrowBack";
import { Container, Grid, IconButton, Stack } from "@mui/material";
import Loader from "components/loader";
import PropertyDetails from "components/PropertyDetails";
import PriceDetailsBox from "components/PropertyDetails/components/PriceDetailsBox";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPropertyDetails } from "store";
import { getBookingDetails } from "store/booking/BookingAction";
import { useAppDispatch, useAppSelector } from "store/hooks";

import ImageGrid from "../edit/components/ImageGrid";

type ReviewParams = {
  bookingId: string;
};

function BookingReview() {
  const { bookingId } = useParams<ReviewParams>();

  const isMobile = useIsMobile("midsm");

  const { isLoading, propertyInfo, activityMediaArr } = useAppSelector(
    s => s.property,
  );

  const [showRules, setShowRules] = useState<boolean>(true);

  const { bookingDetails } = useAppSelector(s => s.booking);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { navigateTo } = useNavigateStep();

  useEffect(() => {
    if (bookingId) {
      dispatch(getBookingDetails(bookingId)).then(response => {
        if (response && !response.error) {
          dispatch(getPropertyDetails(response.data.propertyID));
        }
      });
    }
  }, [dispatch, navigateTo, bookingId]);

  const handleBack = () => navigate("/guest-booking");

  useEffect(() => {
    if (propertyInfo.rules !== undefined) {
      const propInfoRules = JSON.parse(propertyInfo.rules);
      if (propInfoRules.length < 1) {
        setShowRules(false);
      } else if (propInfoRules.length == 1 && propInfoRules[0].length == 0) {
        setShowRules(false);
      }
    }
  }, [propertyInfo]);

  if (isLoading)
    return (
      <Stack alignItems="center" height={"100vh"} justifyContent="center">
        <Loader />
      </Stack>
    );

  return (
    <Container>
      <IconButton
        aria-label="Back Button"
        size="large"
        sx={{ mb: 1, mt: 1 }}
        onClick={() => handleBack()}
      >
        <BackIcon />
      </IconButton>
      <ImageGrid activityMediaArr={activityMediaArr} />
      <Grid container pt={2} spacing={{ xs: 0, md: 5 }}>
        <Grid item md={8} xs={12}>
          <PropertyDetails
            property={propertyInfo}
            propertyBookingDetails={bookingDetails}
            showLocation={true}
            showRules={showRules}
          />
        </Grid>
        <Grid item md={4} pt={isMobile ? 3 : 0} xs={12}>
          <PriceDetailsBox
            isBookingReview
            bookingDetails={bookingDetails}
            property={propertyInfo}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default BookingReview;
