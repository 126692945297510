import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "store/hooks";
import { PropertySteps } from "store/property/PropertyTypes";
import theme from "theme";

interface IFooterProps {
  handleNext: () => void;
  handleBack?: () => void;
  primaryText?: string;
  secondaryText?: string;
  loading?: boolean;
  disabled?: boolean;
}

function Footer(props: IFooterProps) {
  const {
    disabled = false,
    handleNext,
    handleBack,
    loading = false,
    primaryText = "Next",
    secondaryText = "Cancel",
  } = props;

  const { goToHome, navigateTo } = useNavigateStep();
  const { propertyStep, isPropertyEditing } = useAppSelector(s => s.property);
  const isMobile = useIsMobile("midsm");
  const navigate = useNavigate();

  const goBack = () => {
    switch (propertyStep) {
      case PropertySteps.GET_STARTED_STEP1:
      case PropertySteps.GET_STARTED_STEP2:
      case PropertySteps.GET_STARTED_STEP3:
        goToHome();
        break;
      // case PropertySteps.GET_STARTED_STEP2:
      // case PropertySteps.GET_STARTED_STEP3:
      //  // For now moving to next step, but need to change to respective step.
      //  navigateTo(propertyStep + 1);
      //  break;
      default: {
        if (isPropertyEditing) {
          if (propertyStep === PropertySteps.REVIEW) {
            navigate("/host/listings");
          } else {
            navigateTo(PropertySteps.REVIEW);
          }
        } else {
          navigateTo(propertyStep - 1);
        }
      }
    }
  };

  return (
    <Box height={isMobile ? "70px" : "88px"} width="100%">
      <Divider sx={{ borderColor: theme.palette.text.secondary }} />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ px: theme.spacing(isMobile ? 3 : 5), height: "inherit" }}
      >
        {propertyStep !== PropertySteps.PROPERTY_DETAILS ||
        isPropertyEditing ? (
          <Button onClick={handleBack || goBack}>
            <Typography>
              {isPropertyEditing ? "Close" : secondaryText}
            </Typography>
          </Button>
        ) : (
          <Box />
        )}
        <LoadingButton
          disableRipple
          disableTouchRipple
          color="secondary"
          disabled={disabled}
          loading={loading}
          sx={{
            px: theme.spacing(5),
            py: theme.spacing(1),
          }}
          type="button"
          variant="contained"
          onClick={handleNext}
        >
          {primaryText}
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default Footer;
