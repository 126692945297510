import { styled, Tab, Tabs } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import {
  IMenu,
  NAVIGATION_MENU,
  REF_NAV_ITEMS,
  userMenu,
} from "constants/constants";
import { BottomNavBurgerMenuIcon, WingItLogo } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import useLogout from "hooks/useLogout";
import useUpdateRole from "hooks/useUpdateRole";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  alertSuccess,
  AlertType,
  AuthFlowTypes,
  IAlertPayload,
  showHideAuthModal,
  updateAuthStep,
  updateVerifyEmailInfo,
  verifyEmail,
  verifyLinkExpiration,
} from "store";
import { IResponseData } from "store/common/CommonInterface";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { clearSession } from "utils/cookies";
import { getCookieItem } from "utils/utils";

import Auth from "../../pages/Auth/Auth";
import ModalContainer from "../Modal/ModalContainer";

interface StyledTabProps {
  label: string;
  value: string;
  onClick: () => void;
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
}));

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: theme.palette.grey[600],
  "&.Mui-selected": {
    color: theme.palette.primary.dark,
  },
  "&.Mui-focusVisible": {
    backgroundColor: theme.palette.primary.main,
  },
}));

function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const userRole = getCookieItem("userRole");
  const updateRole = useUpdateRole();
  const logout = useLogout();

  const { showAuthModal, userAuth } = useAppSelector(s => s.auth);

  const { userProfileData } = useAppSelector(s => s.profile);
  const { croppedImageReadUrl } = userProfileData;

  const croppedImage: string = croppedImageReadUrl || "";

  // Header Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const navTabs = useMemo(() => {
    return NAVIGATION_MENU.filter(menu => menu.roles.includes(userRole));
  }, [userRole]);

  useEffect(() => {
    const handleVerifyEmail = async () => {
      if (
        pathname.includes("/activate-account") ||
        pathname.includes("/change-email")
      ) {
        const params = pathname.split("/");
        const email = decodeURIComponent(params[5]);
        const token = params[3];

        const response: IResponseData = (await dispatch(
          verifyLinkExpiration(token),
        )) as IResponseData;

        if (response && !response.error) {
          const res: IResponseData = (await verifyEmail(
            token,
          )) as IResponseData;
          if (res && !res.error) {
            if (pathname.includes("/activate-account")) {
              dispatch(updateVerifyEmailInfo(email, token));
              toggleAuthModal();
              dispatch(updateAuthStep(AuthFlowTypes.PASSWORD));
            } else {
              logout();
              clearSession();
              dispatch(
                alertSuccess({
                  message: "Your email has been verified, Please login again",
                  delay: 5000,
                }),
              );
            }
          } else {
            const errorPayload: IAlertPayload = {
              message: res?.message,
            };
            dispatch({
              type: AlertType.ALERT_ERROR,
              payload: errorPayload,
            });
          }
        } else {
          const errorPayload: IAlertPayload = {
            message: response?.message,
          };
          dispatch({
            type: AlertType.ALERT_ERROR,
            payload: errorPayload,
          });
        }
      } else localStorage.clear();
    };

    handleVerifyEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const toggleAuthModal = useCallback(
    (resetAuthStep = false) => {
      if (pathname.includes("/login")) {
        return;
      }
      dispatch(showHideAuthModal(!showAuthModal));
      if (resetAuthStep) {
        setTimeout(() => {
          dispatch(updateAuthStep(AuthFlowTypes.EMAIL));
        }, 100);
      }
    },
    [dispatch, pathname, showAuthModal],
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const navigateTo = (path: string) => {
    if (isMobile) {
      navigate("/account/");
    } else {
      navigate(path);
    }
  };

  const MenuItemStyles = {
    px: theme.spacing(3),
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{
          py: theme.spacing(2),
          px: theme.spacing(3),
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            sx={{ cursor: "pointer" }}
          >
            <Box onClick={() => navigate("/")}>
              <SvgIcon
                inheritViewBox
                component={WingItLogo}
                sx={{
                  height: { sm: "36px", xs: "20px" },
                  width: { sm: "135px", xs: "80px" },
                }}
              />
            </Box>
            <Stack direction="row" ml={2} spacing={1}>
              {REF_NAV_ITEMS.map(item => {
                return (
                  <Button
                    key={item.label}
                    color="secondary"
                    variant="text"
                    onClick={() => window.open(item.link, "_self", "resources")}
                  >
                    {item.label}
                  </Button>
                );
              })}
            </Stack>
          </Stack>
          {userAuth ? (
            <Stack alignItems="center" direction="row" spacing={3}>
              <StyledTabs
                aria-label="secondary tabs example"
                value={pathname}
                onChange={(_e, path) => navigate(path)}
              >
                {navTabs.map((navMenu, index) => {
                  return (
                    <StyledTab
                      key={navMenu.label + index.toString()}
                      label={navMenu.label}
                      value={navMenu.path}
                      onClick={() => navigate(navMenu.path)}
                    />
                  );
                })}
              </StyledTabs>

              <Button
                disableRipple
                sx={{ p: 0, "&:hover": { backgroundColor: "transparent" } }}
              >
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  spacing={1.5}
                  sx={{
                    border: "0.75px solid #D9D9D9",
                    borderRadius: "30px",
                    paddingLeft: "12px",
                    paddingRight: "6px",
                    height: "44px",
                    "&:hover": {
                      boxShadow: "0 2px 4px rgba(0,0,0,0.18)",
                    },
                  }}
                  onClick={handleMenuOpen}
                >
                  <SvgIcon
                    inheritViewBox
                    component={BottomNavBurgerMenuIcon}
                    sx={{
                      height: "15px",
                      width: "22px",
                      color: theme.palette.text.primary,
                    }}
                  />
                  <Avatar
                    alt=""
                    src={croppedImage}
                    sx={{ width: 32, height: 32 }}
                  />
                </Stack>
              </Button>
            </Stack>
          ) : (
            <Stack alignItems="center" direction="row" spacing={2}>
              <Button
                sx={{ px: theme.spacing(3.25), py: theme.spacing(1.5) }}
                onClick={() => toggleAuthModal()}
              >
                Sign In
              </Button>
              <Button
                size="small"
                sx={{
                  px: theme.spacing(3.25),
                  py: theme.spacing(1.5),
                  borderRadius: "40px",
                }}
                variant="contained"
                onClick={() => toggleAuthModal()}
              >
                Sign Up
              </Button>
            </Stack>
          )}
        </Stack>
      </AppBar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        autoFocus={false}
        id="account-menu"
        MenuListProps={{ sx: { p: 0 } }}
        open={openMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            borderRadius: "20px",
            overflow: "hidden",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
            border: "1px solid #D9D9D9",
            minWidth: "224px",
            mt: theme.spacing(2),
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        onClick={handleMenuClose}
        onClose={handleMenuClose}
      >
        {userMenu
          .filter((menu: IMenu) => menu.roles.includes(userRole))
          .map((menu: IMenu, index: number) => [
            <MenuItem
              key={menu.label.replace(" ", "") + index.toString()}
              disableGutters
              divider={menu?.showDivider}
              sx={{
                ...MenuItemStyles,
                pt: theme.spacing(2),
                pb: menu?.showDivider ? theme.spacing(2) : theme.spacing(1),
              }}
              onClick={() => {
                if (menu.externalLink) {
                  window.open(menu.path, "_self");
                } else {
                  menu.switchRole ? updateRole() : navigateTo(menu.path);
                }
              }}
            >
              {menu.label}
            </MenuItem>,
          ])}
        <MenuItem
          sx={{
            ...MenuItemStyles,
            pt: theme.spacing(1),
            pb: theme.spacing(2),
            fontWeight: 600,
          }}
          onClick={logout}
        >
          Sign Out
        </MenuItem>
      </Menu>
      <ModalContainer open={showAuthModal}>
        <Auth />
      </ModalContainer>
    </Box>
  );
}

export default Header;
