import moment from "moment";
import { IPasswordRule } from "store/common/CommonInterface";

export function validateEmail(email: string) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
}

export function validatePassword(password: string) {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,128})/;
  return passwordRegex.test(password);
}

export function validatePasswordRules(password: string) {
  let rules: IPasswordRule[] = [];
  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;
  const specialCharacters = /[!@#$%^&*]/g;

  rules = [
    ...rules,
    {
      rule: "At least 8 characters",
      valid: password.length >= 8,
    },
    {
      rule: "At least 1 lowercase letter",
      valid: !!password.match(lowerCaseLetters),
    },
    {
      rule: "At least 1 uppercase letter",
      valid: !!password.match(upperCaseLetters),
    },
    {
      rule: "At least 1 number",
      valid: !!password.match(numbers),
    },
    {
      rule: "At least 1 special character",
      valid: !!password.match(specialCharacters),
    },
  ];
  return rules;
}

export function validateFileFormat(name: string, allowedExtensions: RegExp) {
  return allowedExtensions.exec(name);
}

export function validateFileSize(fileSize: number, maxFileSize: number) {
  const size: number = Math.round(fileSize / 1024);
  return size <= 1024 * maxFileSize;
}

export function validateFileSizeLimit(
  fileSize: number,
  max: number,
  min: number,
) {
  const size: number = Math.round(fileSize / 1024);
  return { min: 1024 * min < size, max: size <= 1024 * max };
}

export const validateFileResolution = (
  file: any,
  validImageWidth: number,
  validImageHeight: number,
) => {
  return new Promise(resolve => {
    let imgHeight;
    let imgWidth;
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = e => {
      // Initiate the JavaScript Image object.
      const image = new Image();

      // Set the Base64 string return from FileReader as source.
      image.src = (e?.target?.result as string) ?? "";

      // Validate the File Height and Width.
      image.onload = function imageOnLoad(this: any) {
        const { height, width } = this;
        imgHeight = height;
        imgWidth = width;
        const validFile =
          imgWidth && imgHeight
            ? imgWidth >= validImageWidth && imgHeight >= validImageHeight
            : false;
        resolve(validFile);
      };
    };
  });
};

export function validateName(name: string) {
  const nameRegex = /^[a-zA-Z' -]*$/;
  return nameRegex.test(name);
}

export function validateUSPhone(phoneNumber: string) {
  const phoneRegex =
    /^((\()?[1-9]{1}[0-9]{2}(\))?)[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/;
  return phoneRegex.test(phoneNumber);
}

export function getAgeInYears(dateOfBirth: string) {
  return moment().diff(moment(dateOfBirth), "years");
}

export const validateUSZipCode = (zipcode: string) => {
  const zipcodeRegex = /^\d{5}(-\d{4})?$/;
  return zipcodeRegex.test(zipcode);
};

export function validatePhoneNumber(phoneNumber: string) {
  // const phoneRegex = /^\+?([0-9]{1,2})\)?([0-9]{10})$/;
  // return phoneNumber.match(phoneRegex);
  if (phoneNumber.startsWith("+91")) {
    return phoneNumber.slice(3).length === 10;
  }
  if (phoneNumber.startsWith("+1")) {
    return phoneNumber.slice(2).length === 10;
  }
  return false;
}
