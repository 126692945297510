import Stack from "@mui/material/Stack";

import SearchPage from "./SearchPage";

function Home() {
  return (
    <Stack flex={1}>
      <SearchPage />
    </Stack>
  );
}

export default Home;
