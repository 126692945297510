import Button from "@mui/material/Button";
import ToggleDrawerDialog from "components/customDrawer/ToggleDrawerDialog";
import ModalHeader from "components/Modal/ModalHeader";
import { useIsMobile } from "hooks/useIsMobile";
import { useState } from "react";
import { useAppSelector } from "store/hooks";
import theme from "theme";

import RemoveCardModal from "./RemoveCardModal";

function RemoveCard() {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { loading } = useAppSelector(s => s.billing);
  const isMobile = useIsMobile();

  const handlePopUp = (value: boolean) => {
    if (isMobile) {
      setOpenDrawer(value);
    } else {
      setOpenModal(value);
    }
  };

  return (
    <>
      <Button
        disableRipple
        disableTouchRipple
        fullWidth
        disabled={loading}
        sx={{
          borderColor: theme.palette.text.primary,
        }}
        variant="outlined"
        onClick={() => handlePopUp(true)}
      >
        Remove Card
      </Button>
      <ToggleDrawerDialog
        header={
          <ModalHeader showCloseIcon handleClose={() => setOpenModal(false)} />
        }
        openDrawer={openDrawer}
        openModal={openModal}
        setDrawer={setOpenDrawer}
        setModal={setOpenModal}
      >
        <RemoveCardModal onClose={() => handlePopUp(false)} />
      </ToggleDrawerDialog>
    </>
  );
}

export default RemoveCard;
