import { Reducer } from "redux";

import { AccountActions, IAccountState } from "./AccountInterface";
import AccountTypes from "./AccountTypes";

const initialState: IAccountState = {
  loading: false,
  isUpdateAccountloading: false,
  activities: [],
  emailExists: false,
  emailVerified: false,
  phoneExists: false,
};

const AccountReducer: Reducer<IAccountState, AccountActions> = (
  state = initialState,
  action = {} as AccountActions,
) => {
  switch (action.type) {
    case AccountTypes.GET_ACTIVITIES_REQUEST: {
      return {
        ...state,
        loading: true,
        activities: [],
      };
    }
    case AccountTypes.GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        activities: action.payload,
      };
    }
    case AccountTypes.GET_ACTIVITIES_ERROR: {
      return {
        ...state,
        loading: false,
        activities: [],
      };
    }
    case AccountTypes.CHECK_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AccountTypes.CHECK_EMAIL_SUCCESS: {
      const { emailExists, isEmailVerified } = action.payload;
      return {
        ...state,
        loading: false,
        emailExists: emailExists,
        emailVerified: isEmailVerified,
      };
    }
    case AccountTypes.CHECK_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AccountTypes.CHECK_PHONE_REQUEST:
    case AccountTypes.CHECK_PHONE_ERROR: {
      return {
        ...state,
      };
    }
    case AccountTypes.CHECK_PHONE_SUCCESS: {
      return {
        ...state,
        phoneExists: action.payload.exists,
      };
    }
    case AccountTypes.UPDATE_USER_ACCOUNT_INFO_REQUEST: {
      return {
        ...state,
        isUpdateAccountloading: true,
      };
    }
    case AccountTypes.UPDATE_USER_ACCOUNT_INFO_SUCCESS:
    case AccountTypes.UPDATE_USER_ACCOUNT_INFO_ERROR: {
      return {
        ...state,
        isUpdateAccountloading: false,
      };
    }
    default:
      return state;
  }
};

export default AccountReducer;
