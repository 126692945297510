import BackIcon from "@mui/icons-material/ArrowBack";
import { Container, Grid, IconButton, Stack } from "@mui/material";
import Loader from "components/loader";
import PropertyDetails from "components/PropertyDetails";
import BookingPriceBox from "components/PropertyDetails/components/BookingPriceDetails";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import ImageGrid from "pages/host/property/edit/components/ImageGrid";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPropertyDetails } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";

type ReviewParams = {
  propertyId: string;
};

function PropertyView() {
  const navigate = useNavigate();
  const { propertyId } = useParams<ReviewParams>();

  const isMobile = useIsMobile("midsm");

  const { isLoading, activityMediaArr, propertyInfo } = useAppSelector(
    s => s.property,
  );

  const [showRules, setShowRules] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const { navigateTo } = useNavigateStep();

  useEffect(() => {
    if (propertyId) {
      dispatch(getPropertyDetails(propertyId)).then(response => {
        if (!response?.error) {
          navigateTo(parseInt(response.data.lastStep, 10));
        } else {
          navigate("/");
        }
      });
    }
  }, [dispatch, navigate, navigateTo, propertyId]);

  const handleBookProperty = () => {
    console.info("-----> handleBookProperty");
  };

  const handleBack = () => navigate("/search-listing");

  useEffect(() => {
    if (propertyInfo.rules !== undefined && propertyInfo.rules) {
      try {
        const propInfoRules = JSON.parse(propertyInfo.rules);
        if (propInfoRules.length < 1) {
          setShowRules(false);
        } else if (
          propInfoRules.length === 1 &&
          propInfoRules[0].length === 0
        ) {
          setShowRules(false);
        }
      } catch (e) {
        setShowRules(false);
      }
    } else {
      setShowRules(false);
    }
  }, [propertyInfo]);

  if (isLoading)
    return (
      <Stack alignItems="center" height={"100vh"} justifyContent="center">
        <Loader />
      </Stack>
    );

  return (
    <Container sx={{ pt: 2 }}>
      <IconButton
        aria-label="Back Button"
        size="large"
        sx={{ mb: 1, mt: 1 }}
        onClick={() => handleBack()}
      >
        <BackIcon />
      </IconButton>
      <ImageGrid activityMediaArr={activityMediaArr} />
      <Grid container pt={2} spacing={{ xs: 0, md: 5 }}>
        <Grid item md={8} xs={12}>
          <PropertyDetails
            property={propertyInfo}
            showLocation={false}
            showRules={showRules}
          />
        </Grid>
        <Grid item md={4} pt={isMobile ? 3 : 0} xs={12}>
          <BookingPriceBox
            handleBook={handleBookProperty}
            property={propertyInfo}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default PropertyView;
