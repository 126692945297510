enum FinanceTypes {
  ADD_ACH_PAYMENT_REQUEST = "ADD_ACH_PAYMENT_REQUEST",
  ADD_ACH_PAYMENT_SUCCESS = "ADD_ACH_PAYMENT_SUCCESS",
  ADD_ACH_PAYMENT_ERROR = "ADD_ACH_PAYMENT_ERROR",

  GET_ACH_PAYMENT_REQUEST = "GET_ACH_PAYMENT_REQUEST",
  GET_ACH_PAYMENT_SUCCESS = "GET_ACH_PAYMENT_SUCCESS",
  GET_ACH_PAYMENT_ERROR = "GET_ACH_PAYMENT_ERROR",

  UPDATE_ACH_PAYMENT_REQUEST = "UPDATE_ACH_PAYMENT_REQUEST",
  UPDATE_ACH_PAYMENT_SUCCESS = "UPDATE_ACH_PAYMENT_SUCCESS",
  UPDATE_ACH_PAYMENT_ERROR = "UPDATE_ACH_PAYMENT_ERROR",

  DELETE_ACH_PAYMENT_REQUEST = "DELETE_ACH_PAYMENT_REQUEST",
  DELETE_ACH_PAYMENT_SUCCESS = "DELETE_ACH_PAYMENT_SUCCESS",
  DELETE_ACH_PAYMENT_ERROR = "DELETE_ACH_PAYMENT_ERROR",
}

export default FinanceTypes;
