enum BillingType {
  GET_CARDS_REQUEST = "GET_CARDS_REQUEST",
  GET_CARDS_SUCCESS = "GET_CARDS_SUCCESS",
  GET_CARDS_ERROR = "GET_CARDS_ERROR",

  SHOW_CARD_FORM = "SHOW_CARD_FORM",

  ADD_CARD_REQUEST = "ADD_CARD_REQUEST",
  ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS",
  ADD_CARD_ERROR = "ADD_CARD_ERROR",

  SET_EDIT_CARD_ACTIVE = "SET_EDIT_CARD_ACTIVE",

  EDIT_CARD_REQUEST = "EDIT_CARD_REQUEST",
  EDIT_CARD_SUCCESS = "EDIT_CARD_SUCCESS",
  EDIT_CARD_ERROR = "EDIT_CARD_ERROR",

  DELETE_CARD_REQUEST = "DELETE_CARD_REQUEST",
  DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS",
  DELETE_CARD_ERROR = "DELETE_CARD_ERROR",

  RESET_BILLING_STATE = "RESET_BILLING_STATE",
}

export enum CardFormType {
  ADD = 1,
  EDIT = 2,
}

export default BillingType;
