import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import Loader from "components/loader";
import { useIsMobile } from "hooks/useIsMobile";
import React, { useEffect } from "react";
import { getACHPayment } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { scrollToTop } from "utils/utils";

import DirectDepositForm from "./DirectDepositForm";
import ListOptions from "./ListOptions";

function ACHInfo() {
  const isMobile = useIsMobile("midsm");
  const dispatch = useAppDispatch();
  const { achPaymentAccountDetails, loading } = useAppSelector(s => s.finance);

  useEffect(() => {
    dispatch(getACHPayment());
    scrollToTop();
  }, []);

  return (
    <Stack alignItems="start" justifyContent="center" spacing={2}>
      <Typography alignItems="center" component="div" variant="h2">
        Finance
      </Typography>
      <Divider sx={{ width: "100%" }} />
      <Typography alignItems="center" component="div" variant="h2">
        Direct Deposit
      </Typography>
      <Typography>
        Wing It will retain this information to send your Bookings&apos;
        payouts. Please keep this information up to date and accurate. ACH
        payments are a way to transfer money from one bank account to another
        without using paper checks, credit card networks, wire transfers, or
        cash.
      </Typography>
      {loading ? (
        <Loader />
      ) : Object.keys(achPaymentAccountDetails).length === 0 ? (
        <Stack spacing={theme.spacing(4)} width="100%">
          <Stack spacing={theme.spacing(3)}>
            <Typography
              align="center"
              color="text.secondary"
              component="div"
              variant={isMobile ? "h5" : "h2"}
            >
              Please enter your Direct Deposit Information
            </Typography>
            <Typography
              align="center"
              color="text.secondary"
              component="div"
              variant="subtitle2"
            >
              Direct Deposit Form
            </Typography>
          </Stack>
          <DirectDepositForm />
        </Stack>
      ) : (
        <Stack width="100%">
          <Stack
            alignItems={{ xs: "flex-start", midxs: "center" }}
            direction={{ xs: "column", midxs: "row" }}
            marginTop={theme.spacing(1)}
            spacing={{ xs: theme.spacing(2), midxs: theme.spacing(5) }}
            width="100%"
          >
            <Typography sx={{ whiteSpace: "nowrap" }}>
              Direct Deposit
            </Typography>
            <Box
              border={`0.25px solid ${theme.palette.text.secondary}`}
              boxSizing="border-box"
              flexGrow={1}
              padding={theme.spacing(1.5, 2)}
              width="100%"
            >
              <Stack width="100%">
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Typography>{achPaymentAccountDetails.name}</Typography>
                  <ListOptions />
                </Stack>
                <Typography>
                  Account: ******
                  {achPaymentAccountDetails.accountNumber?.slice(-4)}
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default ACHInfo;
