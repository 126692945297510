import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface ICustomInputHeaderProps {
  label: string;
  toEdit: any;
  isEditing: boolean;
  saveEditValue: any;
  closeEditing: any;
}

function CustomInputHeader({
  label,
  toEdit,
  isEditing,
  saveEditValue,
  closeEditing,
}: ICustomInputHeaderProps) {
  return (
    <Stack direction="row" justifyContent="space-between" width="100%">
      <Typography component="span" variant="h5">
        {label}
      </Typography>
      {isEditing ? (
        <Stack direction="row" spacing={2}>
          <Typography
            className="clickable-text"
            variant="h5"
            onClick={closeEditing}
          >
            Close
          </Typography>
          <Typography
            className="clickable-text"
            variant="h5"
            onClick={saveEditValue}
          >
            Save
          </Typography>
        </Stack>
      ) : (
        <Typography
          className="clickable-text"
          variant="h5"
          onClick={() => {
            toEdit(true);
          }}
        >
          Edit
        </Typography>
      )}
    </Stack>
  );
}

export default CustomInputHeader;
