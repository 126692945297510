import { Reducer } from "redux";

import {
  BookingActions,
  IBookingDetails,
  IBookingExistingCardData,
  IBookingState,
  IGetAreaActivityPriceData,
} from "./BookingInterface";
import BookingTypes from "./BookingType";

const initialState: IBookingState = {
  loading: false,
  hostBookings: [],
  bookingActivity: "",
  bookingDate: [],
  areaActivityPrice: {} as IGetAreaActivityPriceData,
  bookingSuccess: {} as IBookingExistingCardData,
  guestActiveBookings: [],
  guestPastBookings: [],
  bookingDetails: {} as IBookingDetails,
};

const BookingReducer: Reducer<IBookingState, BookingActions> = (
  state = initialState,
  action = {} as BookingActions,
) => {
  switch (action.type) {
    case BookingTypes.GET_GUEST_BOOKING_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BookingTypes.GET_GUEST_BOOKING_SUCCESS: {
      if (action.payload.type === "active") {
        return {
          ...state,
          loading: false,
          guestActiveBookings: action.payload.bookings,
        };
      } else {
        return {
          ...state,
          loading: false,
          guestPastBookings: action.payload.bookings,
        };
      }
    }
    case BookingTypes.GET_GUEST_BOOKING_ERROR: {
      return {
        ...state,
        loading: false,
        guestBookings: [],
      };
    }
    case BookingTypes.SET_BOOKING_ACTIVITY: {
      return {
        ...state,
        loading: false,
        bookingActivity: action.payload,
      };
    }
    case BookingTypes.SET_BOOKING_DATE: {
      return {
        ...state,
        loading: false,
        bookingDate: action.payload,
      };
    }
    case BookingTypes.GET_AREA_ACTIVITY_PRICE_REQUEST: {
      return {
        ...state,
        loading: true,
        areaActivityPrice: {} as IGetAreaActivityPriceData,
      };
    }
    case BookingTypes.GET_AREA_ACTIVITY_PRICE_SUCCESS: {
      return {
        ...state,
        loading: false,
        areaActivityPrice: action.payload,
      };
    }
    case BookingTypes.GET_AREA_ACTIVITY_PRICE_ERROR: {
      return {
        ...state,
        loading: false,
        areaActivityPrice: {} as IGetAreaActivityPriceData,
      };
    }
    case BookingTypes.ADD_BOOKING_EXISTING_CARD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BookingTypes.ADD_BOOKING_EXISTING_CARD_SUCCESS: {
      return {
        ...state,
        loading: false,
        bookingSuccess: action.payload,
      };
    }
    case BookingTypes.ADD_BOOKING_EXISTING_CARD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BookingTypes.GET_HOST_BOOKINGS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BookingTypes.GET_HOST_BOOKINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        hostBookings: action.payload,
      };
    }
    case BookingTypes.GET_HOST_BOOKINGS_ERROR: {
      return {
        ...state,
        loading: false,
        hostBookings: [],
      };
    }
    case BookingTypes.GET_BOOKING_DETAILS_REQUEST: {
      return {
        ...state,
        loading: true,
        bookingDetails: {} as IBookingDetails,
      };
    }
    case BookingTypes.GET_BOOKING_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        bookingDetails: action.payload,
      };
    }
    case BookingTypes.GET_BOOKING_DETAILS_ERROR: {
      return {
        ...state,
        loading: false,
        bookingDetails: {} as IBookingDetails,
      };
    }
    default:
      return state;
  }
};

export default BookingReducer;
