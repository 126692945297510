import BackIcon from "@mui/icons-material/ArrowBack";
import { Button, IconButton } from "@mui/material";
import Box from "@mui/material/Box/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Loader from "components/loader";
import { getStateCode } from "helpers/getStateCode";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllProperties,
  IProperty,
  IResponseData,
  updatePropertyStatus,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import PropertyActionsModal from "./PropertyActionsModal";

export default function Listings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedUnpublishProperty, setSelectedUnpublishProperty] =
    useState<IProperty>();
  const [showCancelBookingModal, setShowCancelBookingModal] =
    useState<boolean>(false);

  const { isLoading, allProperties } = useAppSelector(s => s.property);

  useEffect(() => {
    dispatch(getAllProperties());
  }, [dispatch]);

  const handleEdit = async (property: IProperty) => {
    navigate(`/host/property/edit/${property.documentId}`);
  };

  const navToReview = async (property: IProperty) => {
    navigate(`/host/property/review/${property.documentId}`);
  };

  const handlePublish = async (
    event: React.SyntheticEvent,
    property: IProperty,
  ) => {
    event.stopPropagation();
    const response: IResponseData = (await dispatch(
      updatePropertyStatus(
        { propertyStatus: "published" },
        property.documentId,
      ),
    )) as IResponseData;

    if (response && !response.error) {
      dispatch(getAllProperties());
    }
  };

  const handleSelectProperty = async (
    event: React.SyntheticEvent,
    property: IProperty,
  ) => {
    event.stopPropagation();
    setSelectedUnpublishProperty(property);
    setShowCancelBookingModal(true);
  };

  const handleBack = () => {
    navigate("/host");
  };

  const modalStatusChange = () => {
    setShowCancelBookingModal(!showCancelBookingModal);
  };

  return (
    <Paper
      sx={{ width: "100%", padding: theme.spacing(5), boxSizing: "border-box" }}
    >
      <IconButton
        aria-label="Back Button"
        size="large"
        sx={{ mb: 2 }}
        onClick={() => handleBack()}
      >
        <BackIcon />
      </IconButton>
      <Typography sx={{ marginBottom: theme.spacing(3) }} variant="h3">
        Listings
      </Typography>
      <TableContainer>
        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  fontSize: "14px",
                  fontWeight: 700,
                },
              }}
            >
              <TableCell>Listing&nbsp;Name</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Instant&nbsp;Book</TableCell>
              <TableCell align="left">Size</TableCell>
              <TableCell align="left">Activities</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Publish&nbsp;Date</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableCell colSpan={7}>
                <Loader position="center" />
              </TableCell>
            ) : !allProperties?.length ? (
              <TableCell colSpan={7}>
                <Box
                  alignItems="center"
                  display="flex"
                  height={140}
                  justifyContent="center"
                >
                  <Typography variant="h2">No Properties available</Typography>
                </Box>
              </TableCell>
            ) : (
              allProperties.map((property: IProperty) => (
                <TableRow
                  key={property.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    "& .MuiTableCell-root": {
                      fontSize: "14px",
                    },
                  }}
                  onClick={() => navToReview(property)}
                >
                  {/* Property Name */}
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    sx={{ textDecoration: "underline !important" }}
                  >
                    {property.name}
                  </TableCell>

                  {/* Property Status */}
                  <TableCell
                    align="left"
                    sx={{ "&:first-letter": { textTransform: "uppercase" } }}
                  >
                    {property.propertyStatus}
                  </TableCell>

                  {/* Instant Booking */}
                  <TableCell align="left">Yes</TableCell>

                  {/* Total Acres */}
                  <TableCell align="left">
                    {property.totalAcres} acres
                  </TableCell>

                  {/* Activity type & details */}
                  <TableCell align="left">{property.activityNames}</TableCell>

                  {/* Location - City & State */}
                  <TableCell align="left">
                    {`${property.city}, ${getStateCode(property.state)}`}
                  </TableCell>

                  {/* Listing Date */}
                  <TableCell align="left">
                    {property.publishedAt &&
                      moment(property.publishedAt).format("MMM DD, YYYY")}
                  </TableCell>

                  <TableCell align="left">
                    <Stack direction="row" spacing={2}>
                      <Button
                        disableRipple
                        disableTouchRipple
                        color="secondary"
                        sx={{
                          px: theme.spacing(3),
                          py: theme.spacing(0.5),
                        }}
                        type="submit"
                        variant="contained"
                        onClick={e => {
                          e.stopPropagation();
                          handleEdit(property);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        disableRipple
                        disableTouchRipple
                        color="secondary"
                        sx={{
                          px: theme.spacing(3),
                          py: theme.spacing(0.5),
                        }}
                        type="submit"
                        variant="contained"
                        onClick={e => {
                          property.propertyStatus === "published"
                            ? handleSelectProperty(e, property)
                            : handlePublish(e, property);
                        }}
                      >
                        {property.propertyStatus === "published"
                          ? "Unpublish"
                          : "Publish"}
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PropertyActionsModal
        modalStatusChange={modalStatusChange}
        open={showCancelBookingModal}
        selectedUnpublishProperty={selectedUnpublishProperty}
      />
    </Paper>
  );
}
