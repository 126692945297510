import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomDatePicker from "components/TextField/CustomDatePicker";
import CustomInputField from "components/TextField/CustomInputField";
import CustomMobileInputField from "components/TextField/CustomMobileInputField";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { updateUserAuthData } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { handleMaskToUSNumber, unmaskUSNumber } from "utils/utils";
import {
  getAgeInYears,
  validateName,
  validatePhoneNumber,
} from "utils/validator";

interface IInformationProps {
  handleNextStep: () => void;
}

interface IValidationState {
  error: boolean;
  message: string;
}

function Information({ handleNextStep }: IInformationProps) {
  const dispatch = useAppDispatch();
  const [phoneErrorMessage, setPhoneErrorMessage] = useState<string>("");
  const initialValidationState = { error: false, message: "" };
  const [firstNameValidation, setFirstNameValidation] =
    useState<IValidationState>(initialValidationState);
  const [lastNameValidation, setLastNameValidation] =
    useState<IValidationState>(initialValidationState);
  const [dobError, setDOBError] = useState<string>("");

  const { userAuthData, phoneValidationFlags, loading } = useAppSelector(
    s => s.auth,
  );
  const { dob, email, firstName, lastName, phone } = userAuthData;

  const handleNameCheck = (value: string, fieldName: string) => {
    if (value.trim().length > 0) {
      if (!(value.length >= 1 && value.length <= 35) || !validateName(value)) {
        if (fieldName === "firstName") {
          setFirstNameValidation({
            error: true,
            message:
              "Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.",
          });
        }
        if (fieldName === "lastName") {
          setLastNameValidation({
            error: true,
            message:
              "Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.",
          });
        }
      } else {
        if (fieldName === "firstName") {
          setFirstNameValidation(initialValidationState);
        }
        if (fieldName === "lastName") {
          setLastNameValidation(initialValidationState);
        }
      }
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    let tempValue = value;
    if (name === "phone") {
      tempValue = handleMaskToUSNumber(value);
      setPhoneErrorMessage("");
    }

    dispatch(
      updateUserAuthData({
        ...userAuthData,
        [name]: tempValue,
      }),
    );
    if (name === "firstName") {
      if (value.length === 0) setFirstNameValidation(initialValidationState);
      else handleNameCheck(value, name);
    }
    if (name === "lastName") {
      if (value.length === 0) setLastNameValidation(initialValidationState);
      else handleNameCheck(value, name);
    }
  };

  const handleDOBValidation = (dob: string) => {
    const age = getAgeInYears(dob);
    let errMsg = "";
    if (Number.isNaN(age) || age < 0) {
      errMsg = "Please enter a valid date";
    } else if (age === 0 || (age > 0 && age < 18)) {
      errMsg = "Age should be above 18 years";
    } else if (age > 100) {
      errMsg = "Date of Birth cannot be more than 100 years";
    }
    return errMsg;
  };

  const handleInputDateChange = (newValue: Date | null) => {
    const dob: string = moment(newValue).format("MM/DD/YYYY");
    const errMsg = handleDOBValidation(dob);
    setDOBError(errMsg);
    dispatch(
      updateUserAuthData({
        ...userAuthData,
        dob: dob,
      }),
    );
  };

  const calcDateDiff = useMemo(() => {
    const dateDiff = getAgeInYears(dob);
    return dateDiff;
  }, [dob]);

  const isPhoneValid = async () => {
    const phoneNumber = unmaskUSNumber(phone);
    if (phoneNumber) {
      if (validatePhoneNumber(phoneNumber)) {
        setPhoneErrorMessage("");
      } else {
        setPhoneErrorMessage("Please enter a valid phone number.");
        return false;
      }
    }
    return true;
  };

  const isFormValid = useCallback(() => {
    return [
      !firstNameValidation.error && userAuthData.firstName.trim().length > 0,
      !lastNameValidation.error && userAuthData.lastName.trim().length > 0,
      userAuthData.email.length > 0,
      userAuthData.phone.length > 0 && phoneErrorMessage.length === 0,
      userAuthData.dob.length > 0 && calcDateDiff >= 18 && calcDateDiff < 100,
    ].every(Boolean);
  }, [
    userAuthData,
    calcDateDiff,
    firstNameValidation,
    lastNameValidation,
    phoneErrorMessage,
  ]);

  const handleUserInfo = async () => {
    let phoneValid = true;
    if (!phoneValidationFlags.verifiedPhonePresent) {
      if (
        !phoneValidationFlags.phoneExistsValidationCompleted ||
        phone !== phoneValidationFlags.previousPhone
      ) {
        phoneValid = await isPhoneValid();
      }
    }

    if (isFormValid() && phoneValid) {
      const payload = {
        firstName,
        lastName,
        dob,
        phone,
        email,
      };
      dispatch(updateUserAuthData({ ...userAuthData, ...payload }));

      handleNextStep();
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isFormValid()) {
      e.preventDefault();
      handleUserInfo();
    }
  };

  const handleOnChange = (value: any) => {
    dispatch(
      updateUserAuthData({
        ...userAuthData,
        phone: value,
      }),
    );
    setPhoneErrorMessage("");
  };

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={3}
      >
        <Typography component="h3" variant="h3">
          Account Details
        </Typography>
        <Stack direction="column" spacing={2} sx={{ width: "100%" }}>
          <CustomInputField
            autoFocus
            autoComplete="new-password"
            error={firstNameValidation.error}
            errormessage={firstNameValidation.message}
            inputProps={{ maxLength: 35 }}
            label="First Name"
            name="firstName"
            type="text"
            value={firstName}
            onBlur={() => handleNameCheck(firstName, "firstName")}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <CustomInputField
            autoComplete="new-password"
            error={lastNameValidation.error}
            errormessage={lastNameValidation.message}
            inputProps={{ maxLength: 35 }}
            label="Last Name"
            name="lastName"
            type="text"
            value={lastName}
            onBlur={() => handleNameCheck(lastName, "lastName")}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
          <CustomDatePicker
            disableFuture
            DOB={new Date(dob)}
            errorMessage={dobError}
            onChange={handleInputDateChange}
          />
          <CustomMobileInputField
            autoComplete="off"
            error={phoneErrorMessage.length > 0}
            errormessage={phoneErrorMessage}
            inputProps={{ maxLength: 14 }}
            label="Phone"
            name="phone"
            placeholder="(xxx) xxx-xxxx"
            value={phone}
            onChange={handleOnChange}
            onKeyPress={handleKeyPress}
          />
          <CustomInputField
            disabled
            autoComplete="off"
            className={"MuiInputBase-disabled"}
            label="Email"
            name="email"
            type="email"
            value={email}
          />
        </Stack>
        <LoadingButton
          fullWidth
          disabled={!isFormValid()}
          loading={loading}
          variant="contained"
          onClick={handleUserInfo}
        >
          Next
        </LoadingButton>
      </Stack>
    </Box>
  );
}

export default Information;
