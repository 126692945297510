import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import PasswordRules from "components/PasswordRules/PasswordRules";
import { defaultPasswordRules } from "constants/constants";
import { useIsMobile } from "hooks/useIsMobile";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkUserSession,
  IPasswordRule,
  IResponseData,
  resetPassword,
  ResponseCodes,
  signInEmail,
  signUpEmail,
  updateEmailValidationFlags,
  updatePassword,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { redirectToMobileApp } from "utils/utils";
import { validatePassword, validatePasswordRules } from "utils/validator";

import CustomInputField from "../../../components/TextField/CustomInputField";

interface IPasswordProps {
  type?: string;
  handleNextStep?: () => void;
}

interface IErrorState {
  password: boolean;
  message: string;
}

const { BAD_REQUEST } = ResponseCodes;

const DEFAULT_ERROR: IErrorState = { password: false, message: "" };

function Password(props: IPasswordProps) {
  const { type } = props;

  const { token: resetPasswordToken, email: resetPasswordEmail } = useParams();

  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const {
    loading,
    isResetPasswordLoading,
    userAuthData,
    signUpEmailData,
    emailValidationFlags,
  } = useAppSelector(s => s.auth);
  const { emailExists } = useAppSelector(s => s.account);
  const { loading: getProfileDetailsLoading } = useAppSelector(s => s.profile);
  const { email } = userAuthData;

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showResetPassword, setShowResetPassword] = useState<boolean>(false);
  const [passwordRules, setPasswordRules] =
    useState<IPasswordRule[]>(defaultPasswordRules);
  const [error, setError] = useState(DEFAULT_ERROR);

  useEffect(() => {
    if (type === "reset-password") {
      dispatch(
        updateEmailValidationFlags({
          ...emailValidationFlags,
          resetPasswordActive: true,
        }),
      );
      // When opening from the mobile browser, It opens wingit mobile app(if installed).
      redirectToMobileApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const toggleError = (error: boolean, message = "") => {
    setError({
      password: error,
      message: error ? (message as string) : "",
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length === 0) {
      toggleError(false);
    }
    setPassword(value);
    const rules: IPasswordRule[] = validatePasswordRules(value);
    setPasswordRules(rules);
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    setShowResetPassword(true);
    dispatch(resetPassword(email));
  };

  const handleSignUp = async () => {
    const { email } = signUpEmailData;
    const response = await dispatch(signUpEmail(email, password));
    localStorage.clear();
    if (response && !response.error) {
      dispatch(checkUserSession());
      // dispatch(showHideAuthModal(false));
      // navigate("/");
    }
  };

  const handleSignIn = async () => {
    const parsedEmail =
      type === "reset-password" ? resetPasswordEmail || "" : email;
    const response = await dispatch(signInEmail(parsedEmail, password));

    if (response && !response.error) {
      dispatch(checkUserSession());
      if (isMobile) {
        navigate("/");
      }
    } else if (response.status === BAD_REQUEST) {
      toggleError(true, "Invalid Password, please try again.");
    }
  };

  const handleSigninSignUp = async () => {
    if (emailExists) {
      handleSignIn();
    } else {
      handleSignUp();
    }
  };

  const handleUpdatePassword = async () => {
    const response = (await dispatch(
      updatePassword(password, resetPasswordToken as string),
    )) as IResponseData;
    if (response && !response.error) {
      handleSignIn();
    }
  };

  const isFormValid = () => {
    if (emailExists) {
      return password.length > 0;
    }
    return validatePassword(password);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isFormValid()) {
      e.preventDefault();
      handleSigninSignUp();
    }
  };

  return (
    <>
      {showResetPassword ? (
        <Box>
          <Stack alignItems="center" direction="column" justifyContent="center">
            <Typography component="h3" variant="h3">
              Reset Password
            </Typography>
            <Box sx={{ my: theme.spacing(4), textAlign: "center" }}>
              {isResetPasswordLoading ? (
                <CircularProgress size="25px" />
              ) : (
                <Typography component="div" variant="h6">
                  A link to reset your password has been sent to &nbsp;
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {email}.
                  </Typography>
                </Typography>
              )}
            </Box>
          </Stack>
        </Box>
      ) : (
        <Box>
          <Stack alignItems="center" direction="column" justifyContent="center">
            <Typography gutterBottom component="h3" variant="h3">
              {type === "reset-password"
                ? "Reset Password"
                : emailExists
                ? "Sign In"
                : "Sign Up"}
            </Typography>
            <Typography component="span" variant="h6">
              {emailExists
                ? "Enter your password below"
                : "Create your password below"}
            </Typography>
            <Box sx={{ my: theme.spacing(3), width: "100%" }}>
              <CustomInputField
                autoComplete={emailExists ? "password" : "new-password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleTogglePassword}>
                      {showPassword ? (
                        <VisibilityOffIcon fontSize="small" />
                      ) : (
                        <VisibilityIcon fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                error={error.password}
                errormessage={error.message}
                inputProps={{ maxLength: 128 }}
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
              {emailExists && (
                <Stack
                  direction="row"
                  sx={{ mt: theme.spacing(1) }}
                  onClick={handleForgotPassword}
                >
                  <Typography variant="body2">I forgot my password</Typography>
                </Stack>
              )}
            </Box>
            {!emailExists && (
              <Box sx={{ mb: theme.spacing(3), width: "100%" }}>
                <PasswordRules rules={passwordRules} />
              </Box>
            )}
            {type === "reset-password" ? (
              <LoadingButton
                fullWidth
                disabled={!isFormValid()}
                loading={loading}
                variant="contained"
                onClick={handleUpdatePassword}
              >
                Sign In
              </LoadingButton>
            ) : (
              <LoadingButton
                fullWidth
                disabled={!isFormValid()}
                loading={loading || getProfileDetailsLoading}
                variant="contained"
                onClick={handleSigninSignUp}
              >
                {emailExists ? "Sign In" : "Create"}
              </LoadingButton>
            )}
          </Stack>
        </Box>
      )}
    </>
  );
}

export default Password;
