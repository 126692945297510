import { Reducer } from "redux";

import {
  AuthActions,
  IAuthState,
  IEmailValidationFlags,
  IGetCodeResponseData,
  IPhoneValidationFlags,
  ISignUpEmailData,
  IUserAuthRequestData,
  IUserData,
  IVerifyCodeData,
} from "./AuthenticationInterface";
import AuthenticationType from "./AuthenticationTypes";

export const initialUserAuthState: IUserAuthRequestData = {
  firstName: "",
  lastName: "",
  dob: "",
  phone: "",
  email: "",
  privacyPolicyAccepted: false,
  tncAccepted: false,
  hearAboutUs: "",
  interestedActivity: "",
  isSignupFinished: false,
};

export const initialPhoneValidationFlags: IPhoneValidationFlags = {
  previousPhone: "",
  phoneExistsValidationCompleted: false,
  verifiedPhonePresent: false,
};

export const initialEmailValidationFlags: IEmailValidationFlags = {
  socialEmail: "",
  previousEmail: "",
  emailExistsValidationCompleted: false,
  sendEmailForSignUp: false,
  sendEmailForVerify: false,
  verifiedEmailPresent: false,
  resetPasswordActive: false,
  sendVerifyEmailForPasswordSetup: false,
};

const initialState: IAuthState = {
  showAuthModal: false,
  loading: false,
  isResendCodeLoading: false,
  isResetPasswordLoading: false,
  authStep: 1,
  codeData: {} as IGetCodeResponseData,
  isNewUserCreated: false,
  userData: {} as IUserData,
  userAuth: null,
  emailExists: false,
  userAuthData: { ...initialUserAuthState } as IUserAuthRequestData,
  delayVerifyUserSession: false,
  signUpEmailData: {} as ISignUpEmailData,
  verifyCodeComponentActive: false,
  phoneValidationFlags: initialPhoneValidationFlags as IPhoneValidationFlags,
  emailValidationFlags: {
    ...initialEmailValidationFlags,
  } as IEmailValidationFlags,
  isMobileViewAuthSessionActive: false,
};

const AuthReducer: Reducer<IAuthState, AuthActions> = (
  state = initialState,
  action = {} as AuthActions,
) => {
  switch (action.type) {
    case AuthenticationType.RESET_AUTH_STATE: {
      return {
        ...state,
        phoneValidationFlags:
          initialPhoneValidationFlags as IPhoneValidationFlags,
        emailValidationFlags: {
          ...initialEmailValidationFlags,
        } as IEmailValidationFlags,
        userAuthData: { ...initialUserAuthState } as IUserAuthRequestData,
        authStep: 1,
      };
    }
    case AuthenticationType.MOBILE_VIEW_AUTH_SESSION_ACTIVE: {
      return {
        ...state,
        isMobileViewAuthSessionActive: action.payload,
      };
    }
    case AuthenticationType.SHOW_AUTH_MODAL: {
      return {
        ...state,
        showAuthModal: action.payload,
      };
    }
    case AuthenticationType.UPDATE_AUTH_STEP: {
      return {
        ...state,
        authStep: action.payload,
      };
    }
    case AuthenticationType.UPDATE_USER_AUTH_INFO: {
      return {
        ...state,
        userAuthData: action.payload,
      };
    }
    case AuthenticationType.UPDATE_VERIFY_EMAIL_INFO: {
      return {
        ...state,
        signUpEmailData: { ...action.payload },
      };
    }
    case AuthenticationType.UPDATE_PHONE_VALIDATION_FLAGS: {
      return {
        ...state,
        phoneValidationFlags: action.payload,
      };
    }
    case AuthenticationType.UPDATE_EMAIL_VALIDATION_FLAGS: {
      return {
        ...state,
        emailValidationFlags: action.payload,
      };
    }
    case AuthenticationType.UPDATE_VERIFY_CODE_ACTIVE: {
      return {
        ...state,
        verifyCodeComponentActive: action.payload,
      };
    }
    case AuthenticationType.USER_AUTH_STATUS: {
      return {
        ...state,
        userAuth: action.payload,
      };
    }
    case AuthenticationType.CHECK_USER_SESSION: {
      return {
        ...state,
        delayVerifyUserSession: action.payload,
      };
    }
    case AuthenticationType.MOBILE_AUTH_GET_CODE_REQUEST: {
      return {
        ...state,
        loading: true,
        codeData: {} as IGetCodeResponseData,
      };
    }
    case AuthenticationType.MOBILE_AUTH_GET_CODE_SUCCESS: {
      return {
        ...state,
        loading: false,
        codeData: action.payload,
      };
    }
    case AuthenticationType.MOBILE_AUTH_GET_CODE_ERROR: {
      return {
        ...state,
        loading: false,
        codeData: {} as IGetCodeResponseData,
      };
    }
    case AuthenticationType.MOBILE_AUTH_VERIFY_CODE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.MOBILE_AUTH_VERIFY_CODE_SUCCESS: {
      const { createdNewUser, user } = action.payload as IVerifyCodeData;
      user.email = user.email ?? "";
      user.phoneNumber = user.phoneNumber ?? "";
      return {
        ...state,
        loading: false,
        isNewUserCreated: createdNewUser,
        userData: user,
      };
    }
    case AuthenticationType.MOBILE_AUTH_VERIFY_CODE_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthenticationType.MOBILE_AUTH_RESEND_CODE_REQUEST: {
      return {
        ...state,
        isResendCodeLoading: true,
      };
    }
    case AuthenticationType.MOBILE_AUTH_RESEND_CODE_SUCCESS:
    case AuthenticationType.MOBILE_AUTH_RESEND_CODE_ERROR: {
      return {
        ...state,
        isResendCodeLoading: false,
      };
    }
    case AuthenticationType.CHECK_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.CHECK_EMAIL_SUCCESS: {
      const { exists, users } = action.payload;
      return {
        ...state,
        loading: false,
        emailExists: exists,
        userData: users,
      };
    }
    case AuthenticationType.CHECK_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthenticationType.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        isResetPasswordLoading: true,
      };
    }
    case AuthenticationType.RESET_PASSWORD_SUCCESS:
    case AuthenticationType.RESET_PASSWORD_ERROR: {
      return {
        ...state,
        isResetPasswordLoading: false,
      };
    }
    case AuthenticationType.UPDATE_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.UPDATE_PASSWORD_SUCCESS:
    case AuthenticationType.UPDATE_PASSWORD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthenticationType.SIGNUP_EMAIL_REQUEST:
    case AuthenticationType.SIGNIN_EMAIL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.SIGNUP_EMAIL_SUCCESS:
    case AuthenticationType.SIGNIN_EMAIL_SUCCESS: {
      return {
        ...state,
        loading: false,
        userData: action.payload,
      };
    }
    case AuthenticationType.SIGNUP_EMAIL_ERROR:
    case AuthenticationType.SIGNIN_EMAIL_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case AuthenticationType.CHECK_MAIL_EXPIRATION_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case AuthenticationType.CHECK_MAIL_EXPIRATION_SUCCESS:
    case AuthenticationType.CHECK_MAIL_EXPIRATION_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
