import { BreakpointsOptions, createTheme } from "@mui/material/styles";

import palette from "./palette";
import typography from "./typography";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    midsm: true;
    md: true;
    lg: true;
    xl: true;
  }
}

interface ExtendedBreakpointOptions extends BreakpointsOptions {
  midsm: number;
}

const lightTheme = createTheme({
  palette,
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      midsm: 800,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  } as ExtendedBreakpointOptions,
  components: {
    // Name of the component
    // MuiButton: {
    //   styleOverrides: {
    //     // Name of the slot
    //     root: {
    //       // Some CSS
    //       padding: "13px",
    //       lineHeight: "22px",
    //       fontSize: "14px",
    //       fontStyle: "normal",
    //       fontWeight: 700,
    //       "&.Mui-disabled": {
    //         backgroundColor: palette.text.secondary,
    //         color: palette.common.white,
    //       },
    //     },
    //     outlined: {
    //       borderColor: palette.text.secondary,
    //       color: palette.text.primary,
    //       fontWeight: 500,
    //       "&:hover": {
    //         borderColor: palette.text.secondary,
    //         background: "#F7F7F7",
    //       },
    //     },
    //   },
    // },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "30px",
          border: `1px solid ${palette.text.secondary}`,
          borderRadius: "20px",
          marginBottom: "10px",
          ":hover": {
            backgroundColor: "#48633F",
          },
        },
        label: {
          padding: "4px 16px",
        },
        filled: {
          background: "#48633F",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: palette.common.white,
          boxShadow: "none",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          pointerEvents: "none",
        },
        message: {
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: "72px",
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "18px",
          marginTop: "3px",
          color: "#555555",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  },
});

export default lightTheme;
