interface IAction {
  type: string;
  payload?: unknown;
}

export type Action = IAction;

export interface ICountryDataInterface {
  name: string;
  code: string;
  id: number;
  phone: number;
  symbol: string;
  capital: string;
  currency: string;
  continent: string;
  continent_code: string;
  alpha_3: string;
}

export interface IResponseData<T = any> {
  data: T;
  error: boolean;
  message: string;
  status: number | string;
  [Key: string]: any;
}

export enum ResponseCodes {
  OK_RESPONSE = 200,
  BAD_REQUEST = 400,
  CONFLICT_REQUEST = 409,
  UNPROCESSIBLE_ENTITY = 422,
}

export interface IPasswordRule {
  rule: string;
  valid: boolean;
}

export enum UserRole {
  GUEST = "guest",
  HOST = "host",
}
