import { Box, Button, List, ListItem, Stack, Typography } from "@mui/material";
import ModalContainer from "components/Modal/ModalContainer";
import ModalHeader from "components/Modal/ModalHeader";
import { useIsMobile } from "hooks/useIsMobile";
import { getAllProperties, updatePropertyStatus } from "store";
import { useAppDispatch } from "store/hooks";
import theme from "theme";

interface IModalProps {
  open: boolean;
  modalStatusChange: () => void;
  selectedUnpublishProperty: any;
}

const PropertyActionsModal = (props: IModalProps) => {
  const { open, modalStatusChange, selectedUnpublishProperty } = props;

  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();

  const handleUnpublish = async (
    event: React.SyntheticEvent,
    refund?: boolean,
  ) => {
    event.stopPropagation();

    if (refund) {
      await dispatch(
        updatePropertyStatus(
          { propertyStatus: "unpublish", refund: true },
          selectedUnpublishProperty.documentId,
        ),
      );
    } else {
      await dispatch(
        updatePropertyStatus(
          { propertyStatus: "unpublish", refund: false },
          selectedUnpublishProperty.documentId,
        ),
      );
    }
    modalStatusChange();
    dispatch(getAllProperties());
  };

  return (
    <ModalContainer maxWidth="lg" open={open}>
      <Box
        sx={{
          maxWidth: "100%",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <ModalHeader
          showCloseIcon
          handleClose={() => {
            modalStatusChange();
          }}
        />
      </Box>
      <Box p={theme.spacing(3)}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            component={"h2"}
            sx={{ fontSize: "32px", fontWeight: "700", lineHeight: "48px" }}
          >
            Are you sure you want to unpublish{" "}
            {selectedUnpublishProperty &&
              `${selectedUnpublishProperty.name} - ${selectedUnpublishProperty.activityNames} ? `}
          </Typography>

          {selectedUnpublishProperty &&
          selectedUnpublishProperty.bookingCount > 0 ? (
            <>
              <Typography
                component={"p"}
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
              >
                You have 3 scheduled Bookings. Please select your preferred
                outcome.
              </Typography>

              <Stack direction="column">
                <Stack direction={isMobile ? "column" : "row"}>
                  <Box
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #898989",
                      margin: "15px",
                      padding: "15px",
                      textAlign: "left",
                    }}
                    width={isMobile ? "auto" : "50%"}
                  >
                    <Typography
                      component={"h2"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      Cancel Booking & Initiate Refund
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 4 }}>
                      <ListItem sx={{ display: "list-item", pl: 0 }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            lineHeight: "24px",
                          }}
                        >
                          No further Guests will be able to view/book this
                          property.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item", pl: 0 }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            lineHeight: "24px",
                          }}
                        >
                          Notify Guests.
                        </Typography>
                        <Typography component={"p"} sx={{ fontSize: "12px" }}>
                          Wing It will send an automated email detailing the
                          Refund. <br />
                          Please update your Guests with any additional
                          information necessary
                        </Typography>
                      </ListItem>
                    </List>
                    <Button
                      color="secondary"
                      sx={{ display: "flex", margin: "auto", mt: 1 }}
                      variant="contained"
                      onClick={e => {
                        handleUnpublish(e, true);
                      }}
                    >
                      Unpublish & Refund
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "20px",
                      border: "1px solid #898989",
                      margin: "15px",
                      padding: "15px",
                      textAlign: "left",
                    }}
                    width={isMobile ? "auto" : "50%"}
                  >
                    <Typography
                      component={"h2"}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "24px",
                      }}
                    >
                      Allow Scheduled Bookings
                    </Typography>
                    <List sx={{ listStyleType: "disc", pl: 4 }}>
                      <ListItem sx={{ display: "list-item", pl: 0 }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            lineHeight: "24px",
                          }}
                        >
                          No further Guests will be able to view/book this
                          property.
                        </Typography>
                      </ListItem>
                      <ListItem sx={{ display: "list-item", pl: 0 }}>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "400",
                            lineHeight: "24px",
                          }}
                        >
                          Disable future Bookings
                        </Typography>
                      </ListItem>
                    </List>
                    <Button
                      color="secondary"
                      sx={{
                        display: "flex",
                        margin: "auto",
                        marginTop: "48px",
                      }}
                      variant="contained"
                      onClick={e => {
                        handleUnpublish(e, false);
                      }}
                    >
                      Unpublish
                    </Button>
                  </Box>
                </Stack>
                <Box>
                  <Button
                    sx={{
                      fontSize: "18px",
                      textDecoration: "underline",
                      color: "#2A2A2A",
                      marginTop: "15px",
                    }}
                    onClick={() => modalStatusChange()}
                  >
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </>
          ) : (
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ mt: 5, mb: 3 }}
            >
              <Button
                color="secondary"
                sx={{ mr: 2 }}
                variant="contained"
                onClick={e => handleUnpublish(e, false)}
              >
                Unpublish
              </Button>
              <Button onClick={() => modalStatusChange()}>Cancel</Button>
            </Stack>
          )}
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default PropertyActionsModal;
