import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { defaultPasswordRules } from "constants/constants";
import { IPasswordRule } from "store/common/CommonInterface";
import theme from "theme";

interface IPasswordRulesProps {
  rules?: IPasswordRule[];
}

function PasswordRules({ rules = defaultPasswordRules }: IPasswordRulesProps) {
  return (
    <>
      <Typography sx={{ fontWeight: "bold" }} variant="h6">
        Requirements
      </Typography>
      <Stack
        alignItems="flex-start"
        direction="column"
        justifyContent="center"
        spacing={1}
        sx={{ my: theme.spacing(2) }}
      >
        {rules.map(rule => {
          return (
            <Stack
              key={rule.rule}
              alignItems="center"
              direction="row"
              justifyContent="flex-start"
              spacing={2}
            >
              <Box
                sx={{
                  height: "12px",
                  width: "12px",
                  borderRadius: "50%",
                  background: rule.valid
                    ? theme.palette.success.light
                    : theme.palette.text.secondary,
                }}
              />
              <Typography variant="h6">{rule.rule}</Typography>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
}

export default PasswordRules;
