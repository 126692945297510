import { useAppSelector } from "store/hooks";

import CreateDateRange from "./CreateDateRange";
import ListDateRange from "./ListDateRange";

function ActivityDates() {
  const { showRangeCalender } = useAppSelector(s => s.property);

  return <>{showRangeCalender ? <CreateDateRange /> : <ListDateRange />}</>;
}

export default ActivityDates;
