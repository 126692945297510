const {
  REACT_APP_AUTH_URL,
  REACT_APP_BASE_UI_URL,
  REACT_APP_BASE_API_URL,
  REACT_APP_ACCOUNTS_URL,
  REACT_APP_PAYMENT_URL,
  REACT_APP_PROPERTY_URL,
  REACT_APP_ACTIVITY_URL,
  REACT_APP_MAP_BOX_URL,
  REACT_APP_MAP_QUEST_URL,
} = process.env;

const baseApiUrl = `${REACT_APP_BASE_API_URL}`;
const baseUIUrl = `${REACT_APP_BASE_UI_URL}`;
const authUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_AUTH_URL}`;
const accountsUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_ACCOUNTS_URL}`;
const paymentUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_PAYMENT_URL}`;
const propertyUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_PROPERTY_URL}`;
const activityUrl = `${REACT_APP_BASE_API_URL}${REACT_APP_ACTIVITY_URL}`;
const mapBoxUrl = REACT_APP_MAP_BOX_URL;
const mapQuestUrl = REACT_APP_MAP_QUEST_URL;

export {
  accountsUrl,
  activityUrl,
  authUrl,
  baseApiUrl,
  baseUIUrl,
  mapBoxUrl,
  mapQuestUrl,
  paymentUrl,
  propertyUrl,
};
