import { DateRange, DateRangeProps } from "react-date-range";

interface IProps extends DateRangeProps {}

function DateRangePicker(props: IProps) {
  return (
    <DateRange
      preventSnapRefocus
      showPreview
      editableDateInputs={true}
      months={2}
      moveRangeOnFirstSelection={false}
      rangeColors={["#48633F"]}
      showDateDisplay={false}
      {...props}
    />
  );
}

export default DateRangePicker;
