import ToggleDrawerDialog from "components/customDrawer/ToggleDrawerDialog";
import ListCardMenu from "components/Menu/ListCardMenu";
import ModalHeader from "components/Modal/ModalHeader";
import React, { useState } from "react";

import DeletePayment from "./DeletePayment";
import DirectDepositForm from "./DirectDepositForm";

function ListOptions() {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <>
      <ListCardMenu
        onDelete={() => setShowDeleteModal(true)}
        onEdit={() => setShowEditModal(true)}
      />
      {showEditModal && (
        <ToggleDrawerDialog
          breakpoint="midsm"
          header={<ModalHeader />}
          openDrawer={showEditModal}
          openModal={showEditModal}
          setDrawer={setShowEditModal}
          setModal={setShowEditModal}
        >
          <DirectDepositForm
            type="edit"
            onEditModalClose={() => setShowEditModal(false)}
          />
        </ToggleDrawerDialog>
      )}
      {showDeleteModal && (
        <ToggleDrawerDialog
          breakpoint="midsm"
          header={<ModalHeader />}
          openDrawer={showDeleteModal}
          openModal={showDeleteModal}
          setDrawer={setShowDeleteModal}
          setModal={setShowDeleteModal}
        >
          <DeletePayment
            onDeleteModalClose={() => setShowDeleteModal(false)}
            onEditModalOpen={() => setShowEditModal(true)}
          />
        </ToggleDrawerDialog>
      )}
    </>
  );
}

export default ListOptions;
