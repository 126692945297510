import { Box, Typography } from "@mui/material";
import ModalContainer from "components/Modal/ModalContainer";
import theme from "theme";

interface IBookingModalSuccessProps {
  open: boolean;
  modalStatusChange: () => void;
}

const PrivacyPolicyModal = ({
  open,
  modalStatusChange,
}: IBookingModalSuccessProps) => {
  return (
    <ModalContainer
      showCloseIcon
      fullWidth={true}
      handleClose={() => {
        modalStatusChange();
      }}
      maxWidth="lg"
      open={open}
      showHeader={true}
    >
      <Box
        sx={{
          maxWidth: "100%",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Box p={theme.spacing(3)}>
          <Box>
            <Typography
              component={"p"}
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Privacy
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Cookie Settings –{" "}
              <a
                href="https://resources.wingit.us/cookies/"
                rel="noreferrer"
                target="_blank"
              >
                Learn More or Adjust Consent Do Not Sell My Information
              </a>
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Wing It™ PRIVACY POLICY
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Last updated: September 27, 2023
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Wing It respects your privacy and is committed to protecting it
              through our compliance with this policy. This privacy policy
              applies to your use of the website located at http://wingit.us/,
              including any subdomains or versions thereof and the Wing It
              mobile and desktop applications (collectively, the “Wing It
              Platform”) provided by Wing It, LLC.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              This policy describes the types of information we may collect from
              you or that you may provide when you visit the Wing It Platform,
              and our practices for collecting, using, maintaining, protecting,
              and disclosing that information.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Please read this policy carefully. If you do not agree with our
              policies and practices contained herein, your choice is to not use
              the Wing It Platform. By accessing or using the Wing It Platform,
              you agree to our privacy policy. This policy may change from time
              to time. Your continued use of the Wing It Platform after we make
              changes is deemed an acceptance of said changes. Any capitalized
              terms used herein carry the same meaning as listed in Schedule A
              of the Terms and Conditions of Service.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1</strong>&nbsp;<strong>APPLICATION</strong>. This policy
              applies to information we collect: (1) on the Wing It Platform,
              (2) in email, text, and other electronic messages between you and
              Wing It, and (3) when you interact with our advertising and
              applications on Third-Party Services, if those applications or
              advertising include links to this policy. This policy does not
              apply to information collected by: (1) us offline or through any
              other means, including on any other website operated by Wing It or
              any third party (including our affiliates and subsidiaries), and
              (2) any third party (including our affiliates and subsidiaries),
              including through any application or content (including
              advertising) that may link to or be accessible from or on the Wing
              It Platform.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2</strong>&nbsp;<strong>CHILDREN UNDER AGE OF 18</strong>
              .&nbsp; The Wing It Platform is not intended for children under
              18years of age. No one under age 18may provide any personal
              information to or on the Wing It Platform. We do not knowingly
              collect personal information from children under 16 without
              parental consent.
              <strong>
                If you are under 18, do not use or provide any information on
                the Wing It Platform or through any of its features, register on
                the Wing It Platform, make any purchases through the Wing It
                Platform, use any of the interactive comment features on the
                Wing It Platform, or provide any information about yourself to
                us, including your name, address, telephone number, email
                address, or any screen name or username you may use.
              </strong>
              <p>
                If we learn we have collected or received personal information
                from a child under 18 years of age without verification of
                parental consent, we will delete that information. If you
                believe we might have any information from or about a child
                under 18 years of age, please contact us at support@wingit.us
              </p>
              <p>
                California residents under 18 years of age may have additional
                rights regarding the collection and sale of their personal
                information.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3</strong>&nbsp;
              <strong>
                INFORMATION WE COLLECT ABOUT YOU AND HOW WE COLLECT IT
              </strong>
              We collect several types of information from and about Users
              including information:
              <p>
                a. That may personally identify you, such as name, postal
                address, e-mail address, telephone number, or any other
                identifier by which you may be contacted online or offline.
              </p>
              <p>
                b. About your internet connection, the equipment you use to
                access the Wing It Platform, and usage details.
              </p>
              <p>
                We collect this information: (1) directly from you when you
                provide it to us, (2) automatically as you navigate through the
                Wing It Platform, which includes usage details, IP addresses,
                and information collected through cookies, and (3) from third
                parties.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4</strong>&nbsp;
              <strong>INFORMATION YOU PROVIDE DIRECTLY TO US</strong>
              The information we collect on or through our website that you
              directly provide to us may include:
              <p>
                a. Information that you provide by filing in forms on the Wing
                It Platform. This includes information provided at the time of
                registering to use the Wing It Platform, posting Content on the
                Wing It Platform, or requesting other services. When you
                register an account on the Wing It Platform, you provide
                personal information, such as your name, email address, and
                password, and you have the option to provide further personal
                information. We may also ask you for information when you report
                a problem with the Wing It Platform. Your personal information
                is maintained on a secure site, and we never sell your personal
                information.
              </p>
              <p>
                b. Information that you provide when you book or list an
                Activity through the Wing It Platform. At times, you may be
                asked to provide additional information, such as payment to
                provide under a secure payment process. When you provide your
                credit card number, it will only be shared with verified third
                parties who perform tasks required to complete the purchase
                transaction, and only the last four digits will be stored.
              </p>
              <p>
                c. Records and copies of your correspondence (including email
                addresses), if you contact us.
              </p>
              <p>
                d. You also may provide information to be published or displayed
                on public areas of the Wing It Platform, or transmitted to Users
                or third-parties of the Wing It Platform. Your display of
                Content is posted on and transmitted to others at your own risk.
                Please be aware that no security measures are perfect or
                impenetrable. We cannot control the actions of Users of the Wing
                It Platform with whom you may choose to share Content.
                Therefore, we cannot and do not guarantee that your Content will
                not be viewed by unauthorized persons.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>5</strong>&nbsp;
              <strong>
                INFORMATION WE COLLECT THROUGH AUTOMATIC DATA COLLECTION
              </strong>
              As you navigate through and interact with the Wing It Platform, we
              may use automatic data collection technologies to collect certain
              information about your equipment, browsing actions, and patterns,
              including:
              <p>
                a. Details of your visits to the Wing It Platform, including
                traffic data, location data, logs, and other communication data
                and the resources that you access and use on the Wing It
                Platform.
              </p>
              <p>
                b. Information that your computer, mobile device, or other
                device and your internet connection, including your IP address,
                GPS, operating system, and browser type, and depending on your
                device settings.
              </p>
              <p>
                c. Payment Transaction Data. Such as payment instrument used,
                date and time, payment amount, payment instrument expiration
                date and billing postcode, email address, IBAN information, your
                address, and other related transaction details. We also may use
                these technologies to collection information about your online
                activities over time and across third-party websites or other
                online services (behavioral tracking).
              </p>
              <p>
                The information we collect automatically may include personal
                information. It helps us improve the Wing It Platform and to
                deliver a better and more personalized service, including
                enabling us to:
              </p>
              <p>a. Estimate our audience size and usage patterns.</p>
              <p>
                b. Store information about your preferences, allowing us to
                customize the Wing It Platform according to your individual
                interests.
              </p>
              <p>c. Speed up your searches.</p>
              <p>d. Recognize you when you return to the Wing It Platform. </p>
              <p>
                The technologies we use for this automatic data collection may
                include:
              </p>
              <p>
                a. Cookies (or browser cookies) and similar technologies. A
                cookie is a small file placed on the hard drive of your
                computer. You may refuse to accept browser cookies by activating
                the appropriate setting on your browser. However, if you select
                this setting you may be unable to access certain parts of the
                Wing It Platform. Unless you have adjusted your browser setting
                so that it will refuse cookies, our system will issue cookies
                when you direct your browser to the Wing It Platform. [Cookie
                Policy link]
              </p>
              <p>b. Flash Cookies.</p>
              <p>
                c. Web Beacons. Pages of the Wing It Platform and our emails may
                contain small electronic files known as web beacons (also
                referred to as clear gifs, pixel tags, and single-pixel gifs)
                that permit Wing It to count Users who have visited those pages
                or opened an email and for other related website statistics.
              </p>
              <p>
                WE DO NOT SELL THIS INFORMATION TO ANY THIRD PARTY, BUT MAY
                PROVIDE INFORMATION TO PARTNERS THAT ASSIST US IN UPDATING,
                MANAGING OR MAINTAINING THE WING IT PLATFORM.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>6</strong>&nbsp;
              <strong>
                THIRD PARTY USE OF COOKIES AND OTHER TRACKING TECHNOLOGIES
              </strong>
              .&nbsp;
              <p>
                Some content or applications, including advertisements, on the
                Wing It Platform are served by third-parties, including
                advertisers, ad networks and servers, content providers, and
                application providers. These third parties may use cookies alone
                or in conjunction with web beacons or other tracking
                technologies to collect information about you when you use the
                Wing It Platform. The information they collect may be associated
                with your personal information or they may collect information,
                including personal information, about your online activities
                over time and across different websites and other online
                services. They may use this information to provide you with
                interest-based (behavioral) advertising or other targeted
                content.
              </p>
              <p>
                We do not control these third parties’ tracking technologies or
                how they may be used. If you have any questions about an
                advertisement or other targeted content, you should contact the
                responsible provider directly. For more information about how
                you can opt-out of receiving targeted advertising from many
                providers, see Section 9.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>7</strong>&nbsp;<strong>HOW WE USE YOUR DATA</strong>
              We use information that we collect about you or that you provide
              to us, including personal information to:
              <p>Present the Wing It Platform and its contents to you;</p>
              <ol type="a">
                <li>Present the Wing It Platform and its contents to you;</li>
                <li>
                  Provide you with information, products, or services that you
                  request from us;
                </li>
                <li>Fulfill any other purpose for which you provide it;</li>
                <li>
                  Provide you with notices about your account, including
                  expiration and renewal notices;
                </li>
                <li>
                  Carry out our obligations and enforce our rights arising from
                  any contracts entered into between you and us, including for
                  billing and collection;
                </li>
                <li>
                  Notify you about changes to the Wing It Platform or any
                  services we offer or provide through the Wing It Platform;
                </li>
                <li>
                  Allow you to participate in interactive features on the Wing
                  It Platform;
                </li>
                <li>
                  Prevent illegal activities, enforce the Terms and Conditions
                  of Service, or as otherwise requited by law;
                </li>
                <li>
                  Comply with and enforce applicable legal and regulatory
                  requirements;
                </li>
                <li>Personalize your experience with the Wing It Platform;</li>
                <li>
                  Establish and manage your account with us, and identify and
                  authenticate you so you can access and use the Wing It
                  Platform; and
                </li>
                <li>
                  Provide payment services in accordance with the Wing It
                  Payment Terms.
                </li>
              </ol>
              Additionally, we use your information in any other way we may
              describe to you when you provide the information or any other
              purpose for which you provide your consent.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>8&nbsp;</strong>
              <strong>DISCLOSURE OF YOUR INFORMATION</strong>
              We may disclose aggregated information about Users, and
              information that does not identify any individual, without
              restriction.
              <p>
                We may disclose personal information that we collect or you
                provide as described in this Privacy Policy:
              </p>
              <ol type="a">
                <li>To our subsidiaries and affiliates</li>
                <li>
                  To contractors, service providers, and other third parties we
                  use to support our business.
                </li>
                <li>
                  To a buyer or other successor in the event of a merger,
                  divesture, restructuring, reorganization, dissolution, or
                  other sale or transfer of some or all of Wing It’s assets,
                  whether as a going concern or as part of bankruptcy,
                  liquidation, or similar proceeding, in which personal
                  information held by Wing It about Users is among the assets
                  transferred.
                </li>
                <li>
                  To third parties to market their products or services to you
                  if you have consented to these disclosures. For more
                  information see Section 9 below.
                </li>
                <li>To fulfill the purpose for which you provide it.</li>
                <li>
                  For any other purpose disclosed by us when you provide the
                  information.
                </li>
                <li>
                  With your consent. We may also disclose your personal
                  information:
                </li>
              </ol>
              <ol type="a">
                <li>
                  To comply with any court order, law, or legal process,
                  including to respond to any government or regulatory request.
                </li>
                <li>
                  To enforce or apply our Terms and Conditions of Service and
                  other agreements, including for billing and collection
                  purposes.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to
                  protect the rights, property, or safety of Wing It, Users, or
                  others.
                </li>
              </ol>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>9&nbsp;</strong>
              <strong>
                CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION
              </strong>
              <p>
                Wing It strives to provide you with choices regarding the
                personal information you provide to us. Wing It has created
                mechanisms to provide you with the following control over your
                information.
              </p>
              <p>
                a. Tracking Technologies and Advertising. You can set your
                browser to refuse all or some browser cookies, or to alert you
                when cookies are being sent. If you disable or refuse cookies,
                please note that some areas of the Wing It Platform may be
                inaccessible or not function properly.
              </p>
              <p>
                b. Disclosure of Your Information for Third-Party Advertising.
                If you do not want us to share your personal information with
                unaffiliated or non-agent third parties for promotional
                purposes, you can opt-out by…
              </p>
              <p>
                c. Promotional Offers from Wing It. If you do not want wish to
                have your contact information used by Wing It to promote our own
                or third parties’ products or services, you can opt-out by
                emailing support@wingit.us.
              </p>
              <p>
                d. Targeted Advertising. If you do not want us to use
                information that we collect or that you provide to us to deliver
                advertisements according to our advertiser’s target audience
                preferences, you can opt-out by submitting a request to
                support@wingit.us.{" "}
              </p>
              <p>
                Wing It does not control third parties’ collection or use of
                your information to serve interest-based advertising. However,
                these third parties may provide you with ways to choose not to
                have your information collected or used in a certain way. You
                can opt out of receiving targeted ads from members of the
                Network Advertising initiative on its website.
              </p>
              <p>
                California residents may have additional personal information
                rights and choices. Please see the California Privacy Rights
                Section below.
              </p>
              <p>
                Nevada residents who wish to exercise their sale opt-out rights
                under Nevada Revised Statutes Chapter 603A may submit a request
                to support@wingit.us. However, please know that WingIt does not
                currently sell data triggering that statute’s opt-out
                requirement.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>10</strong>&nbsp;
              <strong>CALIFORNIA PRIVACY RIGHTS</strong>.
              <p>
                If you are a California resident, California law may provide you
                with additional rights regarding our use of your personal
                information.
              </p>
              <p>
                California’s “Shine the Light” law (Civil Code Section 1798.33)
                permits Users of the Wing It Platform that are California
                residents to request certain information regarding our
                disclosure of personal information to third parties for their
                direct marketing purposes. To make such a request please send an
                email to support@wingit.us or write us at: 706 Massachusetts
                St., Lawrence, KS 66044.
              </p>
              <p>Exercising Your CCPA Data Protection Rights</p>
              <p>
                In order to exercise any of Your rights under the CCPA, and if
                You are a California resident, You can contact Us: By email:
                support@wingit.us
              </p>
              <p>
                Only You, or a person registered with the California Secretary
                of State that You authorize to act on Your behalf, may make a
                verifiable request related to Your personal information.
              </p>
              <p>Your request to Us must:</p>
              <ul>
                <li>
                  Provide sufficient information that allows Us to reasonably
                  verify You are the person about whom We collected personal
                  information or an authorized representative
                </li>
                <li>
                  Describe Your request with sufficient detail that allows Us to
                  properly understand, evaluate, and respond to it
                </li>
              </ul>
              <p>
                We cannot respond to Your request or provide You with the
                required information if We cannot:
              </p>
              <ul>
                <li>Verify Your identity or authority to make the request</li>
                <li>
                  And confirm that the personal information relates to You
                </li>
              </ul>
              <p>
                We will disclose and deliver the required information free of
                charge within 45 days of receiving Your verifiable request. The
                time period to provide the required information may be extended
                once by an additional 45 days when reasonably necessary and with
                prior notice.
              </p>
              <p>
                Any disclosures We provide will only cover the 12-month period
                preceding the verifiable request’s receipt.
              </p>
              <p>
                For data portability requests, We will select a format to
                provide Your personal information that is readily usable and
                should allow You to transmit the information from one entity to
                another entity without hindrance.
              </p>
              <p>Do Not Sell My Personal Information</p>
              <p>
                You have the right to opt-out of the sale of Your personal
                information. Once We receive and confirm a verifiable consumer
                request from You, we will stop selling Your personal
                information. To exercise Your right to opt-out, please contact
                Us at support@wingit.us
              </p>
              <p>
                WingIt does not sell personal information to third parties:
                we’re not a data broker and we don’t put personal information on
                the open market. However, there may be certain third parties
                that we partner with (for example, our analytics or advertising
                partners) that may use technology on theWing It
                Platformwhichsells personal information as defined by the CCPA
                law. If you wish to opt out of the use of Your personal
                information for interest-based advertising purposes and these
                potential sales as defined under CCPA law, you may do so by
                emailing support@wingit.us.
              </p>
              <p>
                Please note that any opt out is specific to the browser You use.
                You may need to opt out on every browser that You use.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>11</strong>&nbsp;
              <strong>
                GENERAL DATA PROTECTION REGULATION (“GDPR”) COMPLIANCE
              </strong>
              .&nbsp; As a result of the General Data Protection Regulation
              (“GDPR”), Wing It has implemented a number of systems across our
              organization to ensure we comply with the intent and spirit of the
              law.
              <p>
                If you are a citizen under the European Union, you have certain
                rights under the General Data Protection Regulation regarding
                information we collect, including the following:{" "}
              </p>
              <ul>
                <li>
                  The right to be provided with a copy of your personal
                  information (the right of access).
                </li>
                <li>
                  The right to require us to correct any mistakes in your
                  personal information.
                </li>
                <li>
                  The right to require us to delete your personal information in
                  certain situations
                </li>
                <li>
                  The right to require us to restrict processing of your
                  personal information in certain circumstances, e.g. if you
                  contest the accuracy of the data.
                </li>
                <li>
                  The right to receive the personal information you provided to
                  us, in a structured, commonly used and machine-readable format
                  and/or transmit that data to a third party – in certain
                  situations.
                </li>
                <li>
                  The right to object:
                  <ul>
                    <li>
                      at any time to your personal information being processed
                      for direct marketing (including profiling);
                    </li>
                    <li>
                      in certain other situations to our continued processing of
                      your personal information, e.g. processing carried out for
                      the purpose of our legitimate interests.
                    </li>
                  </ul>
                </li>
                <li>
                  The right not to be subject to a decision based solely on
                  automated processing (including profiling) that produces legal
                  effects concerning you or similarly significantly affects you.
                </li>
              </ul>
              If you are a citizen under the European Union who has provided us
              with information subject to GDPR and would like exercise any of
              the foregoing rights, please contact us at support@wingit.us.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>12</strong>&nbsp;
              <strong>DATA SECURITY</strong>.To protect and prevent unauthorized
              access to your personal information, maintain data accuracy,
              prevent accidental loss, destruction, damage or theft of data,
              Wing It implements and maintains security and organizational
              measures with commercially reasonable physical, technical and
              administrative controls. Please note that no method of
              transmission over the Internet is 100% secure. Remember, when
              visiting a third-party site from the Wing It Platform, we do not
              control the privacy policies of other parties, so please check
              before browsing on those sites to ensure you agree with their
              terms.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>13</strong>&nbsp;
              <strong>CHANGES TO THIS PRIVACY POLICY</strong>.This privacy
              policy may be updated from time to time, and when the policy is
              updated, the version date will appear at the top of this privacy
              policy. If we make material changes, you will be notified through
              a notice on the Wing It Platform. You are responsible for
              periodically visiting the Wing It Platform and this privacy policy
              to check for any changes
            </Typography>
          </Box>
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default PrivacyPolicyModal;
