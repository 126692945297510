import client from "Api";
import axios from "axios";
import { MAP_QUEST_TOKEN, MAPBOX_TOKEN } from "constants/constants";
import { mapBoxUrl, mapQuestUrl, propertyUrl } from "helpers/urls";
import { Dispatch } from "redux";
import { AlertActions, AlertType, IAlertPayload, IResponseData } from "store";

import {
  IActivity,
  IActivityDate,
  IMarker,
  IProperty,
  IPropertyRequest,
  IUpdatePropertyState,
  PropertyActions,
} from "./PropertyInterface";
import { PropertyType } from "./PropertyTypes";

export const updatePropertyStep = (payload: number) => {
  return (dispatch: Dispatch<PropertyActions>) => {
    dispatch({ type: PropertyType.UPDATE_PROPERTY_STEP, payload });
  };
};

export const updatePropertyDetails = (
  payload: IUpdatePropertyState,
  propertyId: string,
) => {
  return async (
    dispatch: Dispatch<PropertyActions | AlertActions>,
  ): Promise<IResponseData> => {
    try {
      dispatch({ type: PropertyType.UPDATE_PROPERTY_REQUEST });
      const response: IResponseData = await client.patch(
        `${propertyUrl}owner/update/${propertyId}`,
        payload,
      );
      dispatch({
        type: PropertyType.UPDATE_PROPERTY_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.UPDATE_PROPERTY_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const updatePropertyStatus = (
  payload: IUpdatePropertyState,
  propertyId: string,
) => {
  return async (
    dispatch: Dispatch<PropertyActions | AlertActions>,
  ): Promise<IResponseData> => {
    try {
      dispatch({ type: PropertyType.UPDATE_PROPERTY_STATUS_REQUEST });
      const response: IResponseData = await client.patch(
        `${propertyUrl}owner/updatestatus/${propertyId}`,
        payload,
      );
      dispatch({
        type: PropertyType.UPDATE_PROPERTY_STATUS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.UPDATE_PROPERTY_STATUS_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const searchProperties = async (searchParams: string) => {
  try {
    const url = `${mapBoxUrl}${searchParams}.json?access_token=${MAPBOX_TOKEN}&cachebuster=1566806258853&autocomplete=true&language=en&limit=10&types=place,postcode,address,region,district&country=us`;
    // const url = `${mapBoxUrl}${searchParams}.json?access_token=${MAPBOX_TOKEN}&cachebuster=1566806258853&autocomplete=true&language=en&limit=10&types=place,postcode,address,region,district`;
    const response = await axios.get(url);
    return response.data.features;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAddressDetailsByLatLong_MAPBOX = async (points: number[]) => {
  try {
    const url = `${mapBoxUrl}${points[0]},${points[1]}.json?access_token=${MAPBOX_TOKEN}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAddressDetailsByLatLong = async (points: number[]) => {
  try {
    const url = `${mapQuestUrl}address?key=${MAP_QUEST_TOKEN}&location=${points[1]},${points[0]}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const getAddressDetailsByPlace = async (place: string) => {
  try {
    const url = `${mapQuestUrl}address?key=${MAP_QUEST_TOKEN}&location=${place}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const createProperty = (payload: IPropertyRequest) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.CREATE_PROPERTY_REQUEST });

      const response: IResponseData = await client.post(
        `${propertyUrl}owner/create`,
        payload,
      );
      dispatch({
        type: PropertyType.CREATE_PROPERTY_SUCCESS,
        payload: response.data as IProperty,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.CREATE_PROPERTY_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const clearPropertyData = () => {
  return (dispatch: Dispatch<PropertyActions>) => {
    dispatch({ type: PropertyType.CLEAR_PROPERTY_DATA });
  };
};

export const clearFoundPropertyData = () => {
  return (dispatch: Dispatch<PropertyActions>) => {
    dispatch({ type: PropertyType.CLEAR_FOUND_PROPERTY_DATA });
  };
};

export const claimPropertyData = (propertyId: string) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.CLAIM_PROPERTY_REQUEST });
      const body = {
        lastStep: "3",
      };
      const response: IResponseData = await client.patch(
        `${propertyUrl}owner/claim/${propertyId}`,
        body,
      );
      dispatch({
        type: PropertyType.CLAIM_PROPERTY_SUCCESS,
        // payload: response.data as IProperty,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.CLAIM_PROPERTY_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const updateMarkersData = (
  propertyId: string,
  type: string,
  markers: IMarker[],
  lastStep: string,
) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.MANAGE_MARKERS_REQUEST });
      const body = {
        lastStep,
        // lastStep: type === MarkerTypes.PARKING ? "9" : "8",
        markers,
      };
      const response: IResponseData = await client.put(
        `${propertyUrl}owner/property/${propertyId}/markers/${type}`,
        body,
      );
      dispatch({
        type: PropertyType.MANAGE_MARKERS_SUCCESS,
        payload: response.data as IProperty,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.MANAGE_MARKERS_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};
export const getAllProperties = () => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.GET_ALL_PROPERTIES_REQUEST });
      const response: IResponseData = await client.get(
        `${propertyUrl}owner/get/all`,
      );
      dispatch({
        type: PropertyType.GET_ALL_PROPERTIES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.GET_ALL_PROPERTIES_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const getPropertyDetails = (propertyId: string) => {
  return async (
    dispatch: Dispatch<PropertyActions | AlertActions>,
  ): Promise<IResponseData<IProperty>> => {
    try {
      dispatch({ type: PropertyType.GET_PROPERTY_DETAILS_REQUEST });
      const response: IResponseData = await client.get(
        `${propertyUrl}owner/get/${propertyId}`,
      );
      dispatch({
        type: PropertyType.GET_PROPERTY_DETAILS_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.GET_PROPERTY_DETAILS_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const createPropertyActivity = (
  payload: any,
  propertyId: string,
  activityId: string,
) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({
        type: PropertyType.CREATE_PROPERTY_ACTIVITY_REQUEST,
      });
      const response: IResponseData = await client.post(
        `${propertyUrl}owner/property/${propertyId}/activity/${activityId}`,
        payload,
      );
      dispatch({
        type: PropertyType.CREATE_PROPERTY_ACTIVITY_SUCCESS,
        payload: response.data[0],
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.CREATE_PROPERTY_ACTIVITY_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const setSelectedActivityDetail = (payload: string) => {
  return async (dispatch: Dispatch<PropertyActions>) => {
    dispatch({ type: PropertyType.SET_ACTIVITY_DETAIL, payload });
  };
};

export const showDateRangeCalender = (payload: boolean) => {
  return async (dispatch: Dispatch<PropertyActions>) => {
    dispatch({
      type: PropertyType.SHOW_DATE_RANGE_CALENDER,
      payload,
    });
  };
};

export const addSelectedActivityDate = (
  payload: IActivityDate,
  areaActivityId: string,
) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({
        type: PropertyType.ADD_ACTIVITY_DATE_REQUEST,
      });
      const response: IResponseData = await client.post(
        `${propertyUrl}owner/areaActivity/${areaActivityId}/date`,
        payload,
      );
      dispatch({
        type: PropertyType.ADD_ACTIVITY_DATE_SUCCESS,
        payload: { ...payload, documentId: response.data.documentID },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.ADD_ACTIVITY_DATE_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const setEditActiveIndex = (
  editActive: boolean,
  index: number | undefined,
) => {
  return async (dispatch: Dispatch<PropertyActions>) => {
    dispatch({
      type: PropertyType.SET_EDIT_ACTIVE_INDEX,
      payload: { editActive, index },
    });
  };
};

export const editSelectedActivityDate = (
  payload: IActivityDate,
  areaActivityId: string,
) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({
        type: PropertyType.EDIT_ACTIVITY_DATE_REQUEST,
      });
      const response: IResponseData = await client.put(
        `${propertyUrl}owner/areaActivity/${areaActivityId}/date/${payload.documentId}`,
        payload,
      );
      dispatch({
        type: PropertyType.EDIT_ACTIVITY_DATE_SUCCESS,
        payload: { ...payload },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.EDIT_ACTIVITY_DATE_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const deleteSelectedActivityDate = (
  dateId: string,
  areaActivityId: string,
) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({
        type: PropertyType.DELETE_ACTIVITY_DATE_REQUEST,
      });
      const response: IResponseData = await client.delete(
        `${propertyUrl}owner/areaActivity/${areaActivityId}/date/${dateId}`,
      );
      dispatch({
        type: PropertyType.DELETE_ACTIVITY_DATE_SUCCESS,
        payload: dateId,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.DELETE_ACTIVITY_DATE_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const getPropertyActivities = () => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.GET_ACTIVITIES_REQUEST });
      const response: IResponseData = await client.get(
        `${propertyUrl}owner/allActivities`,
      );
      dispatch({
        type: PropertyType.GET_ACTIVITIES_SUCCESS,
        payload: response.data as IActivity[],
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.GET_ACTIVITIES_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const getPropertyActivityDetails = (activityID: string) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.GET_ACTIVITY_DETAILS_REQUEST });
      const response: IResponseData = await client.get(
        `${propertyUrl}details/["${activityID}"]`,
      );
      dispatch({
        type: PropertyType.GET_ACTIVITY_DETAILS_SUCCESS,
        payload: response.data[0] as IActivity,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.GET_ACTIVITY_DETAILS_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const addActivityImage = (payload: any, areaActivityId: string) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      const formData = new FormData();
      formData.append("image", payload.image);
      formData.append("cropped_image", payload.croppedFile);
      formData.append("data", JSON.stringify(payload.data));
      formData.append("canvasData", JSON.stringify(payload.canvasData));
      formData.append("cropBoxData", JSON.stringify(payload.cropBoxData));
      formData.append("minZoom", payload.minZoom);
      formData.append("zoom", payload.zoom);

      dispatch({ type: PropertyType.ADD_ACTIVITY_IMAGE_REQUEST });
      const response: IResponseData = await client.post(
        `${propertyUrl}owner/areaActivity/${areaActivityId}/media`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );
      const successPayload: IAlertPayload = { message: "Upload Successful" };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      dispatch({
        type: PropertyType.ADD_ACTIVITY_IMAGE_SUCCESS,
        payload: {
          croppedImageDetails: JSON.parse(
            window.atob(response.data?.croppedImageDetails),
          ),
          croppedImagePath: response.data?.croppedImageReadUrl, // URL.createObjectURL(payload.croppedFile),
          originalImagePath: response.data?.originalImageReadUrl,
          documentId: response.data?.documentId,
        },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.ADD_ACTIVITY_IMAGE_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};

export const deleteActivityImage = (mediaId: string) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.DELETE_ACTIVITY_IMAGE_REQUEST });
      const response: IResponseData = await client.delete(
        `${propertyUrl}owner/areaActivity/media/${mediaId}`,
      );
      const successPayload: IAlertPayload = { message: "Delete Successful" };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      dispatch({
        type: PropertyType.DELETE_ACTIVITY_IMAGE_SUCCESS,
        payload: mediaId,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.DELETE_ACTIVITY_IMAGE_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};

export const updateAreaActivity = (payload: any, areaActivityId: string) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.UPDATE_AREA_ACTIVITY_REQUEST });
      const response: IResponseData = await client.post(
        `${propertyUrl}owner/areaActivity/${areaActivityId}`,
        payload,
      );
      dispatch({
        type: PropertyType.UPDATE_AREA_ACTIVITY_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.UPDATE_AREA_ACTIVITY_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const setEditingProperty = (payload: boolean) => {
  return async (dispatch: Dispatch<PropertyActions>) => {
    dispatch({ type: PropertyType.SET_EDITING_PROPERTY, payload });
  };
};

export const updatePropertyFields = (payload: any, propertyId: string) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.UPDATE_PROPERTY_FIELDS_REQUEST });
      const response: IResponseData = await client.patch(
        `${propertyUrl}owner/updatefields/${propertyId}`,
        payload,
      );
      dispatch({
        type: PropertyType.UPDATE_PROPERTY_FIELDS_SUCCESS,
        payload,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.UPDATE_PROPERTY_FIELDS_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const getAvailableDates = (areaActivityId: string) => {
  return async (dispatch: Dispatch<PropertyActions | AlertActions>) => {
    try {
      dispatch({ type: PropertyType.GET_PROPERTY_AVAILABLE_DATES_REQUEST });
      const response: IResponseData = await client.get(
        `${propertyUrl}owner/areaActivity/${areaActivityId}/availabledates`,
      );
      dispatch({
        type: PropertyType.GET_PROPERTY_AVAILABLE_DATES_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: PropertyType.GET_PROPERTY_AVAILABLE_DATES_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};
