import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useFocus from "hooks/useFocus";
import { useEffect, useState } from "react";
import {
  checkUserSession,
  getActivities,
  IActivites,
  IResponseData,
  IUserAuthRequestData,
  updateAccountsInfo,
  updateUserAuthData,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import palette from "theme/palette";
import { unmaskUSNumber } from "utils/utils";

function Activity() {
  const dispatch = useAppDispatch();

  const { userAuthData } = useAppSelector(s => s.auth);
  const { loading, isUpdateAccountloading, activities } = useAppSelector(
    s => s.account,
  );
  const [activityIds, setActivityIds] = useState<string[]>([]);

  useEffect(() => {
    if (userAuthData.interestedActivity) {
      setActivityIds(userAuthData.interestedActivity.split(","));
    }
  }, [userAuthData]);

  useEffect(() => {
    if (activities.length === 0) {
      dispatch(getActivities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusFinishButtonRef = useFocus(
    userAuthData.interestedActivity.length >= 3,
  );

  const handleSelection = (activity: string) => {
    const newSet = new Set(activityIds);
    if (newSet.has(activity)) {
      newSet.delete(activity);
    } else {
      newSet.add(activity);
    }
    dispatch(
      updateUserAuthData({
        ...userAuthData,
        interestedActivity: [...newSet].join(","),
      }),
    );
  };

  const handleCloseModal = () => {
    dispatch(checkUserSession());
  };

  const handleFinishSetup = async () => {
    const payload: IUserAuthRequestData = {
      ...userAuthData,
      phone: `${unmaskUSNumber(userAuthData.phone)}`,
      isSignupFinished: true,
    };

    const response: IResponseData = (await dispatch(
      updateAccountsInfo(payload),
    )) as IResponseData;

    if (response && !response?.error) {
      handleCloseModal();
    }
  };

  return (
    <Box>
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="center"
        spacing={theme.spacing(3)}
      >
        <>
          <Box sx={{ textAlign: "center", px: theme.spacing(1) }}>
            <Typography gutterBottom component="h3" variant="h3">
              Activities
            </Typography>
            <Typography component="p" variant="h6">
              Please select a minimum of 3 activities you enjoy. You can update
              these any time in your profile. We&apos;ll use this to help you
              find great bookings!
            </Typography>
          </Box>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            spacing={1.75}
            sx={{ flexWrap: "wrap" }}
          >
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                {activities.length > 0 ? (
                  <>
                    {activities.map((activity: IActivites) => {
                      return (
                        <Box key={activity.activityId}>
                          <Chip
                            clickable
                            label={
                              <Typography
                                component="div"
                                sx={{
                                  color: activityIds.includes(
                                    activity.activityId,
                                  )
                                    ? palette.primary.contrastText
                                    : "",
                                }}
                                variant="h6"
                              >
                                {activity.activityName}
                              </Typography>
                            }
                            variant={
                              activityIds.includes(activity.activityId)
                                ? "filled"
                                : "outlined"
                            }
                            onClick={() => handleSelection(activity.activityId)}
                          />
                        </Box>
                      );
                    })}
                  </>
                ) : (
                  <Typography component="p" variant="h6">
                    No activities available at the moment.
                  </Typography>
                )}
              </>
            )}
          </Stack>
          <LoadingButton
            ref={focusFinishButtonRef}
            fullWidth
            disabled={!(activityIds.length >= 3)}
            loading={isUpdateAccountloading}
            variant="contained"
            onClick={handleFinishSetup}
          >
            Finish
          </LoadingButton>
        </>
      </Stack>
    </Box>
  );
}

export default Activity;
