import BackIcon from "@mui/icons-material/ArrowBack";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Loader from "components/loader";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getGuestBookings } from "store/booking/BookingAction";
import { IGuestBooking } from "store/booking/BookingInterface";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { getFormattedDateRange } from "utils/utils";

interface IProps {
  loading: boolean;
  guestBookings: IGuestBooking[];
  type: string;
}

const GuestBookingTable = (props: IProps) => {
  const { loading, guestBookings, type } = props;

  const navigate = useNavigate();

  const guestBookingReview = async (booking: IGuestBooking) => {
    navigate(`/booking-review/${booking.bookingID}`);
  };

  return (
    <TableContainer sx={{ marginTop: theme.spacing(3) }}>
      <Table aria-label="guest booking table" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow
            sx={{
              "& .MuiTableCell-root": {
                fontSize: "12px",
                fontWeight: 700,
                color: "#555555",
                borderBottom: "1px solid #D9D9D9",
              },
            }}
          >
            <TableCell align="left">Scheduled Date</TableCell>
            <TableCell align="left">Listing</TableCell>
            <TableCell align="center">Participants #</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <TableCell colSpan={7}>
              <Loader position="center" />
            </TableCell>
          ) : !guestBookings.length ? (
            <TableCell colSpan={7}>
              <Box
                alignItems="center"
                display="flex"
                height={140}
                justifyContent="center"
              >
                <Typography variant="h2">No Bookings available</Typography>
              </Box>
            </TableCell>
          ) : (
            guestBookings.map(booking => (
              <TableRow
                key={booking.documentID}
                sx={{
                  "& .MuiTableCell-root": {
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                  borderBottom: "1px solid #D9D9D9",
                }}
              >
                <TableCell align="left" sx={{ borderWidth: 0 }}>
                  {getFormattedDateRange(
                    booking.bookingStartDate,
                    booking.bookingEndDate,
                  )}
                </TableCell>
                <TableCell align="left" sx={{ borderWidth: 0 }}>
                  <Typography
                    sx={{
                      textDecoration: "underline",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => guestBookingReview(booking)}
                  >
                    {booking.activityName} at {booking.propertyName}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{ borderWidth: 0 }}>
                  {booking.participantCount}
                </TableCell>
                <TableCell align="right" sx={{ borderWidth: 0 }}>
                  <Stack
                    direction="row"
                    justifyContent={type === "active" ? "flex-end" : "center"}
                    spacing={2}
                  >
                    <Button
                      // disabled
                      startIcon={<EmailOutlinedIcon />}
                      sx={{
                        color: "#000000",
                        textDecoration: "underline",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={(e: any) => {
                        window.location.href = `mailto: ${
                          booking.hostEmailID || ""
                        }`;
                        e.preventDefault();
                      }}
                    >
                      Message
                    </Button>
                    {type === "active" && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          window.open(
                            "https://support.wingit.us/hc/en-us/requests/new",
                            "_blank",
                          );
                        }}
                      >
                        Cancel Booking
                      </Button>
                    )}
                  </Stack>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const GuestBookings = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, guestActiveBookings, guestPastBookings } = useAppSelector(
    s => s.booking,
  );

  useEffect(() => {
    dispatch(getGuestBookings("active"));
  }, [dispatch]);

  const [value, setValue] = useState("active");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(getGuestBookings(newValue));
    setValue(newValue);
  };
  const handleBack = () => navigate("/");

  return (
    <Paper
      sx={{
        width: "100%",
        minHeight: "450px",
        padding: theme.spacing(5),
        boxSizing: "border-box",
      }}
    >
      <IconButton
        aria-label="Back Button"
        size="large"
        sx={{ mb: 3 }}
        onClick={() => handleBack()}
      >
        <BackIcon />
      </IconButton>
      <Typography
        sx={{ marginBottom: theme.spacing(3), fontSize: "48px" }}
        variant="h2"
      >
        Bookings
      </Typography>
      <Box sx={{ width: "100%", marginTop: "30px" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              aria-label="Bookings Tablist"
              indicatorColor={"secondary"}
              sx={{
                ".MuiTab-textColorPrimary": {
                  color: "#2A2A2A",
                  fontSize: "16px",
                  fontWeight: "600",
                },
                ".Mui-selected": {
                  color: "#2A2A2A !important",
                },
              }}
              onChange={handleChange}
            >
              <Tab
                label={`Active Bookings (${guestActiveBookings?.length || 0})`}
                value="active"
              />
              <Tab label="Past Bookings" value="past" />
            </TabList>
          </Box>
          <TabPanel sx={{ padding: 0 }} value="active">
            <GuestBookingTable
              guestBookings={guestActiveBookings}
              loading={loading}
              type="active"
            />
          </TabPanel>
          <TabPanel sx={{ padding: 0 }} value="past">
            <GuestBookingTable
              guestBookings={guestPastBookings}
              loading={loading}
              type="past"
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Paper>
  );
};

export default GuestBookings;
