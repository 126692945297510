import Grid from "@mui/material/Grid";
import { useIsMobile } from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import theme from "theme";

import LeftContainer from "./LeftContainer";
import { RightContainer } from "./RightContainer";

function Property() {
  const isMobile = useIsMobile("midsm");
  const [innerHeight, setInnerHeight] = useState(0);

  useEffect(() => {
    if (isMobile) {
      setInnerHeight(window.innerHeight);
    }
  }, [isMobile]);

  return (
    <Grid
      container
      sx={{
        height: "100%",
        ...(isMobile && {
          position: "fixed",
          bottom: 0,
        }),
      }}
    >
      <Grid
        container
        item
        sx={{
          position: "relative",
          height: "100%",
          maxHeight: "100%",
          boxSizing: "border-box",
          ...(isMobile && {
            background: theme.palette.secondary.main,
          }),
        }}
      >
        <Grid
          item
          md={3}
          sm={12}
          sx={{
            height: "100vh",
            width: "50vw",
            ...(isMobile && {
              height: `${(35 / 100) * innerHeight}px`,
              width: "100vh",
            }),
          }}
          xs={12}
        >
          <LeftContainer />
        </Grid>
        <Grid
          item
          md
          sm={12}
          sx={{
            background: theme.palette.common.white,
            overflow: "hidden",
            height: "100vh",
            ...(isMobile && {
              height: `${(68 / 100) * innerHeight}px`,
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "100%",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }),
          }}
          xs={12}
        >
          <RightContainer />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Property;
