import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Modal from "components/Modal/ModalContainer";
import { WingItBlueLogo } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { Fragment, useState } from "react";
import { PropertySteps } from "store/property/PropertyTypes";
import theme from "theme";

import { RightMainComponentWrapper } from "../RightContainer";

interface IUnsureList {
  title: string;
  description: string;
}

const showUnsureText: IUnsureList[] = [
  {
    title: "Individual Property",
    description:
      "Select this option if your land is owned by a person and not a company or institution.",
  },
  {
    title: "Company / Organization",
    description:
      "Select this option if your land is owned by a company or institution.",
  },
];

function AddProperty() {
  const { navigateTo } = useNavigateStep();
  const [showModal, setShowModal] = useState<boolean>(false);
  const isMobile = useIsMobile("midsm");

  const addIndividualProperty = () =>
    navigateTo(PropertySteps.FIND_YOUR_PROPERTY);

  return (
    <RightMainComponentWrapper fullHeight center={!isMobile}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          flexGrow: 1,
        }}
      >
        <Box>
          <SvgIcon
            inheritViewBox
            component={WingItBlueLogo}
            sx={{ height: "42px", width: "49px" }}
          />
        </Box>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ textAlign: "center" }}
        >
          <Typography
            component="div"
            sx={{ mt: theme.spacing(isMobile ? 2 : 3) }}
            variant="h3"
          >
            Add New Property
          </Typography>
          <Typography component="div" mt={theme.spacing(2)} variant="h7">
            Select the account this property is attached to:
          </Typography>
        </Stack>
        <Stack
          alignItems="center"
          my={theme.spacing(isMobile ? 3 : 4)}
          width="100%"
        >
          <Button
            color="secondary"
            sx={{ width: "50%" }}
            variant="contained"
            onClick={addIndividualProperty}
          >
            Individual
          </Button>
          {/* <Stack
            alignItems="center"
            direction="row"
            sx={{ my: theme.spacing(isMobile ? 2 : 3) }}
          >
            <Box sx={hrLineStyle} />
            <Typography component="span" variant="subtitle1">
              or
            </Typography>
            <Box sx={hrLineStyle} />
          </Stack>
          <Button
            sx={{ width: "50%", whiteSpace: "nowrap" }}
            variant="outlined"
            onClick={() => {}}
          >
            Company / Organization
          </Button> */}
        </Stack>
        {/* <Typography
          className="clickable-text"
          sx={{ mt: isMobile ? 1 : 3 }}
          variant="h5"
          onClick={() => setShowModal(true)}
        >
          Unsure which to select?
        </Typography> */}
      </Stack>

      {showModal && (
        <Modal open={showModal}>
          <Box
            sx={{
              width: isMobile ? "100%" : "470px",
              maxWidth: "100%",
              backgroundColor: theme.palette.common.white,
            }}
          >
            <Box p={theme.spacing(3)}>
              <Stack
                alignItems="flex-start"
                direction="column"
                justifyContent="center"
                spacing={3}
              >
                {showUnsureText.map(
                  ({ title, description }: IUnsureList, index: number) => (
                    <Fragment key={title.replace(" ", "") + index.toString()}>
                      <Typography
                        component="h3"
                        sx={{
                          mb: theme.spacing(1),
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        variant="h3"
                      >
                        {title}
                      </Typography>
                      <Typography
                        component="p"
                        sx={{
                          mb: theme.spacing(2),
                          fontSize: "14px",
                        }}
                        variant="h6"
                      >
                        {description}
                      </Typography>
                    </Fragment>
                  ),
                )}
                <Button
                  fullWidth
                  color="secondary"
                  sx={{ mt: theme.spacing(1) }}
                  variant="contained"
                  onClick={() => setShowModal(false)}
                >
                  Okay
                </Button>
              </Stack>
            </Box>
          </Box>
        </Modal>
      )}
    </RightMainComponentWrapper>
  );
}

export default AddProperty;
