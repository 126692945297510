import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { MAPBOX_TOKEN } from "constants/constants";
import { DotSeperator } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
// import { HuntingIcon } from "helpers/images";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Map, {
  GeolocateControl,
  Marker,
  NavigationControl,
  Popup,
} from "react-map-gl";
import { useNavigate } from "react-router-dom";
import { fetchMapProperties, IProperty } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { formatPrice } from "utils/utils";

import DefaultImg from "../../../assets/searchPageIcons/rectangle.png";

function MapContainer() {
  const navigate = useNavigate();
  const mapRef = useRef<any>();
  const dispatch = useAppDispatch();
  const { mapProperties, currentActivity, currentLocation, currentDate } =
    useAppSelector(s => s.search);

  const isMobile = useIsMobile("midsm");
  const isSmallMobile = useIsMobile("sm");

  const [markers, setMarkers] = useState<any>([]);
  const [selectedProperty, setSelectedProperty] = useState<any>();
  const [showPropertyInfoWindow, setShowPropertyInfoWindow] = useState(false);

  useEffect(() => {
    if (mapProperties?.length > 0) {
      const markers = [] as any[];
      mapProperties.forEach((property: any) => {
        const markerData: any = {
          ...property,
          latitude: parseFloat(property.lat),
          longitude: parseFloat(property.lng),
        };
        markers.push(markerData);
      });
      setMarkers(markers);
    } else {
      setMarkers([]);
    }
    setSelectedProperty(undefined);
    setShowPropertyInfoWindow(false);
  }, [mapProperties]);

  useEffect(() => {
    if (currentLocation) {
      setTimeout(() => {
        mapRef.current?.flyTo({
          center: currentLocation.center,
          duration: 50,
          zoom: 6,
        });
      }, 500);
    }
  }, [currentLocation]);

  const onMarkerClick = (e: any, index: number) => {
    e?.originalEvent?.preventDefault?.();
    e?.originalEvent?.stopPropagation?.();
    setSelectedProperty(markers[index]);
    setShowPropertyInfoWindow(true);
  };

  const renderMarkers = () => {
    return (
      <>
        {markers.map((marker: any, index: number) => (
          <Marker
            key={marker.documentId}
            anchor="center"
            latitude={marker.latitude}
            longitude={marker.longitude}
            onClick={e => onMarkerClick(e, index)}
          >
            {marker.documentId === selectedProperty?.documentId ? (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                spacing={theme.spacing(1)}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  padding: 1,
                  borderRadius: "20px",
                  paddingX: 2,
                }}
              >
                {/* <SvgIcon
                  inheritViewBox
                  component={HuntingIcon}
                  sx={{
                    "& path": { fill: "white" },
                    height: "16px",
                    width: "10.56px",
                  }}
                /> */}
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "white",
                    lineHeight: "20px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      display: "block",
                      fontWeight: 600,
                      color: "#2A2A2A",
                      maxWidth: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {marker.name}
                  </Typography>
                  ${formatPrice(marker.area[0].activities[0].price)}
                </Typography>
              </Stack>
            ) : (
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="center"
                spacing={theme.spacing(1)}
                sx={{
                  backgroundColor: "white",
                  padding: 1,
                  borderRadius: "20px",
                  paddingX: 2,
                }}
              >
                {/* <SvgIcon
                  inheritViewBox
                  component={HuntingIcon}
                  sx={{
                    height: "16px",
                    width: "10.56px",
                  }}
                /> */}
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      display: "block",
                      fontWeight: 600,
                      color: "#2A2A2A",
                      maxWidth: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {marker.name}
                  </Typography>
                  ${formatPrice(marker.area[0].activities[0].price)}
                </Typography>
              </Stack>
            )}
          </Marker>
        ))}
      </>
    );
  };

  const navToPropertyDetails = (property: IProperty) => {
    navigate(`/property/${property.documentId}`);
  };

  const onMapMoveEnd = (e: any) => {
    const bounds = e.target?.getBounds();

    // Extract coordinates
    const ne = bounds?.getNorthEast(); // Northeast corner
    const sw = bounds?.getSouthWest(); // Southwest corner

    if (currentActivity?.id && currentDate.length > 0) {
      const payload = {
        activities: [`${currentActivity.id}`],
        startDate: `${moment(currentDate[0].startDate).format()}`,
        endDate: `${moment(currentDate[0].endDate).format()}`,
        lat: e.viewState.latitude.toString(),
        long: e.viewState.longitude.toString(),
        zoomLevel: e.viewState.zoom.toString(),
        southWestLng: sw.lng.toString(),
        southWestLat: sw.lat.toString(),
        northEastLng: ne.lng.toString(),
        northEastLat: ne.lat.toString(),
      };
      dispatch(fetchMapProperties(payload));
    }
  };

  return (
    <Box height="100%" width="100%">
      <Map
        ref={mapRef}
        initialViewState={{
          longitude: -98.4842,
          latitude: 39.0119,
          zoom: 6,
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
        // mapStyle="mapbox://styles/mapbox/light-v9"
        // mapStyle="mapbox://styles/mapbox/streets-v9?optimize=true"
        style={{
          width: "100%",
          height: isMobile ? "300px" : "calc(100vh - 143px)",
        }}
        onMoveEnd={onMapMoveEnd}
      >
        <GeolocateControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        {renderMarkers()}
        {showPropertyInfoWindow && (
          <Popup
            closeButton={true}
            closeOnClick={false}
            latitude={selectedProperty.lat}
            longitude={selectedProperty.lng}
            maxWidth="400px"
            offset={30}
            onClose={() => setShowPropertyInfoWindow(false)}
          >
            <Stack
              direction="row"
              spacing={0.5}
              sx={{
                height: "94px",
                borderRadius: "15px",
                padding: 0,
                cursor: "pointer",
                maxWidth: isMobile ? "250px" : "unset",
              }}
              onClick={() => {
                navToPropertyDetails(selectedProperty);
              }}
            >
              {!isMobile ? (
                <img
                  alt={"activityImage"}
                  height="100%"
                  src={
                    selectedProperty.area[0].activities[0]
                      ?.areaActivityMedia?.[0]?.originalImagePath || DefaultImg
                  }
                  style={{
                    borderBottomLeftRadius: "15px",
                    borderTopLeftRadius: "15px",
                  }}
                  width="120px"
                />
              ) : null}

              <Stack
                justifyContent="center"
                sx={{
                  width: isMobile ? "100%" : "calc(100% - 120px)",
                  padding: isMobile ? "5px 10px" : "0",
                }}
              >
                <Typography
                  fontSize={isSmallMobile ? "12px" : "14px"}
                  sx={{
                    lineHeight: "22px",
                    fontWeight: 600,
                    width: "100%",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {selectedProperty.name}
                </Typography>
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Typography
                    fontSize={isSmallMobile ? "12px" : "14px"}
                    sx={{
                      lineHeight: "22px",
                      fontWeight: 600,
                    }}
                  >
                    {selectedProperty.area[0].activities[0].name}
                  </Typography>
                  <DotSeperator />
                  <Typography
                    fontSize={isSmallMobile ? "12px" : "14px"}
                    sx={{
                      lineHeight: "22px",
                      fontWeight: 600,
                    }}
                  >
                    ${formatPrice(selectedProperty.area[0].activities[0].price)}
                  </Typography>
                </Stack>
                <Stack
                  alignItems={isSmallMobile ? "start" : "center"}
                  direction={isSmallMobile ? "column" : "row"}
                  spacing={isSmallMobile ? 0 : 2}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "22px",
                      fontWeight: 400,
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        maxWidth: "110px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {selectedProperty.city}
                    </span>
                    <span>{selectedProperty.state}</span>
                  </Typography>
                  {!isSmallMobile && (
                    <DotSeperator height={"3px"} width={"3px"} />
                  )}
                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "22px",
                      fontWeight: 400,
                    }}
                  >
                    {selectedProperty.totalAcres} acres
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Popup>
        )}
      </Map>
    </Box>
  );
}

export default MapContainer;
