import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { IPlace } from "store/property/PropertyInterface";
import theme from "theme";

interface ISearchResultsList {
  mapSelectedArea: IPlace | undefined;
  places: IPlace[];
  onSelectPlace: (item: IPlace) => void;
}

function SearchResultsList({
  places,
  mapSelectedArea,
  onSelectPlace,
}: ISearchResultsList) {
  return (
    <>
      {!mapSelectedArea && places.length > 0 && (
        <Box
          style={{
            backgroundColor: "white",
            borderRadius: "10px",
            border: "none",
            height: 300,
            marginTop: 20,
            overflowY: "scroll",
          }}
        >
          {places.map(item => {
            const key = `${item.place_name}-${item.place_type}`;
            return (
              <Stack
                key={key}
                className="cursor-pointer"
                style={{
                  padding: 10,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
                onClick={() => onSelectPlace(item)}
              >
                <Typography>{item.place_name_en}</Typography>
              </Stack>
            );
          })}
        </Box>
      )}
    </>
  );
}

export default SearchResultsList;
