import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import theme from "theme";

interface IClaimPropertyModalProps {
  handleAgree: () => void;
  handleDisagree: () => void;
}

function ConfirmClaimPropertyModal({
  handleAgree,
  handleDisagree,
}: IClaimPropertyModalProps) {
  return (
    <Box>
      <Stack alignItems="flex-start" justifyContent="flex-start">
        <Typography component="h3" variant="h3">
          Please confirm that you own or control the access to this property
        </Typography>
        <Typography
          component="p"
          sx={{
            mt: theme.spacing(1),
          }}
          variant="h6"
        >
          Wing It reserves the right to confirm this through county ownership
          records and other means of verification
        </Typography>
        <Typography
          component="p"
          sx={{
            mt: theme.spacing(2),
            mb: theme.spacing(2),
          }}
          variant="h6"
        >
          I understand under the penalty of law that this is my property.
        </Typography>
        <Divider
          sx={{ width: "100%", mt: theme.spacing(2), mb: theme.spacing(2) }}
        />
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: theme.spacing(1), width: "100%" }}
        >
          <Button
            sx={{ width: "50%" }}
            variant="outlined"
            onClick={handleDisagree}
          >
            Disagree
          </Button>
          <Button
            color="secondary"
            sx={{ width: "50%" }}
            variant="contained"
            onClick={handleAgree}
          >
            Agree
          </Button>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", mt: theme.spacing(2) }}
        >
          <Typography
            className="clickable-text"
            variant="h5"
            onClick={() => {
              window.open(
                "https://support.wingit.us/hc/en-us/requests/new",
                "_blank",
              );
            }}
          >
            I need assistance
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}

export default ConfirmClaimPropertyModal;
