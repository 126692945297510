import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ImageCropper from "components/imageCropper/ImageCropper";
import CustomInputField from "components/TextField/CustomInputField";
import { DefaultImage } from "helpers/images";
import { useState } from "react";
import { alertError, IAddPhoto } from "store";
import { useAppDispatch } from "store/hooks";
import theme from "theme";
import { dataURItoBlob, handleRestrictSpaces } from "utils/utils";
import { ActivityPhotoUploadSchema } from "utils/validationSchema";
import { validateFileFormat, validateFileSize } from "utils/validator";

import { useAddPhotoForm } from "../usePropertyForm";

interface IAddPhotoProps {
  handleClose: () => void;
}

interface ICroppedImageData {
  originalFile: any;
  croppedFile: any;
  data: any;
  canvasData: any;
  cropBoxData: any;
  minZoom: number;
  zoom: number;
}

interface IUpload {
  previewAvatarImage: string;
  file: File | null;
  editImage: boolean;
  croppedImageDetails: ICroppedImageData;
}

const styles = {
  editIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    fontSize: "14px",
    backgroundColor: "rgba(15, 20, 25, 0.75)",
    borderRadius: "10px",
    padding: "6px",
    color: "whitesmoke",
    width: "60px",
    textAlign: "center",
    cursor: "pointer",
  },
  divider: { width: "100%", mt: theme.spacing(2), mb: theme.spacing(2) },
  photo: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 0,
    paddingTop: "56.25%",
    width: "100%",
  },
};

export default function UploadPhoto(props: IAddPhotoProps) {
  const { handleClose } = props;
  const dispatch = useAppDispatch();
  const [upload, setUpload] = useState<IUpload>({
    previewAvatarImage: "",
    file: null,
    editImage: false,
    croppedImageDetails: {} as ICroppedImageData,
  });

  const initialState: IAddPhoto = {
    title: "",
    description: "",
    file: null,
  };

  const handleSave = () => {};

  const formik = useAddPhotoForm(
    handleSave,
    initialState,
    ActivityPhotoUploadSchema,
  );
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    // isValid,
    // dirty,
  } = formik;

  const croppedImage = upload.croppedImageDetails?.croppedFile || "";
  const originalImage = upload.croppedImageDetails?.originalFile || "";

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isImage: boolean = file.type.includes("image");
    if (target?.files?.length === 1 && isImage) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (isImage && !validateFileFormat(file.name, allowedExtensions)) {
        dispatch(
          alertError({
            message:
              "File format is not supported. Please select one of the following file types: jpg, jpeg or png.",
          }),
        );
        return;
      }
      const maxSize = 100;
      const isFileSizeValid: boolean = validateFileSize(file.size, maxSize);

      if (isFileSizeValid) {
        setUpload({
          ...upload,
          previewAvatarImage: URL.createObjectURL(file),
          file,
          editImage: true,
        });
        console.info(file, URL.createObjectURL(file));
      } else {
        dispatch(
          alertError({
            message: `The image file must be ${maxSize} MB or less.`,
          }),
        );
      }
    } else {
      dispatch(
        alertError({
          message:
            "File format is not supported. Please select an image and try again.",
        }),
      );
    }
  };

  const handleCancelUploadImage = () => {
    setUpload({
      ...upload,
      previewAvatarImage: "",
      file: null,
      editImage: false,
    });
  };

  const handleApply = async (cropper: any, minZoom: number, zoom: number) => {
    const croppedCanvas = cropper.getCroppedCanvas();
    const dataURL = croppedCanvas.toDataURL("image/jpeg");
    const blob = dataURItoBlob(dataURL);
    const croppedFile = new File([blob], "croppedImage.jpeg");

    let newFile: Blob | null = null;
    const createFile = async () => {
      try {
        const existingImage = originalImage;
        const response = await fetch(existingImage);
        const data = await response.blob();
        const metadata = {
          type: "image/jpeg",
        };
        const file = new File([data], "activityPhoto.jpeg", metadata);
        // ... do something with the file or return it
        return file;
      } catch (error) {
        dispatch(
          alertError({
            message: `Error while uploading photo, Please try again later`,
          }),
        );
        return null;
      }
    };
    if (upload.file === null) {
      newFile = await createFile();
      if (newFile === null) return;
    }
    const uploadImageData = {
      originalFile: upload.file,
      croppedFile,
      data: cropper.getData(false),
      canvasData: cropper.getCanvasData(),
      cropBoxData: cropper.getCropBoxData(),
      minZoom,
      zoom,
    };
    setUpload({
      ...upload,
      croppedImageDetails: uploadImageData,
      editImage: false,
    });
  };

  const handleEdit = () => {
    setUpload({ ...upload, editImage: true });
  };

  return (
    <Box width="100%">
      <Stack alignItems="flex-start" justifyContent="flex-start">
        <Typography component="h3" variant="h3">
          Add photo
        </Typography>
        <Typography
          component="p"
          sx={{
            mt: theme.spacing(1),
          }}
          variant="h6"
        >
          Add a title and description to your photo.
        </Typography>
        <Divider sx={{ width: "100%", my: theme.spacing(2) }} />
        <Stack spacing={theme.spacing(3)} width="100%">
          <CustomInputField
            autoComplete="off"
            error={!!(touched?.title && errors?.title)}
            errormessage={touched?.title ? errors?.title : undefined}
            inputProps={{ maxLength: 70 }}
            label="Title"
            name="title"
            type="text"
            value={values.title}
            onBlur={handleBlur}
            onChange={e =>
              handleChange("title")(handleRestrictSpaces(e.target.value))
            }
          />
          <CustomInputField
            multiline
            autoComplete="off"
            error={!!(touched?.description && errors?.description)}
            errormessage={
              touched?.description ? errors?.description : undefined
            }
            inputProps={{ maxLength: 300 }}
            label="Description (optional)"
            maxRows={10}
            minRows={3}
            name="description"
            type="text"
            value={values.description}
            onBlur={handleBlur}
            onChange={e =>
              handleChange("description")(handleRestrictSpaces(e.target.value))
            }
          />
          {/* {values.file === null && ( */}
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              ...(!(croppedImage && originalImage) && {
                py: theme.spacing(10),
              }),
              border: `1px dashed ${theme.palette.text.secondary}`,
              borderRadius: "10px",
              marginTop: theme.spacing(1.5),
              width: "100%",
              position: "relative",
            }}
          >
            {croppedImage && originalImage ? (
              <Paper
                sx={[
                  {
                    backgroundImage: `url(${upload.croppedImageDetails.croppedFile})`,
                  },
                  styles.photo,
                ]}
              >
                <Typography sx={styles.editIcon} onClick={handleEdit}>
                  Edit
                </Typography>
              </Paper>
            ) : (
              <Typography>
                <label htmlFor="file">
                  <input
                    accept=".png,.jpeg,.jpg"
                    className="photo-upload"
                    id="file"
                    name="file"
                    style={{ display: "none" }}
                    type="file"
                    onChange={selectFile}
                    onClick={(e: any) => {
                      e.target.value = null;
                    }}
                  />
                  <DefaultImage style={{ cursor: "pointer" }} />
                </label>
              </Typography>
            )}
          </Stack>
          {/* )} */}
        </Stack>
      </Stack>
      <Divider sx={styles.divider} />
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mt: theme.spacing(1), width: "100%" }}
      >
        <Button sx={{ width: "50%", p: 1 }} onClick={handleClose}>
          <Typography
            component="span"
            sx={{ ml: theme.spacing(1.25), textDecoration: "underline" }}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          color="secondary"
          sx={{ width: "50%" }}
          variant="contained"
          onClick={() => handleSubmit}
        >
          Save
        </Button>
      </Stack>
      {upload.editImage && (
        <ImageCropper
          enableZoom
          footer
          header
          aspectRatio={1.77777777778}
          handleApply={handleApply}
          handleCancelUploadImage={handleCancelUploadImage}
          imageType="activityPhoto"
          initialAspectRatio={16 / 9}
          previewImage={upload.previewAvatarImage}
          selectFile={selectFile}
          styles={{ minHeight: 400, maxHeight: 600, width: "100%" }}
          title="Edit Image"
        />
      )}
    </Box>
  );
}
