enum BookingTypes {
  GET_GUEST_BOOKING_REQUEST = "GET_GUEST_BOOKING_REQUEST",
  GET_GUEST_BOOKING_SUCCESS = "GET_GUEST_BOOKING_SUCCESS",
  GET_GUEST_BOOKING_ERROR = "GET_GUEST_BOOKING_ERROR",

  SET_BOOKING_ACTIVITY = "SET_BOOKING_ACTIVITY",
  SET_BOOKING_DATE = "SET_BOOKING_DATE",

  GET_AREA_ACTIVITY_PRICE_REQUEST = "GET_AREA_ACTIVITY_PRICE_REQUEST",
  GET_AREA_ACTIVITY_PRICE_SUCCESS = "GET_AREA_ACTIVITY_PRICE_SUCCESS",
  GET_AREA_ACTIVITY_PRICE_ERROR = "GET_AREA_ACTIVITY_PRICE_ERROR",

  ADD_BOOKING_EXISTING_CARD_REQUEST = "ADD_BOOKING_EXISTING_CARD_REQUEST",
  ADD_BOOKING_EXISTING_CARD_SUCCESS = "ADD_BOOKING_EXISTING_CARD_SUCCESS",
  ADD_BOOKING_EXISTING_CARD_ERROR = "ADD_BOOKING_EXISTING_CARD_ERROR",

  GET_HOST_BOOKINGS_REQUEST = "GET_HOST_BOOKINGS_REQUEST",
  GET_HOST_BOOKINGS_SUCCESS = "GET_HOST_BOOKINGS_SUCCESS",
  GET_HOST_BOOKINGS_ERROR = "GET_HOST_BOOKINGS_ERROR",

  GET_BOOKING_DETAILS_REQUEST = "GET_BOOKING_DETAILS_REQUEST",
  GET_BOOKING_DETAILS_SUCCESS = "GET_BOOKING_DETAILS_SUCCESS",
  GET_BOOKING_DETAILS_ERROR = "GET_BOOKING_DETAILS_ERROR",
}

export default BookingTypes;
