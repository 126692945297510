import RoomIcon from "@mui/icons-material/Room";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomInputField from "components/TextField/CustomInputField";
import { useEffect, useState } from "react";
import { IMarker } from "store";
import { useAppSelector } from "store/hooks";
import theme from "theme";

interface IEntryPointProps {
  handleSave: (marker: IMarker) => void;
  handleDelete: () => void;
  marker: IMarker;
}

function EntryPoint({ handleSave, handleDelete, marker }: IEntryPointProps) {
  const { isUpdating } = useAppSelector(s => s.property);
  const [toDelete, setToDelete] = useState(false);
  const [toSave, setToSave] = useState(false);

  const [entryPointData, setEntryPointData] = useState({
    label: marker.label,
    gateCode: marker.gateCode,
    notes: marker.notes,
    type: marker.type,
    overlay: true,
    latitude: marker.latitude,
    longitude: marker.longitude,
    point: marker.point,
  });

  useEffect(() => {
    setEntryPointData({
      label: marker.label,
      gateCode: marker.gateCode,
      notes: marker.notes,
      type: marker.type,
      overlay: true,
      latitude: marker.latitude,
      longitude: marker.longitude,
      point: marker.point,
    });
  }, [marker]);

  const [labelErrorMsg, setLabelErrorMsg] = useState("");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const tempValue = value;
    const trimValue: string = tempValue !== " " ? tempValue : tempValue.trim();
    setEntryPointData({ ...entryPointData, [name]: trimValue });
  };

  const handleOnSave = () => {
    if (entryPointData.label) {
      setToSave(true);
      handleSave(entryPointData);
    } else {
      setLabelErrorMsg("Label is required");
    }
  };

  return (
    <Box>
      <Stack alignItems="flex-start" justifyContent="flex-start" spacing={2}>
        <Stack alignItems="center" direction="row" sx={{ width: "100%" }}>
          <RoomIcon
            style={{ fontSize: "30px", color: theme.palette.text.primary }}
          />
          <Typography style={{ fontSize: "24px", fontWeight: 700 }}>
            Entry Point
          </Typography>
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <CustomInputField
          autoComplete="off"
          error={labelErrorMsg.length > 0}
          errormessage={labelErrorMsg}
          inputProps={{ maxLength: 35 }}
          label="Choose a label for the entry point"
          name="label"
          type="text"
          value={entryPointData.label}
          onChange={handleInputChange}
          onFocus={() => {
            setLabelErrorMsg("");
          }}
        />
        <CustomInputField
          autoComplete="off"
          inputProps={{ maxLength: 35 }}
          label="Gate code (optional)"
          name="gateCode"
          type="text"
          value={entryPointData.gateCode}
          onChange={handleInputChange}
        />
        <CustomInputField
          multiline
          autoComplete="off"
          label="Additional notes (optional)"
          minRows={5}
          name="notes"
          type="textarea"
          value={entryPointData.notes}
          onChange={handleInputChange}
        />
        <Divider sx={{ width: "100%" }} />
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: theme.spacing(2), width: "100%" }}
        >
          <LoadingButton
            loading={isUpdating && toDelete}
            sx={{ width: "50%" }}
            variant="outlined"
            onClick={() => {
              setToDelete(true);
              handleDelete();
            }}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            color="secondary"
            loading={isUpdating && toSave}
            sx={{ width: "50%" }}
            variant="contained"
            onClick={handleOnSave}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default EntryPoint;
