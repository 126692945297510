import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CustomInputField from "components/TextField/CustomInputField";
import CustomSelectField from "components/TextField/CustomSelectField";
import { ONLY_ALPHABETS_SPACES, US_STATES } from "constants/constants";
import { FormikErrors, FormikTouched } from "formik";
import React from "react";
import { IAddress } from "store/property/PropertyInterface";
import theme from "theme";
import { handleRestrictSpaces, isNumberKey } from "utils/utils";

interface IAddressProps {
  touched?: FormikTouched<IAddress>;
  values: IAddress;
  errors?: FormikErrors<IAddress>;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T1 = string | React.ChangeEvent<any>>(
      field: T1,
    ): T1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleBlur: (e: React.FocusEvent) => void;
  disabled?: boolean;
  addressType?: string;
  enableAddressField?: boolean;
}

function Address(props: IAddressProps) {
  const {
    handleBlur,
    handleChange,
    disabled = false,
    errors,
    touched,
    values,
    addressType = "mailingAddress",
    enableAddressField,
  } = props;

  const handleAllowOnlyNumber = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    isNumberKey(event);
  };

  return (
    <Stack spacing={theme.spacing(3)}>
      <CustomInputField
        autoComplete="off"
        error={!!(touched?.name && errors?.name)}
        errormessage={touched?.name ? errors?.name : undefined}
        inputProps={{ maxLength: 70 }}
        label="Owner Name"
        name={`${addressType}.name`}
        type="text"
        value={values?.name}
        onBlur={handleBlur}
        onChange={e =>
          handleChange(`${addressType}.name`)(
            handleRestrictSpaces(e.target.value),
          )
        }
      />
      <CustomInputField
        autoComplete="address1"
        disabled={!enableAddressField && disabled}
        error={!!(touched?.address && errors?.address)}
        errormessage={touched?.address ? errors?.address : undefined}
        inputProps={{ maxLength: 40 }}
        label="Address"
        name={`${addressType}.address`}
        type="text"
        value={values.address}
        onBlur={handleBlur}
        onChange={e =>
          handleChange(`${addressType}.address`)(
            handleRestrictSpaces(e.target.value),
          )
        }
      />
      <Stack direction="row" spacing={theme.spacing(3)}>
        <CustomInputField
          autoComplete="city"
          disabled={disabled}
          error={!!(touched?.city && errors?.city)}
          errormessage={touched?.city ? errors?.city : undefined}
          inputProps={{ maxLength: 40 }}
          label="City"
          name={`${addressType}.city`}
          type="text"
          value={values.city}
          onBlur={handleBlur}
          onChange={e =>
            handleChange(`${addressType}.city`)(
              handleRestrictSpaces(e.target.value, ONLY_ALPHABETS_SPACES),
            )
          }
        />
        <CustomSelectField
          autoComplete="state"
          disabled={disabled}
          error={!!(touched?.state && errors?.state)}
          errormessage={touched?.state ? errors?.state : undefined}
          inputProps={{
            name: `${addressType}.state`,
          }}
          label="State"
          value={values.state}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          <option value="">Select state</option>
          {US_STATES.map(state => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </CustomSelectField>
      </Stack>
      <Stack direction="row" mr={theme.spacing(2)} spacing={theme.spacing(3)}>
        <Box width="50%">
          <CustomInputField
            autoComplete="zip-code"
            disabled={disabled}
            error={!!(touched?.zip && errors?.zip)}
            errormessage={touched?.zip ? errors?.zip : undefined}
            inputProps={{ maxLength: 5 }}
            label="Zip Code"
            name={`${addressType}.zip`}
            type="text"
            value={values.zip}
            onBlur={handleBlur}
            onChange={e =>
              handleChange(`${addressType}.zip`)(
                handleRestrictSpaces(e.target.value),
              )
            }
            onKeyPress={handleAllowOnlyNumber}
          />
        </Box>
        <Box width="50%" />
      </Stack>
      <Box />
    </Stack>
  );
}

export default Address;
