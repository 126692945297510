import { useEffect, useRef } from "react";

function useFocus(value: boolean) {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (value) {
      ref?.current?.focus();
    } else {
      ref?.current?.blur();
    }
  }, [value]);

  return ref;
}

export default useFocus;
