import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useIsMobile } from "hooks/useIsMobile";
import { useAppSelector } from "store/hooks";
import { PropertySteps } from "store/property/PropertyTypes";

import ActivityCost from "./activity/ActivityCost";
import ActivityDates from "./activity/activityDates/ActivityDates";
import ActivityDetails from "./activity/ActivityDetails";
import ActivityPhotos from "./activity/ActivityPhotos";
import ActivityType from "./activity/ActivityType";
import Review from "./activity/Review";
import AddProperty from "./addProperty/AddProperty";
import GetStarted from "./addProperty/GetStarted";
import MailingAddress from "./addProperty/MailingAddress";
import PropertyDetailsForm from "./addProperty/PropertyDetailsForm";
import PropertyRules from "./addProperty/PropertyRules";
import MapContainer from "./mapContainer";

interface IRightMainComponentWrapperProps {
  withPadding?: boolean;
  children: React.ReactNode | JSX.Element;
  center?: boolean;
  fullHeight?: boolean;
}

export function RightMainComponentWrapper({
  children,
  withPadding = true,
  center = true,
  fullHeight = false,
}: IRightMainComponentWrapperProps) {
  const isMobile = useIsMobile("midsm");

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        ...(!fullHeight && {
          maxHeight: isMobile ? "calc(100% - 70px)" : "calc(100vh - 88px)",
        }),
        overflowY: "auto",
      }}
    >
      <Stack
        sx={{
          boxSizing: "border-box",
          minHeight: "100%",
          ...(withPadding && {
            padding: isMobile ? "20px" : "40px",
          }),
        }}
      >
        {center ? (
          <Stack alignItems="center" flexGrow={1} justifyContent="center">
            {children}
          </Stack>
        ) : (
          <>{children}</>
        )}
      </Stack>
    </Box>
  );
}

export function RightContainer() {
  const { propertyStep } = useAppSelector(s => s.property);

  const renderRightChild = () => {
    const {
      ACTIVITY_DATES,
      ACTIVITY_DETAILS,
      ACTIVITY_PHOTOS,
      ACTIVITY_TYPE,
      ENTRY_POINT,
      EXCLUSION_AREA,
      FIND_YOUR_PROPERTY,
      GET_STARTED_STEP1,
      GET_STARTED_STEP2,
      GET_STARTED_STEP3,
      MAILING_ADDRESS,
      PARKING_POINT,
      PROPERTY_DETAILS,
      REVIEW,
      ACTIVITY_COST,
      PROPERTY_RULES,
    } = PropertySteps;

    switch (propertyStep) {
      case GET_STARTED_STEP1:
      case GET_STARTED_STEP2:
      case GET_STARTED_STEP3:
        return <GetStarted />;
      case FIND_YOUR_PROPERTY:
      case EXCLUSION_AREA:
      case ENTRY_POINT:
      case PARKING_POINT:
        return <MapContainer />;
      case PROPERTY_DETAILS:
        return <PropertyDetailsForm />;
      case ACTIVITY_TYPE:
        return <ActivityType />;
      case MAILING_ADDRESS:
        return <MailingAddress />;
      case ACTIVITY_PHOTOS:
        return <ActivityPhotos />;

      case ACTIVITY_DETAILS:
        return <ActivityDetails />;
      case ACTIVITY_DATES:
        return <ActivityDates />;
      case REVIEW:
        return <Review />;
      case ACTIVITY_COST:
        return <ActivityCost />;
      case PROPERTY_RULES:
        return <PropertyRules />;
      default:
        return <AddProperty />;
    }
  };

  return <>{renderRightChild()}</>;
}
