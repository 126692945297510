/* eslint-disable @typescript-eslint/no-explicit-any */
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import ImageCropper from "components/imageCropper/ImageCropper";
import { CameraIcon } from "helpers/images";
import { useState } from "react";
import { alertError, IResponseData, uploadProfilePhoto } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { dataURItoBlob, getRoundedCanvas } from "utils/utils";
import { validateFileFormat, validateFileSize } from "utils/validator";

interface IUpload {
  previewAvatarImage: string;
  file: Blob | null;
  editImage: boolean;
}

interface ICameraImageProps {
  onClick?: () => void;
}

function CameraImage(props: ICameraImageProps) {
  const { onClick = () => {} } = props;
  return (
    <Avatar
      sx={{
        width: 30,
        height: 30,
        backgroundColor: theme.palette.grey[50],
        border: `1px solid ${theme.palette.info.main}`,
        color: theme.palette.info.main,
        borderRadius: "50%",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <CameraIcon />
    </Avatar>
  );
}

function ProfileAvatar() {
  const dispatch = useAppDispatch();
  const { isProfileImageUploading, userProfileData } = useAppSelector(
    s => s.profile,
  );
  const { originalImageReadUrl, croppedImageDetails, croppedImageReadUrl } =
    userProfileData;
  const [upload, setUpload] = useState<IUpload>({
    previewAvatarImage: "",
    file: null,
    editImage: false,
  });

  const croppedImage: string = croppedImageReadUrl || "";
  const originalImage: string = originalImageReadUrl || "";

  const handleEdit = () => {
    setUpload({ ...upload, editImage: true });
  };

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isImage: boolean = file.type.includes("image");
    if (target?.files?.length === 1 && isImage) {
      const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (isImage && !validateFileFormat(file.name, allowedExtensions)) {
        dispatch(
          alertError({
            message:
              "File format is not supported. Please select one of the following file types: jpg, jpeg or png.",
          }),
        );
        return;
      }
      const maxSize = 100;
      const isFileSizeValid: boolean = validateFileSize(file.size, maxSize);

      if (isFileSizeValid) {
        setUpload({
          ...upload,
          previewAvatarImage: URL.createObjectURL(file),
          file,
          editImage: true,
        });
      } else {
        dispatch(
          alertError({
            message: `The image file must be ${maxSize} MB or less.`,
          }),
        );
      }
    } else {
      dispatch(
        alertError({
          message:
            "File format is not supported. Please select an image and try again.",
        }),
      );
    }
  };

  const handleCancelUploadImage = () => {
    setUpload({
      ...upload,
      previewAvatarImage: "",
      file: null,
      editImage: false,
    });
  };

  const handleApply = async (cropper: any, minZoom: number, zoom: number) => {
    let croppedCanvas = cropper.getCroppedCanvas();
    croppedCanvas = getRoundedCanvas(croppedCanvas);
    const dataURL = croppedCanvas.toDataURL("image/jpeg");
    const blob = dataURItoBlob(dataURL);
    const croppedFile = new File([blob], "croppedImage.jpeg");
    let newFile: Blob | null = null;
    const createFile = async () => {
      try {
        const existingImage = originalImage;
        const response = await fetch(existingImage);
        const data = await response.blob();
        const metadata = {
          type: "image/jpeg",
        };
        const file = new File([data], "profileImage.jpeg", metadata);
        // ... do something with the file or return it
        return file;
      } catch (error) {
        dispatch(
          alertError({
            message: `Error while uploading profile image, Please try again later`,
          }),
        );
        return null;
      }
    };
    if (upload.file === null) {
      newFile = await createFile();
      if (newFile === null) return;
    }
    const uploadImageData = {
      originalFile: upload.file || newFile,
      croppedFile,
      data: cropper.getData(false),
      canvasData: cropper.getCanvasData(),
      cropBoxData: cropper.getCropBoxData(),
      minZoom,
      zoom,
    };
    dispatch(uploadProfilePhoto(uploadImageData)).then(
      (response: IResponseData) => {
        if (!response.error) handleCancelUploadImage();
      },
    );
  };

  return (
    <>
      {upload.editImage && (
        <ImageCropper
          enableZoom
          footer
          header
          aspectRatio={1}
          croppedImageData={croppedImageDetails}
          handleApply={handleApply}
          handleCancelUploadImage={handleCancelUploadImage}
          image={originalImage}
          imageType="profile"
          initialAspectRatio={1 / 1}
          loading={isProfileImageUploading}
          previewImage={upload.previewAvatarImage}
          selectFile={selectFile}
          title="Edit Profile Image"
        />
      )}
      <div className="d-flex align-items-center">
        <Badge
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            croppedImage && originalImage ? (
              <CameraImage onClick={handleEdit} />
            ) : (
              <label className="d-flex align-items-center" htmlFor="btn-upload">
                <input
                  accept=".png,.jpeg,.jpg"
                  className="d-none"
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: "none" }}
                  type="file"
                  onChange={selectFile}
                  onClick={(e: any) => {
                    e.target.value = null;
                  }}
                />
                <CameraImage />
              </label>
            )
          }
          overlap="circular"
        >
          <Avatar
            src={croppedImage || upload.previewAvatarImage}
            sx={{
              width: 95,
              height: 95,
            }}
          />
        </Badge>
      </div>
    </>
  );
}

export default ProfileAvatar;
