import { FormikHelpers, useFormik } from "formik";
import { IACHPaymentState } from "store";
import { directDepositFormValidationSchema } from "utils/validationSchema";

const useDirectDepositForm = (
  onSubmit: (
    values: IACHPaymentState,
    formikHelpers: FormikHelpers<IACHPaymentState>,
  ) => void | Promise<unknown>,
  initialValues: IACHPaymentState,
) => {
  return useFormik<IACHPaymentState>({
    initialValues,
    validationSchema: directDepositFormValidationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export default useDirectDepositForm;
