import LocalParkingIcon from "@mui/icons-material/LocalParking";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StepperInput from "components/stepperInput/StepperInput";
import CustomInputField from "components/TextField/CustomInputField";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";
import { IMarker } from "store/property/PropertyInterface";
import theme from "theme";

interface IParkingProps {
  handleSave: (marker: IMarker) => void;
  handleDelete: () => void;
  marker: IMarker;
}

function Parking({ handleSave, handleDelete, marker }: IParkingProps) {
  const { isUpdating } = useAppSelector(s => s.property);
  const [toDelete, setToDelete] = useState(false);
  const [toSave, setToSave] = useState(false);

  const [labelErrorMsg, setLabelErrorMsg] = useState("");

  const [parkingData, setParkingData] = useState({
    parkingSpots: marker.parkingSpots || 1,
    label: marker.label,
    gateCode: marker.gateCode,
    notes: marker.notes,
    type: marker.type,
    overlay: true,
    latitude: marker.latitude,
    longitude: marker.longitude,
    point: marker.point,
  });

  useEffect(() => {
    setParkingData({
      parkingSpots: marker.parkingSpots || 1,
      label: marker.label,
      gateCode: marker.gateCode,
      notes: marker.notes,
      type: marker.type,
      overlay: true,
      latitude: marker.latitude,
      longitude: marker.longitude,
      point: marker.point,
    });
  }, [marker]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const tempValue = value;
    let trimValue: string | number =
      tempValue !== " " ? tempValue : tempValue.trim();
    if (name === "parkingSpots") {
      trimValue = parseInt(trimValue);
    }
    setParkingData({ ...parkingData, [name]: trimValue });
    // setErrorMsgs({ ...errorMsgs, [name]: "" });
  };

  const handleStepperClick = (type: string) => {
    if (type === "minus") {
      if (parkingData.parkingSpots > 1) {
        setParkingData({
          ...parkingData,
          parkingSpots: parkingData.parkingSpots - 1,
        });
      }
    } else {
      setParkingData({
        ...parkingData,
        parkingSpots: parkingData.parkingSpots + 1,
      });
    }
  };

  const handleOnSave = () => {
    if (parkingData.label) {
      setToSave(true);
      handleSave(parkingData);
    } else {
      setLabelErrorMsg("Label is required");
    }
  };

  return (
    <Box>
      <Stack alignItems="flex-start" justifyContent="flex-start" spacing={2}>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          sx={{ width: "100%" }}
        >
          <LocalParkingIcon
            style={{
              fontSize: "30px",
              backgroundColor: theme.palette.text.primary,
              color: theme.palette.common.white,
            }}
          />
          <Typography style={{ fontSize: "24px", fontWeight: 700 }}>
            Parking
          </Typography>
        </Stack>
        <Divider sx={{ width: "100%" }} />
        <CustomInputField
          autoComplete="off"
          error={labelErrorMsg.length > 0}
          errormessage={labelErrorMsg}
          inputProps={{ maxLength: 35 }}
          label="Choose a label for the parking area"
          name="label"
          type="text"
          value={parkingData.label}
          onChange={handleInputChange}
          onFocus={() => {
            setLabelErrorMsg("");
          }}
        />

        <CustomInputField
          multiline
          autoComplete="off"
          label="Additional notes (optional)"
          minRows={6}
          name="notes"
          type="text"
          value={parkingData.notes}
          onChange={handleInputChange}
        />

        <StepperInput
          label="Parking Spaces Available"
          name="parkingSpots"
          value={parkingData.parkingSpots}
          onChange={handleInputChange}
          onClick={handleStepperClick}
        />
        <Divider sx={{ width: "100%" }} />
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: theme.spacing(2), width: "100%" }}
        >
          <LoadingButton
            loading={isUpdating && toDelete}
            sx={{ width: "50%" }}
            variant="outlined"
            onClick={() => {
              setToDelete(true);
              handleDelete();
            }}
          >
            Delete
          </LoadingButton>
          <LoadingButton
            color="secondary"
            disabled={parkingData.parkingSpots < 1}
            loading={isUpdating && toSave}
            sx={{ width: "50%" }}
            variant="contained"
            onClick={handleOnSave}
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Parking;
