import { Box, Typography } from "@mui/material";
import ModalContainer from "components/Modal/ModalContainer";
import theme from "theme";

interface IBookingModalSuccessProps {
  open: boolean;
  modalStatusChange: () => void;
}

const BookingTermsModal = ({
  open,
  modalStatusChange,
}: IBookingModalSuccessProps) => {
  return (
    <ModalContainer
      showCloseIcon
      fullWidth={true}
      handleClose={() => {
        modalStatusChange();
      }}
      maxWidth="lg"
      open={open}
      showHeader={true}
    >
      <Box
        sx={{
          maxWidth: "100%",
          backgroundColor: theme.palette.common.white,
        }}
      >
        {/* <ModalHeader
          showCloseIcon
          handleClose={() => {
            modalStatusChange();
          }}
        /> */}
        <Box px={theme.spacing(3)} py={theme.spacing(1)}>
          <Box>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                marginBottom: "15px",
                fontSize: "24px",
                fontWeight: "700",
                lineHeight: "24px",
                textAlign: "center",
              }}
            >
              Payment Terms of Service
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              These Payment Terms of Service (“<strong>Payment Terms</strong>”)
              are a binding legal agreement between you and Wing It that govern
              the Payment Services (defined below) conducted through or in
              connection with the Wing It Platform.
              <p>
                Wing It provides payment services to Users publishing, offering
                and booking Activities. These payment services may include (if
                available) the following (collectively, “
                <strong>Payment Services</strong>”):
              </p>
              <ul>
                <li>
                  Collecting payments from Members (“<strong>Payment</strong>”),
                  by charging the payment method associated with their Wing It
                  account, such as credit card, debit card, or bank account (“
                  <strong>Payment Method</strong>”);
                </li>
                <li>
                  Effecting payments to Landowners (“<strong>Payout</strong>”)
                  to a financial instrument associated with their Wing It
                  account, such as a bank account, a prepaid card, or a debit
                  card (“<strong>Payout Method</strong>”);
                </li>
                <li>
                  Effecting payments to a third-party Payout Method designated
                  by a Landowner; and
                </li>
                <li>Payment collection services.</li>
              </ul>
              <p>
                In order to use the Payment Services, you must be at least 18
                years old, must have a Wing It account in good standing in
                accordance with the Wing It Terms and Conditions of Service
                (“Terms”), and must keep your payment and personal information
                accurate and complete.
              </p>
              <strong>
                Section 17 of these Payment Terms contains an arbitration
                agreement and class actions waiver. Please read them carefully.
              </strong>
              <p>
                The Terms separately govern your use of the Wing It Platform. If
                you see an undefined term in these Payment Terms, it has the
                same definition as in the Terms.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1</strong>&nbsp;
              <strong>Your use of the Payment Services.</strong>
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.1</strong>&nbsp;
              <strong>Wing It Payment Services</strong>. By using the Payment
              Services, you agree to comply with these Payment Terms. Wing It
              may temporarily limit or suspend your access to or use of the
              Payment Services, or its features, to carry out maintenance
              measures that ensure the proper functioning of the Payment
              Services. Wing It may improve, enhance and modify the Payment
              Services and introduce new Payment Services from time to time.
              Wing It will provide notice to Users of any changes to the Payment
              Services, unless such changes do not materially increase the
              Users’ contractual obligations or decrease the Users’ rights under
              these Payment Terms.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.2</strong>&nbsp;<strong>Third Party Services</strong>
              .&nbsp; The Payment Services may contain links to third-party
              websites or resources (“Third-Party Services”). Such Third-Party
              Services are subject to different terms of service and privacy
              policies, which Users should review. Wing It is not responsible or
              liable for the use of such Third-Party Services. Links to any
              Third-Party Services are not an endorsement by Wing It of those
              Third-Party Services.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.3</strong>&nbsp;<strong>Your Wing It Account</strong>
              .&nbsp; You may authorize another User to use your Wing It account
              if the feature is enabled for your Wing It account. You
              acknowledge and agree that anyone you authorize to use your Wing
              It account may use the Payment Services on your behalf and that
              you will be responsible for any payments made by such person.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.4</strong>&nbsp;<strong>Verification</strong>
              You authorize Wing It, directly or through third parties, to make
              any inquiries we consider necessary to verify your identity and
              information you provide. Wing It reserves the right to terminate,
              suspend, or limit access to the Payment Services in the event we
              are unable to obtain or verify any of this information.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.5</strong>&nbsp;<strong>Fees</strong>
              Wing It may charge fees for use of certain Payment Services and
              any applicable fees will be disclosed to you in the Terms or via
              the Wing It Platform.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2</strong>&nbsp;
              <strong>
                Appointment of Wing It as Limited Payment Collection Agent.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2.1</strong>&nbsp;<strong>Appointment</strong>.&nbsp; Each
              Landowner hereby appoints Wing It as the Landowner’s payment
              collection agent solely for the limited purpose of accepting and
              processing funds from Members purchasing Activities on the
              Landowner’s behalf.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2.2</strong>&nbsp;<strong>Acting as Agent</strong>
              Each Landowner agrees that payment made by a Member through Wing
              It shall be considered the same as a payment made directly to the
              Landowner, and the Landowner will provide the Activity booked by
              the Member in the agreed-upon manner as if the Landowner has
              received the payment directly from the Member. Each Landowner
              agrees that Wing It may refund the Member in accordance with the
              Terms. Each Landowner understands that Wing It’s obligation to pay
              the Landowner is subject to and conditional upon successful
              receipt of the associated payment from the Member. Wing It
              guarantees payments to the Landowner only for such amounts that
              have been successfully received by Wing It from the Member in
              accordance with these Payment Terms. In accepting appointment as
              the limited payment collection agent of the Landowner, Wing It
              assumes no liability for any acts or omissions of the Landowner.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2.3&nbsp;</strong>
              <strong>Member Acknowledgement</strong>. Each Member acknowledges
              and agrees that, notwithstanding the fact that Wing It is not a
              party to the agreement between you and the Landowner, Wing It acts
              as each Landowner’s payment collection agent for the limited
              purpose of accepting payments from you on behalf of the Landowner.
              Upon a Member’s payment of funds to Wing It, the Member’s payment
              obligation to the Landowner for the agreed upon amount is
              extinguished, and Wing It is responsible for remitting the funds
              successfully received by Wing It to the Landowner in the manner
              described in these Payment Terms. In the event that Wing It does
              not remit any such amount, the Landowner will have recourse only
              against Wing It and not the Member directly.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3</strong>
              <strong>Payment Terms</strong>.&nbsp;&nbsp;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.1</strong>&nbsp;<strong>Adding a Payment Method</strong>
              . When you add a Payment Method to your Wing It account, you must
              provide billing information either to Wing It or its third-party
              payment processor(s). You authorize Wing It and its payment
              service providers to collect and store your Payment Method
              information.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.2</strong>&nbsp;
              <strong>Payment Method Verification</strong>.&nbsp; When you add
              or use a new Payment Method, Wing It may verify the Payment
              Method. When you add a Payment Method during checkout, we will
              automatically save and add that Payment Method to your Wing It
              account so it can be used for a future transaction. You can remove
              the Payment Method from your Wing It account as long as it is not
              associated with an active or future reservation.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.3</strong>&nbsp;
              <strong>Payment Authorization</strong>. You allow Wing It to
              charge your Payment Method, either directly or indirectly, for all
              charges due (including any applicable taxes) in connection with
              your Wing It account.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.4</strong>&nbsp;
              <strong>Automatic Update of Payment Method</strong>.&nbsp;
              <strong>
                If your Payment Method’s account information changes as a result
                of re-issuance or otherwise, we may acquire that information
                from our financial services partners or your bank and
                automatically update your Payment Method on file.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.5&nbsp;</strong>
              <strong>Timing of Payment</strong>. Wing It charges the price due
              after the Landowner accepts your Activity request. Wing It may
              offer alternative options for the timing and manner of payment.
              Any additional fees for using those alternative payment options
              will be displayed via the Wing It Platform and included in price,
              and you agree to pay such fees by selecting the payment option.
              Additional terms and conditions may apply for the use of an
              alternative payment option. If Wing It is unable to collect the
              price due, as scheduled, Wing It will collect the price due at a
              later point in accordance with Section 5.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.6&nbsp;</strong>
              <strong>Activity Status</strong>. If a requested Activity is
              declined either because it is not accepted by the Landowner or you
              cancel the Activity before it is accepted by the Landowner, any
              amounts collected by Wing It will be refunded to you, and any
              pre-authorization of your Payment Method will be released (if
              applicable). The timing to receive the refund or for the
              pre-authorization to be released will vary based on the Payment
              Method and any applicable payment system rules.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.7</strong>&nbsp;<strong>Payment Restrictions</strong>
              .&nbsp; When Wing It reserves the right to decline or limit
              payments that we believe (i) may violate Wing It’s risk management
              policies or procedures, (ii) may violate these Payment Terms or
              the Terms, (iii) are unauthorized, fraudulent or illegal; or (iv)
              expose you, Wing It, or others to risks unacceptable to Wing It.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.8&nbsp;</strong>
              <strong>Payment Service Providers</strong>. Payment Methods may
              involve the use of third-party payment service providers. These
              payment service providers may charge you additional fees when
              processing payments in connection with the Payment Services, and
              Wing It is not responsible for any such fees and disclaims all
              liability in this regard. Your Payment Method may also be subject
              to additional terms of use.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.9</strong>&nbsp;
              <strong>Your Payment Method, Your Responsibility</strong>. Wing It
              is not responsible for any loss suffered by you as a result of
              incorrect Payment Method information provided by you.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4&nbsp;</strong>
              <strong>Payout Terms</strong>.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.1&nbsp;</strong>
              <strong>Payment Collection</strong>. Wing It generally collects
              the price of an Activity at the time the Member’s Activity request
              is accepted by the Landowner, unless noted otherwise.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.2&nbsp;</strong>
              <strong>Valid Payout Method</strong>.In order to receive a Payout
              you must have a valid Payout Method linked to your Wing It
              account. Providing requested information is required for a Payout
              Method to be valid. The information Wing It requires for a valid
              Payout Method may change, and Wing It may request additional
              information at any time. Failing to provide requested information
              may result in Wing It temporarily placing a hold, suspending, or
              canceling any Payout until the information is provided and, if
              necessary, validated. You authorize Wing It to collect and store
              your Payout Method information. Wing It may also share your
              information with governmental authorities as required by
              applicable law.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.3&nbsp;</strong>
              <strong>Timing of Payout</strong>.Subject to and conditional upon
              successful receipt of the payment from the Member, Wing It will
              generally initiate Payouts to your selected Payout Method within
              72 hours after the Member’s scheduled check-in time for the
              Activity (or 72 hours after 3:00 pm local time - or 3:00 pm UTC if
              local time is unknown - if the check-in time is flexible or not
              specified).
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.4&nbsp;</strong>
              <strong>Payout</strong>.Your Payout for a booking will be the
              price and any Taxes the Landowner lists on the Activity Listing in
              accordance with Section 5 of the Terms, less the Wingit Fee. In
              the event of cancellation of a confirmed Activity, Wing It will
              remit the amount you are due (if any) as provided in the Terms and
              applicable cancellation policy.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.5&nbsp;</strong>
              <strong>Payout Restrictions</strong>.Wing It may temporarily place
              a hold, suspend, or cancel any Payout for purposes of preventing
              unlawful activity or fraud, risk assessment, security, or
              completing an investigation; or if we are unable to verify your
              identity, or to obtain or verify requested information.
              Furthermore, Wing It may temporarily place a hold on, suspend, or
              delay initiating or processing any Payout due to you under the
              Terms as a result of high-volume Activity cancellations or
              modifications arising from a Force Majeure Event.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.6&nbsp;</strong>
              <strong>Limits on Payouts</strong>.For compliance or operational
              reasons, Wing It may limit the amount of a Payout. If you are due
              an amount above that limit, Wing It may make a series of Payouts
              in order to provide your full Payout amount.
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.7&nbsp;</strong>
              <strong>Payment Service Providers</strong>.Payout Methods may
              involve the use of third-party payment service providers. These
              payment service providers may charge you additional fees when
              processing Payouts in connection with the Payment Services, and
              Wing It is not responsible for any such fees and disclaims all
              liability in this regard. Your Payout Method may also be subject
              to additional terms of use. Please review them before using your
              Payout Method.
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.8&nbsp;</strong>
              <strong>Handling of Funds</strong>.Wing It may combine amounts
              that it collects from Members and invest them as permitted under
              applicable laws. Wing It will retain any interest it earns on
              those investments.
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.9&nbsp;</strong>
              <strong>Your Payout Method, Your Responsibility</strong>.Wing It
              is not responsible for any loss suffered by you as a result of
              incorrect Payout Method information provided by you.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>5&nbsp;</strong>
              <strong>Collections</strong>.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>5.1&nbsp;</strong>
              <strong>Right to Collect</strong>. If Wing It is unable to collect
              any amounts you owe under these Payment Terms, Wing It may engage
              in collection efforts to recover such amounts from you.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>5.2&nbsp;</strong>
              <strong>Amounts Overdue</strong>. For all authorized charges, Wing
              It deems any owed amounts overdue when one hundred and twenty
              (120) days have elapsed after Wing It first attempts to charge
              your Payment Method or the associated services have been provided,
              whichever is later.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>5.3&nbsp;</strong>
              <strong>Amounts in Default</strong>.For all authorized charges,
              Wing It deems any overdue amounts not collected to be in default
              when three hundred and sixty-five (365) days have elapsed after
              Wing It first attempts to charge your Payment Method or the
              associated services have been provided, whichever is later.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>5.4&nbsp;</strong>
              <strong>Collections Communications</strong>. You hereby explicitly
              agree that all of Wing It’s communications to you in relation to
              amounts owed will be made by electronic mail or by phone. Such
              communications may be made by Wing It or by anyone on their behalf
              including, but not limited to, a third-party collection agent.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>6&nbsp;</strong>
              <strong>Payment Processing Errors.</strong>.
              <p>
                Wing It will take the necessary steps to rectify any payment
                processing errors that we become aware of. This may be performed
                by Wing It or a third party. We may also take steps to recover
                funds sent to you in error (including but not limited to an
                event of duplicate payments made to you due to a processing
                error), by reducing, setting off and/or debiting the amount of
                such funds from any future Payouts owed to you. To the extent
                you receive any funds in error, you agree to immediately return
                such funds to Wing It.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>7&nbsp;</strong>
              <strong>Refunds</strong>.
              <p>
                Any refunds or credits due to a Member pursuant to the Terms,
                will be initiated and remitted by Wing It in accordance with
                these Payment Terms. The timing to receive any refund will
                depend on the Payment Method and any applicable payment system
                rules. In the event of a Force Majeure Event, Wing It will
                initiate and process the refund as soon as is practicable.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>8&nbsp;</strong>
              <strong>Damage Claims and Security Deposits</strong>.
              <p>
                If you as a Member (i) agree to pay the Landowner in connection
                with a damage claim, or (ii) Wing It determines that you are
                responsible for damaging any real or personal property pursuant
                to the Terms, you authorize Wing It to charge the Payment Method
                used to book the Activity in order to collect any security
                deposit associated with the Activity, as well as any fees, costs
                and/or expenses associated with the damage claim. If Wing It is
                unable to collect from the Payment Method used to make the
                booking, you agree that Wing It may charge any other Payment
                Method on file in your Wing It account at the time of the damage
                claim (unless you have previously removed the authorization to
                charge such Payment Method(s)).
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>9&nbsp;</strong>
              <strong>Abandoned Property</strong>.
              <p>
                If Wing It holds funds due to you because we are unable to issue
                you a Payout or refund for the relevant period of time set forth
                by your state, country, or other governing body in its unclaimed
                property laws, we may process the funds due to you in accordance
                with our legal obligations, including reporting and sending such
                funds to the appropriate governing body as required by the
                applicable unclaimed property laws.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>10&nbsp;</strong>
              <strong>Prohibited Activities</strong>.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>10.1&nbsp;</strong>
              You are solely responsible for compliance with any and all laws,
              rules, regulations, and tax obligations that may apply to your use
              of the Payment Services. In connection with your use of the
              Payment Services, you may not and you agree that you will not and
              will not assist or enable others to:
              <ul>
                <li>
                  breach or circumvent any applicable laws or regulations;
                </li>
                <li>
                  breach or circumvent any agreements with third parties,
                  third-party rights, or the Terms;
                </li>
                <li>
                  use the Payment Services for any commercial or other purposes
                  that are not expressly permitted by these Payment Terms;
                </li>
                <li>
                  register or use any Payment Method or Payout Method with your
                  Wing It account that is not yours or you do not have
                  authorization to use;
                </li>
                <li>
                  avoid, bypass, remove, deactivate, impair, descramble, or
                  otherwise circumvent any technological measure implemented by
                  Wing It or any of Wing It’s providers or any other third party
                  to protect the Payment Services;
                </li>
                <li>
                  take any action that damages or adversely affects, or could
                  damage or adversely affect, the performance or proper
                  functioning of the Payment Services;
                </li>
                <li>
                  attempt to decipher, decompile, disassemble, or reverse
                  engineer any of the software used to provide the Payment
                  Services; or
                </li>
                <li>
                  violate or infringe anyone else’s rights or otherwise cause
                  harm to anyone.
                </li>
              </ul>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>10.2&nbsp;</strong>
              You may not use or assist others to use the Payment Services to
              send or receive funds: (i) into any United States embargoed
              countries; or (ii) to anyone on the U.S. Treasury Department’s
              list of Specially Designated Nationals or the U.S. Department of
              Commerce’s Denied Persons List or Entity List. You represent and
              warrant that: (i) neither you nor your Activities are located or
              take place in a country that is subject to a U.S. Government
              embargo, or that has been designated by the U.S. Government as a
              “terrorist supporting” country; and (ii) you are not listed on any
              U.S. Government list of prohibited or restricted parties. In
              addition to complying with the above, you must also comply with
              any relevant export control laws in your local jurisdiction.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              <strong>11&nbsp;</strong>
              <strong>Force Majeure</strong>
              Wing It shall not be liable for any delay or failure to fulfill
              any obligation under these Payment Terms resulting from causes
              outside the reasonable control of Wing It, including, but not
              limited to, acts of God, natural disasters, war, terrorism, riots,
              embargoes, acts of civil or military authorities, fire, floods,
              accidents, pandemics, epidemics or disease, strikes or shortages
              of transportation facilities, fuel, energy, labor or materials
              (“Force Majeure Event”).
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              <strong>12&nbsp;</strong>
              <strong>Liability</strong>
              <strong>
                You acknowledge and agree that, to the maximum extent permitted
                by law, the entire risk arising out of your access to and use of
                the Payment Services remains with you. If you permit or
                authorize another person to use your Wing It account in any way,
                you are responsible for the actions taken by that person.
                Neither Wing It nor any other party involved in creating,
                producing, or delivering the Payment Services will be liable for
                any incidental, special, exemplary, or consequential damages,
                including lost profits, loss of data or loss of goodwill,
                service interruption, computer damage or system failure or the
                cost of substitute products or services, or for any damages for
                personal or bodily injury or emotional distress arising out of
                or in connection with (i) these Payment Terms, (ii) from the use
                of or inability to use the Payment Services, or (iii) from any
                communications, interactions, or meetings with other Users or
                other persons with whom you communicate, interact, transact, or
                meet with as a result of your use of the Payment Services,
                whether based on warranty, contract, tort (including
                negligence), product liability, or any other legal theory, and
                whether or not Wing It has been informed of the possibility of
                such damage, even if a limited remedy set forth herein is found
                to have failed of its essential purpose. Except for our
                obligations to pay amounts to applicable Landowner’s pursuant to
                these Payment Terms, in no event will Wing It’s aggregate
                liability arising out of or in connection with these Payment
                Terms and your use of the Payment Services including, but not
                limited to, from your use of or inability to use the Payment
                Services, exceed (i) the amounts you have paid or owe for
                Activities via the Wing It Platform as a Member in the twelve
                (12) month period prior to the event giving rise to the
                liability, or if you are a Landowner, the amounts paid by Wing
                It to you in the twelve (12) month period prior to the event
                giving rise to the liability, or (ii) one hundred U.S. dollars
                (US$100), if no such payments have been made, as applicable. The
                limitations of damages set forth above are fundamental elements
                of the basis of the bargain between Wing It and you. Some
                jurisdictions do not allow the exclusion or limitation of
                liability for consequential or incidental damages, so the above
                limitation may not apply to you. If you reside outside of the
                U.S., this does not affect Wing It’s liability for death or
                personal injury arising from its negligence, nor for fraudulent
                misrepresentation, misrepresentation as to a fundamental matter,
                or any other liability which cannot be excluded or limited under
                applicable law.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              <strong>13&nbsp;</strong>
              <strong>Disclaimers</strong>
              <p>
                <strong>
                  13.1 If you choose to use the Payment Services, you do so
                  voluntarily and at your sole risk. To the maximum extent
                  permitted by law, the Payment Services are provided “as is”,
                  without warranty of any kind, either express or implied.
                </strong>
              </p>
              <p>
                <strong>
                  13.2 Notwithstanding Wing It’s appointment as the limited
                  payment collection agent of Landowners pursuant to Section 2,
                  Wing It explicitly disclaims all liability for any act or
                  omission of any User or other third party. Wing It does not
                  have any duties or obligations as agent for each Landowner
                  except to the extent expressly set forth in these Payment
                  Terms, and any additional duties or obligations as may be
                  implied by law are, to the maximum extent permitted by
                  applicable law, expressly excluded.
                </strong>
              </p>
              <p>
                <strong>
                  13.3 If we choose to conduct identity verification on any
                  User, to the extent permitted by applicable law, we disclaim
                  warranties of any kind, either express or implied, that such
                  checks will identify prior misconduct by a User or guarantee
                  that a User will not engage in misconduct in the future.
                </strong>
              </p>
              <p>
                <strong>
                  13.4 The foregoing disclaimers apply to the maximum extent
                  permitted by law. You may have other statutory rights or
                  warranties which cannot lawfully be excluded. However, the
                  duration of any statutorily required warranties shall be
                  limited to the maximum extent (if any) permitted by law.
                </strong>
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>14</strong>.<strong>Indemnification</strong>
              To the maximum extent permitted by applicable law, you agree to
              release, defend, indemnify, and hold Wing It and its affiliates
              and subsidiaries, and their officers, directors, employees, and
              agents, harmless from and against any claims, liabilities,
              damages, losses, and expenses, including, without limitation,
              reasonable legal and accounting fees, arising out of or in any way
              connected with (i) your breach of these Payment Terms; (ii) your
              improper use of the Payment Services; (iii) your failure, or our
              failure at your direction, to accurately report, collect or remit
              taxes; or (iv) your breach of any laws, regulations, or
              third-party rights.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              <strong>
                15. Modification, Term, Termination, and Suspension.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>15.1&nbsp;</strong>
              <strong>Modification</strong>.
              <p>
                Except as otherwise required by applicable law, Wing It may
                modify these Payment Terms at any time. If we make material
                changes to these Payment Terms, we will post the revised Payment
                Terms on the Wing It Platform and update the “Last Updated” date
                at the top of these Payment Terms. If you are affected by the
                modification, we will also provide you with notice of the
                modifications at least thirty (30) days before the date they
                become effective. If you do not terminate your agreement before
                the date the revised Payment Terms become effective, your
                continued use of the Payment Services will constitute acceptance
                of any changes to the revised Payment Terms.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>15.2&nbsp;</strong>
              <strong>Term</strong>.
              <p>
                This agreement between you and Wing It reflected by these
                Payment Terms is effective when you create a Wing It account or
                use the Payment Services and remains in effect until either you
                or we terminate this agreement in accordance with Section 15.3.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>15.3&nbsp;</strong>
              <strong>Termination</strong>.
              <p>
                You may terminate this agreement at any time by sending us an
                email or by deleting your Wing It account. Terminating this
                agreement will also serve as notice to cancel your Wing It
                account pursuant to the Terms. Without limiting our rights
                specified below, Wing It may terminate this agreement for
                convenience at any time by giving you thirty (30) days’ notice
                via email to your registered email address. Wing It may also
                terminate this agreement immediately without notice if (i) you
                have materially breached your obligations under this agreement;
                (ii) you have provided inaccurate, fraudulent, outdated, or
                incomplete information; (iii) you have violated applicable laws,
                regulations, or third-party rights; or (iv) Wing It believes in
                good faith that such action is reasonably necessary to protect
                other Users, Wing It, or third parties.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>15.4&nbsp;</strong>
              <strong>Suspension and Other Measures</strong>.
              <p>
                Wing It may limit or temporarily or permanently suspend your use
                of or access to the Payment Services (i) to comply with
                applicable law, or the order or request of a court, law
                enforcement, or other administrative agency or governmental
                body, (ii) if you have breached these Payment Terms, the Terms,
                applicable laws, regulations or third-party rights, (iii) if you
                have provided inaccurate, fraudulent, outdated, or incomplete
                information regarding a Payment Method or Payout Method, (iv)
                for any amounts you owe under these Payment Terms that are
                overdue or in default, or (v) if Wing It believes in good faith
                that such action is reasonably necessary to protect the personal
                safety or property of Wing It, its Users, or third parties, or
                to prevent fraud or other illegal activity. Further, for any
                unsuccessful payment due to card expiration, insufficient funds,
                or otherwise, we may temporarily suspend your access to the
                Payment Services until we can charge a valid Payment Method.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>15.5&nbsp;</strong>
              <strong>Effect of Termination</strong>.
              <p>
                If you cancel your Wing It account as a Landowner or Wing It
                takes any of the measures described above, Wing It may provide a
                full refund to any Member with confirmed booking(s), and you
                will not be entitled to any compensation for pending or
                confirmed bookings that were cancelled. If you cancel your Wing
                It account as a Member, Wing It will initiate a refund for any
                confirmed booking(s) based on the Activity Listing’s
                cancellation policy. If your access to or use of the Payment
                Services has been suspended or limited or this agreement has
                been terminated by us, you may not register a new Wing It
                account or attempt to access and use the Payment Services
                through a Wing It account of another User.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>16&nbsp;</strong>
              <strong>Governing Law and Dispute Resolution</strong>.
              <p>
                These Payment Terms are interpreted in accordance with the laws
                of the State of Kansas and the United States of America, without
                regard to conflict-of-law provisions. Legal proceedings (other
                than small claims actions) that are excluded from the
                Arbitration Agreement in Section 17 must be brought in state or
                federal court in Johnson County, Kansas. You and we both consent
                to venue and personal jurisdiction in District Court of Johnson
                County, Kansas.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17&nbsp;</strong>
              <strong>Arbitration Agreement</strong>.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.1&nbsp;</strong>
              <strong>Application</strong>. Except as provided in Section 17.5,
              this Arbitration Agreement applies to you if you attempt to bring
              any legal claim against Wing It in the United States.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.2&nbsp;</strong>
              <strong>Overview of Dispute Resolution Process</strong> These
              Payment Terms provide for a two-part process for individuals to
              whom this Section 17 applies: (1) an informal negotiation directly
              with Wing It’s customer service team, and if necessary (2) a
              binding arbitration administered by the American Arbitration
              Association (“AAA”). You and Wing It each retain the right to seek
              relief in small claims court as an alternative to arbitration
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.3&nbsp;</strong>
              <strong>
                Mandatory Pre-Arbitration Dispute Resolution and Notification
              </strong>
              . At least 30 days prior to initiating an arbitration, you and
              Wing It each agree to notify the other party of the dispute in
              writing and attempt in good faith to negotiate an informal
              resolution. You must send your notice of dispute to Wing It by
              mailing it to Wing It’s agent for service: [INSERT]. Wing It will
              send its notice of dispute to the email address associated with
              your Wing It account. A notice of dispute must include: the
              party’s name and preferred contact information, a brief
              description of the dispute, and the relief sought. If the parties
              are unable to resolve the dispute within the 30-day period, only
              then may either party commence arbitration by filing a written
              Demand for Arbitration (available at www.adr.org) with the AAA and
              providing a copy to the other party as specified in the AAA Rules
              (available at www.adr.org).
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.4&nbsp;</strong>
              <strong>Agreement to Arbitrate</strong>.
              <strong>
                You and Wing It mutually agree that any dispute, claim or
                controversy arising out of or relating to these Payment Terms or
                the applicability, breach, termination, validity, enforcement or
                interpretation thereof, or any use of the Payment Services
                (collectively, “Disputes”) will be settled by binding individual
                arbitration (the “Arbitration Agreement”). If there is a dispute
                about whether this Arbitration Agreement can be enforced or
                applies to our Dispute, you and Wing It agree that the
                arbitrator will decide that issue.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.5&nbsp;</strong>
              <strong>Exceptions to Arbitration Agreement</strong>. You and Wing
              It each agree that the following causes of action and/or claims
              for relief are exceptions to the Arbitration Agreement and will be
              brought in a judicial proceeding in a court of competent
              jurisdiction: (i) any claim or cause of action seeking emergency
              injunctive relief based on exigent circumstances (e.g., imminent
              danger or commission of a crime, hacking, cyber-attack); or (ii) a
              request for the remedy of public injunctive relief. You and Wing
              It agree that the remedy of public injunctive relief will proceed
              after the arbitration of all arbitrable claims, remedies, or
              causes of action, and will be stayed pending the outcome of the
              arbitration pursuant to section 3 of the Federal Arbitration Act.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.6&nbsp;</strong>
              <strong>Arbitration Rules and Governing Law</strong>. This
              Arbitration Agreement evidences a transaction in interstate
              commerce and the Federal Arbitration Act governs all substantive
              and procedural interpretation and enforcement of this provision.
              The arbitration will be administered by AAA in accordance with the
              Consumer Arbitration Rules and/or other AAA arbitration rules
              determined to be applicable by the AAA (the “AAA Rules”) then in
              effect, except as modified here. The AAA Rules are available at
              www.adr.org. In order to initiate arbitration, a completed written
              demand must be filed with the AAA and provided to the other party,
              as specified in the AAA rules.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.7&nbsp;</strong>
              <strong>
                Modification to AAA Rules - Arbitration Hearing/Location
              </strong>
              . You and Wing It agree that any required arbitration hearing may
              be conducted, at your option: (a) in Johnson County, Kansas; (b)
              via phone or video conference; or (c) if all parties agree, by
              solely the submission of documents to the arbitrator.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.8&nbsp;</strong>
              <strong>
                Modification of AAA Rules - Attorney’s Fees and Costs
              </strong>
              . Your arbitration fees and your share of arbitrator compensation
              shall be governed by the AAA Rules and, where appropriate, limited
              by the AAA Consumer Rules. If such costs are determined by the
              arbitrator to be excessive, Wing It will pay all arbitration fees
              and expenses. Either party may make a request that the arbitrator
              award attorneys’ fees and costs upon proving that the other party
              has asserted a claim, cross-claim or defense that is groundless in
              fact or law, brought in bad faith or for the purpose of
              harassment, or is otherwise frivolous, as allowed by applicable
              law and the AAA Rules.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.9&nbsp;</strong>
              <strong>Arbitrator’s Decision</strong>.The arbitrator’s decision
              will include the essential findings and conclusions upon which the
              arbitrator based the award. Judgment on the arbitration award may
              be entered in any court with proper jurisdiction. The arbitrator
              may award any relief allowed by law or the AAA Rules, but
              declaratory or injunctive relief may be awarded only on an
              individual basis and only to the extent necessary to provide
              relief warranted by the claimant’s individual claim.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.10&nbsp;</strong>
              <strong>Jury Trial Waiver</strong>. You and Wing It acknowledge
              and agree that we are each waiving the right to a trial by jury as
              to all arbitrable Disputes.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.11&nbsp;</strong>
              <strong>No Class Actions or Representative Proceedings</strong>.
              You and Wing It acknowledge and agree that, to the fullest extent
              permitted by law, we are each waiving the right to participate as
              a plaintiff or class member in any purported class action lawsuit,
              class-wide arbitration, private attorney general action, or any
              other representative or consolidated proceeding. Unless we agree
              in writing, the arbitrator may not consolidate more than one
              party’s claims and may not otherwise preside over any form of any
              class or representative proceeding. If there is a final judicial
              determination that applicable law precludes enforcement of the
              waiver contained in this paragraph as to any claim, cause of
              action or requested remedy, then that claim, cause of action or
              requested remedy, and only that claim, cause of action or
              requested remedy, will be severed from this agreement to arbitrate
              and will be brought in a court of competent jurisdiction. In the
              event that a claim, cause of action or requested remedy is severed
              pursuant to this paragraph, then you and we agree that the claims,
              causes of action or requested remedies that are not subject to
              arbitration will be stayed until all arbitrable claims, causes of
              action and requested remedies are resolved by the arbitrator.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.12&nbsp;</strong>
              <strong>Severability</strong>. Except as provided in Section
              17.11, in the event that any portion of this Arbitration Agreement
              is deemed illegal or unenforceable, such provision shall be
              severed and the remainder of the Arbitration Agreement will be
              given full force and effect.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.13&nbsp;</strong>
              <strong>Changes to Agreement to Arbitrate</strong>. If Wing It
              changes this Section 17 after the date you last accepted these
              Payment Terms (or accepted any subsequent changes to these Payment
              Terms), you may reject that change by sending us written notice
              (including by email) within thirty (30) days of the date the
              change is effective. Rejecting a new change, however, does not
              revoke or alter your prior consent to any earlier agreements to
              arbitrate any Dispute between you and Wing It (or your prior
              consent to any subsequent changes thereto), which will remain in
              effect and enforceable as to any Dispute between you and Wing It.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.14&nbsp;</strong>
              <strong>Survival</strong>. Except as provided in Section 17.12,
              this Section 17 will survive any termination of these Payment
              Terms and will continue to apply even if you stop using the
              Payment Services or terminate your Wing It account.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18&nbsp;</strong>
              <strong>Miscellaneous</strong>.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.1&nbsp;</strong>
              <strong>Interpreting these Payment Terms</strong>. Except as they
              may be supplemented by additional terms and conditions, policies,
              guidelines, or standards, these Payment Terms constitute the
              entire agreement between Wing It and you regarding the subject
              matter hereof, and supersede any and all prior oral or written
              understandings or agreements between Wing It and you regarding the
              Payment Services. If any provision of these Payment Terms is held
              to be invalid or unenforceable, such provision will be struck and
              will not affect the validity and enforceability of the remaining
              provisions.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.2&nbsp;</strong>
              <strong>No Waiver</strong>. Wing It’s failure to enforce any right
              or provision in these Payment Terms will not constitute a waiver
              of such right or provision unless acknowledged and agreed to by us
              in writing. Except as expressly set forth in these Payment Terms,
              the exercise by either party of any of its remedies under these
              Payment Terms will be without prejudice to its other remedies
              under these Payment Terms or otherwise permitted under law.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.3&nbsp;</strong>
              <strong>Assignment</strong>. You may not assign, transfer, or
              delegate this agreement or your rights and obligations hereunder
              without Wing It’s prior written consent. Wing It may, without
              restriction, assign, transfer, or delegate this agreement and any
              rights and obligations, at its sole discretion, with thirty (30)
              days’ prior notice.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.4&nbsp;</strong>
              <strong>Notices</strong>. Unless specified otherwise, any notices
              or other communications permitted or required under this
              agreement, will be in writing and given by Wing It via email or
              such other communication as reasonably chosen by Wing It. The date
              of receipt will be deemed the date on which Wing It transmits the
              notice.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>19&nbsp;</strong>
              <strong>Additional Clauses for Users that are Businesses</strong>.
              <p>
                The following paragraphs also apply if you are using the Payment
                Services as a representative (“Representative”) acting on behalf
                of a business, company or other legal entity (in such event, for
                purposes of the Payment Terms, “you” and “your” will refer and
                apply to that business, company or other legal entity).
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>19.1&nbsp;</strong>
              You accept the Payment Terms and you will be responsible for any
              act or omission of employees or third-party agents using the
              Payment Service on your behalf.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>19.2&nbsp;</strong>
              You and your Representative individually affirm that you are
              authorized to provide the information described in Section 3.1 and
              Section 4.2 and your Representative has the authority to bind you
              to these Payment Terms. We may require you to provide additional
              information or documentation demonstrating your Representative’s
              authority.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>19.3&nbsp;</strong>
              <strong>Notices</strong>. Unless specified otherwise, any notices
              or other communications permitted or required under this
              agreement, will You represent and warrant to us that: (i) you are
              duly organized, validly existing and in good standing under the
              laws of the country in which your business is registered and that
              you are registering for receiving the Payment Services; and (ii)
              you have all requisite right, power, and authority to enter into
              this agreement, perform your obligations, and grant the rights,
              licenses, and authorizations in this agreement.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>19.4&nbsp;</strong>
              For any Payout Method linked to your Wing It account, you
              authorize Wing It to store the Payout Method, remit payments using
              the Payout Method for Activities associated with your Wing It
              account and take any other action as permitted in the Payment
              Terms in respect of the Payout Method.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>19.5&nbsp;</strong>
              If you handle, store or otherwise process payment card information
              on behalf of anyone or any third-party, you agree to comply on an
              ongoing basis with applicable data privacy and security
              requirements under the current Payment Card Industry Data Security
              Standard with regards to the use, access, and storage of such
              credit card information. For additional information, including
              tools to help you assess your compliance, see
              https://www.visa.com/cisp and https://www.mastercard.com/sdp.
            </Typography>
          </Box>
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default BookingTermsModal;
