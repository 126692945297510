import MoreVertIcon from "@mui/icons-material/MoreVert";
import Avatar from "@mui/material/Avatar";
import BottomNavigation, {
  BottomNavigationProps,
} from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import { REF_NAV_ITEMS } from "constants/constants";
import {
  BottomNavBurgerMenuIcon,
  BottomNavListingIcon,
  LoginIcon,
  WingItBlackLogo,
} from "helpers/images";
import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertType,
  AuthFlowTypes,
  IAlertPayload,
  setMobileViewAuthSession,
  showHideAuthModal,
  updateAuthStep,
  updateVerifyEmailInfo,
  UserRole,
  verifyEmail,
  verifyLinkExpiration,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { getCookieItem } from "utils/utils";

interface ICustomBottomNavigationProps extends BottomNavigationProps {
  role: string;
  userauth: number;
}

interface ISocialUserData {
  createdNewUser: boolean;
  status: string;
  user: {
    email: string;
    id: string;
    thirdParty: {
      firstName: string;
      id: string; // Type of ThirdParty i.e., Google, Facebook, Apple Etc.
      lastName: string;
      userId: string;
    };
    timeJoined: number;
  };
}
interface ISocialUser {
  data: ISocialUserData;
  error: boolean;
  message: string;
  status: number;
}

const StyledBottomNavigation = styled(
  BottomNavigation,
)<ICustomBottomNavigationProps>(({ role, userauth }) => ({
  "& .MuiSvgIcon-root": {
    color: "#555555",
  },
  "& .Mui-selected": {
    "& .MuiBottomNavigationAction-label": {
      fontSize: "10px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
    "& .MuiSvgIcon-root, & .MuiBottomNavigationAction-label": {
      color:
        !userauth || role === UserRole.GUEST
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
    },
  },
}));

function MobileViewBottomNav() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    userAuth,
    showAuthModal,
    userAuthData,
    emailValidationFlags,
    phoneValidationFlags,
    isMobileViewAuthSessionActive,
  } = useAppSelector(s => s.auth);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { userProfileData } = useAppSelector(s => s.profile);
  const { croppedImageReadUrl } = userProfileData;

  const croppedImage: string = croppedImageReadUrl || "";

  const [value, setValue] = React.useState(0);
  const role = getCookieItem("userRole");
  const authRoutes = useMemo(() => ["/login", "/reset-password"], []);

  useEffect(() => {
    if (!showAuthModal) {
      setValue(0);
    } else {
      setValue(3);
    }
  }, [showAuthModal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);

  useEffect(() => {
    const handleVerifyEmail = async () => {
      if (pathname.includes("/")) {
        setValue(0);
      }
      if (pathname.includes("/host/listings")) {
        setValue(1);
      }
      if (pathname.includes("/account")) {
        setValue(2);
      }
      if (authRoutes.includes(pathname)) {
        if (!isMobileViewAuthSessionActive) {
          dispatch(setMobileViewAuthSession(true));
        }
      }
      if (pathname.includes("/activate-account")) {
        const params = pathname.split("/");
        const email = decodeURIComponent(params[5]);
        const token = params[3];

        const response = await dispatch(verifyLinkExpiration(token));

        if (response && !response.error) {
          const res = await verifyEmail(token);
          if (res && !res.error) {
            dispatch(updateVerifyEmailInfo(email, token));
            dispatch(updateAuthStep(AuthFlowTypes.PASSWORD));
            navigate("/login");
          } else {
            const errorPayload: IAlertPayload = { message: res?.message };
            dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
          }
        } else {
          const errorPayload: IAlertPayload = { message: response?.message };
          dispatch({ type: AlertType.ALERT_ERROR, payload: errorPayload });
        }
      } else {
        localStorage.clear();
      }
    };

    handleVerifyEmail();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // const handleClickExplore = () => {
  //   if (!userAuth && showAuthModal) {
  //     dispatch(showHideAuthModal(false));
  //   }
  //   navigate("/");
  // };

  const handleClickLogin = () => navigate("/login");

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        display: isMobileViewAuthSessionActive ? "none" : "block",
        left: 0,
        right: 0,
        borderTop: "1px solid #B1B1B1",
        zIndex: "1305",
        overflow: "hidden",
      }}
    >
      <StyledBottomNavigation
        showLabels
        role={role}
        userauth={+(userAuth as boolean)}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        {/* {userAuth && role === UserRole.HOST ? (
          <BottomNavigationAction
            icon={<SvgIcon inheritViewBox component={BottomNavHomeIcon} />}
            label="Home"
            onClick={() => navigate("/")}
          />
        ) : // <BottomNavigationAction
        //   icon={<SvgIcon inheritViewBox component={BottomNavExploreIcon} />}
        //   label="Explore"
        //   onClick={handleClickExplore}
        // />
        null} */}
        <BottomNavigationAction
          icon={<SvgIcon inheritViewBox component={WingItBlackLogo} />}
          label="Home"
          onClick={() => navigate("/")}
        />
        {userAuth ? (
          role === UserRole.GUEST ? (
            <BottomNavigationAction
              icon={<SvgIcon inheritViewBox component={BottomNavListingIcon} />}
              label="Bookings"
              onClick={() => navigate("/guest-booking")}
            />
          ) : (
            <BottomNavigationAction
              icon={<SvgIcon inheritViewBox component={BottomNavListingIcon} />}
              label="Listings"
              onClick={() => navigate("/host/listings")}
            />
          )
        ) : null}
        {userAuth ? (
          <BottomNavigationAction
            icon={
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={0.75}
                sx={{
                  border: "0.75px solid #555555",
                  borderRadius: "30px",
                  height: "21px",
                  p: "0 3px 0 6px",
                  textAlign: "center",
                  width: "auto",
                }}
              >
                <Box sx={{ height: "7px", width: "10px" }}>
                  <SvgIcon component={BottomNavBurgerMenuIcon} />
                </Box>
                <Avatar
                  alt=""
                  src={croppedImage}
                  sx={{ width: 15, height: 15 }}
                />
              </Stack>
            }
            label="My Account"
            onClick={() => navigate("/account")}
          />
        ) : (
          <BottomNavigationAction
            icon={<SvgIcon inheritViewBox component={LoginIcon} />}
            label="Sign In"
            onClick={handleClickLogin}
          />
        )}

        <BottomNavigationAction
          aria-controls={open ? "more-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          icon={<SvgIcon inheritViewBox component={MoreVertIcon} />}
          id="more-button"
          label="More"
          onClick={handleMoreClick}
        />

        <Menu
          anchorEl={anchorEl}
          id="more-menu"
          MenuListProps={{
            "aria-labelledby": "more-button",
          }}
          open={open}
          onClose={handleClose}
        >
          {REF_NAV_ITEMS.map(item => {
            return (
              <MenuItem
                key={item.label}
                onClick={() => {
                  handleClose();
                  window.open(item.link, "_self", "resources");
                }}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Menu>
      </StyledBottomNavigation>
    </Box>
  );
}

export default MobileViewBottomNav;
