import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper, { StepperProps } from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import styled from "@mui/system/styled";
import { propertyTitles } from "constants/constants";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { Fragment } from "react";
import { useAppSelector } from "store/hooks";
import { PropertySteps } from "store/property/PropertyTypes";
import theme from "theme";

import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";

interface IStepList {
  activeStep: number;
  description: string;
  title: string;
  shortTitle: string;
}

const {
  ACTIVITY_TYPE,
  FIND_YOUR_PROPERTY,
  GET_STARTED_STEP1,
  GET_STARTED_STEP2,
  GET_STARTED_STEP3,
} = PropertySteps;

const stepsList: IStepList[] = [
  {
    activeStep: GET_STARTED_STEP1,
    description:
      "In the 1st section, you will add your property using a map and search.",
    shortTitle: "Add property",
    title: "Step 1: Add property",
  },
  {
    activeStep: GET_STARTED_STEP2,
    description: `In the next section, you will be able to add information about the Activities offered on this Property. <br/>
      <ul>
        <li>Activity Type</li>
        <li>Where are the entrances?</li>
        <li>Where is the activity located?</li>
        <li>Availability</li>
        <li>Pricing</li>
        <li>Parking and Entrances</li>
      </ul>`,
    shortTitle: "Details",
    title: "Step 2: Activity Details",
  },
  {
    activeStep: GET_STARTED_STEP3,
    description: `In the 3rd section, you will have the ability to add activity specific information. <br/>
    <ul>
      <li>What type of activity is offered?</li>
      <li>Where are some amenities offered?</li>
      <li>How many people can access?</li>
      <li>How much do you charge?</li>
      <li>When are your activities available?</li>
    </ul>`,
    shortTitle: "Activities",
    title: "Step 3: Add activities",
  },
];

const CustomStepper = styled(Stepper)<StepperProps>(({ theme }) => ({
  "&.MuiStepper-root": {
    width: "100%",
    "& .MuiStepConnector-root": {
      left: "calc(-50% + 30px)",
      right: "calc(50% + 30px)",
      top: "15px",
      "& .MuiStepConnector-line": {
        borderColor: theme.palette.text.secondary,
        borderTopStyle: "dashed",
        borderTopWidth: "2px",
      },
    },
    "& .MuiStep-root": {
      "& .MuiStepLabel-root": {
        "& .MuiStepLabel-labelContainer": {
          "& .MuiStepLabel-label.Mui-active": {
            color: theme.palette.secondary.main,
          },
        },
        "& .MuiStepLabel-iconContainer": {
          "& .MuiSvgIcon-root": {
            width: 32,
            height: 32,
            "&.Mui-active, &.Mui-completed": {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
    },
  },
}));

function GetStarted() {
  const { navigateTo } = useNavigateStep();
  const { propertyStep } = useAppSelector(s => s.property);
  const isMobile = useIsMobile("midsm");
  const propertyStepTitle = propertyTitles[propertyStep];

  const handleStart = () => {
    switch (propertyStep) {
      case GET_STARTED_STEP1:
        navigateTo(FIND_YOUR_PROPERTY);
        break;
      case GET_STARTED_STEP2:
        navigateTo(ACTIVITY_TYPE);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <RightMainComponentWrapper>
        <Stack
          alignItems={isMobile ? "flex-start" : "center"}
          justifyContent="center"
          sx={{ textAlign: isMobile ? "start" : "center" }}
        >
          <Typography
            component="div"
            dangerouslySetInnerHTML={{
              __html: propertyStepTitle.title,
            }}
            sx={{ mb: isMobile ? 2 : 3 }}
            variant={isMobile ? "h3" : "h1"}
          />
          {propertyStep === PropertySteps.GET_STARTED_STEP1 && (
            <Typography
              component="div"
              mx={theme.spacing(isMobile ? 0 : 4)}
              variant={isMobile ? "h6" : "h7"}
            >
              We will walk you through 3 simple steps to onboard your property
              and activities
            </Typography>
          )}
        </Stack>
        <CustomStepper alternativeLabel activeStep={0} sx={{ mt: 4 }}>
          {stepsList.map(({ shortTitle, activeStep }: IStepList) => (
            <Step
              key={shortTitle.replace(" ", "")}
              active={propertyStep === activeStep}
              completed={propertyStep > activeStep}
            >
              <StepLabel
                sx={{
                  ".MuiStepLabel-label": {
                    textDecoration: "none",
                    cursor: "default",
                    color:
                      activeStep === propertyStep
                        ? theme.palette.secondary.main
                        : theme.palette.text.secondary,
                  },
                }}
              >
                {shortTitle}
              </StepLabel>
            </Step>
          ))}
        </CustomStepper>
        <Box p={isMobile ? `${theme.spacing(4)} 0` : theme.spacing(4)}>
          <Stack alignItems="flex-start" justifyContent="center">
            {stepsList.map(
              (
                { title, description, activeStep }: IStepList,
                index: number,
              ) => (
                <Fragment key={title.replaceAll(" ", "") + index.toString()}>
                  <Typography
                    component="h3"
                    sx={{
                      mb: theme.spacing(1),
                      fontSize: "16px",
                      ...(activeStep !== propertyStep
                        ? {
                            fontWeight: "500",
                            color: theme.palette.text.secondary,
                          }
                        : {
                            color: theme.palette.secondary.main,
                          }),
                    }}
                    variant="h3"
                  >
                    {title}
                  </Typography>
                  {activeStep === propertyStep && (
                    <Typography
                      component="p"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                      sx={{
                        mb: theme.spacing(2),
                      }}
                      variant={isMobile ? "h6" : "h7"}
                    />
                  )}
                </Fragment>
              ),
            )}
          </Stack>
        </Box>
      </RightMainComponentWrapper>

      <Footer
        handleNext={handleStart}
        primaryText={
          propertyStep === PropertySteps.GET_STARTED_STEP1 ? "Start" : "Next"
        }
      />
    </>
  );
}

export default GetStarted;
