import Box from "@mui/material/Box";
import { PropertySteps } from "store";
import { useAppSelector } from "store/hooks";

import FindProperty from "./addProperty/FindProperty";
import LeftHeader from "./LeftHeader";

function LeftContainer() {
  const { propertyStep } = useAppSelector(s => s.property);
  const {
    ACTIVITY_DATES,
    ACTIVITY_DETAILS,
    ACTIVITY_PHOTOS,
    ACTIVITY_TYPE,
    ENTRY_POINT,
    EXCLUSION_AREA,
    FIND_YOUR_PROPERTY,
    MAILING_ADDRESS,
    PARKING_POINT,
    PROPERTY_DETAILS,
    REVIEW,
    ACTIVITY_COST,
    PROPERTY_RULES,
  } = PropertySteps;

  const renderLeftChild = () => {
    switch (propertyStep) {
      case REVIEW:
      case ACTIVITY_DATES:
      case ACTIVITY_DETAILS:
      case ACTIVITY_PHOTOS:
      case ACTIVITY_TYPE:
      case ENTRY_POINT:
      case EXCLUSION_AREA:
      case FIND_YOUR_PROPERTY:
      case MAILING_ADDRESS:
      case PARKING_POINT:
      case PROPERTY_DETAILS:
      case ACTIVITY_COST:
      case PROPERTY_RULES:
        return <FindProperty />;
      default:
        return (
          <Box className="left-backgroundImg" height="inherit" width="100%">
            <LeftHeader />
          </Box>
        );
    }
  };

  return <>{renderLeftChild()}</>;
}

export default LeftContainer;
