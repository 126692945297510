import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Container from "@mui/system/Container";
import HeaderBanner from "components/banner/HeaderBanner";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";
import Header from "components/Header/Header";
import MobileViewBottomNav from "components/MobileView/MobileViewBottomNav";
import { WingItLogo } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import Account from "pages/account/AccountContainer";
import Billing from "pages/account/components/billing/Billing";
import ACHInfo from "pages/account/components/finance/ach";
import Profile from "pages/account/components/Profile";
import Security from "pages/account/components/security/SecurityContainer";
import Auth from "pages/Auth/Auth";
import Password from "pages/Auth/components/Password";
import Home from "pages/home";
import GuestBookings from "pages/home/Bookings";
import SearchListing from "pages/home/Listing";
import PropertyView from "pages/home/Property";
import Host from "pages/host/home";
import BookingReview from "pages/host/property/booking-review";
import PropertyEdit from "pages/host/property/edit";
import Listing from "pages/host/property/listing";
import HostPropertyReview from "pages/host/property/review";
import IFramePOC from "pages/IFramePOC";
import { useEffect, useMemo } from "react";
import ReactGA from "react-ga4";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { UserRole } from "store";
import { useAppSelector } from "store/hooks";
import theme from "theme";
import browserPath from "utils/browserPath";
import { getCookieItem } from "utils/utils";

interface IWrapperComponentProps {
  children: React.ReactNode | JSX.Element;
  isMobile: boolean;
}

function Wrapper({ children, isMobile }: IWrapperComponentProps) {
  return (
    <Stack
      alignItems="center"
      direction="column"
      justifyContent="flex-start"
      sx={{
        padding: "40px",
        height: "inherit",
        ...(isMobile && {
          width: "100%",
          padding: "auto",
        }),
      }}
    >
      <Paper
        elevation={isMobile ? 0 : 2}
        sx={{
          borderRadius: "20px",
          overflow: "hidden",
          ...(isMobile && {
            width: "100%",
            borderRadius: "0px",
          }),
        }}
      >
        {children}
      </Paper>
    </Stack>
  );
}

interface IRouteProps {
  roles: string[];
}

const { GUEST, HOST } = UserRole;

function PrivateRoute({ roles }: IRouteProps) {
  const { userAuth } = useAppSelector(s => s.auth);
  const userRole = getCookieItem("userRole");

  const defaultRoute = useMemo(() => {
    return userAuth && userRole !== UserRole.GUEST ? "/host" : "/";
  }, [userAuth, userRole]);

  if (userAuth) {
    if (roles.includes(userRole)) {
      return <Outlet />;
    }
    return <Navigate replace to={defaultRoute} />;
  }

  return (
    <Grid container spacing={8}>
      <Grid item xs>
        <LinearProgress />
      </Grid>
    </Grid>
  );
}

function PublicRoute({ roles }: IRouteProps) {
  const userRole = getCookieItem("userRole");

  const defaultRoute = userRole === UserRole.HOST ? "/host" : "/";

  if (roles.includes(userRole)) {
    return <Outlet />;
  }

  return <Navigate replace to={defaultRoute} />;
}

function AppRoutes() {
  const { REACT_APP_GA_ID } = process.env;
  const { pathname } = window.location;

  const location = useLocation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const userRole = getCookieItem("userRole");

  const defaultRoute = userRole === UserRole.HOST ? "/host" : "/";

  const { userAuth, isMobileViewAuthSessionActive } = useAppSelector(
    s => s.auth,
  );

  const isPropertyRoute = pathname.includes("/host/property");
  const isCreatePropertyRoute = pathname.includes("/host/property/create");
  const isEditPropertyRoute = pathname.includes("host/property/edit");
  const isIFrame = pathname.includes("/iframe");

  useEffect(() => {
    if (REACT_APP_GA_ID) {
      const title = Object.entries(browserPath).find(([key]) =>
        location.pathname.includes(key),
      )?.[1];
      document.title = `${title}` || "Wing It";
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search,
        title: `${title}` || "Wing It",
      });
    }
  }, [REACT_APP_GA_ID, location]);

  return (
    <>
      {/** Create a Skeleton for header bar when `userAuth` is null */}
      {!isIFrame ? (
        <Box sx={{ position: "sticky", top: 0, zIndex: 10 }}>
          {userAuth && !isPropertyRoute ? <HeaderBanner /> : null}
          {!(isCreatePropertyRoute || isEditPropertyRoute) ? (
            isMobile ? (
              <>
                <Box
                  sx={{
                    padding: 0.5,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                  onClick={() => navigate("/")}
                >
                  <SvgIcon
                    inheritViewBox
                    component={WingItLogo}
                    sx={{
                      height: { sm: "36px", xs: "20px" },
                      width: { sm: "135px", xs: "80px" },
                    }}
                  />
                </Box>
                <MobileViewBottomNav />
              </>
            ) : (
              <Header />
            )
          ) : null}
        </Box>
      ) : null}
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowScrolling: "touch",
          WebkitOverflowScrolling: "touch",
          zIndex: 9,
          position: "relative",
          ...(isMobile && {
            ...(!isPropertyRoute && {
              marginBottom: isMobileViewAuthSessionActive ? "auto" : "72px",
            }),
            maxHeight: "100%",
            height: "100%",
          }),
        }}
      >
        <Box
          display="flex"
          flex={1}
          flexDirection="column"
          paddingBottom={isMobile ? "75px" : 0}
        >
          <ErrorBoundary>
            <Routes>
              <Route element={<IFramePOC />} path="/iframe" />
              {/* GUEST Public Routes */}
              <Route element={<PublicRoute roles={[GUEST]} />}>
                {/* <Route
                element={<Home />}
                path="/change-email/token/:token/email/:email"
              /> */}
                <Route
                  element={
                    <Wrapper isMobile={isMobile}>
                      <Box sx={{ p: theme.spacing(6) }}>
                        <Password type="reset-password" />
                      </Box>
                    </Wrapper>
                  }
                  path="/reset-password/token/:token/email/:email"
                />
                <Route
                  element={
                    <Wrapper isMobile={isMobile}>
                      <Auth />
                    </Wrapper>
                  }
                  path="/login"
                />
                <Route element={<Home />} path="/" />
                <Route element={<SearchListing />} path="search-listing" />
                <Route
                  element={<PropertyView />}
                  path="/property/:propertyId"
                />
              </Route>

              {/* HOST Public Routes */}
              {/* <Route element={<PublicRoute role={[HOST]} />}></Route> */}

              {/* GUEST Private Routes */}
              <Route element={<PrivateRoute roles={[GUEST]} />}>
                <Route element={<GuestBookings />} path="/guest-booking" />
                <Route
                  element={<BookingReview />}
                  path="/booking-review/:bookingId"
                />
              </Route>

              {/* Common Private Routes */}
              <Route element={<PrivateRoute roles={[GUEST, HOST]} />}>
                <Route element={<Account />} path="/account">
                  <Route index element={<Profile />} />
                  <Route element={<Profile />} path="profile" />
                  <Route element={<Security />} path="security" />
                  <Route element={<PrivateRoute roles={[GUEST]} />}>
                    <Route element={<Billing />} path="billing" />
                  </Route>
                  <Route element={<PrivateRoute roles={[HOST]} />}>
                    <Route element={<ACHInfo />} path="finance" />
                  </Route>
                </Route>
              </Route>
              {/* Guest Private Routes */}
              <Route element={<PrivateRoute roles={[GUEST]} />}></Route>

              {/* HOST Private Routes */}
              <Route element={<PrivateRoute roles={[HOST]} />}>
                <Route element={<Host />} path="/host" />
                <Route path="/host/property">
                  <Route element={<PropertyEdit />} path="create/" />
                  <Route element={<PropertyEdit />} path="edit/:propertyId" />
                  <Route
                    element={<HostPropertyReview />}
                    path="review/:propertyId"
                  />
                </Route>
                <Route element={<Listing />} path="/host/listings" />
                <Route
                  element={<BookingReview />}
                  path="/host/booking-review/:bookingId"
                />
              </Route>

              {/* Final Redirection */}
              <Route
                element={<Navigate replace to={defaultRoute} />}
                path="*"
              />
              {/* Final Redirection */}
            </Routes>
          </ErrorBoundary>
        </Box>
      </Container>
    </>
  );
}

export default AppRoutes;
