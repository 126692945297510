import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import valid from "card-validator";
import CustomInputField from "components/TextField/CustomInputField";
import CustomSelectField from "components/TextField/CustomSelectField";
import { ONLY_ALPHABETS_SPACES, US_STATES } from "constants/constants";
import useCard from "hooks/useCard";
import { useIsMobile } from "hooks/useIsMobile";
import React, { useEffect, useState } from "react";
import {
  addCard,
  editCard,
  ICreateCardRequestData,
  IResponseData,
  showCardForm,
} from "store";
import { CardFormType } from "store/billing/BillingTypes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { isNumberKey, scrollToTop } from "utils/utils";

import { ReactComponent as Amex } from "../../../../assets/cards/amex.svg";
import { ReactComponent as Diners } from "../../../../assets/cards/dinersclub.svg";
import { ReactComponent as Discover } from "../../../../assets/cards/discover.svg";
import { ReactComponent as Jcb } from "../../../../assets/cards/jcb.svg";
import { ReactComponent as Master } from "../../../../assets/cards/master.svg";
import { ReactComponent as Visa } from "../../../../assets/cards/visa.svg";
import RemoveCard from "./RemoveCard";
import useCardForm from "./useCardForm";

export interface ICardState {
  cardAlias: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  setAsDefault: boolean;
}

const { EDIT } = CardFormType;

function AddCardForm() {
  const dispatch = useAppDispatch();
  const { cards, activeEditCardIndex, loading, formType } = useAppSelector(
    s => s.billing,
  );
  const currentYear = new Date().getFullYear().toString().slice(-2);
  const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");
  const isMobile = useIsMobile();
  const { FALLBACK_CARD, formatCardNumber, removeNonNumber } = useCard();
  const [cardType, setCardType] = useState("visa");
  const initialAddState = {
    cardAlias: "",
    cardNumber: "",
    cardExpiryMonth: currentMonth,
    cardExpiryYear: currentYear,
    name: "",
    address: "",
    city: "",
    state: US_STATES[0],
    zipcode: "",
    setAsDefault: cards.length === 0,
  };

  const card = cards[activeEditCardIndex];
  const initialEditState = {
    cardAlias: card?.aliasName,
    cardNumber: `Ending in ${card?.lastNumbers}  `,
    cardExpiryMonth: card?.expirationDate.slice(0, 2),
    cardExpiryYear: card?.expirationDate.slice(2),
    name: card?.billingName,
    address: card?.billingAddress.address,
    city: card?.billingAddress.city,
    state: card?.billingAddress.state,
    zipcode: card?.billingAddress.zip,
    setAsDefault: card?.isDefault,
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleTrimObjectValues = (object: ICardState) => {
    type T = keyof ICardState;
    const values = object;
    Object.keys(values).forEach((key: string) => {
      if (typeof values[key as T] === "string") {
        (values[key as T] as string) = (values[key as T] as string).trim();
      }
    });
    return values;
  };

  const onAddCardSubmit = async () => {
    const {
      cardAlias,
      name,
      cardNumber,
      cardExpiryMonth,
      cardExpiryYear,
      address,
      city,
      state,
      zipcode,
      setAsDefault,
    } = handleTrimObjectValues(values);

    const createRequestObj: ICreateCardRequestData = {
      aliasName: cardAlias,
      billingName: name,
      numbers: removeNonNumber(cardNumber),
      expirationDate: `${cardExpiryMonth}${cardExpiryYear}`,
      billingAddress: {
        address,
        city,
        state,
        zip: zipcode,
      },
      isDefault: setAsDefault,
    };

    const response = (await dispatch(
      addCard(createRequestObj),
    )) as IResponseData;
    if (response && typeof response.error !== "undefined" && !response.error) {
      dispatch(showCardForm(false, undefined));
    }
  };

  const onEditCardSubmit = async () => {
    const {
      cardAlias,
      name,
      cardExpiryMonth,
      cardExpiryYear,
      address,
      city,
      state,
      zipcode,
      setAsDefault,
    } = handleTrimObjectValues(values);

    const createRequestObj: ICreateCardRequestData = {
      aliasName: cardAlias,
      billingName: name,
      expirationDate: `${cardExpiryMonth}${cardExpiryYear}`,
      billingAddress: {
        address,
        city,
        state,
        zip: zipcode,
      },
      isDefault: setAsDefault,
    };

    const response = (await dispatch(
      editCard(createRequestObj, cards[activeEditCardIndex].documentId),
    )) as IResponseData;
    if (response && typeof response.error !== "undefined" && !response.error) {
      dispatch(showCardForm(false, undefined));
    }
  };

  const handlePasteCardNumber = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    let pastedText = "";
    if (event.clipboardData && event.clipboardData.getData) {
      pastedText = event.clipboardData.getData("text/plain");
    }
    setFieldValue("cardNumber", cardFormat(pastedText));
  };

  const handleAllowOnlyNumber = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    isNumberKey(event);
  };

  const handleRestrictSpaces = (
    value: string,
    customRegexValidation?: RegExp,
  ) => {
    return value !== " "
      ? value
          .replace(customRegexValidation as RegExp, "")
          /* Restrict space at first position */
          .replace(/^\s+/g, "")
          /* Allow only one space between words */
          .replace(/\s{2,}/g, " ")
      : value.trim();
  };

  const cardFormat = (text: string) => {
    const userInputCard = valid.number(text).card || FALLBACK_CARD;
    setCardType(userInputCard.type);
    const formatted = formatCardNumber(text, userInputCard);
    return formatted;
  };

  const handleDisablePastMonth = (month: string): boolean => {
    return parseInt(values.cardExpiryYear, 10) > parseInt(currentYear, 10)
      ? false
      : parseInt(month, 10) < parseInt(currentMonth, 10);
  };

  const handleDisableDefaultCheckbox = () => {
    if (formType === EDIT) {
      return card?.isDefault;
    }
    return cards.length === 0;
  };

  const formik = useCardForm(
    formType === EDIT ? onEditCardSubmit : onAddCardSubmit,
    formType === EDIT ? initialEditState : initialAddState,
    formType as number,
  );
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    setFieldValue,
    dirty,
  } = formik;

  useEffect(() => {
    if (
      values.cardExpiryYear === currentYear &&
      !(values.cardExpiryMonth > currentMonth)
    ) {
      setFieldValue("cardExpiryMonth", currentMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.cardExpiryYear]);

  return (
    <Box noValidate component="form" onSubmit={handleSubmit}>
      <Stack spacing={theme.spacing(3)}>
        <CustomInputField
          autoComplete="off"
          error={!!(touched.cardAlias && errors.cardAlias)}
          errormessage={touched.cardAlias ? errors.cardAlias : undefined}
          inputProps={{ maxLength: 35 }}
          label="Name"
          name="cardAlias"
          type="text"
          value={values.cardAlias}
          onBlur={handleBlur}
          onChange={e =>
            handleChange("cardAlias")(handleRestrictSpaces(e.target.value))
          }
        />
        <CustomInputField
          autoComplete="off"
          disabled={formType === EDIT}
          endAdornment={
            <InputAdornment position="end">
              <SvgIcon
                inheritViewBox
                component={
                  cardType === "american-express"
                    ? Amex
                    : cardType === "visa"
                    ? Visa
                    : cardType === "diners-club"
                    ? Diners
                    : cardType === "discover"
                    ? Discover
                    : cardType === "mastercard"
                    ? Master
                    : Jcb
                }
                sx={{ height: "45px", width: "60px" }}
              />
            </InputAdornment>
          }
          error={!!(touched.cardNumber && errors?.cardNumber)}
          errormessage={touched.cardNumber ? errors?.cardNumber : undefined}
          inputProps={{ minLength: 13, maxLength: 25 }}
          label="Card Number"
          name="cardNumber"
          sx={{
            "& .Mui-disabled": {
              WebkitTextFillColor: theme.palette.text.primary,
              fontWeight: "bold",
            },
          }}
          type="text"
          value={values.cardNumber}
          onBlur={handleBlur}
          onChange={e => handleChange("cardNumber")(cardFormat(e.target.value))}
          onKeyPress={handleAllowOnlyNumber}
          onPaste={handlePasteCardNumber}
        />
        <CustomInputField
          autoComplete="off"
          error={!!(touched.name && errors?.name)}
          errormessage={touched.name ? errors?.name : undefined}
          inputProps={{ maxLength: 30 }}
          label="Card Name"
          name="name"
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={e =>
            handleChange("name")(handleRestrictSpaces(e.target.value))
          }
        />
        <Stack direction="row" spacing={theme.spacing(3)}>
          <CustomSelectField
            error={!!(touched.cardExpiryMonth && errors?.cardExpiryMonth)}
            errormessage={
              touched.cardExpiryMonth ? errors?.cardExpiryMonth : undefined
            }
            inputProps={{
              name: "cardExpiryMonth",
            }}
            label="Exp Month"
            value={values.cardExpiryMonth}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            {Array(12)
              .fill("")
              .map((_, index) => {
                const month = (index + 1).toString().padStart(2, "0");
                return (
                  <option
                    key={`expiryMonth-${index + 1}`}
                    disabled={handleDisablePastMonth(month)}
                    value={month}
                  >
                    {month}
                  </option>
                );
              })}
          </CustomSelectField>
          <CustomSelectField
            error={!!(touched.cardExpiryYear && errors?.cardExpiryYear)}
            errormessage={
              touched.cardExpiryYear ? errors?.cardExpiryYear : undefined
            }
            inputProps={{
              name: "cardExpiryYear",
            }}
            label="Exp Year"
            value={values.cardExpiryYear}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            {Array(16)
              .fill("")
              .map((_, index) => (
                <option
                  key={`expiryYear-${index + 1}`}
                  value={parseInt(currentYear, 10) + index}
                >
                  {parseInt(currentYear, 10) + index}
                </option>
              ))}
          </CustomSelectField>
        </Stack>
        <Divider />
        <CustomInputField
          autoComplete="new-password"
          error={!!(touched.address && errors?.address)}
          errormessage={touched.address ? errors?.address : undefined}
          inputProps={{ maxLength: 32 }}
          label="Billing Address"
          name="address"
          type="text"
          value={values.address}
          onBlur={handleBlur}
          onChange={e =>
            handleChange("address")(handleRestrictSpaces(e.target.value))
          }
        />
        <Stack direction="row" spacing={theme.spacing(3)}>
          <CustomInputField
            autoComplete="new-password"
            error={!!(touched.city && errors?.city)}
            errormessage={touched.city ? errors?.city : undefined}
            inputProps={{ maxLength: 36 }}
            label="City"
            name="city"
            type="text"
            value={values.city}
            onBlur={handleBlur}
            onChange={e =>
              handleChange("city")(
                handleRestrictSpaces(e.target.value, ONLY_ALPHABETS_SPACES),
              )
            }
          />
          <CustomSelectField
            error={!!(touched.state && errors?.state)}
            errormessage={touched.state ? errors?.state : undefined}
            inputProps={{
              name: "state",
            }}
            label="State"
            value={values.state}
            onBlur={handleBlur}
            onChange={handleChange}
          >
            {US_STATES.map(state => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </CustomSelectField>
        </Stack>
        <Stack direction="row" mr={theme.spacing(2)} spacing={theme.spacing(3)}>
          <Box width="50%">
            <CustomInputField
              autoComplete="new-password"
              error={!!(touched.zipcode && errors?.zipcode)}
              errormessage={touched.zipcode ? errors?.zipcode : undefined}
              inputProps={{ maxLength: 5 }}
              label="Zip Code"
              name="zipcode"
              type="text"
              value={values.zipcode}
              onBlur={handleBlur}
              onChange={e =>
                handleChange("zipcode")(handleRestrictSpaces(e.target.value))
              }
              onKeyPress={handleAllowOnlyNumber}
            />
          </Box>
          <Box width="50%" />
        </Stack>
        <Stack direction="column" spacing={theme.spacing(2)}>
          <Divider />
          <Stack direction="row" sx={{ my: theme.spacing(1) }}>
            <Checkbox
              checked={values.setAsDefault}
              inputProps={{ "aria-label": "controlled" }}
              name="setAsDefault"
              sx={{
                p: 0,
                pointerEvents: handleDisableDefaultCheckbox() ? "none" : "auto",
                "&.Mui-checked": {
                  color: theme.palette.text.primary,
                },
              }}
              onChange={handleChange}
            />
            <Box sx={{ ml: theme.spacing(1) }}>
              <Typography component="span">Set as Default card</Typography>
            </Box>
          </Stack>
          <Divider />
        </Stack>
        <Box />
        <Stack
          alignItems="center"
          direction={isMobile ? "column-reverse" : "row"}
          justifyContent="center"
          spacing={theme.spacing(3)}
        >
          {formType === EDIT && <RemoveCard />}
          <LoadingButton
            disableRipple
            disableTouchRipple
            fullWidth
            disabled={!(isValid && dirty)}
            loading={loading}
            sx={{
              backgroundColor: theme.palette.text.primary,
              "&:hover": {
                backgroundColor: theme.palette.text.primary,
              },
            }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
}

export default AddCardForm;
