import { Reducer } from "redux";

import { AlertActions, IAlertState } from "./AlertInterface";
import { AlertStatusTypes, AlertType } from "./AlertTypes";

const initialState: IAlertState = {
  status: "success" || "info" || "warning" || "error",
  message: "",
  open: false,
  delay: 4000,
};

const AlertReducer: Reducer<IAlertState, AlertActions> = (
  state = initialState,
  action = {} as AlertActions,
) => {
  switch (action.type) {
    case AlertType.CLEAR_ALERT:
      return {
        ...state,
        open: false,
      };
    case AlertType.ALERT_SUCCESS:
    case AlertType.ALERT_ERROR:
    case AlertType.ALERT_WARNING:
    case AlertType.ALERT_INFO:
      return {
        ...state,
        open: true,
        status: AlertStatusTypes[action.type],
        message:
          action.payload.message || "Something went wrong, please try again.",
        delay: action.payload?.delay ? action.payload.delay : state.delay,
      };
    default:
      return state;
  }
};

export default AlertReducer;
