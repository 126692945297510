import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { IProperty } from "store/property/PropertyInterface";
import theme from "theme";

interface IFoundProperty {
  property: IProperty;
  location: number[];
  onRemove: () => void;
}

function FoundProperty({ property, location, onRemove }: IFoundProperty) {
  return (
    <>
      <Stack
        spacing={theme.spacing(1)}
        style={{
          backgroundColor: "white",
          border: "none",
          padding: 20,
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
        >
          <Typography className="property-info-title">
            Property Found
          </Typography>
          <Box>
            <IconButton sx={{ p: 0 }} onClick={onRemove}>
              <CloseIcon sx={{ color: theme.palette.common.black }} />
            </IconButton>
          </Box>
        </Stack>
        <Stack alignItems="flex-start" direction="row">
          <Typography className="property-info-sub-title">Location:</Typography>
          <Typography className="property-info-sub-title-value">
            {property?.address1}{" "}
          </Typography>
        </Stack>
        <Stack alignItems="flex-start" direction="row">
          <Typography className="property-info-sub-title">Lat/Lon:</Typography>
          <Typography className="property-info-sub-title-value">
            {location[1]}/{location[0]}{" "}
          </Typography>
        </Stack>
        <Stack alignItems="flex-start" direction="row">
          <Typography className="property-info-sub-title">Acres:</Typography>
          <Typography className="property-info-sub-title-value">
            {property?.calcAcres}
          </Typography>
        </Stack>
        <Stack alignItems="flex-start" direction="row">
          <Typography className="property-info-sub-title">Owner:</Typography>
          <Typography className="property-info-sub-title-value">
            {property?.owner}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
}

export default FoundProperty;
