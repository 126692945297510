import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { Stack, Typography } from "@mui/material";
import DateRangePicker from "components/DateRangePicker";
import CustomInputField from "components/TextField/CustomInputField";
import { useIsMobile } from "hooks/useIsMobile";
import moment from "moment";
import React, { useState } from "react";
import { Range } from "react-date-range";
import {
  addSelectedActivityDate,
  editSelectedActivityDate,
  setEditActiveIndex,
  showDateRangeCalender,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import Footer from "../../Footer";
import { RightMainComponentWrapper } from "../../RightContainer";

function CreateNewDateRange() {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile("midsm");
  const { activityDates, activeEditRangeIndex, editActive } = useAppSelector(
    s => s.property,
  );
  const { selectedAreaActivity } = useAppSelector(s => s.property);

  const [rangeName, setRangeName] = useState(
    editActive
      ? activityDates[activeEditRangeIndex as number]?.name
      : `Dates ${activityDates.length + 1}`,
  );
  const [error, setError] = useState({
    rangeName: false,
    message: "",
  });
  const [range, setRange] = useState<Range[]>([
    {
      startDate: editActive
        ? new Date(
            activityDates[activeEditRangeIndex as number]?.startDate || "",
          )
        : new Date(),
      endDate: editActive
        ? new Date(activityDates[activeEditRangeIndex as number]?.endDate || "")
        : new Date(),
      key: `selection`,
    },
  ]);

  const handleCheckUniqueRangeName = () => {
    if (editActive) {
      if (rangeName === activityDates[activeEditRangeIndex as number]?.name) {
        return false;
      }
    }
    return activityDates.some(
      range =>
        range.name.toLowerCase() === (rangeName?.toLocaleLowerCase() as string),
    );
  };

  const handleNameCheck = (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    if (value.length > 0) {
      const isPresent = handleCheckUniqueRangeName();
      if (isPresent) {
        setError({
          ...error,
          rangeName: true,
          message: "Please enter a unique date range name",
        });
      } else if (error.rangeName) {
        setError({
          ...error,
          rangeName: false,
          message: "",
        });
      }
    } else {
      setError({
        ...error,
        rangeName: true,
        message: "Please enter a date range name",
      });
    }
  };

  const handleCheckUniqueDateRange = () => {
    let isPresent = false;
    activityDates.forEach(({ startDate, endDate }) => {
      if (
        moment(range[0].startDate).isBetween(startDate, endDate, "days", "[]")
      ) {
        isPresent = true;
      }
    });
    return isPresent;
  };

  const handleCheckSameDateRange = () => {
    let isSame = false;
    if (
      moment(range[0].startDate).isSame(
        activityDates[activeEditRangeIndex as number]?.startDate,
      ) &&
      moment(range[0].endDate).isSame(
        activityDates[activeEditRangeIndex as number]?.endDate,
      )
    ) {
      isSame = true;
    }
    return isSame;
  };

  const isFormValid = () => {
    if (editActive) {
      return [
        !(rangeName === activityDates[activeEditRangeIndex as number]?.name) &&
          !error.rangeName &&
          rangeName.trim().length > 0,
        !handleCheckSameDateRange(),
      ].some(Boolean);
    }
    return [
      !error.rangeName && rangeName.trim().length > 0,
      !handleCheckUniqueDateRange(),
    ].every(Boolean);
  };

  const handleHideDateRangeCalender = () => {
    dispatch(showDateRangeCalender(false));
  };

  const handleSave = async () => {
    handleHideDateRangeCalender();
    if (editActive) {
      const response = await dispatch(
        editSelectedActivityDate(
          {
            startDate: range[0].startDate,
            endDate: range[0].endDate,
            name: rangeName,
            documentId:
              activityDates[activeEditRangeIndex as number]?.documentId,
          },
          selectedAreaActivity?.documentId || "",
        ),
      );
      if (response && !response.error) {
        dispatch(setEditActiveIndex(false, undefined));
      }
    } else {
      dispatch(
        addSelectedActivityDate(
          {
            startDate: range[0].startDate,
            endDate: range[0].endDate,
            name: rangeName,
          },
          selectedAreaActivity?.documentId || "",
        ),
      );
    }
  };

  const handleCancel = () => {
    handleHideDateRangeCalender();
    if (editActive) {
      dispatch(setEditActiveIndex(false, undefined));
    }
  };

  const getInBetweenDates = (startDate: Date, endDate: Date) => {
    const now = moment(startDate).clone();
    const dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.toDate());
      now.add(1, "days");
    }

    return dates;
  };

  const handleDisableDates = () => {
    const tobeDisabledDates: Date[] = [];
    activityDates.forEach(({ startDate, endDate }, index) => {
      if (index === activeEditRangeIndex) return;
      const dates = getInBetweenDates(startDate as Date, endDate as Date);
      dates.map(date => tobeDisabledDates.push(date));
    });
    return tobeDisabledDates;
  };

  return (
    <>
      <RightMainComponentWrapper center={false}>
        <Stack
          spacing={theme.spacing(2)}
          sx={{
            boxSizing: "border-box",
            mt: theme.spacing(isMobile ? 0 : 5),
            px: theme.spacing(isMobile ? 0 : 3),
          }}
          width="100%"
        >
          <Stack spacing={theme.spacing(2)}>
            <Typography component="div" variant="subtitle3">
              {editActive ? "Edit" : "New"} Date Range
            </Typography>
            <CustomInputField
              autoComplete="off"
              error={error.rangeName}
              errormessage={error.message}
              inputProps={{ maxLength: 70 }}
              name="rangeName"
              placeholder="Enter range name"
              type="text"
              value={rangeName}
              onBlur={handleNameCheck}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setRangeName(e.target.value)
              }
            />
            <DateRangePicker
              direction={isMobile ? "vertical" : "horizontal"}
              disabledDates={handleDisableDates()}
              endDatePlaceholder="End Date"
              maxDate={moment().add(365, "days").toDate()}
              minDate={moment().toDate()}
              rangeColors={["#3C2B6B"]}
              ranges={range}
              startDatePlaceholder="Start Date"
              onChange={ranges => setRange([ranges.selection])}
            />
          </Stack>
        </Stack>
      </RightMainComponentWrapper>
      <Footer
        disabled={!isFormValid()}
        handleBack={handleCancel}
        handleNext={handleSave}
        primaryText="Save"
        secondaryText="Back"
      />
    </>
  );
}

export default CreateNewDateRange;
