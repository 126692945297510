import BackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { IMenu, sideMenuItems } from "constants/constants";
import { useIsMobile } from "hooks/useIsMobile";
import useLogout from "hooks/useLogout";
import useUpdateRole from "hooks/useUpdateRole";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { fetchUserProfileInfo, UserRole } from "store";
import { useAppDispatch } from "store/hooks";
import theme from "theme";
import { getCookieItem } from "utils/utils";

import ProfileAvatar from "./components/ProfileAvatar";

function Account() {
  const id = window.location.pathname.split("/").pop();
  const userRole = getCookieItem("userRole");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const updateRole = useUpdateRole();
  const clearSession = useLogout();

  const isMobile = useIsMobile();

  const [showChildRoute, setShowChildRoute] = useState(true);
  const [showNavList, setShowNavList] = useState(true);
  const [childRoute, setChildRoute] = useState("");

  useEffect(() => {
    dispatch(fetchUserProfileInfo());
  }, [dispatch]);

  useEffect(() => {
    if (isMobile) {
      setShowChildRoute(false);
      setShowNavList(true);
    }
  }, [isMobile]);

  const navigateToChildRoute = (item: IMenu) => {
    if (item.externalLink) {
      window.open(item.path, "_self");
    } else {
      if (isMobile) {
        setShowNavList(false);
        setShowChildRoute(true);
        setChildRoute(item.path);
      } else {
        navigate(item.path);
      }
    }
  };
  useEffect(() => {
    if (showChildRoute && !showNavList) {
      navigate(childRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showChildRoute, showNavList]);

  const onBack = () => {
    setShowNavList(true);
    setShowChildRoute(false);
    navigate(-1);
  };

  return (
    <Grid
      container
      spacing={5}
      sx={{
        padding: {
          xs: 3,
          sm: 3,
          md: 5,
          lg: 10,
          xl: 10,
        },
      }}
    >
      {showNavList && (
        <Grid item md={4} sm={4} xs={12}>
          <ProfileAvatar />
          <List>
            {sideMenuItems
              .filter((sideMenu: IMenu) => {
                if (isMobile)
                  return (
                    sideMenu.roles.includes(userRole) &&
                    sideMenu?.mode?.includes("mobile")
                  );

                return (
                  sideMenu.roles.includes(userRole) &&
                  sideMenu?.mode?.includes("web")
                );
              })
              .map((item: IMenu) => {
                const labelId = `checkbox-list-label-${item}`;

                return (
                  <ListItem
                    key={item.label}
                    divider
                    className="cursor-pointer"
                    role={undefined}
                    sx={{ fontWeight: id === item.path ? 700 : 500 }}
                    onClick={() => navigateToChildRoute(item)}
                  >
                    <ListItemText
                      id={labelId}
                      primary={
                        <Typography
                          style={{ fontWeight: id === item.path ? 700 : 500 }}
                          variant="h6"
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction
                      onClick={() => navigateToChildRoute(item)}
                    >
                      <IconButton aria-label="comments" edge="end">
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
          <Stack direction="column" marginTop={2} spacing={2}>
            <Button
              color={userRole === UserRole.GUEST ? "secondary" : "primary"}
              sx={{
                px: theme.spacing(3.25),
                py: theme.spacing(1),
              }}
              variant="contained"
              onClick={() => updateRole()}
            >
              {`Switch to ${userRole === UserRole.GUEST ? "Host" : "Guest"}`}
            </Button>
            <Button
              sx={{
                px: theme.spacing(3.25),
                py: theme.spacing(1),
                // borderRadius: "40px",
              }}
              variant="outlined"
              onClick={clearSession}
            >
              Sign Out
            </Button>
          </Stack>
        </Grid>
      )}

      {!isMobile && (
        <Grid item>
          <Divider orientation="vertical" />
        </Grid>
      )}
      {showChildRoute && (
        <Grid item md={7} sm={7} xs={12}>
          {isMobile && (
            <IconButton
              aria-label="Back Button"
              size="large"
              sx={{ mb: 2, mt: 1, p: 0 }}
              onClick={onBack}
            >
              <BackIcon />
            </IconButton>
          )}
          <Outlet />
        </Grid>
      )}
    </Grid>
  );
}

export default Account;
