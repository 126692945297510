import "./index.css";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import store from "./store/store";

const { REACT_APP_GA_ID, REACT_APP_GTM_ID } = process.env;
// Google Tag Manager (GTM)
if (REACT_APP_GTM_ID) {
  const tagManagerArgs = {
    gtmId: `${REACT_APP_GTM_ID}`,
  };
  TagManager.initialize(tagManagerArgs);
}

// Google Analytics (GA)
if (REACT_APP_GA_ID) ReactGA.initialize(`${REACT_APP_GA_ID}`);

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <App />
        </Router>
      </LocalizationProvider>
    </Provider>
  </React.StrictMode>,
);
