import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Popper,
  Radio,
  RadioGroup,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { ReactComponent as Amex } from "assets/cards/amex.svg";
import { ReactComponent as Diners } from "assets/cards/dinersclub.svg";
import { ReactComponent as Discover } from "assets/cards/discover.svg";
import { ReactComponent as Jcb } from "assets/cards/jcb.svg";
import { ReactComponent as Master } from "assets/cards/master.svg";
import { ReactComponent as Visa } from "assets/cards/visa.svg";
import valid from "card-validator";
import DateRangePicker from "components/DateRangePicker";
import CustomInputField from "components/TextField/CustomInputField";
import CustomSelectField from "components/TextField/CustomSelectField";
import { ONLY_ALPHABETS_SPACES, US_STATES } from "constants/constants";
import { ISelectDate } from "constants/types";
import { DecrementButton, IncrementButton, UsersIcon } from "helpers/images";
import useCard from "hooks/useCard";
import useNavigateStep from "hooks/useNavigateStep";
import moment from "moment";
import useCardForm from "pages/account/components/billing/useCardForm";
import { CommonInput } from "pages/home/SearchPage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RangeKeyDict } from "react-date-range";
import {
  alertWarning,
  AuthFlowTypes,
  getAvailableDates,
  getCards,
  IProperty,
  PropertySteps,
  SearchTypes,
  showHideAuthModal,
  updateAuthStep,
} from "store";
import {
  addBookingExistingCard,
  addBookingUsingNewCard,
  getAreaActivityPrice,
} from "store/booking/BookingAction";
import BookingTypes from "store/booking/BookingType";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { getFormattedDateRange, isNumberKey } from "utils/utils";

import BookingSuccessModal from "./BookingSuccessModal";
import BookingTermsModal from "./BookingTermsModal";
import RenderEdit from "./RenderEdit";

interface IProps {
  isEdit?: boolean;
  property: IProperty;
  handleBook: () => void;
}

export interface ICardState {
  cardAlias: string;
  cardNumber: string;
  cardExpiryMonth: string;
  cardExpiryYear: string;
  name: string;
  address: string;
  city: string;
  state: string;
  zipcode: string;
  setAsDefault: boolean;
}

const DEFAULT_SELECTED_DATE = [
  {
    startDate: undefined,
    endDate: new Date(),
    key: "selection",
  },
];

const currentYear = new Date().getFullYear().toString().slice(-2);
const currentMonth = (new Date().getMonth() + 1).toString().padStart(2, "0");

function BookingPriceBox(props: IProps) {
  const { property, isEdit } = props;

  const { navigateTo } = useNavigateStep();
  const dispatch = useAppDispatch();

  const { FALLBACK_CARD, formatCardNumber } = useCard();

  const [selectDate, setSelectDate] = useState<ISelectDate[]>(
    DEFAULT_SELECTED_DATE,
  );
  const [bookingActivity, setBookingActivity] = useState<string>("");
  const [numberOfGuests, setNumberOfGuests] = useState<number>(1);
  const [maxGuests, setMaxGuests] = useState<number>(1);
  const [bookingDays, setBookingDays] = useState(0);
  const [initialBookingAmount, setInitialBookingAmount] = useState(0);
  const [finalBookingAmount, setFinalBookingAmount] = useState(0);
  const [addCreditCard, setAddCreditCard] = useState(false);
  const [cardValue, setCardValue] = useState<string>("");
  const [successBookingModal, setSuccessBookingModal] = useState(false);
  const [termsModalSuccess, setTermsModalSuccess] = useState<boolean>(false);
  const [dateAccordion, setDateAccordion] = useState(false);
  const [guestDropdown, setGuestDropdown] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [disabledDates, setDisabledDates] = useState<Date[]>([]);
  const [cardType, setCardType] = useState("visa");
  const [dateAnchorEl, setDateAnchorEl] = useState<null | HTMLElement>(null);
  const [guestAnchorEl, setGuestAnchorEl] = useState<null | HTMLElement>(null);

  const { currentDate } = useAppSelector(s => s.search);
  const { availableDates } = useAppSelector(s => s.property);
  const { cards } = useAppSelector(s => s.billing);
  const { userAuth, showAuthModal } = useAppSelector(s => s.auth);
  const { areaActivityPrice, bookingSuccess, loading } = useAppSelector(
    s => s.booking,
  );
  const { price, wingitFee, taxes } = areaActivityPrice;

  useEffect(() => {
    getDisabledDates();
  }, [availableDates]);

  useEffect(() => {
    if (property.area) {
      dispatch(
        getAreaActivityPrice(
          property.area[0].activities[0].documentId,
          property.state,
        ),
      );
      dispatch(getAvailableDates(property.area[0].activities[0].documentId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (userAuth) {
      dispatch(getCards());
    }
  }, [dispatch, userAuth]);

  useEffect(() => {
    if (currentDate?.length > 0) {
      setSelectDate(currentDate);
      dispatch({ type: BookingTypes.SET_BOOKING_DATE, payload: currentDate });
    }
  }, [currentDate]);

  useEffect(() => {
    if (property.area) {
      setBookingActivity(property.area[0].activities[0].name);
      dispatch({
        type: BookingTypes.SET_BOOKING_ACTIVITY,
        payload: bookingActivity,
      });

      setMaxGuests(property.area[0].activities[0].maxParticipants);
    }
  }, [property]);

  useEffect(() => {
    if (selectDate && selectDate?.[0].startDate !== null) {
      const startDate = moment(selectDate?.[0].startDate);
      const endDate = moment(selectDate?.[0].endDate);
      setBookingDays(endDate.diff(startDate, "days") + 1);
    }
  }, [selectDate]);

  useEffect(() => {
    if (price) {
      const bookingAmount = bookingDays * price;
      setInitialBookingAmount(bookingAmount);
      const finalAmount =
        parseFloat(bookingAmount.toFixed(2)) +
        bookingDays * parseFloat(wingitFee.toFixed(2)) +
        bookingDays * parseFloat(taxes.toFixed(2));
      setFinalBookingAmount(
        parseFloat(parseFloat(finalAmount.toString()).toFixed(2)),
      );
    }
  }, [bookingDays, price]);

  const initialAddState = useMemo(() => {
    return {
      cardAlias: "",
      cardNumber: "",
      cardExpiryMonth: currentMonth,
      cardExpiryYear: currentYear,
      name: "",
      address: "",
      city: "",
      state: US_STATES[0],
      zipcode: "",
      setAsDefault: cards.length === 0,
    };
  }, [cards.length]);

  const onAddCardSubmit = async () => {};

  const formik = useCardForm(onAddCardSubmit, initialAddState, 0);
  const {
    touched,
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (
      values.cardExpiryYear === currentYear &&
      !(values.cardExpiryMonth > currentMonth)
    ) {
      setFieldValue("cardExpiryMonth", currentMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.cardExpiryYear]);

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxValue(event.target.checked);
  };

  const handleCardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCardValue((event.target as HTMLInputElement).value);
  };

  const getAllDates = () => {
    const startDate = moment();
    const lastDate = moment().add(1, "y");
    const noOfDays = lastDate.diff(startDate, "d");

    const range = new Array(noOfDays).fill(0).map((_, i) => {
      const rangeStartDate = moment(startDate);
      return rangeStartDate.add(i, "d").endOf("day").toISOString();
    });
    return range;
  };

  const getDisabledDates = () => {
    const allDates = getAllDates();
    const formattedAvailableDates = availableDates.map((date: Date) => {
      return moment(new Date(date)).endOf("day").toISOString();
    });
    let disabledDates: any[] = allDates.filter(
      item => !formattedAvailableDates.includes(item),
    );
    disabledDates = disabledDates.map(item => {
      return new Date(item);
    });
    setDisabledDates(disabledDates);
  };

  const handleAllowOnlyNumber = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    isNumberKey(event);
  };

  const clearCreditCard = () => {
    formik.resetForm();
  };

  const submitBooking = () => {
    if (selectDate[0].startDate == null) {
      dispatch(
        alertWarning({
          message: "Please Enter Booking Date",
          delay: 5000,
        }),
      );
    } else {
      const bookingStartDate = moment(selectDate[0].startDate).format();
      const bookingEndDate = moment(selectDate[0].endDate).format();
      if (addCreditCard) {
        const payload = {
          bookingStartDate: bookingStartDate,
          bookingEndDate: bookingEndDate,
          participantCount: numberOfGuests,
          amount: finalBookingAmount,
          stateName: property.state,
          NewCardDetails: {
            AliasName: values.cardAlias,
            BillingName: values.name,
            Number: values.cardNumber,
            ExpirationDate: `${values.cardExpiryMonth}${values.cardExpiryYear}`,
            BillingAddress: {
              Address: values.address,
              City: values.city,
              State: values.state,
              Zip: values.zipcode,
            },
            IsDefault: cards.length === 0,
          },
        };
        dispatch(
          addBookingUsingNewCard(
            payload,
            property.area[0].activities[0].documentId,
          ),
        ).then(response => {
          if (response && !response.error) {
            setSuccessBookingModal(true);
          }
        });
      } else {
        const payload = {
          bookingStartDate: bookingStartDate,
          bookingEndDate: bookingEndDate,
          participantCount: numberOfGuests,
          amount: parseInt(finalBookingAmount.toString()),
          stateName: property.state,
        };
        dispatch(
          addBookingExistingCard(
            payload,
            cardValue,
            property.area[0].activities[0].documentId,
          ),
        ).then(response => {
          if (response && !response.error) {
            setSuccessBookingModal(true);
          }
        });
      }
    }
  };

  const handlePasteCardNumber = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    let pastedText = "";
    if (event.clipboardData && event.clipboardData.getData) {
      pastedText = event.clipboardData.getData("text/plain");
    }
    setFieldValue("cardNumber", cardFormat(pastedText));
  };

  const handleRestrictSpaces = (
    value: string,
    customRegexValidation?: RegExp,
  ) => {
    return value !== " "
      ? value
          .replace(customRegexValidation as RegExp, "")
          /* Restrict space at first position */
          .replace(/^\s+/g, "")
          /* Allow only one space between words */
          .replace(/\s{2,}/g, " ")
      : value.trim();
  };

  const cardFormat = (text: string) => {
    const userInputCard = valid.number(text).card || FALLBACK_CARD;
    setCardType(userInputCard.type);
    const formatted = formatCardNumber(text, userInputCard);
    return formatted;
  };

  const handleDisablePastMonth = (month: string): boolean => {
    return parseInt(values.cardExpiryYear, 10) > parseInt(currentYear, 10)
      ? false
      : parseInt(month, 10) < parseInt(currentMonth, 10);
  };

  const modalStatusChange = () => setSuccessBookingModal(v => !v);

  const termsModalChange = () => {
    setTermsModalSuccess(!termsModalSuccess);
  };

  const toggleAuthModal = useCallback(
    (resetAuthStep = false) => {
      if (location.pathname.includes("/login")) {
        return;
      }
      dispatch(showHideAuthModal(!showAuthModal));
      if (resetAuthStep) {
        setTimeout(() => {
          dispatch(updateAuthStep(AuthFlowTypes.EMAIL));
        }, 100);
      }
    },
    [dispatch, location.pathname, showAuthModal],
  );

  const handleGuestChange = (updated: number) => {
    if (updated >= 1 && updated <= maxGuests) {
      setNumberOfGuests(updated);
    } else {
      let message = "Number of guests cannot be less than 1";

      if (updated > maxGuests) {
        message = "You have reached the max capacity of guests allowed";
      }
      dispatch(alertWarning({ message, delay: 5000 }));
    }
  };

  const handleDateChange = (item: RangeKeyDict) => {
    dispatch({ type: SearchTypes.SET_CURRENT_DATE, payload: [item.selection] });
    setSelectDate([item.selection]);
  };

  const handleDateInputClick = (event?: React.MouseEvent<HTMLElement>) => {
    setDateAnchorEl(event?.currentTarget || null);
    setDateAccordion(Boolean(event));
  };

  const handleGuestInputClick = (event?: React.MouseEvent<HTMLElement>) => {
    setGuestAnchorEl(event?.currentTarget || null);
    setGuestDropdown(Boolean(event));
  };

  return (
    <Box border="#898989 1px solid" borderRadius={5} px={3} py={2}>
      <Box display="flex" flexDirection="column" gap={2}>
        <ClickAwayListener onClickAway={() => handleDateInputClick()}>
          <Box position="relative">
            <CommonInput
              icon={
                <IconButton aria-label="open drawer" color="inherit">
                  <CalendarTodayIcon style={{ color: "black" }} />
                </IconButton>
              }
              label="When?"
              placeholder="Search dates"
              value={getFormattedDateRange(
                selectDate?.[0].startDate,
                selectDate?.[0].endDate,
              )}
              onChange={() => handleDateInputClick()}
              onClick={handleDateInputClick}
            />
            <Popper
              anchorEl={dateAnchorEl}
              id="date-picker"
              open={dateAccordion}
              placement="bottom"
              sx={{
                zIndex: 10,
                border: "1px solid #898989",
                borderRadius: "20px",
                display: "flex",
                flexFlow: "column",
                overflow: "hidden",
                background: "#FFF",
              }}
            >
              <DateRangePicker
                direction={"vertical"}
                disabledDates={disabledDates}
                maxDate={moment().add(1, "y").toDate()}
                minDate={moment().toDate()}
                ranges={selectDate}
                onChange={handleDateChange}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={() => setDateAccordion(false)}
                >
                  Submit
                </Button>
              </Box>
            </Popper>
          </Box>
        </ClickAwayListener>

        <Box position="relative">
          <CommonInput
            readOnly
            disabled={true}
            icon={<SearchIcon sx={{ color: "black" }} />}
            label={"What do you want to do?"}
            placeholder="Search activities"
            value={bookingActivity}
          />
        </Box>

        <ClickAwayListener onClickAway={() => handleGuestInputClick()}>
          <Box position="relative">
            <CommonInput
              icon={<UsersIcon />}
              label={"How many guests"}
              placeholder="Search dates"
              value={`${numberOfGuests} people`}
              onChange={() => handleGuestInputClick()}
              onClick={handleGuestInputClick}
            />
            <Popper
              anchorEl={guestAnchorEl}
              id="guest-selection"
              open={guestDropdown}
              placement="bottom"
              sx={{ zIndex: 10 }}
            >
              <Paper>
                <Box sx={{ width: "320px", padding: "20px" }}>
                  <Typography variant="h2">How many guests?</Typography>
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <Typography>Guests</Typography>
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      mt={2}
                      spacing={2}
                    >
                      <SvgIcon
                        inheritViewBox
                        component={DecrementButton}
                        sx={{
                          "& path": { fill: theme.palette.text.secondary },
                          height: theme.spacing(1.75),
                          width: theme.spacing(1.75),
                        }}
                        onClick={() => handleGuestChange(numberOfGuests - 1)}
                      />
                      <Typography>{numberOfGuests}</Typography>
                      <SvgIcon
                        inheritViewBox
                        component={IncrementButton}
                        sx={{
                          "& path": { fill: theme.palette.text.secondary },
                          height: theme.spacing(1.75),
                          width: theme.spacing(1.75),
                        }}
                        onClick={() => handleGuestChange(numberOfGuests + 1)}
                      />
                    </Stack>
                  </Stack>

                  <Divider style={{ width: "100%", marginTop: "20px" }} />
                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    ml={2}
                    mt={2}
                    spacing={4}
                  >
                    <Button
                      variant="text"
                      onClick={() => setGuestDropdown(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{ width: "50%", px: 2 }}
                      variant="contained"
                      onClick={() => setGuestDropdown(false)}
                    >
                      Save
                    </Button>
                  </Stack>
                </Box>
              </Paper>
            </Popper>
          </Box>
        </ClickAwayListener>
      </Box>

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>Cost per day</Typography>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography fontWeight="bold">${price?.toFixed(2)}</Typography>
          {isEdit ? (
            <RenderEdit
              onClick={() => navigateTo(PropertySteps.ACTIVITY_COST)}
            />
          ) : null}
        </Stack>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>Number of days</Typography>
        <Typography fontWeight="bold">{bookingDays}</Typography>
      </Stack>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>Amount</Typography>
        <Typography fontWeight="bold">
          ${initialBookingAmount?.toFixed(2)}
        </Typography>
      </Stack>
      <Divider style={{ marginTop: 20 }} />
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>Wing It fee</Typography>
        <Typography fontWeight="bold">
          ${(wingitFee * bookingDays).toFixed(2)}
        </Typography>
      </Stack>
      {/* <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>Insurance </Typography>
        <Typography fontWeight="bold">${insurance}</Typography>
      </Stack> */}
      {/* <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>
          Taxes (${Number(taxes)?.toFixed(2)} * {bookingDays})
        </Typography>
        <Typography fontWeight="bold">
          ${(Number(taxes) * bookingDays)?.toFixed(2)}
        </Typography>
      </Stack> */}

      <Divider style={{ marginTop: 10, borderColor: "#898989" }} />

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography fontWeight="bold">Total </Typography>
        <Typography fontWeight="bold">
          ${finalBookingAmount.toFixed(2)}
        </Typography>
      </Stack>
      <Divider style={{ marginTop: 10, borderColor: "#898989" }} />
      <Stack>
        {userAuth ? (
          <>
            <Typography component={"strong"} fontWeight="bold" mt={2}>
              Select payment method
            </Typography>
            <RadioGroup
              aria-labelledby="activityType"
              id="activityType"
              name="activityType"
              sx={{ marginTop: "10px" }}
              value={cardValue}
              onChange={handleCardChange}
            >
              {cards.map(card => {
                return (
                  <Stack key={card.documentId}>
                    <FormControlLabel
                      control={<Radio color="secondary" />}
                      label={
                        <Box my={theme.spacing(1)}>
                          <Typography variant="subtitle3">
                            {`${card.aliasName} ending with ${card.lastNumbers}`}
                          </Typography>
                        </Box>
                      }
                      value={card.documentId}
                    />
                  </Stack>
                );
              })}
            </RadioGroup>
            {!addCreditCard ? (
              <Button
                startIcon={<AddIcon />}
                sx={{ marginTop: "15px" }}
                variant="outlined"
                onClick={() => {
                  setAddCreditCard(true);
                  setCardValue("");
                }}
              >
                Add New Credit Card
              </Button>
            ) : (
              <Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                  sx={{ marginTop: "15px", marginBottom: "15px" }}
                >
                  <Typography variant="h4">Add New Credit Card</Typography>
                  {addCreditCard && (
                    <Typography
                      className="text-ul-offset_1"
                      variant="h5"
                      onClick={() => {
                        setAddCreditCard(false);
                        clearCreditCard();
                      }}
                    >
                      Cancel
                    </Typography>
                  )}
                </Stack>

                <Box noValidate component="form">
                  <Stack spacing={theme.spacing(3)}>
                    <CustomInputField
                      autoComplete="off"
                      error={!!(touched.cardAlias && errors.cardAlias)}
                      errormessage={
                        touched.cardAlias ? errors.cardAlias : undefined
                      }
                      inputProps={{ maxLength: 35 }}
                      label="Name"
                      name="cardAlias"
                      type="text"
                      value={values.cardAlias}
                      onBlur={handleBlur}
                      onChange={e =>
                        handleChange("cardAlias")(
                          handleRestrictSpaces(e.target.value),
                        )
                      }
                    />
                    <CustomInputField
                      autoComplete="off"
                      endAdornment={
                        <InputAdornment position="end">
                          <SvgIcon
                            inheritViewBox
                            component={
                              cardType === "american-express"
                                ? Amex
                                : cardType === "visa"
                                ? Visa
                                : cardType === "diners-club"
                                ? Diners
                                : cardType === "discover"
                                ? Discover
                                : cardType === "mastercard"
                                ? Master
                                : Jcb
                            }
                            sx={{ height: "45px", width: "60px" }}
                          />
                        </InputAdornment>
                      }
                      error={!!(touched.cardNumber && errors?.cardNumber)}
                      errormessage={
                        touched.cardNumber ? errors?.cardNumber : undefined
                      }
                      inputProps={{ minLength: 13, maxLength: 25 }}
                      label="Card Number"
                      name="cardNumber"
                      sx={{
                        "& .Mui-disabled": {
                          WebkitTextFillColor: theme.palette.text.primary,
                          fontWeight: "bold",
                        },
                      }}
                      type="text"
                      value={values.cardNumber}
                      onBlur={handleBlur}
                      onChange={e =>
                        handleChange("cardNumber")(cardFormat(e.target.value))
                      }
                      onKeyPress={handleAllowOnlyNumber}
                      onPaste={handlePasteCardNumber}
                    />
                    <CustomInputField
                      autoComplete="off"
                      error={!!(touched.name && errors?.name)}
                      errormessage={touched.name ? errors?.name : undefined}
                      inputProps={{ maxLength: 30 }}
                      label="Card Name"
                      name="name"
                      type="text"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={e =>
                        handleChange("name")(
                          handleRestrictSpaces(e.target.value),
                        )
                      }
                    />
                    <Stack direction="row" spacing={theme.spacing(3)}>
                      <CustomSelectField
                        error={
                          !!(touched.cardExpiryMonth && errors?.cardExpiryMonth)
                        }
                        errormessage={
                          touched.cardExpiryMonth
                            ? errors?.cardExpiryMonth
                            : undefined
                        }
                        inputProps={{
                          name: "cardExpiryMonth",
                        }}
                        label="Exp Month"
                        value={values.cardExpiryMonth}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {Array(12)
                          .fill("")
                          .map((_, index) => {
                            const month = (index + 1)
                              .toString()
                              .padStart(2, "0");
                            return (
                              <option
                                key={`expiryMonth-${index + 1}`}
                                disabled={handleDisablePastMonth(month)}
                                value={month}
                              >
                                {month}
                              </option>
                            );
                          })}
                      </CustomSelectField>
                      <CustomSelectField
                        error={
                          !!(touched.cardExpiryYear && errors?.cardExpiryYear)
                        }
                        errormessage={
                          touched.cardExpiryYear
                            ? errors?.cardExpiryYear
                            : undefined
                        }
                        inputProps={{
                          name: "cardExpiryYear",
                        }}
                        label="Exp Year"
                        value={values.cardExpiryYear}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {Array(16)
                          .fill("")
                          .map((_, index) => (
                            <option
                              key={`expiryYear-${index + 1}`}
                              value={parseInt(currentYear, 10) + index}
                            >
                              {parseInt(currentYear, 10) + index}
                            </option>
                          ))}
                      </CustomSelectField>
                    </Stack>
                    <Divider />
                    <CustomInputField
                      autoComplete="new-password"
                      error={!!(touched.address && errors?.address)}
                      errormessage={
                        touched.address ? errors?.address : undefined
                      }
                      inputProps={{ maxLength: 32 }}
                      label="Billing Address"
                      name="address"
                      type="text"
                      value={values.address}
                      onBlur={handleBlur}
                      onChange={e =>
                        handleChange("address")(
                          handleRestrictSpaces(e.target.value),
                        )
                      }
                    />
                    <Stack direction="row" spacing={theme.spacing(3)}>
                      <CustomInputField
                        autoComplete="new-password"
                        error={!!(touched.city && errors?.city)}
                        errormessage={touched.city ? errors?.city : undefined}
                        inputProps={{ maxLength: 36 }}
                        label="City"
                        name="city"
                        type="text"
                        value={values.city}
                        onBlur={handleBlur}
                        onChange={e =>
                          handleChange("city")(
                            handleRestrictSpaces(
                              e.target.value,
                              ONLY_ALPHABETS_SPACES,
                            ),
                          )
                        }
                      />
                      <CustomSelectField
                        error={!!(touched.state && errors?.state)}
                        errormessage={touched.state ? errors?.state : undefined}
                        inputProps={{
                          name: "state",
                        }}
                        label="State"
                        value={values.state}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        {US_STATES.map(state => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </CustomSelectField>
                    </Stack>
                    <Stack
                      direction="row"
                      mr={theme.spacing(2)}
                      spacing={theme.spacing(3)}
                    >
                      <Box width="50%">
                        <CustomInputField
                          autoComplete="new-password"
                          error={!!(touched.zipcode && errors?.zipcode)}
                          errormessage={
                            touched.zipcode ? errors?.zipcode : undefined
                          }
                          inputProps={{ maxLength: 5 }}
                          label="Zip Code"
                          name="zipcode"
                          type="text"
                          value={values.zipcode}
                          onBlur={handleBlur}
                          onChange={e =>
                            handleChange("zipcode")(
                              handleRestrictSpaces(e.target.value),
                            )
                          }
                          onKeyPress={handleAllowOnlyNumber}
                        />
                      </Box>
                      <Box width="50%" />
                    </Stack>
                    <Box />
                  </Stack>
                </Box>
              </Stack>
            )}

            <Divider sx={{ marginTop: "10px" }} />
            <Stack direction="row" sx={{ my: theme.spacing(1) }}>
              <Checkbox
                checked={checkboxValue}
                inputProps={{ "aria-label": "controlled" }}
                name="termsAndConditions"
                sx={{
                  p: 0,
                }}
                onChange={handleCheckbox}
              />
              <Box sx={{ ml: theme.spacing(1) }}>
                <Typography component="span">
                  {" "}
                  I accept{" "}
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      setTermsModalSuccess(true);
                    }}
                  >
                    Terms & Conditions
                  </Link>
                </Typography>
              </Box>
            </Stack>

            <LoadingButton
              disableRipple
              disableTouchRipple
              fullWidth
              disabled={
                !(checkboxValue && (addCreditCard ? isValid : cardValue))
              }
              loading={loading}
              size="large"
              sx={{ marginTop: "10px" }}
              variant="contained"
              onClick={submitBooking}
            >
              Book
            </LoadingButton>

            {/* <Button


              variant="contained"
              onClick={submitBooking}
            >
              Book
            </Button> */}
          </>
        ) : (
          <Button
            sx={{ marginTop: "10px" }}
            variant="contained"
            onClick={() => toggleAuthModal()}
          >
            Book
          </Button>
        )}
      </Stack>
      {successBookingModal ? (
        <BookingSuccessModal
          bookingSuccess={bookingSuccess}
          modalStatusChange={modalStatusChange}
          open={successBookingModal}
          property={property}
        />
      ) : null}

      {termsModalSuccess && (
        <BookingTermsModal
          modalStatusChange={termsModalChange}
          open={termsModalSuccess}
        />
      )}
    </Box>
  );
}

export default BookingPriceBox;
