import { colors } from "@mui/material";

const white = "#FFFFFF";
const black = "#000000";

export default {
  type: "light",
  common: {
    black,
    white,
  },
  primary: {
    contrastText: white,
    dark: "#35492f", //  900: '#35492f',
    main: "#48633F", // custom: '#48633F',
    light: "#6f9961", //  600: '#6f9961',
  },
  secondary: {
    contrastText: white,
    dark: "#302255",
    main: "#3C2B6B",
    light: "#583f9e",
  },
  success: {
    contrastText: white,
    dark: colors.green[900], //  900: '#1b5e20',
    main: colors.green[600], //  600: '#43a047',
    light: "#5F934E",
  },
  info: {
    contrastText: white,
    dark: colors.blue[900], //  900: '#0d47a1',
    main: colors.blue[600], //  600: '#1e88e5',
    light: colors.blue[400], //  400: '#42a5f5',
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900], // 900: '#e65100',
    main: colors.orange[600], //  600: '#fb8c00',
    light: colors.orange[400], //  400: '#ffa726',
  },
  error: {
    contrastText: white,
    dark: colors.red[900], //  900: '#b71c1c',
    main: colors.red[700], //  600: '#d32f2f',
    light: colors.red[400], //  400: '#ef5350',
  },
  text: {
    primary: "#2A2A2A",
    secondary: "#898989",
    link: colors.blue[600], //  600: '#1e88e5',
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  divider: "#D9D9D9",
  //   icon: colors.blueGrey[600], //  600: '#546e7a',
};
