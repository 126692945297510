import LocalParkingIcon from "@mui/icons-material/LocalParking";
import RoomIcon from "@mui/icons-material/Room";
import Box from "@mui/material/Box";
import bbox from "@turf/bbox";
import ToggleDrawerDialog from "components/customDrawer/ToggleDrawerDialog";
import ModalHeader from "components/Modal/ModalHeader";
import { MAPBOX_TOKEN, mapLayer } from "constants/constants";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import pointInPolygon from "point-in-polygon";
import { useEffect, useRef, useState } from "react";
import {
  GeolocateControl,
  Layer,
  LngLatLike,
  Map,
  MapLayerMouseEvent,
  Marker,
  MarkerDragEvent,
  NavigationControl,
  Source,
} from "react-map-gl";
import {
  claimPropertyData,
  IArea,
  IMarker,
  MarkerTypes,
  PropertySteps,
  updateMarkersData,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { getMapZoomPosition } from "utils/utils";
// eslint-disable-next-line
// @ts-ignore
import { parse, stringify } from "wkt";

import Footer from "../Footer";
import ConfirmClaimPropertyModal from "./ConfirmClaimPropertyModal";
import EntryPoint from "./EntryPoint";
import ExclusionArea from "./ExclusionArea";
import Parking from "./Parking";
import SearchContainer from "./searchContainer";

const {
  FIND_YOUR_PROPERTY,
  ENTRY_POINT,
  PARKING_POINT,
  ACTIVITY_DATES,
  REVIEW,
} = PropertySteps;

const { ENTRY, PARKING } = MarkerTypes;

function MapContainer() {
  const mapRef = useRef<any>();
  const searchRef = useRef<any>();
  const { navigateTo } = useNavigateStep();
  const dispatch = useAppDispatch();

  const { propertyInfo, propertyStep, isPropertyEditing } = useAppSelector(
    s => s.property,
  );

  const [showClaimConfirmationModal, setShowClaimConfirmationModal] =
    useState(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [openEntryPointDrawer, setOpenEntryPointDrawer] =
    useState<boolean>(false);
  const [showEntryPointModal, setShowEntryPointModal] = useState(false);

  const [openParkingDrawer, setOpenParkingDrawer] = useState<boolean>(false);
  const [showParkingModal, setShowParkingModal] = useState(false);

  const [openExclusionAreaDrawer, setOpenExclusionAreaDrawer] =
    useState<boolean>(false);
  const [showExclusionAreaModal, setShowExclusionAreaModal] = useState(false);

  const isMobile = useIsMobile("midsm");

  const [markers, setMarkers] = useState<IMarker[]>([]);

  const [selectedMarker, setSelectedMarker] = useState<IMarker>({} as IMarker);
  const [selectedArea, setSelectedArea] = useState<IArea>({} as IArea);

  useEffect(() => {
    if (propertyInfo) {
      setTimeout(() => {
        if (propertyInfo?.outline && pointData) {
          const [minLng, minLat, maxLng, maxLat] = bbox(pointData);

          mapRef.current?.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 50 },
          );
        } else {
          if (propertyInfo.lng && propertyInfo.lat) {
            mapRef.current?.flyTo({
              center: [propertyInfo.lng, propertyInfo.lat],
              duration: 50,
              zoom: getMapZoomPosition(propertyInfo.totalAcres),
            });
          }
        }
      }, 500);
    }
  }, [propertyInfo, propertyInfo.lng, propertyInfo.lat]);

  useEffect(() => {
    if (propertyInfo.markers) {
      const markers = [] as IMarker[];
      propertyInfo.markers.forEach(marker => {
        const pointInfo = parse(marker?.point || "");
        const markerData: IMarker = {
          ...marker,
          latitude: pointInfo.coordinates[1],
          longitude: pointInfo.coordinates[0],
        };
        markers.push(markerData);
      });
      setMarkers(markers);
    }
  }, [propertyInfo, propertyInfo.markers]);

  const checkPointInPolygon = (point: number[], coordinates: Array<any>) => {
    let pointExists = false;
    coordinates.forEach(coordinate => {
      if (pointInPolygon(point, coordinate[0])) {
        pointExists = true;
      }
    });
    return pointExists;
  };

  const onMapClick = (e: MapLayerMouseEvent) => {
    const { lat, lng } = e.lngLat;
    switch (propertyStep) {
      case FIND_YOUR_PROPERTY: {
        searchRef.current.getCreatePropertyDetails(lat, lng);
        break;
      }
      case ENTRY_POINT: {
        if (checkPointInPolygon([lng, lat], polygon.coordinates)) {
          const entryMarkers = markers.filter(m => m.type === ENTRY);
          setSelectedMarker({
            label: `Entry ${entryMarkers.length + 1}`,
            gateCode: "",
            notes: "",
            type: ENTRY,
            overlay: true,
            latitude: lat,
            longitude: lng,
            point: stringify({
              type: "Point",
              coordinates: [lng, lat],
            }),
          });
          handleEntryPointPopup(true);
        }
        break;
      }
      case PARKING_POINT: {
        if (checkPointInPolygon([lng, lat], polygon.coordinates)) {
          const parkingMarkers = markers.filter(m => m.type === PARKING);
          setSelectedMarker({
            label: `Parking ${parkingMarkers.length + 1}`,
            gateCode: "",
            notes: "",
            type: PARKING,
            overlay: true,
            latitude: lat,
            longitude: lng,
            point: stringify({
              type: "Point",
              coordinates: [lng, lat],
            }),
          });
          handleParkingPopup(true);
        }
        break;
      }
      default:
      // return
    }
  };

  const pointData = {
    type: "Feature",
    geometry: parse(propertyInfo?.outline || ""),
  };

  const polygon = pointData.geometry;

  const handlePopUp = (value: boolean) => {
    if (isMobile) {
      setOpenDrawer(value);
    } else {
      setShowClaimConfirmationModal(value);
    }
  };

  const claimProperty = () => {
    handlePopUp(false);
    if (propertyInfo?.documentId) {
      dispatch(claimPropertyData(propertyInfo?.documentId));
      navigateTo(PropertySteps.PROPERTY_DETAILS);
    }
  };

  // const onUpdate = useCallback((e: any) => {
  //   setFeatures((currFeatures: any) => {
  //     const newFeatures = { ...currFeatures };
  //     // eslint-disable-next-line
  //     for (const f of e.features) {
  //       newFeatures[f.id] = f;
  //     }
  //     return newFeatures;
  //   });
  // }, []);

  // const onDelete = useCallback((e: any) => {
  //   setFeatures((currFeatures: any) => {
  //     const newFeatures = { ...currFeatures };
  //     // eslint-disable-next-line
  //     for (const f of e.features) {
  //       delete newFeatures[f.id];
  //     }
  //     return newFeatures;
  //   });
  // }, []);

  let markerLastCoordinates: LngLatLike;
  const onMarkerDrag = (e: MarkerDragEvent) => {
    const { lng, lat } = e.lngLat;
    if (checkPointInPolygon([lng, lat], polygon.coordinates)) {
      markerLastCoordinates = e.lngLat;
    } else {
      e.target.setLngLat(markerLastCoordinates ?? [0, 0]);
    }
  };

  const onMarkerDragEnd = (e: MarkerDragEvent, index: number) => {
    const { lng, lat } = e.lngLat;
    const marker = markers[index];
    marker.latitude = lat;
    marker.longitude = lng;
    marker.point = stringify({
      type: "Point",
      coordinates: [lng, lat],
    });
  };

  const onMarkerClick = (e: any, index: number) => {
    e?.originalEvent?.preventDefault?.();
    e?.originalEvent?.stopPropagation?.();
    if (
      (propertyStep === ENTRY_POINT && ENTRY === markers[index].type) ||
      (propertyStep === PARKING_POINT && PARKING === markers[index].type)
    ) {
      setSelectedMarker(markers[index]);

      switch (propertyStep) {
        case ENTRY_POINT: {
          handleEntryPointPopup(true);
          break;
        }
        case PARKING_POINT: {
          handleParkingPopup(true);
          break;
        }
        default:
        // return
      }
    }
  };

  const renderMarkers = () => {
    return (
      <>
        {markers.map((marker, index) => (
          <Marker
            key={marker.label}
            anchor="center"
            draggable={
              (marker.type === PARKING && propertyStep === PARKING_POINT) ||
              (marker.type === ENTRY && propertyStep === ENTRY_POINT)
            }
            latitude={marker.latitude}
            longitude={marker.longitude}
            onClick={e => onMarkerClick(e, index)}
            onDrag={onMarkerDrag}
            onDragEnd={e => onMarkerDragEnd(e, index)}
          >
            {marker.type === PARKING && (
              <LocalParkingIcon
                style={{
                  backgroundColor:
                    propertyStep === PARKING_POINT
                      ? "#471FB5"
                      : theme.palette.common.white,
                  color:
                    propertyStep === PARKING_POINT
                      ? theme.palette.common.white
                      : theme.palette.text.primary,
                }}
              />
            )}
            {marker.type === ENTRY && (
              <RoomIcon
                style={{
                  color:
                    propertyStep === ENTRY_POINT
                      ? "#471FB5"
                      : theme.palette.text.primary,
                }}
              />
            )}
          </Marker>
        ))}
      </>
    );
  };

  const handleEntryPointPopup = (value: boolean) => {
    if (isMobile) {
      setOpenEntryPointDrawer(value);
    } else {
      setShowEntryPointModal(value);
    }
  };

  const handleSaveEntryPoint = async (marker: IMarker) => {
    if (selectedMarker.label) {
      let markersList = [...markers];
      const index = markersList.findIndex(
        x => x.type === ENTRY && x.point === selectedMarker.point,
      );

      if (index >= 0) {
        markersList[index] = marker;
        // setMarkers([...markersList]);
      } else {
        markersList = [...markers, marker];
        // setMarkers([...markers, marker]);
      }

      const entryMarkers = [...markersList].filter(
        marker => marker.type === ENTRY,
      );
      const lastStep =
        parseInt(propertyInfo.lastStep, 10) > PropertySteps.ENTRY_POINT
          ? propertyInfo.lastStep
          : PropertySteps.ENTRY_POINT.toString();
      const response = await dispatch(
        updateMarkersData(
          propertyInfo?.documentId,
          ENTRY,
          entryMarkers,
          lastStep,
        ),
      );
      if (!response.error) {
        setMarkers([...markersList]);
      }
    } else {
      setMarkers([...markers, marker]);
    }
    handleEntryPointPopup(false);
  };

  const handleDeleteEntryPoint = async () => {
    if (selectedMarker.label) {
      const markersList = [...markers];
      const index = markersList.findIndex(
        x => x.type === ENTRY && x.point === selectedMarker.point,
      );
      if (index >= 0) {
        markersList.splice(index, 1);

        const entryMarkers = [...markersList].filter(
          marker => marker.type === ENTRY,
        );
        const lastStep =
          parseInt(propertyInfo.lastStep, 10) > PropertySteps.ENTRY_POINT
            ? propertyInfo.lastStep
            : PropertySteps.ENTRY_POINT.toString();
        const response = await dispatch(
          updateMarkersData(
            propertyInfo?.documentId,
            ENTRY,
            entryMarkers,
            lastStep,
          ),
        );
        if (!response.error) {
          setMarkers([...markersList]);
        }
      }
    }
    handleEntryPointPopup(false);
  };

  const handleParkingPopup = (value: boolean) => {
    if (isMobile) {
      setOpenParkingDrawer(value);
    } else {
      setShowParkingModal(value);
    }
  };

  const handleSaveParking = async (marker: IMarker) => {
    if (selectedMarker.label) {
      let markersList = [...markers];
      const index = markersList.findIndex(
        x => x.type === PARKING && x.point === selectedMarker.point,
      );
      if (index >= 0) {
        markersList[index] = marker;
      } else {
        markersList = [...markers, marker];
      }
      const parkingMarkers = [...markersList].filter(
        marker => marker.type === PARKING,
      );
      const lastStep =
        parseInt(propertyInfo.lastStep, 10) > PropertySteps.PARKING_POINT
          ? propertyInfo.lastStep
          : PropertySteps.PARKING_POINT.toString();
      const response = await dispatch(
        updateMarkersData(
          propertyInfo?.documentId,
          PARKING,
          parkingMarkers,
          lastStep,
        ),
      );
      if (!response.error) {
        setMarkers([...markersList]);
      }
    } else {
      setMarkers([...markers, marker]);
    }
    handleParkingPopup(false);
  };

  const handleDeleteParking = async () => {
    if (selectedMarker.label) {
      const markersList = [...markers];
      const index = markersList.findIndex(
        x => x.type === PARKING && x.point === selectedMarker.point,
      );
      if (index >= 0) {
        markersList.splice(index, 1);

        const parkingMarkers = [...markersList].filter(
          marker => marker.type === PARKING,
        );
        const lastStep =
          parseInt(propertyInfo.lastStep, 10) > PropertySteps.PARKING_POINT
            ? propertyInfo.lastStep
            : PropertySteps.PARKING_POINT.toString();
        const response = await dispatch(
          updateMarkersData(
            propertyInfo?.documentId,
            PARKING,
            parkingMarkers,
            lastStep,
          ),
        );
        if (!response.error) {
          setMarkers([...markersList]);
        }
      }
    }
    handleParkingPopup(false);
  };

  const handleSaveExclusionArea = () => {};

  const handleDeleteExclusionArea = () => {};

  const saveEntryPoints = async () => {
    if (isPropertyEditing) {
      navigateTo(REVIEW);
    } else {
      await dispatch(
        updatePropertyDetails(
          {
            lastStep:
              parseInt(propertyInfo.lastStep, 10) > PropertySteps.ENTRY_POINT
                ? propertyInfo.lastStep
                : PropertySteps.ENTRY_POINT.toString(),
          },
          propertyInfo?.documentId,
        ),
      );
      navigateTo(PARKING_POINT);
    }
  };

  const saveParkingPoints = async () => {
    if (isPropertyEditing) {
      navigateTo(REVIEW);
    } else {
      await dispatch(
        updatePropertyDetails(
          {
            lastStep:
              parseInt(propertyInfo.lastStep, 10) > PropertySteps.PARKING_POINT
                ? propertyInfo.lastStep
                : PropertySteps.PARKING_POINT.toString(),
          },
          propertyInfo?.documentId,
        ),
      );
      navigateTo(ACTIVITY_DATES);
    }
  };

  const checkMarkerIsAdded = (type: string) => {
    const marker = markers.find(x => x.type === type);
    if (marker) {
      return true;
    }
    return false;
  };

  const renderFooter = () => {
    switch (propertyStep) {
      case FIND_YOUR_PROPERTY:
        return (
          <>
            <Footer
              disabled={!propertyInfo?.documentId}
              handleNext={() => handlePopUp(true)}
              primaryText="Claim Property"
            />
          </>
        );
      case ENTRY_POINT:
        return (
          <>
            <Footer
              disabled={!checkMarkerIsAdded(ENTRY)}
              handleNext={saveEntryPoints}
              primaryText={isPropertyEditing ? "Save" : "Next"}
              secondaryText="Back"
            />
          </>
        );
      case PARKING_POINT:
        return (
          <>
            <Footer
              disabled={!checkMarkerIsAdded(PARKING)}
              handleNext={saveParkingPoints}
              primaryText={isPropertyEditing ? "Save" : "Next"}
              secondaryText="Back"
            />
          </>
        );
      // case EXCLUSION_AREA:
      //   return (
      //     <>
      //       <Footer
      //         // disabled
      //         handleNext={() => navigateTo(PropertySteps.ACTIVITY_DATES)}
      //         primaryText="Next"
      //       />
      //     </>
      //   );
      default:
        return <></>;
    }
  };

  return (
    <Box height="100%" width="100%">
      <Map
        ref={mapRef}
        initialViewState={{
          longitude: -98.4842,
          latitude: 39.0119,
          zoom: 6,
        }}
        mapboxAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
        // mapStyle="mapbox://styles/mapbox/light-v9"
        // mapStyle="mapbox://styles/mapbox/streets-v9?optimize=true"
        style={{
          width: "100%",
          height: isMobile ? "calc(100% - 70px)" : "calc(100vh - 88px)",
        }}
        onClick={onMapClick}
      >
        <GeolocateControl position="bottom-right" />
        <NavigationControl position="bottom-right" />
        {propertyInfo?.documentId && (
          <>
            {/* eslint-disable-next-line */}
            {/* @ts-ignore */}
            <Source data={pointData} type="geojson">
              {/* eslint-disable-next-line */}
              {/* @ts-ignore */}
              <Layer {...mapLayer} />
            </Source>

            {/* Don't delete this code */}
            {/* {propertyStep === EXCLUSION_AREA && (
              <DrawControl
                ref={drawRef}
                controls={{
                  line_string: false,
                  combine_features: false,
                  uncombine_features: false,
                  point: false,
                  polygon: true,
                  trash: true,
                }}
                displayControlsDefault={false}
                position="top-left"
                onCreate={onUpdate}
                onUpdate={onUpdate}
                onDelete={onDelete}
              />
            )} */}

            {renderMarkers()}
          </>
        )}

        {propertyStep === FIND_YOUR_PROPERTY && (
          <SearchContainer ref={searchRef} mapRef={mapRef} />
        )}
      </Map>
      <ToggleDrawerDialog
        breakpoint="midsm"
        header={<ModalHeader />}
        openDrawer={openDrawer}
        openModal={showClaimConfirmationModal}
        setDrawer={setOpenDrawer}
        setModal={setShowClaimConfirmationModal}
      >
        <ConfirmClaimPropertyModal
          handleAgree={claimProperty}
          handleDisagree={() => handlePopUp(false)}
        />
      </ToggleDrawerDialog>

      <ToggleDrawerDialog
        breakpoint="midsm"
        header={
          !isMobile && (
            <ModalHeader
              showCloseIcon
              handleClose={() => setShowEntryPointModal(false)}
            />
          )
        }
        openDrawer={openEntryPointDrawer}
        openModal={showEntryPointModal}
        setDrawer={setOpenEntryPointDrawer}
        setModal={setShowEntryPointModal}
      >
        <EntryPoint
          handleDelete={handleDeleteEntryPoint}
          handleSave={handleSaveEntryPoint}
          marker={selectedMarker}
        />
      </ToggleDrawerDialog>

      <ToggleDrawerDialog
        breakpoint="midsm"
        header={
          !isMobile && (
            <ModalHeader
              showCloseIcon
              handleClose={() => setShowParkingModal(false)}
            />
          )
        }
        openDrawer={openParkingDrawer}
        openModal={showParkingModal}
        setDrawer={setOpenParkingDrawer}
        setModal={setShowParkingModal}
      >
        <Parking
          handleDelete={handleDeleteParking}
          handleSave={handleSaveParking}
          marker={selectedMarker}
        />
      </ToggleDrawerDialog>

      <ToggleDrawerDialog
        breakpoint="midsm"
        header={
          !isMobile && (
            <ModalHeader
              showCloseIcon
              handleClose={() => setShowExclusionAreaModal(false)}
            />
          )
        }
        openDrawer={openExclusionAreaDrawer}
        openModal={showExclusionAreaModal}
        setDrawer={setOpenExclusionAreaDrawer}
        setModal={setShowExclusionAreaModal}
      >
        <ExclusionArea
          area={selectedArea}
          handleDelete={handleDeleteExclusionArea}
          handleSave={handleSaveExclusionArea}
        />
      </ToggleDrawerDialog>

      {renderFooter()}
    </Box>
  );
}

export default MapContainer;
