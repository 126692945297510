import { TypographyOptions } from "@mui/material/styles/createTypography";

import palette from "./palette";

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    accountSubtitle: true;
    subtitle3: true;
    h7: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  accountSubtitle: React.CSSProperties;
  subtitle3: React.CSSProperties;
  h7: React.CSSProperties;
}

export default {
  fontFamily: "Inter",
  h1: {
    color: palette.common.black,
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "36px",
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: "-0.24px",
    lineHeight: "32px",
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  accountSubtitle: {
    color: palette.text.primary,
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    fontFamily: "Inter",
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: "14px",
    letterSpacing: "-0.01em",
    textDecoration: "none",
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "22px",
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
  },
  h7: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Inter",
  },
  subtitle1: {
    color: palette.text.secondary,
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "22px",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#555555",
    textUnderlineOffset: "2px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  subtitle3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Inter",
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
  },
  body2: {
    color: "#555555",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "20px",
    textUnderlineOffset: "2px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  button: {
    color: palette.text.primary,
    fontSize: "14px",
  },
  caption: {
    color: palette.text.secondary,
    fontSize: "12px",
    letterSpacing: "0.33px",
    lineHeight: "17px",
  },
} as ExtendedTypographyOptions;
