import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useFocus from "hooks/useFocus";
import React, { useState } from "react";
import { updateUserAuthData } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import PrivacyPolicyModal from "./PrivacyPolicyModal";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
interface ITermsConditionsProps {
  handleNextStep: () => void;
}

function TermsAndConditions({ handleNextStep }: ITermsConditionsProps) {
  const dispatch = useAppDispatch();
  const { userAuthData } = useAppSelector(s => s.auth);

  const focusButtonRef = useFocus(userAuthData.tncAccepted);
  const [termsNewModalSuccess, setTermsNewModalSuccess] =
    useState<boolean>(false);
  const termsNewModalChange = () => {
    setTermsNewModalSuccess(!termsNewModalSuccess);
  };

  const [privacyModalSuccess, setPrivacyModalSuccess] =
    useState<boolean>(false);
  const privacyModalChange = () => {
    setPrivacyModalSuccess(!privacyModalSuccess);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateUserAuthData({
        ...userAuthData,
        tncAccepted: event.target.checked,
      }),
    );
  };

  const handleNext = () => {
    handleNextStep();
  };

  return (
    <Box>
      <Stack alignItems="center" justifyContent="center">
        <Typography gutterBottom component="h3" variant="h3">
          Terms and Privacy
        </Typography>
        <Typography component="p" variant="h6">
          Verify, select, and agree to the terms:
        </Typography>
        <Stack
          alignContent="center"
          direction="row"
          justifyContent="center"
          sx={{ my: theme.spacing(3) }}
        >
          <Box>
            <Checkbox
              checked={userAuthData.tncAccepted}
              inputProps={{ "aria-label": "controlled" }}
              sx={{ p: 0 }}
              onChange={handleChange}
            />
          </Box>
          <Box sx={{ ml: theme.spacing(1) }}>
            <Typography
              component="span"
              variant="subtitle2"
              onClick={() => {
                setTermsNewModalSuccess(true);
              }}
            >
              Terms &amp; Conditions of Service
            </Typography>
            <Typography component="span">&nbsp;,&nbsp;</Typography>
            <Typography
              component="span"
              variant="subtitle2"
              onClick={() => {
                setPrivacyModalSuccess(true);
              }}
            >
              Privacy Policy
            </Typography>
          </Box>
        </Stack>
        <Button
          ref={focusButtonRef}
          fullWidth
          disabled={!userAuthData.tncAccepted}
          variant="contained"
          onClick={handleNext}
        >
          I Agree
        </Button>
      </Stack>

      {termsNewModalSuccess && (
        <TermsAndConditionsModal
          modalStatusChange={termsNewModalChange}
          open={termsNewModalSuccess}
        />
      )}

      {privacyModalSuccess && (
        <PrivacyPolicyModal
          modalStatusChange={privacyModalChange}
          open={privacyModalSuccess}
        />
      )}
    </Box>
  );
}

export default TermsAndConditions;
