import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import useUpdateRole from "hooks/useUpdateRole";
import { UserRole } from "store/common/CommonInterface";
import theme from "theme";
import { getCookieItem } from "utils/utils";

function HeaderBanner() {
  const userRole: string = getCookieItem("userRole");
  const updateRole = useUpdateRole();

  return (
    <Typography
      className="header-banner"
      component="div"
      sx={{
        background:
          userRole && userRole === UserRole.GUEST
            ? theme.palette.secondary.main
            : theme.palette.primary.main,
        textAlign: "center",
        padding: "14px 4px 10px",
      }}
    >
      {userRole && userRole === UserRole.GUEST ? (
        <Typography component="span" sx={{ fontSize: "14px", color: "#fff" }}>
          You can host any experience, anywhere &nbsp;{" "}
        </Typography>
      ) : null}
      <Button variant="text" onClick={() => updateRole()}>
        <strong>
          Switch to {userRole && userRole === UserRole.GUEST ? "Host" : "Guest"}
        </strong>
      </Button>
    </Typography>
  );
}

export default HeaderBanner;
