import { Divider, Skeleton, Stack } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import React from "react";
import theme from "theme";

interface IProps {
  withTopDivider?: boolean;
}

export default function ListPropertyActivitiesSkeleton({
  withTopDivider = true,
}: IProps) {
  const isMobile = useIsMobile("midsm");

  return (
    <Stack spacing={theme.spacing(isMobile ? 1.5 : 3)}>
      {withTopDivider && <Divider />}
      {Array(2)
        .fill("")
        .map((_, index) => {
          return (
            <React.Fragment key={`pa-${index + 1}`}>
              <Stack direction="row" spacing={2}>
                <Skeleton animation="wave" height={24} width={24} />
                <Skeleton animation="wave" width="100%" />
              </Stack>
              <Divider />
            </React.Fragment>
          );
        })}
    </Stack>
  );
}
