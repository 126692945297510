import { Dispatch } from "redux";
import { AlertActions, AlertType, AuthActions, IAlertPayload } from "store";
import AuthenticationType from "store/authentication/AuthenticationTypes";
import { IResponseData } from "store/common/CommonInterface";
import { CookieNames, getCookieItem } from "utils/cookies";
import { setCookieItem } from "utils/utils";

import client from "../../Api";
import { accountsUrl, authUrl } from "../../helpers/urls";
import {
  ISocialAccountData,
  IUserEmergencyContactData,
  IUserProfileData,
  ProfileActions,
} from "./ProfileInterface";
import ProfileType from "./ProfileTypes";

export const fetchUserProfileInfo = () => {
  return async (dispatch: Dispatch<ProfileActions | AuthActions>) => {
    try {
      const userId = getCookieItem(CookieNames.USER_ID);
      dispatch({ type: ProfileType.FETCH_USER_PROFILE_INFO_REQUEST });
      const response: IResponseData = await client.get(
        `${accountsUrl}users/${userId}`,
      );
      dispatch({
        type: ProfileType.FETCH_USER_PROFILE_INFO_SUCCESS,
        payload: response.data as IUserProfileData,
      });
      dispatch({
        type: AuthenticationType.USER_AUTH_STATUS,
        payload: true,
      });
      return response;
    } catch (error) {
      dispatch({ type: ProfileType.FETCH_USER_PROFILE_INFO_ERROR });
      dispatch({
        type: AuthenticationType.USER_AUTH_STATUS,
        payload: false,
      });
      setCookieItem("userRole", "guest");
      return error;
    }
  };
};

export const updateUserProfileInfo = (payload: IUserProfileData) => {
  return async (dispatch: Dispatch<ProfileActions>) => {
    try {
      dispatch({ type: ProfileType.UPDATE_USER_PROFILE_INFO_REQUEST });
      const response: IResponseData = await client.patch(
        `${accountsUrl}users`,
        payload,
      );
      dispatch({
        type: ProfileType.UPDATE_USER_PROFILE_INFO_SUCCESS,
        payload,
      });
      return response;
    } catch (error) {
      dispatch({ type: ProfileType.UPDATE_USER_PROFILE_INFO_ERROR });
      return error;
    }
  };
};

export const updateUserEmergencyContactInfo = (
  payload: IUserEmergencyContactData,
) => {
  return async (dispatch: Dispatch<ProfileActions>) => {
    try {
      dispatch({
        type: ProfileType.UPDATE_USER_EMERGENCY_CONTACT_INFO_REQUEST,
      });
      const response: IResponseData = await client.put(
        `${accountsUrl}users/emergencyContact`,
        payload,
      );
      dispatch({
        type: ProfileType.UPDATE_USER_EMERGENCY_CONTACT_INFO_SUCCESS,
        payload: payload as IUserEmergencyContactData,
      });

      return response;
    } catch (error) {
      dispatch({ type: ProfileType.UPDATE_USER_EMERGENCY_CONTACT_INFO_ERROR });
      return error;
    }
  };
};

export const uploadProfilePhoto = (payload: any) => {
  return async (dispatch: Dispatch<ProfileActions | AlertActions>) => {
    try {
      const formData = new FormData();
      formData.append("image", payload.originalFile);
      formData.append("cropped_image", payload.croppedFile);
      formData.append("data", JSON.stringify(payload.data));
      formData.append("canvasData", JSON.stringify(payload.canvasData));
      formData.append("cropBoxData", JSON.stringify(payload.cropBoxData));
      formData.append("minZoom", payload.minZoom);
      formData.append("zoom", payload.zoom);
      dispatch({ type: ProfileType.UPLOAD_PROFILE_AVATAR_REQUEST });
      const response: IResponseData = await client.put(
        `${accountsUrl}users/image`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      );
      const successPayload: IAlertPayload = { message: "Upload Successful" };
      dispatch({
        type: AlertType.ALERT_SUCCESS,
        payload: successPayload,
      });
      dispatch({
        type: ProfileType.UPLOAD_PROFILE_AVATAR_SUCCESS,
        payload: {
          croppedImageDetails: JSON.parse(
            window.atob(response.data?.croppedImageDetails),
          ),
          croppedImageReadUrl: response.data?.croppedImageReadUrl, // URL.createObjectURL(payload.croppedFile),
          originalImageReadUrl: response.data?.originalImageReadUrl,
        },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: ProfileType.UPLOAD_PROFILE_AVATAR_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      return error;
    }
  };
};

export const updateUserPassword = (newPassword: string) => {
  return async (dispatch: Dispatch<ProfileActions>) => {
    try {
      dispatch({ type: ProfileType.UPDATE_USER_PASSWORD_REQUEST });
      const payload = {
        newPassword,
      };
      const response: IResponseData = await client.post(
        `${authUrl}users/updatePassword`,
        payload,
      );
      dispatch({ type: ProfileType.UPDATE_USER_PASSWORD_SUCCESS });

      return response;
    } catch (error) {
      dispatch({ type: ProfileType.UPDATE_USER_PASSWORD_ERROR });
      return error;
    }
  };
};

export const updateUserProfileStoreData = (payload: IUserProfileData) => {
  return async (dispatch: Dispatch<ProfileActions>) => {
    dispatch({
      type: ProfileType.UPDATE_USER_PROFILE_STORE_DATA,
      payload,
    });
  };
};

export const removeSocialAccount = (accountId: string) => {
  return async (dispatch: Dispatch<ProfileActions>) => {
    try {
      dispatch({ type: ProfileType.REMOVE_SOCIAL_ACCOUNT_REQUEST });
      const response: IResponseData = await client.delete(
        `${accountsUrl}accounts/socials/${accountId}`,
      );
      dispatch({
        type: ProfileType.REMOVE_SOCIAL_ACCOUNT_SUCCESS,
        payload: accountId,
      });
      return response;
    } catch (error) {
      dispatch({ type: ProfileType.REMOVE_SOCIAL_ACCOUNT_ERROR });
      return error;
    }
  };
};

export const addSocialAccount = (accountType: string, email: string) => {
  return async (dispatch: Dispatch<ProfileActions>) => {
    try {
      dispatch({ type: ProfileType.ADD_SOCIAL_ACCOUNT_REQUEST });
      const payload = {
        socialProviderName: accountType,
        email,
      };
      const response: IResponseData = await client.post(
        `${accountsUrl}accounts/socials`,
        payload,
      );
      dispatch({
        type: ProfileType.ADD_SOCIAL_ACCOUNT_SUCCESS,
        payload: response.data as ISocialAccountData,
      });
      return response;
    } catch (error) {
      dispatch({ type: ProfileType.ADD_SOCIAL_ACCOUNT_ERROR });
      return error;
    }
  };
};

export const checkEmailExistsToUpdate = async (email: string) => {
  try {
    const response: IResponseData = await client.get(
      `${accountsUrl}users/exists?email=${encodeURIComponent(email)}`,
    );
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const updateEmail = async (email: string) => {
  try {
    const body = {
      email,
    };
    const response: IResponseData = await client.post(
      `${authUrl}users/updateEmail`,
      body,
    );
    return response;
  } catch (error: unknown) {
    return error;
  }
};
