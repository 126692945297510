import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Box, Stack } from "@mui/material";
import Button from "@mui/material/Button/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Loader from "components/loader";
import { useIsMobile } from "hooks/useIsMobile";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getHostBookings } from "store/booking/BookingAction";
import { IHostBooking } from "store/booking/BookingInterface";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { getFormattedDateRange } from "utils/utils";

export default function Bookings() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, hostBookings } = useAppSelector(s => s.booking);
  const isMobile = useIsMobile("midsm");
  useEffect(() => {
    dispatch(getHostBookings("active"));
  }, [dispatch]);

  const guestBookingReview = async (booking: IHostBooking) => {
    navigate(`/host/booking-review/${booking.bookingID}`);
  };

  return (
    <Paper
      sx={{ width: "100%", padding: theme.spacing(5), boxSizing: "border-box" }}
    >
      <Typography sx={{ marginBottom: theme.spacing(3) }} variant="h3">
        Bookings Report
      </Typography>
      {loading ? (
        <Loader position="center" />
      ) : isMobile && !hostBookings?.length ? (
        <Box
          alignItems="center"
          display="flex"
          height={100}
          justifyContent="center"
        >
          <Typography variant="h2">No Bookings available</Typography>
        </Box>
      ) : (
        <TableContainer>
          <Table aria-label="simple table" sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    fontSize: "14px",
                    fontWeight: 700,
                  },
                }}
              >
                <TableCell align="left">Scheduled Date</TableCell>
                <TableCell align="left">Listing</TableCell>
                <TableCell align="left">Guest Name</TableCell>
                <TableCell align="center">Guests #</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableCell colSpan={7}>
                  <Loader position="center" />
                </TableCell>
              ) : !hostBookings?.length ? (
                <TableCell colSpan={7}>
                  <Box
                    alignItems="center"
                    display="flex"
                    height={100}
                    justifyContent="center"
                  >
                    <Typography variant="h2">No Bookings available</Typography>
                  </Box>
                </TableCell>
              ) : (
                hostBookings.map(booking => (
                  <TableRow
                    key={booking.documentID}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& .MuiTableCell-root": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    <TableCell align="left" sx={{ borderWidth: 0 }}>
                      {getFormattedDateRange(
                        booking.bookingStartDate,
                        booking.bookingEndDate,
                      )}
                    </TableCell>
                    <TableCell align="left" sx={{ borderWidth: 0 }}>
                      <Typography
                        sx={{
                          textDecoration: "underline",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => guestBookingReview(booking)}
                      >
                        {booking.activityName} at {booking.propertyName}
                      </Typography>
                    </TableCell>
                    <TableCell align="center" sx={{ borderWidth: 0 }}>
                      {`${booking.firstName} ${booking.lastName}`}
                    </TableCell>
                    <TableCell align="center" sx={{ borderWidth: 0 }}>
                      {booking.participantCount}
                    </TableCell>
                    <TableCell align="right" sx={{ borderWidth: 0 }}>
                      <Stack
                        direction="row"
                        justifyContent={"flex-end"}
                        spacing={2}
                      >
                        <Button
                          // disabled
                          startIcon={<EmailOutlinedIcon />}
                          sx={{
                            color: "#000000",
                            textDecoration: "underline",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={(e: any) => {
                            e.preventDefault();
                            window.location.href = `mailto: ${
                              booking.userEmail || ""
                            }`;
                          }}
                        >
                          Message
                        </Button>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            window.open(
                              "https://support.wingit.us/hc/en-us/requests/new",
                              "_blank",
                            );
                          }}
                        >
                          Cancel Booking
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}
