import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  ButtonBase,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { ResponsiveStyleValue } from "@mui/system";
import { useIsMobile } from "hooks/useIsMobile";
import SearchResultsList from "pages/host/property/edit/components/mapContainer/searchContainer/SearchResultsList";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  fetchSearchResults,
  getAllLocationList,
  IPlace,
  makeSearchRequest,
  searchProperties,
} from "store";
import { useAppDispatch } from "store/hooks";

import SearchBackgroundImage from "../../assets/search_background.webp";
import { SearchTypes } from "../../store/search/SearchTypes";

interface IInputProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "height" | "width"
  > {
  icon?: JSX.Element;
  label?: string;
  width?: ResponsiveStyleValue<string | number>;
  height?: ResponsiveStyleValue<string | number>;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onResetInput?: any;
}

export const CommonInput = (props: IInputProps) => {
  const {
    value,
    icon,
    label,
    width = "90%",
    height = 58,
    onClick,
    onResetInput,
    ...rest
  } = props;

  return (
    <>
      <Box
        alignItems="center"
        borderRadius={40}
        display="flex"
        gap={2}
        height={height}
        px={2}
        sx={{
          border: "1px solid #D9D9D9",
        }}
        width={width}
        onClick={onClick}
      >
        <Box>{icon}</Box>
        <Box position="relative" width="100%">
          <Typography
            fontSize={12}
            sx={{
              color: "#555",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {label}
          </Typography>

          <input
            autoComplete={"off"}
            style={{ border: "none", outline: "none", width: "100%" }}
            value={value}
            {...rest}
          />
        </Box>
        {value && (
          <IconButton
            edge="end"
            onClick={() => {
              onResetInput();
            }}
            onMouseDown={(event: any) => event.preventDefault()}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
};

function SearchPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [placeAccordion, setPlaceAccordion] = useState(false);

  const [locationSearchText, setLocationSearchText] = useState<string>("");
  const [locationSearchResults, setLocationSearchResults] = useState<IPlace[]>(
    [],
  );
  const [selectedPlace, setSelectedPlace] = useState<IPlace>();
  const [selectedPlaceRegionBoundingBox, setSelectedPlaceRegionBoundingBox] =
    useState<number[]>();

  const [placesAnchorEl, setPlacesAnchorEl] = useState<null | HTMLElement>(
    null,
  );

  useEffect(() => {
    dispatch(getAllLocationList());
  }, []);

  const handleSearch = () => {
    if (selectedPlace) {
      dispatch({
        type: SearchTypes.SET_CURRENT_LOCATION,
        payload: selectedPlace,
      });

      if (
        selectedPlaceRegionBoundingBox &&
        selectedPlaceRegionBoundingBox?.length > 0
      ) {
        const payload = {
          activities: [],
          startDate: "",
          endDate: "",
          lat: selectedPlace.center[1].toString(),
          long: selectedPlace.center[0].toString(),
          southWestLng: selectedPlaceRegionBoundingBox[0].toString(),
          southWestLat: selectedPlaceRegionBoundingBox[1].toString(),
          northEastLng: selectedPlaceRegionBoundingBox[2].toString(),
          northEastLat: selectedPlaceRegionBoundingBox[3].toString(),
          // lat: "35.9885949444454",
          // long: "-85.9823352140893",
          // southWestLng: "-90.310491",
          // southWestLat: "34.982971",
          // northEastLng: "-81.6469",
          // northEastLat: "36.678255",
        };
        dispatch(fetchSearchResults(payload));
      } else {
        const payload = {
          locations: locationSearchText,
          lat: selectedPlace.center[1].toString(),
          long: selectedPlace.center[0].toString(),
        };
        dispatch(makeSearchRequest(payload));
      }

      navigate("/search-listing");
    }
  };

  useEffect(() => {
    const debounceSearchFn = setTimeout(async () => {
      if (locationSearchText.length >= 3 && !selectedPlace) {
        const response = await searchProperties(locationSearchText);
        if (response) {
          setLocationSearchResults(response);
        }
      }
    }, 200);

    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSearchText]);

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setLocationSearchText(value);
  };

  const onSelectPlace = async (place: IPlace) => {
    setSelectedPlace(place);
    const response = await searchProperties(place.place_name_en);
    if (response) {
      const regionLocation = response.find(
        (x: any) => x.id.includes("region") || x.id.includes("place"),
      );
      setSelectedPlaceRegionBoundingBox(regionLocation?.bbox || []);
    }
    setLocationSearchResults([]);
    // await getCreatePropertyDetailsByPlace(place);
    setLocationSearchText(place.place_name_en);
  };

  const handlePlaceInputClick = (event?: React.MouseEvent<HTMLElement>) => {
    setPlacesAnchorEl(event?.currentTarget || null);
    setPlaceAccordion(Boolean(event));
  };

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flex={1}
        flexDirection="column"
        sx={{
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            width: { md: "auto", xs: "90%" },
            height: { md: "auto", sm: "250px", xs: "180px" },
            marginTop: { md: 0, xs: 2 },
            top: 0,
            bottom: 20,
            left: 20,
            right: 20,
            position: { md: "absolute", xs: "initial" },
            backgroundImage: `url(${SearchBackgroundImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: -1,
            borderRadius: "30px",
          }}
        />
        <Box
          maxWidth={{ lg: 866, xs: "80%" }}
          mb={{ md: 5, xs: 2 }}
          mt={{ md: 15, xs: 5 }}
        >
          <Typography
            sx={{
              color: { md: "white" },
              fontWeight: 700,
              fontSize: { md: "48px", xs: "22px" },
              lineHeight: { md: "64px", xs: "32px" },
              textAlign: "center",
            }}
            variant="h4"
          >
            Book Unique US Properties for Outdoor Adventures
          </Typography>
        </Box>
        <Grid
          container
          alignItems="center"
          bgcolor="white"
          gap={1}
          gridRow={{ lg: 4, md: 2 }}
          maxWidth={900}
          p={{ md: 1, xs: 0 }}
          sx={{
            borderRadius: { md: "40px", xs: "20px" },
            margin: { lg: "0px" },
            width: { xl: "100%", xs: "90%" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <ClickAwayListener onClickAway={() => handlePlaceInputClick()}>
            <Grid
              item
              borderRadius={50}
              maxHeight={58}
              padding={0}
              position="relative"
              sx={{ flexGrow: 2 }}
            >
              <CommonInput
                // icon={<LocationOnIcon sx={{ color: "black" }} />}
                id="activity-location"
                label={"Where?"}
                name="activity-location"
                placeholder={"Search location"}
                value={locationSearchText}
                width={isMobile ? "90%" : "95%"}
                onChange={handleLocationChange}
                onClick={handlePlaceInputClick}
                onFocus={() => setSelectedPlace(undefined)}
                onResetInput={() => {
                  setLocationSearchText("");
                  setSelectedPlace(undefined);
                  setLocationSearchResults([]);
                }}
              />

              {/* Hidden input to tackle chrome's auto-filling last input issue: https://stackoverflow.com/posts/22694173/revisions */}
              <input
                style={{
                  position: "absolute",
                  top: -1000,
                  width: 0.1,
                  height: 0.1,
                }}
              />

              <Popper
                anchorEl={placesAnchorEl}
                id={"places"}
                open={placeAccordion}
                placement="bottom-start"
                sx={{ zIndex: 10 }}
              >
                <Paper
                  sx={{
                    margin: "15px",
                    width: "320px",
                    maxHeight: "300px",
                    borderRadius: "20px",
                  }}
                >
                  <SearchResultsList
                    mapSelectedArea={undefined}
                    places={locationSearchResults}
                    onSelectPlace={onSelectPlace}
                  />
                </Paper>
              </Popper>
            </Grid>
          </ClickAwayListener>
          <Grid item>
            <ButtonBase
              sx={{
                backgroundColor: "#48633F",
                padding: "12px",
                borderRadius: "9999px",
                width: { md: "auto", xs: "100%" },
              }}
              onClick={handleSearch}
            >
              <SearchIcon sx={{ color: "white" }} />
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
export default SearchPage;
