import { useNavigate } from "react-router-dom";
import { UserRole } from "store";
import { getCookieItem, setCookieItem } from "utils/utils";

const useUpdateRole = () => {
  const navigate = useNavigate();

  const switchUserRole = async () => {
    const isGuest: boolean = getCookieItem("userRole") === UserRole.GUEST;
    const nextRole: string = isGuest ? "host" : "guest";
    setCookieItem("userRole", nextRole);
    navigate(nextRole === "host" ? "/host" : "/");

    // const response = { error: false, status: 200 }//(await updateUserRole(nextRole)) as IResponseData;
    // if (response && !response.error) {
    //   setCookieItem("userRole", nextRole);
    //   navigate(nextRole === "host" ? "/host" : "/");
    // } else if (
    //   response.error &&
    //   (response.status === 500 || response.status === 401)
    // ) {
    //   dispatch(
    //     alertError({ message: "Please SignIn/Signup to switch roles." }),
    //   );
    // } else {
    //   dispatch(
    //     alertError({ message: "Something went wrong, please try again." }),
    //   );
    // }
  };

  return switchUserRole;
};

export default useUpdateRole;
