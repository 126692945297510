import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { propertyTitles } from "constants/constants";
import { useIsMobile } from "hooks/useIsMobile";
import { useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { PropertySteps } from "store/property/PropertyTypes";
import theme from "theme";

import LeftHeader from "../LeftHeader";

function FindProperty() {
  const { propertyStep, propertyInfo } = useAppSelector(s => s.property);

  const isMobile = useIsMobile("midsm");

  const propertyText = useMemo(() => {
    if (propertyStep === PropertySteps.REVIEW) {
      return {
        title: "Review",
        subTitle: `Awesome! ${propertyInfo.name} has been successfully created. You can review and update any information on your properties in the Listings section.`,
      };
    }

    return propertyTitles[propertyStep];
  }, [propertyInfo.name, propertyStep]);

  const styles = {
    color: theme.palette.common.white,
    fontWeight: 400,
  };

  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.secondary.main,
        height: "inherit",
        boxSizing: "border-box",
      }}
    >
      <LeftHeader />
      <Stack
        boxSizing="border-box"
        flexGrow={1}
        justifyContent={isMobile ? "flex-end" : "center"}
        marginBottom={isMobile ? theme.spacing(4) : "auto"}
        p={theme.spacing(isMobile ? 2.5 : 8)}
        paddingTop={theme.spacing(isMobile ? 1 : 8)}
        spacing={theme.spacing(isMobile ? 1 : 3)}
      >
        <Typography
          component="div"
          sx={{
            color: theme.palette.common.white,
            fontSize: isMobile ? "24px" : "48px",
            fontWeight: 700,
            lineHeight: isMobile ? "32px" : "64px",
          }}
        >
          {propertyText.title}
        </Typography>
        {propertyText.subTitle && (
          <Typography style={styles} variant={isMobile ? "subtitle1" : "h7"}>
            {propertyText.subTitle}
          </Typography>
        )}
        {/* {propertyText?.addon && (
          <Typography style={styles} variant={isMobile ? "h6" : "h7"}>
            {propertyText?.addon}
          </Typography>
        )} */}
        {/* {propertyStep === PropertySteps.ACTIVITY_DATES && (
          <Typography style={styles} variant={isMobile ? "h6" : "h7"}>
            &quot;
            {[selectedActivity, propertyInfo.name].filter(Boolean).join(" , ")}
            &quot;
          </Typography>
        )} */}
        {propertyStep === PropertySteps.PROPERTY_DETAILS && (
          <Stack spacing={theme.spacing(isMobile ? 0.5 : 2)}>
            <Typography component="span" sx={styles} variant="h6">
              Lat/Long:{" "}
              {[propertyInfo?.lat, propertyInfo?.lng]
                .filter(Boolean)
                .join(" / ")}
            </Typography>
            <Typography component="span" sx={styles} variant="h6">
              Acres: {propertyInfo?.totalAcres}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}

export default FindProperty;
