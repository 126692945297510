import "./App.css";
import "./responsive.css";
import "@fontsource/inter";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/slick-carousel/slick/slick.css";

import { ThemeProvider } from "@mui/material/styles";
import Toast from "components/Toast/Toast";
import { useIsMobile } from "hooks/useIsMobile";
import { useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "routes";
import {
  AuthFlowTypes,
  checkUserSession,
  fetchUserProfileInfo,
  IResponseData,
  IUserAuthRequestData,
  IUserProfileData,
  resetAuthState,
  setMobileViewAuthSession,
  showHideAuthModal,
  updateAuthStep,
  updateEmailValidationFlags,
  updateUserAuthData,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { CookieNames } from "utils/cookies";
import { getCookieItem, setCookieItem } from "utils/utils";

const PUBLIC_ROUTES = [
  "/login",
  "/reset-password",
  "iframe",
  "/search-listing",
  "/property/",
];

function App() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useIsMobile();
  const {
    delayVerifyUserSession,
    userAuthData,
    emailValidationFlags,
    userAuth,
  } = useAppSelector(s => s.auth);

  const checkAuth = useCallback(async () => {
    const userId = getCookieItem(CookieNames.USER_ID);
    if (userId) {
      const userDataResponse = (await dispatch(
        fetchUserProfileInfo(),
      )) as IResponseData;
      if (userDataResponse && !userDataResponse.error) {
        dispatch(checkUserSession(false));

        const {
          email,
          isSignupFinished,
          isEmailVerified,
          phone,
          firstName,
          lastName,
          dob,
        } = userDataResponse.data || ({} as IUserProfileData);

        if (email && !isSignupFinished) {
          const userPayload: IUserAuthRequestData = {
            ...userAuthData,
            dob: dob || "",
            email: email || "",
            firstName: firstName || "",
            lastName: lastName || "",
            phone: phone || "",
          };

          if (isEmailVerified) {
            dispatch(
              updateEmailValidationFlags({
                ...emailValidationFlags,
                verifiedEmailPresent: true,
              }),
            );
          }

          dispatch(updateUserAuthData(userPayload));
          dispatch(updateAuthStep(AuthFlowTypes.ACCOUNT_DETAILS));
          if (isMobile) {
            navigate("/login");
          } else {
            dispatch(showHideAuthModal(true));
          }
        } else {
          location.pathname.includes("/reset-password") && navigate("/");
          if (isMobile) {
            dispatch(setMobileViewAuthSession(false));
          } else {
            dispatch(showHideAuthModal(false));
          }
          dispatch(resetAuthState());
          setTimeout(() => {
            dispatch(updateAuthStep(AuthFlowTypes.EMAIL));
          }, 100);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!getCookieItem("userRole")) {
      setCookieItem("userRole", "guest");
    }
    let timer: ReturnType<typeof setTimeout>;
    if (delayVerifyUserSession) {
      timer = setTimeout(() => {
        checkAuth();
      }, 500);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [checkAuth, delayVerifyUserSession]);

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    if (!code) {
      checkAuth();
    }
  }, [checkAuth]);

  useEffect(() => {
    if (userAuth !== null && !userAuth) {
      const publicPath = PUBLIC_ROUTES.some(i => location.pathname.includes(i));
      if (publicPath) {
        return;
      }
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, userAuth]);

  return (
    <ThemeProvider theme={theme}>
      <Toast />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
