import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListCardSkeleton from "components/skeleton/ListCardSkeleton";
import { CardIcon } from "helpers/images";
import React, { useEffect } from "react";
import { ICardData, setActiveEditCardIndex, showCardForm } from "store";
import { CardFormType } from "store/billing/BillingTypes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { scrollToTop } from "utils/utils";

const { EDIT } = CardFormType;

function ListCards() {
  const dispatch = useAppDispatch();
  const { loading, cards } = useAppSelector(s => s.billing);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleEdit = (index: number) => {
    dispatch(setActiveEditCardIndex(index));
    dispatch(showCardForm(true, EDIT));
  };

  return (
    <>
      {loading ? (
        <ListCardSkeleton />
      ) : cards.length > 0 ? (
        cards.map((card: ICardData, index: number) => {
          return (
            <Stack key={card.documentId} direction="row">
              <Stack direction="row" flexGrow={1} spacing={theme.spacing(3)}>
                <Stack
                  alignItems="center"
                  sx={{ position: "relative", height: "fit-content" }}
                >
                  <CardIcon />
                  {card.isDefault && (
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "9px",
                        fontWeight: 600,
                        position: "absolute",
                        bottom: "-20px",
                      }}
                    >
                      DEFAULT
                    </Typography>
                  )}
                </Stack>
                <Stack spacing={theme.spacing(0.5)}>
                  <Typography variant="h5">{card.aliasName}</Typography>
                  <Typography
                    sx={{ color: theme.palette.text.secondary }}
                    variant="subtitle1"
                  >
                    Ending {card.lastNumbers}
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.text.secondary }}
                    variant="subtitle1"
                  >
                    Expiry: {card.expirationDate.slice(0, 2)}/
                    {card.expirationDate.slice(2)}
                  </Typography>
                </Stack>
              </Stack>
              <Typography
                className="text-ul-offset_1"
                sx={{ height: "fit-content" }}
                variant="h5"
                onClick={() => handleEdit(index)}
              >
                Edit
              </Typography>
            </Stack>
          );
        })
      ) : null}
    </>
  );
}

export default ListCards;
