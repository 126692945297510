enum ProfileType {
  FETCH_USER_PROFILE_INFO_REQUEST = "FETCH_USER_PROFILE_INFO_REQUEST",
  FETCH_USER_PROFILE_INFO_SUCCESS = "FETCH_USER_PROFILE_INFO_SUCCESS",
  FETCH_USER_PROFILE_INFO_ERROR = "FETCH_USER_PROFILE_INFO_ERROR",

  UPDATE_USER_PROFILE_INFO_REQUEST = "UPDATE_USER_PROFILE_INFO_REQUEST",
  UPDATE_USER_PROFILE_INFO_SUCCESS = "UPDATE_USER_PROFILE_INFO_SUCCESS",
  UPDATE_USER_PROFILE_INFO_ERROR = "UPDATE_USER_PROFILE_INFO_ERROR",

  UPDATE_USER_EMERGENCY_CONTACT_INFO_REQUEST = "UPDATE_USER_EMERGENCY_CONTACT_INFO_REQUEST",
  UPDATE_USER_EMERGENCY_CONTACT_INFO_SUCCESS = "UPDATE_USER_EMERGENCY_CONTACT_INFO_SUCCESS",
  UPDATE_USER_EMERGENCY_CONTACT_INFO_ERROR = "UPDATE_USER_EMERGENCY_CONTACT_INFO_ERROR",

  UPLOAD_PROFILE_AVATAR_REQUEST = "UPLOAD_PROFILE_AVATAR_REQUEST",
  UPLOAD_PROFILE_AVATAR_SUCCESS = "UPLOAD_PROFILE_AVATAR_SUCCESS",
  UPLOAD_PROFILE_AVATAR_ERROR = "UPLOAD_PROFILE_AVATAR_ERROR",

  UPDATE_USER_PASSWORD_REQUEST = "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS",
  UPDATE_USER_PASSWORD_ERROR = "UPDATE_USER_PASSWORD_ERROR",

  UPDATE_USER_PROFILE_STORE_DATA = "UPDATE_USER_PROFILE_STORE_DATA",

  REMOVE_SOCIAL_ACCOUNT_REQUEST = "REMOVE_SOCIAL_ACCOUNT_REQUEST",
  REMOVE_SOCIAL_ACCOUNT_SUCCESS = "REMOVE_SOCIAL_ACCOUNT_SUCCESS",
  REMOVE_SOCIAL_ACCOUNT_ERROR = "REMOVE_SOCIAL_ACCOUNT_ERROR",

  ADD_SOCIAL_ACCOUNT_REQUEST = "ADD_SOCIAL_ACCOUNT_REQUEST",
  ADD_SOCIAL_ACCOUNT_SUCCESS = "ADD_SOCIAL_ACCOUNT_SUCCESS",
  ADD_SOCIAL_ACCOUNT_ERROR = "ADD_SOCIAL_ACCOUNT_ERROR",
}

export default ProfileType;
