import Avatar from "@mui/material/Avatar";
import theme from "theme";

interface IBadgeIconProps {
  children: JSX.Element;
  showSecondBadge?: boolean;
  onClick?: () => void;
  styles?: { [Key: string]: string | number };
}

export default function BadgeAvatar({
  children,
  showSecondBadge = false,
  onClick,
  styles = {},
}: IBadgeIconProps) {
  return (
    <Avatar
      sx={{
        width: 30,
        height: 30,
        backgroundColor: theme.palette.grey[50],
        border: `2px solid ${theme.palette.info.main}`,
        color: theme.palette.primary.main,
        borderRadius: "50%",
        cursor: "pointer",
        ...(showSecondBadge && {
          position: "absolute",
          right: "-17px",
          bottom: "-10px",
        }),
        ...styles,
      }}
      onClick={onClick}
    >
      {children}
    </Avatar>
  );
}
