import { Dispatch } from "redux";
import { AlertActions, IAlertPayload } from "store/alerts/AlertInterface";
import { AlertType } from "store/alerts/AlertTypes";
import { CookieNames, getCookieItem, setSession } from "utils/cookies";

import client from "../../Api";
import { accountsUrl, authUrl } from "../../helpers/urls";
import { IResponseData, ResponseCodes } from "../common/CommonInterface";
import {
  AuthActions,
  FlowTypes,
  IEmailExistsPayload,
  IEmailValidationFlags,
  IGetCodeResponseData,
  IPhoneValidationFlags,
  ISignUpEmailData,
  ISocialSignupPayload,
  IUserAuthRequestData,
  IUserData,
  IVerifyCodeData,
} from "./AuthenticationInterface";
import AuthenticationType from "./AuthenticationTypes";

const headers = {
  headers: {
    rid: "thirdpartyemailpassword",
  },
};

export const authorizeSocialLogin = async (type: string) => {
  try {
    const response: IResponseData = await client.get(
      `${authUrl}authorisationurl?thirdPartyId=${type}`,
      headers,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const socialSignUp = (payload: ISocialSignupPayload) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      dispatch({ type: AuthenticationType.CHECK_USER_SESSION, payload: false });
      const response: IResponseData = await client.post(
        `${authUrl}signinup`,
        payload,
        headers,
      );
      if (response?.data && !response.error) {
        dispatch({
          type: AuthenticationType.CHECK_USER_SESSION,
          payload: true,
        });
      }
      return response;
    } catch (error) {
      dispatch({ type: AuthenticationType.CHECK_USER_SESSION, payload: false });
      return error;
    }
  };
};

export const verifyUser = () => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      const response: IResponseData = await client.get(
        // `${authUrl}user/email/verify`, // SuperTokens Verify API
        `${authUrl}session/verify`,
      );
      if (!response.error) {
        dispatch({ type: AuthenticationType.USER_AUTH_STATUS, payload: true });
      }
      return response;
    } catch (error) {
      dispatch({ type: AuthenticationType.USER_AUTH_STATUS, payload: false });
      return error;
    }
  };
};

export const logout = () => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      const response = await client.post(`${authUrl}users/logout`, {
        accessToken: getCookieItem(CookieNames.ACCESS_TOKEN),
        refreshToken: getCookieItem(CookieNames.REFRESH_TOKEN),
      });
      dispatch({ type: AuthenticationType.USER_LOGOUT });
      return response;
    } catch (error) {
      return error;
    }
  };
};

export const refreshToken = async () => {
  try {
    const response: IResponseData = await client.post(
      `${authUrl}users/refreshToken`,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getCodeForPhone = (phoneNumber: string) => {
  return async (dispatch: Dispatch<AuthActions | AlertActions>) => {
    try {
      dispatch({ type: AuthenticationType.MOBILE_AUTH_GET_CODE_REQUEST });
      const response: IResponseData = await client.post(
        `${authUrl}signinup/code`,
        {
          phoneNumber,
        },
        {
          headers: {
            rid: "passwordless",
          },
        },
      );
      dispatch({
        type: AuthenticationType.MOBILE_AUTH_GET_CODE_SUCCESS,
        payload: response.data as IGetCodeResponseData,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      if (e?.status !== ResponseCodes.BAD_REQUEST) {
        const errorPayload: IAlertPayload = { message: e?.message };
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: errorPayload,
        });
      }
      dispatch({ type: AuthenticationType.MOBILE_AUTH_GET_CODE_ERROR });
      return error;
    }
  };
};

export const verifyCode = (
  userInputCode: string,
  deviceId: string,
  preAuthSessionId: string,
  authFlow: boolean,
  flowType?: string,
  accountId?: string,
) => {
  return async (dispatch: Dispatch<AuthActions | AlertActions>) => {
    try {
      dispatch({ type: AuthenticationType.MOBILE_AUTH_VERIFY_CODE_REQUEST });
      const response: IResponseData = await client.post(
        `${authUrl}signinup/code/consume`,
        {
          userInputCode,
          deviceId,
          preAuthSessionId,
        },
        {
          headers: {
            rid: "passwordless",
            ...(((authFlow &&
              [FlowTypes.EMAIL, FlowTypes.SOCIAL].includes(
                flowType as FlowTypes,
              )) ||
              !authFlow) && {
              accountId: `${accountId}`,
            }),
          },
        },
      );
      dispatch({
        type: AuthenticationType.MOBILE_AUTH_VERIFY_CODE_SUCCESS,
        payload: { ...(response.data as IVerifyCodeData) },
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      if (e?.status !== ResponseCodes.BAD_REQUEST) {
        const errorPayload: IAlertPayload = { message: e?.message };
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: errorPayload,
        });
      }
      dispatch({ type: AuthenticationType.MOBILE_AUTH_VERIFY_CODE_ERROR });
      return error;
    }
  };
};

export const resendMobileCode = (
  deviceId: string,
  preAuthSessionId: string,
) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      dispatch({ type: AuthenticationType.MOBILE_AUTH_RESEND_CODE_REQUEST });
      const response: IResponseData = await client.post(
        `${authUrl}signinup/code/resend`,
        {
          deviceId,
          preAuthSessionId,
        },
        {
          headers: {
            rid: "passwordless",
          },
        },
      );
      dispatch({ type: AuthenticationType.MOBILE_AUTH_RESEND_CODE_SUCCESS });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AuthenticationType.MOBILE_AUTH_RESEND_CODE_ERROR });
      return error;
    }
  };
};

export const checkEmailExists = (email: string) => {
  return async (dispatch: Dispatch<AuthActions | AlertActions>) => {
    try {
      dispatch({ type: AuthenticationType.CHECK_EMAIL_REQUEST });
      const response: IResponseData = await client.get(
        `${authUrl}signup/email/exists?email=${encodeURIComponent(email)}`,
      );
      dispatch({
        type: AuthenticationType.CHECK_EMAIL_SUCCESS,
        payload: { ...(response.data as IEmailExistsPayload) },
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: AuthenticationType.CHECK_EMAIL_ERROR });
      return error;
    }
  };
};

export const checkPhoneNumberExists = async (phoneNumber: string) => {
  try {
    const response = await client.get(
      `${accountsUrl}phone/exists?phone=${encodeURIComponent(phoneNumber)}`,
    );
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const resetPassword = (email: string) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      dispatch({ type: AuthenticationType.RESET_PASSWORD_REQUEST });
      const response = await client.post(`${authUrl}sendResetPassword`, {
        email,
      });
      dispatch({
        type: AuthenticationType.RESET_PASSWORD_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AuthenticationType.RESET_PASSWORD_ERROR });
      return error;
    }
  };
};

export const updatePassword = (password: string, token: string) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    try {
      dispatch({ type: AuthenticationType.UPDATE_PASSWORD_REQUEST });
      const response: IResponseData = await client.post(
        `${authUrl}resetPassword`,
        {
          token,
          newPassword: password,
        },
      );
      dispatch({ type: AuthenticationType.UPDATE_PASSWORD_SUCCESS });
      return response;
    } catch (error: unknown) {
      dispatch({ type: AuthenticationType.UPDATE_PASSWORD_ERROR });
      return error;
    }
  };
};

export const signInEmail = (email: string, password: string) => {
  return async (
    dispatch: Dispatch<AuthActions | AlertActions>,
  ): Promise<IResponseData> => {
    try {
      dispatch({ type: AuthenticationType.SIGNIN_EMAIL_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}login`, {
        email,
        password,
      });

      // const decoded: any = jwt_decode(response.data?.accessToken);

      setSession({
        accessToken: response.data?.accessToken,
        documentId: response.data?.user?.documentId,
        refreshToken: response.data?.refreshToken,
        userRole: "", // decoded.role,
      });

      dispatch({
        type: AuthenticationType.SIGNIN_EMAIL_SUCCESS,
        payload: { ...(response.data as IUserData) },
      });
      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      if (e?.status !== ResponseCodes.BAD_REQUEST) {
        const errorPayload: IAlertPayload = { message: e?.message };
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: errorPayload,
        });
      }
      dispatch({ type: AuthenticationType.SIGNIN_EMAIL_ERROR });
      return error;
    }
  };
};

export const signUpEmail = (email: string, password: string) => {
  return async (
    dispatch: Dispatch<AuthActions | AlertActions>,
  ): Promise<IResponseData> => {
    try {
      dispatch({ type: AuthenticationType.SIGNUP_EMAIL_REQUEST });
      const response: IResponseData = await client.post(`${authUrl}signup`, {
        email,
        password,
      });
      setSession({
        accessToken: response.data?.accessToken,
        documentId: response.data?.user?.documentId,
        refreshToken: response.data?.refreshToken,
        userRole: "", // decoded.role,
      });
      dispatch({
        type: AuthenticationType.SIGNUP_EMAIL_SUCCESS,
        payload: { ...(response.data as IUserData) },
      });
      return response;
    } catch (error: any) {
      const e = error as IResponseData;
      if (e?.status !== ResponseCodes.BAD_REQUEST) {
        const errorPayload: IAlertPayload = { message: e?.message };
        dispatch({
          type: AlertType.ALERT_ERROR,
          payload: errorPayload,
        });
      }
      dispatch({ type: AuthenticationType.SIGNUP_EMAIL_ERROR });
      return error;
    }
  };
};

export const showHideAuthModal = (payload: boolean) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({ type: AuthenticationType.SHOW_AUTH_MODAL, payload });
  };
};

export const updateAuthStep = (payload: number) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({ type: AuthenticationType.UPDATE_AUTH_STEP, payload });
  };
};

export const updateUserAuthData = (payload: IUserAuthRequestData) => {
  console.info(payload);
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({
      type: AuthenticationType.UPDATE_USER_AUTH_INFO,
      payload,
    });
  };
};

export const updateUserAuthStatus = (payload: boolean) => {
  return (dispatch: Dispatch<AuthActions>) => {
    dispatch({ type: AuthenticationType.USER_AUTH_STATUS, payload });
  };
};

export const checkUserSession = (payload = true) => {
  return (dispatch: Dispatch<AuthActions>) => {
    dispatch({ type: AuthenticationType.CHECK_USER_SESSION, payload });
  };
};

export const sendEmail = async (email: string) => {
  try {
    const body = {
      email,
    };
    const response: IResponseData = await client.post(
      `${authUrl}users/email/verify`,
      body,
    );
    return response;
  } catch (error: unknown) {
    return error;
  }
};

export const verifyLinkExpiration = (token: string) => {
  return async (dispatch: Dispatch<AuthActions>): Promise<IResponseData> => {
    try {
      dispatch({ type: AuthenticationType.CHECK_MAIL_EXPIRATION_REQUEST });
      const response: IResponseData = await client.get(
        `${authUrl}verify/emailToken/${token}`,
      );
      dispatch({ type: AuthenticationType.CHECK_MAIL_EXPIRATION_SUCCESS });
      return response;
    } catch (error: any) {
      dispatch({ type: AuthenticationType.CHECK_MAIL_EXPIRATION_ERROR });
      return error;
    }
  };
};

export const verifyEmail = async (token: string): Promise<IResponseData> => {
  try {
    const body = { token };
    const response: IResponseData = await client.post(
      `${authUrl}verify/email`,
      body,
    );
    return response;
  } catch (error: any) {
    return error;
  }
};

export const updateUserRole = async (userRole: string) => {
  try {
    const payload = {
      userRole,
    };
    const response: IResponseData = await client.put(
      `${authUrl}users/role`,
      payload,
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const updateVerifyEmailInfo = (email: string, token: string) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    const payload: ISignUpEmailData = { email, token };
    dispatch({ type: AuthenticationType.UPDATE_VERIFY_EMAIL_INFO, payload });
  };
};

export const updatePhoneValidationFlags = (payload: IPhoneValidationFlags) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({
      type: AuthenticationType.UPDATE_PHONE_VALIDATION_FLAGS,
      payload,
    });
  };
};

export const updateEmailValidationFlags = (payload: IEmailValidationFlags) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({
      type: AuthenticationType.UPDATE_EMAIL_VALIDATION_FLAGS,
      payload,
    });
  };
};

export const updateVerifyCodeActive = (payload: boolean) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({
      type: AuthenticationType.UPDATE_VERIFY_CODE_ACTIVE,
      payload,
    });
  };
};

export const resetAuthState = () => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({ type: AuthenticationType.RESET_AUTH_STATE });
  };
};

export const setMobileViewAuthSession = (payload: boolean) => {
  return async (dispatch: Dispatch<AuthActions>) => {
    dispatch({
      type: AuthenticationType.MOBILE_VIEW_AUTH_SESSION_ACTIVE,
      payload,
    });
  };
};

export const markEmailVerified = (email: string, token: string) => {
  return async (dispatch: Dispatch<AlertActions>) => {
    try {
      const response: IResponseData = await client.post(
        `${authUrl}verify/email`,
        { email, token },
      );
      if (response && !response.error) {
        const successPayload: IAlertPayload = {
          message: "Email has been verified.",
        };
        dispatch({
          type: AlertType.ALERT_SUCCESS,
          payload: successPayload,
        });
      }
      return response;
    } catch (error: unknown) {
      return error;
    }
  };
};
