import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import ModalContainer from "components/Modal/ModalContainer";
import { useNavigate } from "react-router-dom";
import { IBookingExistingCardData } from "store/booking/BookingInterface";
import theme from "theme";
import { getFormattedDateRange } from "utils/utils";

interface IBookingModalSuccessProps {
  open: boolean;
  modalStatusChange: () => void;
  property: any;
  bookingSuccess: IBookingExistingCardData;
}

const BookingSuccessModal = ({
  open,
  modalStatusChange,
  property,
  bookingSuccess,
}: IBookingModalSuccessProps) => {
  const navigate = useNavigate();

  return (
    <ModalContainer
      showCloseIcon
      fullWidth={true}
      handleClose={() => {
        modalStatusChange();
        navigate("/");
      }}
      maxWidth="sm"
      open={open}
      showHeader={true}
    >
      <Box
        sx={{
          maxWidth: "100%",
          backgroundColor: theme.palette.common.white,
        }}
      >
        {/* <ModalHeader
          showCloseIcon
          handleClose={() => {
            modalStatusChange();
            navigate("/");
          }}
        /> */}
        {bookingSuccess && (
          <Box p={theme.spacing(3)}>
            <Box sx={{ textAlign: "center" }}>
              <Typography
                component={"h2"}
                sx={{ fontSize: "32px", fontWeight: "700", lineHeight: "48px" }}
              >
                Your activity has been booked!
              </Typography>
              <Typography
                component={"p"}
                sx={{ fontSize: "16px", fontWeight: "400", lineHeight: "24px" }}
              >
                You will receive a confirmation email in a few minutes.
              </Typography>
            </Box>
            <Typography
              component={"p"}
              sx={{
                marginTop: "20px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "22px",
              }}
            >
              Booking Details
            </Typography>
            <Divider sx={{ marginTop: "10px" }} />
            <Stack
              alignItems="center"
              direction="row"
              sx={{ marginTop: "15px" }}
            >
              {property.area &&
              property.area[0].activities[0].areaActivityMedia ? (
                <Box>
                  <img
                    alt={property.area[0].activities[0].name}
                    src={
                      property.area[0].activities[0].areaActivityMedia[0]
                        .croppedImagePath
                    }
                    style={{ width: "120px", borderRadius: "12px" }}
                  />
                </Box>
              ) : null}
              <Box sx={{ marginLeft: "15px" }}>
                <Typography
                  component={"p"}
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  {property.area && property.area[0].activities[0].name}
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                >
                  {property.state} |{" "}
                </Typography>
                <Typography
                  component={"span"}
                  sx={{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                  }}
                >
                  {property.totalAcres}
                </Typography>
              </Box>
            </Stack>
            <Box>
              <Stack direction="row" sx={{ padding: "15px 0" }}>
                <Box sx={{ width: "50%" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    Date
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: "#898989",
                    }}
                  >
                    {getFormattedDateRange(
                      bookingSuccess.bookingStartDate,
                      bookingSuccess.bookingEndDate,
                    )}
                  </Typography>
                </Box>
                <Box sx={{ width: "50%" }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    Guests
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "24px",
                      color: "#898989",
                    }}
                  >
                    {bookingSuccess.participantCount} Guests
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Divider sx={{ marginTop: "10px" }} />
            <Stack direction="row" sx={{ padding: "15px 0" }}>
              <Box sx={{ width: "35%" }}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  Total
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  ${bookingSuccess.amount?.toFixed(2)}
                </Typography>
              </Box>
            </Stack>
            <Button
              sx={{ width: "100%" }}
              variant="contained"
              onClick={() => navigate("/guest-booking")}
            >
              View Booking
            </Button>
          </Box>
        )}
      </Box>
    </ModalContainer>
  );
};

export default BookingSuccessModal;
