import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useFocus from "hooks/useFocus";
import {
  checkUserSession,
  IResponseData,
  IUserAuthRequestData,
  updateAccountsInfo,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { unmaskUSNumber } from "utils/utils";

function Guidelines() {
  const dispatch = useAppDispatch();
  const { userAuthData } = useAppSelector(s => s.auth);

  const focusButtonRef = useFocus(true);

  const handleCloseModal = () => {
    dispatch(checkUserSession());
  };

  const handleNext = async () => {
    const payload: IUserAuthRequestData = {
      ...userAuthData,
      privacyPolicyAccepted: true,
      phone: `${unmaskUSNumber(userAuthData.phone)}`,
      isSignupFinished: true,
    };

    const response: IResponseData = (await dispatch(
      updateAccountsInfo(payload),
    )) as IResponseData;

    if (response && !response?.error) {
      handleCloseModal();
    }
  };

  return (
    <Box>
      <Stack alignItems="center" justifyContent="center">
        <Typography component="h3" variant="h3">
          Community Guidelines
        </Typography>
        <Box
          sx={{
            mt: theme.spacing(3),
            mb: theme.spacing(4),
            mx: theme.spacing(4),
          }}
        >
          <Typography component="p" variant="h6">
            We expect mutual respect and law abiding behavior in our Community.
            We have a zero tolerance policy. Any violations of our Guidelines
            will result in penalties or bans at our discretion.
          </Typography>
        </Box>
        <Stack direction="row" sx={{ mb: theme.spacing(4) }}>
          <ul style={{ margin: 0, padding: 0 }}>
            <Typography component="p" variant="h6">
              <li>Always safety first</li>
              <li>Respect all property and wildlife</li>
              <li>Exit property same condition as you arrived</li>
              <li>Report all issues to Wing It or Owner</li>
            </Typography>
          </ul>
        </Stack>
        <Button
          ref={focusButtonRef}
          fullWidth
          variant="contained"
          onClick={handleNext}
        >
          I Agree
        </Button>
      </Stack>
    </Box>
  );
}

export default Guidelines;
