import { InputAdornment } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StepperInput from "components/stepperInput/StepperInput";
import CustomInputField from "components/TextField/CustomInputField";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import React, { useState } from "react";
import {
  PropertySteps,
  updateAreaActivity,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { isNumberKey } from "utils/utils";

import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";

const { ENTRY_POINT } = PropertySteps;

function ActivityCost() {
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigateStep();

  const { isUpdating, selectedAreaActivity, isPropertyEditing, propertyInfo } =
    useAppSelector(s => s.property);
  const [areaActivityInfo, setAreaActivityInfo] = useState({
    maxParticipants: selectedAreaActivity?.maxParticipants || 1,
    price: selectedAreaActivity?.originalPrice || 0,
  });

  const isMobile = useIsMobile("midsm");

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const tempValue = value;
    let trimValue: string | number =
      tempValue !== " " ? tempValue : tempValue.trim();
    if (name === "maxParticipants" && trimValue) {
      trimValue = parseInt(trimValue);
    } else if (name === "price" && trimValue) {
      trimValue = parseFloat(trimValue);
    }
    setAreaActivityInfo({ ...areaActivityInfo, [name]: trimValue });
  };

  const handleStepperClick = (type: string) => {
    if (type === "minus") {
      if (areaActivityInfo.maxParticipants > 1) {
        setAreaActivityInfo({
          ...areaActivityInfo,
          maxParticipants: areaActivityInfo.maxParticipants - 1,
        });
      }
    } else {
      setAreaActivityInfo({
        ...areaActivityInfo,
        maxParticipants: areaActivityInfo.maxParticipants + 1,
      });
    }
  };

  const handleNext = async () => {
    const response = await dispatch(
      updateAreaActivity(
        areaActivityInfo,
        selectedAreaActivity?.documentId || "",
      ),
    );
    if (response && !response.error) {
      if (isPropertyEditing) {
        navigateTo(PropertySteps.REVIEW);
      } else {
        await dispatch(
          updatePropertyDetails(
            {
              lastStep:
                parseInt(propertyInfo.lastStep, 10) >
                PropertySteps.ACTIVITY_COST
                  ? propertyInfo.lastStep
                  : PropertySteps.ACTIVITY_COST.toString(),
            },
            propertyInfo?.documentId,
          ),
        );
        navigateTo(ENTRY_POINT);
      }
    }
  };

  const handleAllowOnlyNumber = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    isNumberKey(event);
  };

  return (
    <>
      <RightMainComponentWrapper center={true}>
        <Stack
          spacing={theme.spacing(isMobile ? 3 : 4)}
          sx={{
            boxSizing: "border-box",
            mt: theme.spacing(isMobile ? 0 : 5),
            px: theme.spacing(isMobile ? 0 : 9),
          }}
          width="100%"
        >
          <Typography
            component="div"
            sx={{
              fontWeight: 700,
              lineHeight: "36px",
              fontSize: "28px",
            }}
            variant="h6"
          >
            Pricing
          </Typography>

          <StepperInput
            direction="column"
            label="Choose the max number of guests at any time."
            name="maxParticipants"
            value={areaActivityInfo.maxParticipants}
            onChange={handleInputChange}
            onClick={handleStepperClick}
          />

          <CustomInputField
            autoComplete="off"
            inputProps={{ maxLength: 35 }}
            label="Daily Rate per Activity"
            name="price"
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            sx={{ maxWidth: 200 }}
            type="text"
            value={areaActivityInfo.price}
            onChange={handleInputChange}
            onKeyPress={handleAllowOnlyNumber}
          />
        </Stack>
      </RightMainComponentWrapper>
      <Footer
        disabled={
          !areaActivityInfo.price || areaActivityInfo.maxParticipants < 1
        }
        handleNext={handleNext}
        loading={isUpdating}
        primaryText={isPropertyEditing ? "Save" : "Next"}
        secondaryText="Back"
      />
    </>
  );
}

export default ActivityCost;
