import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/hooks";
import {
  clearPropertyData,
  updatePropertyStep,
} from "store/property/PropertyActions";
import { PropertySteps } from "store/property/PropertyTypes";

const useNavigateStep = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goToHome = () => {
    dispatch(updatePropertyStep(PropertySteps.INITIAL));
    dispatch(clearPropertyData());
    navigate("/host");
  };

  const navigateTo = useCallback(
    (step: PropertySteps) => {
      dispatch(updatePropertyStep(step));
    },
    [dispatch],
  );

  return { goToHome, navigateTo };
};

export default useNavigateStep;
