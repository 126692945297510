import { Divider, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import HRLine from "components/hrline/HRLine";
import ListPropertyActivitiesSkeleton from "components/skeleton/ListPropertyActivitiesSkeleton";
import CustomSearchInput from "components/TextField/CustomSearchInput";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import React, { useEffect, useState } from "react";
import {
  createPropertyActivity,
  getPropertyActivities,
  PropertySteps,
  updateAreaActivity,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";

const { ACTIVITY_COST, REVIEW } = PropertySteps;

function ActivityType() {
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigateStep();

  const isMobile = useIsMobile("midsm");

  const {
    loading,
    activities,
    selectedActivity,
    propertyInfo,
    selectedAreaActivity,
    isPropertyEditing,
  } = useAppSelector(s => s.property);
  //
  const [searchInput, setSearchInput] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    if (!(activities.length > 0)) {
      dispatch(getPropertyActivities());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (selectedAreaActivity) {
      setValue(selectedAreaActivity?.activityId);
    }
  }, [selectedAreaActivity]);

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target as HTMLInputElement;
    setSearchInput(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  const handleNext = async () => {
    if (selectedAreaActivity && selectedAreaActivity.documentId) {
      const response = await dispatch(
        updateAreaActivity(
          {
            activityID: value,
          },
          selectedAreaActivity?.documentId || "",
        ),
      );
      if (response && !response.error) {
        if (isPropertyEditing) {
          navigateTo(REVIEW);
        } else {
          await dispatch(
            updatePropertyDetails(
              {
                lastStep:
                  parseInt(propertyInfo.lastStep, 10) >
                  PropertySteps.ACTIVITY_TYPE
                    ? propertyInfo.lastStep
                    : PropertySteps.ACTIVITY_TYPE.toString(),
              },
              propertyInfo?.documentId,
            ),
          );

          navigateTo(ACTIVITY_COST);
        }
      }
    } else {
      const response = await dispatch(
        createPropertyActivity({}, propertyInfo.documentId, value),
      );
      if (response && !response.error) {
        if (isPropertyEditing) {
          navigateTo(REVIEW);
        } else {
          await dispatch(
            updatePropertyDetails(
              {
                lastStep:
                  parseInt(propertyInfo.lastStep, 10) >
                  PropertySteps.ACTIVITY_TYPE
                    ? propertyInfo.lastStep
                    : PropertySteps.ACTIVITY_TYPE.toString(),
              },
              propertyInfo?.documentId,
            ),
          );
          navigateTo(PropertySteps.PROPERTY_RULES);
        }
      }
    }
  };

  return (
    <>
      <RightMainComponentWrapper center={false}>
        <Stack
          spacing={theme.spacing(isMobile ? 3 : 4)}
          sx={{
            boxSizing: "border-box",
            mt: theme.spacing(isMobile ? 0 : 5),
            px: theme.spacing(isMobile ? 0 : 9),
          }}
          width="100%"
        >
          <Typography component="div" variant={isMobile ? "h6" : "h7"}>
            Please select an Activity Type, each activity type will show as a
            separate listing. If you have an activity type that is not listed
            please contact Customer Service.
          </Typography>
          <Box width="100%">
            <CustomSearchInput
              handleInputChange={handleSearchInputChange}
              placeholder="Search for activity type"
              searchText={searchInput}
            />
          </Box>
          <HRLine />
          <RadioGroup
            aria-labelledby="activityType"
            id="activityType"
            name="activityType"
            value={value}
            onChange={handleChange}
          >
            {loading ? (
              <ListPropertyActivitiesSkeleton />
            ) : activities.length > 0 ? (
              activities
                .filter((activity, arr) => {
                  if (searchInput.length >= 3) {
                    return activity.activityName
                      .toLocaleLowerCase()
                      .includes(searchInput.toLocaleLowerCase());
                  }
                  return arr;
                })
                .map((activity, index) => (
                  <Stack key={activity.activityId}>
                    {index === 0 && <Divider />}
                    <FormControlLabel
                      className="radio-button-label"
                      control={<Radio color="secondary" />}
                      label={
                        <Box my={theme.spacing(2)}>
                          <Typography variant="subtitle3">
                            {activity.activityName}
                          </Typography>
                        </Box>
                      }
                      value={activity.activityId}
                    />
                    <Divider />
                  </Stack>
                ))
            ) : null}
          </RadioGroup>
        </Stack>
      </RightMainComponentWrapper>
      <Footer
        disabled={value === ""}
        handleNext={handleNext}
        primaryText={isPropertyEditing ? "Save" : "Next"}
        secondaryText="Back"
      />
      ;
    </>
  );
}

export default ActivityType;
