import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AddIcon } from "helpers/images";
import React, { useEffect } from "react";
import { getCards, resetBillingState, showCardForm } from "store";
import { CardFormType } from "store/billing/BillingTypes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import CardForm from "./CardForm";
import ListCards from "./ListCards";

const { ADD, EDIT } = CardFormType;

function Billing() {
  const dispatch = useAppDispatch();
  const { cards, showForm, formType, loading } = useAppSelector(s => s.billing);

  useEffect(() => {
    dispatch(getCards());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetBillingState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowForm = () => {
    dispatch(showCardForm(true, ADD));
  };

  const handleHideForm = () => {
    if (formType === ADD) {
      dispatch(showCardForm(false, undefined));
    } else {
      dispatch(showCardForm(false, undefined));
    }
  };

  return (
    <Stack alignItems="flex-start" spacing={theme.spacing(3)} width="100%">
      <Typography variant="h3">Billing</Typography>
      <Divider sx={{ width: "inherit", borderColor: "#898989" }} />
      <Box width="inherit">
        <Stack spacing={theme.spacing(3)}>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <Typography variant="accountSubtitle">
              {!showForm && "Credit card"}
              {showForm && formType === ADD && "Add new payment method"}
              {showForm && formType === EDIT && "Edit payment method"}
            </Typography>
            {showForm && (
              <Typography
                className="text-ul-offset_1"
                sx={{
                  ...(loading && {
                    pointerEvents: "none",
                    opacity: 0.5,
                  }),
                }}
                variant="h5"
                onClick={handleHideForm}
              >
                Cancel
              </Typography>
            )}
          </Stack>
          {cards.length > 0 && <Divider />}
          {!showForm && <ListCards />}
          {showForm && <CardForm />}
        </Stack>
      </Box>
      {!showForm && (
        <Box width="inherit">
          <Stack spacing={theme.spacing(3)} width="inherit">
            <Divider />
            <Stack
              alignItems="center"
              direction="row"
              spacing={theme.spacing(2)}
              width="inherit"
            >
              {loading ? <Skeleton width={16} /> : <AddIcon />}
              <Typography
                className="text-ul-offset_4_hover"
                variant="subtitle3"
                onClick={handleShowForm}
              >
                {loading ? <Skeleton width={200} /> : "Add payment method"}
              </Typography>
            </Stack>
            <Divider />
          </Stack>
        </Box>
      )}
    </Stack>
  );
}

export default Billing;
