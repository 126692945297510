export enum SearchTypes {
  SET_CURRENT_ACTIVITY = "SET_CURRENT_ACTIVITY",
  SET_CURRENT_DATE = "SET_CURRENT_DATE",
  SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION",

  MAKE_SEARCH_REQUEST = "MAKE_SEARCH_REQUEST",
  MAKE_SEARCH_REQUEST_SUCCESS = "MAKE_SEARCH_REQUEST_SUCCESS",
  MAKE_SEARCH_REQUEST_ERROR = "MAKE_SEARCH_REQUEST_ERROR",

  FETCH_ACTIVITIES_REQUEST = "FETCH_ACTIVITIES_REQUEST",
  FETCH_ACTIVITIES_REQUEST_SUCCESS = "FETCH_ACTIVITIES_REQUEST_SUCCESS",
  FETCH_ACTIVITIES_REQUEST_ERROR = "FETCH_ACTIVITIES_REQUEST_ERROR",

  FETCH_PROPERTIES_REQUEST = "FETCH_PROPERTIES_REQUEST",
  FETCH_PROPERTIES_REQUEST_SUCCESS = "FETCH_PROPERTIES_REQUEST_SUCCESS",
  FETCH_PROPERTIES_REQUEST_ERROR = "FETCH_PROPERTIES_REQUEST_ERROR",

  FETCH_MAP_PROPERTIES_REQUEST = "FETCH_MAP_PROPERTIES_REQUEST",
  FETCH_MAP_PROPERTIES_SUCCESS = "FETCH_MAP_PROPERTIES_SUCCESS",
  FETCH_MAP_PROPERTIES_ERROR = "FETCH_MAP_PROPERTIES_ERROR",
}
