import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import CustomInputField from "components/TextField/CustomInputField";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import { useMemo, useState } from "react";
import {
  IPropertyDetailsState,
  IUpdatePropertyState,
  PropertySteps,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { handleRestrictSpaces } from "utils/utils";
import { propertyValidationSchema } from "utils/validationSchema";

import Address from "../Address";
import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";
import { usePropertyForm } from "../usePropertyForm";

type ISomeoneLiving = "yes" | "no";

const handleTrimObjectValues = (object: IPropertyDetailsState) => {
  type T = keyof IPropertyDetailsState;
  const values = object;
  Object.keys(values).forEach((key: string) => {
    if (typeof values[key as T] === "string") {
      (values[key as T] as string) = (values[key as T] as string).trim();
    }
  });
  return values;
};

function PropertyDetailsForm() {
  const { navigateTo } = useNavigateStep();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile("sm");

  const { propertyInfo, isUpdating, isPropertyEditing } = useAppSelector(
    s => s.property,
  );
  const someoneLiving = propertyInfo?.someoneLiving ? "yes" : "no";

  const [living, setLiving] = useState<ISomeoneLiving>(someoneLiving);

  const initialState: IPropertyDetailsState = useMemo(() => {
    return {
      propertyName: propertyInfo?.name || propertyInfo?.address1 || "",
      description: propertyInfo?.description || "",
      contactOwnerName:
        propertyInfo?.contactOwnerName || propertyInfo?.owner || "",
      propertyAddress: {
        name: propertyInfo?.owner ?? "",
        address: propertyInfo?.address1 ?? "",
        city: propertyInfo?.city ?? "",
        state: propertyInfo?.state ?? "",
        zip: propertyInfo?.zip ?? "",
      },
    };
  }, [propertyInfo]);

  const onSubmit = async (values: IPropertyDetailsState) => {
    const { propertyName, description, contactOwnerName, propertyAddress } =
      handleTrimObjectValues(values);

    const propertyDetails: IUpdatePropertyState = {
      name: propertyName,
      description,
      contactOwnerName,
      someoneLiving: living === "yes",
      lastStep:
        parseInt(propertyInfo.lastStep, 10) > PropertySteps.PROPERTY_DETAILS
          ? propertyInfo.lastStep
          : PropertySteps.PROPERTY_DETAILS.toString(),
      address1: propertyAddress.address,
      city: propertyAddress.city,
      state: propertyAddress.state,
      zip: propertyAddress.zip,
    };

    const response = await dispatch(
      updatePropertyDetails(propertyDetails, propertyInfo?.documentId),
    );

    if (!response.error) {
      if (isPropertyEditing) {
        navigateTo(PropertySteps.REVIEW);
      } else {
        navigateTo(PropertySteps.ACTIVITY_TYPE);
      }
    }
  };

  const formik = usePropertyForm(
    onSubmit,
    initialState,
    propertyValidationSchema,
  );

  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
  } = formik;

  const handleLivingStatus = (event: React.MouseEvent<HTMLElement>) => {
    const { value } = event?.target as HTMLInputElement;
    setLiving(value as ISomeoneLiving);
  };

  const toggleStyles = {
    px: isMobile ? theme.spacing(4) : theme.spacing(8),
    py: theme.spacing(1),
    mr: theme.spacing(2),
    ...(living
      ? {
          "&.Mui-selected, &.Mui-selected:hover": {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
          },
        }
      : {
          color: theme.palette.common.black,
          backgroundColor: theme.palette.info.contrastText,
        }),
  };

  return (
    <>
      <RightMainComponentWrapper>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ textAlign: "center", mb: theme.spacing(2) }}
        >
          <Typography
            component="div"
            sx={{
              fontWeight: 700,
              lineHeight: "36px",
              fontSize: "28px",
            }}
            variant="h6"
          >
            Property Details
          </Typography>
        </Stack>
        <Box noValidate component="form" onSubmit={handleSubmit}>
          <Typography
            component="div"
            mt={theme.spacing(2)}
            sx={{
              fontWeight: 700,
              mb: theme.spacing(2),
              alignSelf: "flex-start",
            }}
            variant="h6"
          >
            Does someone live on this property:
          </Typography>
          <Stack direction="column" justifyContent="center">
            <ToggleButtonGroup
              exclusive
              sx={{
                ".MuiToggleButtonGroup-grouped:not(:first-of-type)": {
                  borderLeft: "1px solid #D9D9D9",
                },
              }}
              value={living}
              onChange={handleLivingStatus}
            >
              {["NO", "YES"].map((title: string) => (
                <ToggleButton
                  key={title}
                  fullWidth
                  sx={toggleStyles}
                  value={title.toLowerCase()}
                >
                  {title}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
          <Typography
            component="div"
            mt={theme.spacing(2)}
            sx={{ my: theme.spacing(3) }}
            variant="h6"
          >
            Enter a property name, it will be used to keep track of your
            properties.
          </Typography>
          <Stack spacing={theme.spacing(3)}>
            <CustomInputField
              autoComplete="off"
              error={!!(touched.propertyName && errors.propertyName)}
              errormessage={
                touched.propertyName ? errors.propertyName : undefined
              }
              inputProps={{ maxLength: 70 }}
              label="Property Name"
              name="propertyName"
              type="text"
              value={values.propertyName}
              onBlur={handleBlur}
              onChange={e =>
                handleChange("propertyName")(
                  handleRestrictSpaces(e.target.value),
                )
              }
            />
            <CustomInputField
              autoComplete="off"
              error={!!(touched.contactOwnerName && errors.contactOwnerName)}
              errormessage={
                touched.contactOwnerName ? errors.contactOwnerName : undefined
              }
              inputProps={{ maxLength: 70 }}
              label="Contact Owner Name"
              name="contactOwnerName"
              type="text"
              value={values.contactOwnerName}
              onBlur={handleBlur}
              onChange={e =>
                handleChange("contactOwnerName")(
                  handleRestrictSpaces(e.target.value),
                )
              }
            />

            <CustomInputField
              multiline
              autoComplete="off"
              error={!!(touched.description && errors.description)}
              errormessage={
                touched.description ? errors.description : undefined
              }
              inputProps={{ maxLength: 3000, minLength: 0 }}
              label="Description"
              minRows={5}
              name="description"
              type="textarea"
              value={values.description}
              onBlur={handleBlur}
              onChange={e =>
                handleChange("description")(
                  handleRestrictSpaces(e.target.value),
                )
              }
            />
            <Divider
              sx={{
                my: theme.spacing(3),
                borderColor: theme.palette.text.secondary,
              }}
            />
          </Stack>
          <Typography component="div" mb={theme.spacing(2)} variant="h6">
            Verify and confirm your property information
          </Typography>
          <Address
            addressType="propertyAddress"
            enableAddressField={true}
            errors={errors.propertyAddress}
            handleBlur={handleBlur}
            handleChange={handleChange}
            touched={touched.propertyAddress}
            values={values.propertyAddress}
          />
        </Box>
      </RightMainComponentWrapper>
      <Footer
        disabled={!isValid}
        handleNext={handleSubmit}
        loading={isUpdating}
        primaryText={isPropertyEditing ? "Save" : "Next"}
      />
    </>
  );
}

export default PropertyDetailsForm;
