import BackIcon from "@mui/icons-material/ArrowBack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  ButtonBase,
  Divider,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import DateRangePicker from "components/DateRangePicker";
import Loader from "components/loader";
import { CloseIcon, DotSeperator } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import moment from "moment";
import SearchResultsList from "pages/host/property/edit/components/mapContainer/searchContainer/SearchResultsList";
import { useEffect, useRef, useState } from "react";
import { RangeKeyDict } from "react-date-range";
import { useNavigate } from "react-router-dom";
import {
  fetchSearchResults,
  getAllActivities,
  getAllLocationList,
  IActivity,
  IPlace,
  IProperty,
  makeSearchRequest,
  searchProperties,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { formatPrice, getFormattedDateRange } from "utils/utils";

import defaultImage from "../../../assets/searchPageIcons/rectangle.png";
import logo from "../../../assets/wingit_logo.svg";
import { SearchTypes } from "../../../store/search/SearchTypes";
import { CommonInput } from "../SearchPage";
import MapContainer from "./MapContainer";

interface IProps {
  icon: JSX.Element;
  children: any;
  label: string;
  subTitle: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  accordionOpen: boolean;
  disabled?: boolean;
  onResetInput?: any;
  showClearIcon?: boolean;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const DEFAULT_FILTERS = {
  guests: { adults: 1, children: 1 },
  amenities: { drinkingWater: "" },
};

const DEFAULT_ACTIVITY = { id: "", value: "Search Activities" };

const DEFAULT_SELECT_DATE = [
  {
    startDate: null,
    endDate: new Date(""),
    key: "selection",
  },
];

export function CommonField(props: IProps) {
  const {
    icon,
    label,
    subTitle,
    children,
    onClick,
    accordionOpen,
    disabled,
    onResetInput,
    showClearIcon,
  } = props;

  return (
    <Box
      borderRadius={accordionOpen ? 5 : 40}
      minHeight={58}
      pt={2}
      px={2}
      sx={{
        border: "1px solid #D9D9D9",
        background: disabled ? "#ccc" : undefined,
      }}
      width={"85%"}
    >
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box
          alignItems="center"
          display="flex"
          gap={1}
          onClick={!disabled ? onClick : undefined}
        >
          {!accordionOpen && <Box> {icon}</Box>}
          <Box position="relative">
            <Typography
              fontSize={!accordionOpen ? 12 : 16}
              fontWeight={!accordionOpen ? 400 : 600}
              sx={{ color: !accordionOpen ? "#555" : "#000" }}
            >
              {label}
            </Typography>
            {!accordionOpen && <Typography>{subTitle}</Typography>}
          </Box>
        </Box>
        {showClearIcon && (
          <IconButton
            edge="end"
            size="small"
            onClick={() => {
              onResetInput();
            }}
            onMouseDown={(event: any) => event.preventDefault()}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      {children}
    </Box>
  );
}

const SearchListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile("midsm");
  const isSmallMobile = useIsMobile("sm");

  const {
    activities,
    searchResults,
    currentActivity,
    currentLocation,
    currentDate,
    loading,
  } = useAppSelector(s => s.search);

  const searchRef = useRef<any>(null);
  const locationRef = useRef<any>(null);
  const dateRef = useRef<any>(null);

  const [activityAccordion, setActivityAccordion] = useState<boolean>(false);
  const [activity, setActivity] = useState<any>(DEFAULT_ACTIVITY);
  const [dateAccordion, setDateAccordion] = useState<boolean>(false);
  const [selectDate, setSelectDate] = useState<any>(DEFAULT_SELECT_DATE);
  const [locationSearchText, setLocationSearchText] = useState<string>("");
  const [locationSearchResults, setLocationSearchResults] = useState<IPlace[]>(
    [],
  );
  const [selectedPlace, setSelectedPlace] = useState<IPlace>();
  const [locationEditing, setLocationEditing] = useState(false);
  const [activityText, setActivityText] = useState("");
  const [activityOptions, setActivityOptions] =
    useState<IActivity[]>(activities);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [filterList, setFiltersList] = useState(DEFAULT_FILTERS);
  const [selectedPlaceRegionBoundingBox, setSelectedPlaceRegionBoundingBox] =
    useState<number[]>();

  useEffect(() => {
    if (Object.keys(currentLocation).length > 0) {
      setInitialLocationData();
    }
  }, [currentLocation]);

  const setInitialLocationData = async () => {
    setLocationSearchText(currentLocation.place_name_en);
    setSelectedPlace(currentLocation);

    const response = await searchProperties(currentLocation.place_name_en);
    if (response) {
      const regionLocation = response.find(
        (x: any) => x.id.includes("region") || x.id.includes("place"),
      );
      setSelectedPlaceRegionBoundingBox(regionLocation?.bbox || []);
    }
  };

  useEffect(() => {
    if (currentActivity) {
      setActivity(currentActivity);
      // setActivityText(currentActivity.value);
    }
  }, [currentActivity]);

  useEffect(() => {
    if (currentDate && currentDate.length > 0) {
      setSelectDate(currentDate);
    }
  }, [currentDate]);

  useEffect(() => {
    dispatch(getAllActivities());
    dispatch(getAllLocationList());
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        (searchRef.current && !searchRef.current.contains(event.target)) ||
        (locationRef.current && !locationRef.current.contains(event.target)) ||
        (dateRef.current && !dateRef.current.contains(event.target))
      ) {
        setActivityAccordion(false);
        setDateAccordion(false);
        setLocationEditing(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [activityAccordion, locationEditing]);

  useEffect(() => {
    setActivityOptions(activities);
  }, [activities]);

  useEffect(() => {
    const debounceSearchFn = setTimeout(async () => {
      if (locationSearchText?.length >= 3) {
        const response = await searchProperties(locationSearchText);
        if (response) {
          setLocationSearchResults(response);
        }
      }
    }, 200);

    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationSearchText]);

  useEffect(() => {
    if (activityText !== "") {
      const filteredActivities = activityOptions.filter(option => {
        return option.activityName
          .toLowerCase()
          .includes(activityText.toLowerCase());
      });
      setActivityOptions(filteredActivities);
    } else {
      setActivityOptions(activities);
    }
  }, [activityText]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setLocationSearchText(value);
  };

  const onSelectPlace = async (place: IPlace) => {
    setSelectedPlace(place);
    const response = await searchProperties(place.place_name_en);
    if (response) {
      const regionLocation = response.find(
        (x: any) => x.id.includes("region") || x.id.includes("place"),
      );
      setSelectedPlaceRegionBoundingBox(regionLocation?.bbox || []);
    }

    setLocationSearchResults([]);
    // await getCreatePropertyDetailsByPlace(place);
    setLocationSearchText(place.place_name_en);
    setLocationEditing(false);
  };

  const handleSearch = () => {
    if (selectedPlace && Object.keys(selectedPlace).length > 0) {
      dispatch({
        type: SearchTypes.SET_CURRENT_LOCATION,
        payload: selectedPlace,
      });

      if (activity.value !== "") {
        dispatch({
          type: SearchTypes.SET_CURRENT_ACTIVITY,
          payload: activity,
        });
      }

      if (
        selectedPlaceRegionBoundingBox &&
        selectedPlaceRegionBoundingBox?.length > 0
      ) {
        const payload = {
          activities: activity.value ? [`${activity.id}`] : [],
          startDate: selectDate[0].startDate
            ? `${moment(selectDate[0].startDate).format()}`
            : "",
          endDate: selectDate[0].startDate
            ? `${moment(selectDate[0].endDate).format()}`
            : "",
          lat: selectedPlace.center[1].toString(),
          long: selectedPlace.center[0].toString(),
          southWestLng: selectedPlaceRegionBoundingBox[0].toString(),
          southWestLat: selectedPlaceRegionBoundingBox[1].toString(),
          northEastLng: selectedPlaceRegionBoundingBox[2].toString(),
          northEastLat: selectedPlaceRegionBoundingBox[3].toString(),
        };
        dispatch(fetchSearchResults(payload));
      } else {
        const payload = {
          activities: activity.value ? [`${activity.id}`] : [],
          startDate: selectDate[0].startDate
            ? `${moment(selectDate[0].startDate).format()}`
            : "",
          endDate: selectDate[0].endDate
            ? `${moment(selectDate[0].endDate).format()}`
            : "",
          locations: locationSearchText,
          lat: selectedPlace.center[1].toString(),
          long: selectedPlace.center[0].toString(),
        };
        dispatch(makeSearchRequest(payload));
      }
    }
  };

  const navToPropertyDetails = (property: IProperty) => {
    navigate(`/property/${property.documentId}`);
  };

  const handleBack = () => navigate("/");

  const handleDateChange = (item: RangeKeyDict) => {
    dispatch({ type: SearchTypes.SET_CURRENT_DATE, payload: [item.selection] });
    setSelectDate([item.selection]);
  };

  return (
    <Box ml={3} mr={isMobile ? 3 : 0}>
      <Grid
        container
        mt={isMobile ? 2 : 0}
        sx={{ height: !isMobile ? "calc(100vh - 143px)" : "auto" }}
      >
        <Grid item md={4} mt={0} xs={12}>
          <IconButton
            aria-label="Back Button"
            size="large"
            sx={{ mb: 1, mt: 1 }}
            onClick={() => handleBack()}
          >
            <BackIcon />
          </IconButton>
          <Box
            className="custom-scrollbar"
            sx={{
              height: !isMobile ? "calc(100vh - 183px)" : "auto",
              overflowY: "auto",
            }}
          >
            <Box display="flex" flexDirection="column" gap={2}>
              <CommonField
                accordionOpen={!!locationEditing}
                icon={<SearchIcon sx={{ color: "black" }} />}
                label="Where do you want to go?"
                showClearIcon={
                  locationSearchText && !locationEditing ? true : false
                }
                subTitle={locationSearchText || "Search location"}
                onClick={() => {
                  setLocationEditing(!locationEditing);
                  // setLocationSearchText("");
                  // setLocationSearchResults([]);
                }}
                onResetInput={() => {
                  setLocationSearchText("");
                  setLocationSearchResults([]);
                  setSelectedPlace(undefined);
                }}
              >
                {!!locationEditing && (
                  <Box ref={locationRef}>
                    <Box my={2}>
                      <CommonInput
                        height={46}
                        icon={<SearchIcon sx={{ color: "black" }} />}
                        placeholder="Search location"
                        value={locationSearchText}
                        width="90%"
                        onChange={handleInputChange}
                        onResetInput={() => {
                          setLocationSearchText("");
                          setSelectedPlace(undefined);
                          setLocationSearchResults([]);
                        }}
                      />
                    </Box>
                    <Paper
                      style={{
                        width: "100%",
                        height: "300px",
                        boxShadow: "none",
                      }}
                    >
                      <SearchResultsList
                        mapSelectedArea={undefined}
                        places={locationSearchResults}
                        onSelectPlace={onSelectPlace}
                      />
                    </Paper>
                  </Box>
                )}
              </CommonField>

              <CommonField
                accordionOpen={!!dateAccordion}
                icon={
                  <IconButton aria-label="open drawer" color="inherit">
                    <CalendarTodayIcon style={{ color: "black" }} />
                  </IconButton>
                }
                label="When?"
                showClearIcon={
                  selectDate?.[0].startDate && !dateAccordion ? true : false
                }
                subTitle={
                  selectDate?.[0].startDate
                    ? getFormattedDateRange(
                        selectDate?.[0].startDate,
                        selectDate?.[0].endDate,
                      )
                    : "Search dates"
                }
                onClick={() => setDateAccordion(!dateAccordion)}
                onResetInput={() => {
                  setSelectDate(DEFAULT_SELECT_DATE);
                  dispatch({ type: SearchTypes.SET_CURRENT_DATE, payload: [] });
                }}
              >
                {!!dateAccordion && (
                  <Paper
                    ref={dateRef}
                    sx={{
                      zIndex: 10,
                      border: "1px solid #898989",
                      borderRadius: "20px",
                      display: "flex",
                      flexFlow: "column",
                      overflow: "hidden",
                      background: "#FFF",
                    }}
                  >
                    <DateRangePicker
                      maxDate={moment().add(365, "days").toDate()}
                      minDate={moment().toDate()}
                      ranges={selectDate}
                      onChange={handleDateChange}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                        gap: 2,
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => setDateAccordion(false)}
                      >
                        Submit
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setDateAccordion(false);
                          setSelectDate(DEFAULT_SELECT_DATE);
                          dispatch({
                            type: SearchTypes.SET_CURRENT_DATE,
                            payload: [],
                          });
                        }}
                      >
                        Clear
                      </Button>
                    </Box>
                  </Paper>
                )}
              </CommonField>
              <CommonField
                accordionOpen={!!activityAccordion}
                icon={<SearchIcon sx={{ color: "black" }} />}
                label="What do you want to do?"
                showClearIcon={
                  activity?.value && !activityAccordion ? true : false
                }
                subTitle={activity?.value || "Search activities"}
                onClick={() => setActivityAccordion(!activityAccordion)}
                onResetInput={() => {
                  setActivity(DEFAULT_ACTIVITY);
                  setActivityText("");
                  dispatch({
                    type: SearchTypes.SET_CURRENT_ACTIVITY,
                    payload: {},
                  });
                }}
              >
                {activityAccordion ? (
                  <Box ref={searchRef}>
                    <Box my={2}>
                      <CommonInput
                        height={46}
                        icon={<SearchIcon sx={{ color: "black" }} />}
                        placeholder="Search activities"
                        width="90%"
                        onChange={(e: any) => {
                          // setSearchVal({ ...searchVal, activity: e.target.value })
                          setActivity({ ...activity, value: e.target.value });
                          setActivityText(e.target.value);
                        }}
                        onResetInput={() => {
                          setActivity(DEFAULT_ACTIVITY);
                          setActivityText("");
                          dispatch({
                            type: SearchTypes.SET_CURRENT_ACTIVITY,
                            payload: {},
                          });
                        }}
                      />
                    </Box>
                    <Box sx={{ maxHeight: "250px", overflow: "scroll" }}>
                      {activityOptions.map(option => {
                        return (
                          <Box
                            key={option.activityId}
                            sx={{
                              display: "flex",
                              marginX: "20px",
                              padding: "10px",
                              alignItems: "center",
                              gap: "20px",
                              borderBottom: "1px solid #D9D9D9",
                            }}
                            onClick={() => {
                              dispatch({
                                type: SearchTypes.SET_CURRENT_ACTIVITY,
                                payload: {
                                  id: option.activityId,
                                  value: option.activityName,
                                },
                              });
                              setActivity({
                                id: option.activityId,
                                value: option.activityName,
                              });
                              setActivityAccordion(false);
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bold",
                              }}
                            >
                              {option.activityName}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                ) : null}
              </CommonField>
              <Box width={"93%"}>
                <Button
                  disabled={
                    !(selectedPlace && Object.keys(selectedPlace).length > 0)
                  }
                  sx={{
                    // py: theme.spacing(1),
                    width: "100%",
                    px: 2,
                  }}
                  variant="contained"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Box>
            </Box>
            <Box alignItems="center" display="flex" gap={2} my={2}>
              {/* <Chip
                disabled
                label={<img src={filters} />}
                sx={{
                  height: "32px",
                  width: "32px",
                  "& .MuiChip-label": { padding: 0 },
                }}
                variant="outlined"
                onClick={() => setFilterModalOpen(true)}
              /> */}
              <Modal
                aria-describedby="modal-modal-description"
                aria-labelledby="modal-modal-title"
                open={filterModalOpen}
                sx={{
                  "& .MuiBox-root": { border: "none", borderRadius: "20px" },
                }}
                onClose={() => setFilterModalOpen(false)}
              >
                <Box sx={style}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    position={"relative"}
                    py={3}
                  >
                    <img src={logo} style={{ height: "20px" }} />
                    <div
                      style={{
                        position: "absolute",
                        right: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => setFilterModalOpen(false)}
                    >
                      <CloseIcon />
                    </div>
                  </Box>
                  <Divider />
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={2}
                    height={"350px"}
                    mb={10}
                    overflow="scroll"
                    p={2}
                  >
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: 700,
                        lineHeight: "32px",
                      }}
                    >
                      Filters
                    </Typography>
                    <Divider />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "28px",
                          marginBottom: "5px",
                        }}
                      >
                        Guests
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "22px",
                        }}
                      >
                        Select the number of guests.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ marginBottom: "5px" }}>
                        Adults
                      </Typography>
                      <Box alignItems="center" display="flex" gap={2}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "32px",
                            width: "32px",
                            border: "1px solid #898989",
                            borderRadius: "9999px;",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            if (filterList.guests.adults > 0) {
                              setFiltersList({
                                ...filterList,
                                guests: {
                                  ...filterList.guests,
                                  adults: filterList.guests.adults - 1,
                                },
                              });
                            }
                          }}
                        >
                          -
                        </Box>
                        <Typography>{filterList.guests.adults}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "32px",
                            width: "32px",
                            border: "1px solid #898989",
                            borderRadius: "9999px;",
                            fontSize: "20px",
                          }}
                          onClick={() =>
                            setFiltersList({
                              ...filterList,
                              guests: {
                                ...filterList.guests,
                                adults: filterList.guests.adults + 1,
                              },
                            })
                          }
                        >
                          +
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography sx={{ marginBottom: "5px" }}>
                        Children
                      </Typography>
                      <Box alignItems="center" display="flex" gap={2}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "32px",
                            width: "32px",
                            border: "1px solid #898989",
                            borderRadius: "9999px;",
                            fontSize: "20px",
                          }}
                          onClick={() => {
                            if (filterList.guests.children > 0) {
                              setFiltersList({
                                ...filterList,
                                guests: {
                                  ...filterList.guests,
                                  children: filterList.guests.children - 1,
                                },
                              });
                            }
                          }}
                        >
                          -
                        </Box>
                        <Typography>{filterList.guests.children}</Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "32px",
                            width: "32px",
                            border: "1px solid #898989",
                            borderRadius: "9999px;",
                            fontSize: "20px",
                          }}
                          onClick={() =>
                            setFiltersList({
                              ...filterList,
                              guests: {
                                ...filterList.guests,
                                children: filterList.guests.children + 1,
                              },
                            })
                          }
                        >
                          +
                        </Box>
                      </Box>
                    </Box>
                    <Divider />
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "28px",
                          marginBottom: "5px",
                        }}
                      >
                        Amenities
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "22px",
                        }}
                      >
                        Select the amenities you want to filter by.
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "20px",
                          fontWeight: 700,
                          lineHeight: "28px",
                          marginBottom: "5px",
                        }}
                      >
                        Drinking water
                      </Typography>
                      <Box display="flex" gap={1}>
                        <input type="checkbox" />
                        <Typography>Tap</Typography>
                      </Box>
                      <Box display="flex" gap={1}>
                        <input type="checkbox" />
                        <Typography>Hose</Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        height: "82px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "#fff",
                        borderTop: "1px solid #48633F",
                        paddingX: "24px",
                      }}
                    >
                      <Typography
                        marginLeft={8}
                        sx={{ textDecoration: "underline", cursor: "pointer" }}
                      >
                        Clear all
                      </Typography>
                      <ButtonBase
                        sx={{
                          backgroundColor: "#48633F",
                          color: "#fff",
                          padding: "13px 36px",
                          borderRadius: "5px",
                        }}
                      >
                        Show 20 activities
                      </ButtonBase>
                    </Box>
                  </Box>
                </Box>
              </Modal>
              {/* <Chip disabled label="Instant book" variant="outlined" />
              <Chip disabled label="Exclusive use" variant="outlined" /> */}
            </Box>
            {loading ? (
              <Stack alignItems="flexStart" justifyContent="center">
                <Loader />
              </Stack>
            ) : (
              <Box>
                <Typography
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  sx={{ mb: 1 }}
                >
                  {searchResults?.length ? searchResults?.length : "0"} Results{" "}
                  {/* <span>
                    <img src={threeLine} />
                  </span> */}
                </Typography>
                {searchResults?.length > 0 && (
                  <>
                    {searchResults?.map(item => (
                      <Box
                        key={item.documentId}
                        display="flex"
                        height={94}
                        my={2}
                        sx={{
                          cursor: "pointer",
                          border: "1px solid var(--generic-5, #D9D9D9)",
                          borderRadius: "15px",
                          overflow: "hidden",
                        }}
                        onClick={() => navToPropertyDetails(item)}
                      >
                        <img
                          src={
                            item?.area?.[0]?.activities?.[0]?.areaActivityMedia
                              ?.length
                              ? item?.area[0]?.activities[0]
                                  ?.areaActivityMedia[0].croppedImagePath
                              : defaultImage
                          }
                          style={{ width: "112px" }}
                        />
                        <Stack
                          p={2}
                          sx={{
                            width: "calc(100% - 144px)",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            alignItems="center"
                            display="flex"
                            gap="8px"
                            // lineHeight="22px"
                          >
                            <Typography
                              component="span"
                              fontSize={isSmallMobile ? "12px" : "14px"}
                              fontWeight={600}
                              sx={{
                                width: "100%",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.name}
                            </Typography>
                          </Typography>
                          <Typography
                            alignItems="center"
                            display="flex"
                            fontSize={isSmallMobile ? "12px" : "14px"}
                            fontWeight={600}
                            gap="8px"
                            lineHeight="22px"
                          >
                            <span>{item.area?.[0]?.activities?.[0].name}</span>
                            <DotSeperator />
                            <span>
                              $
                              {formatPrice(
                                item.area?.[0].activities?.[0].price,
                              )}
                            </span>
                          </Typography>
                          <Typography
                            alignItems="center"
                            component={"p"}
                            display={isSmallMobile ? "" : "flex"}
                            fontSize="12px"
                            fontWeight={400}
                            gap={isSmallMobile ? "" : "8px"}
                            lineHeight="22px"
                          >
                            <span style={{ display: "flex" }}>
                              <span
                                style={{
                                  maxWidth: "160px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.city} ,{" "}
                              </span>
                              <span>{item.state}</span>
                            </span>
                            {!isSmallMobile && (
                              <DotSeperator height={"3px"} width={"3px"} />
                            )}
                            <span
                              style={{
                                display: isSmallMobile ? "block" : "inline",
                              }}
                            >
                              {item.totalAcres} Acres
                            </span>
                          </Typography>
                          {/* <Typography
                            alignItems="center"
                            display={isSmallMobile ? "" : "flex"}
                            fontSize="12px"
                            fontWeight={400}
                            gap={isSmallMobile ? "" : "8px"}
                            lineHeight="22px"
                          >
                            {!isSmallMobile && (
                              <DotSeperator height={"3px"} width={"3px"} />
                            )}
                            <span
                              style={{
                                display: isSmallMobile ? "block" : "inline",
                              }}
                            >
                              {item.totalAcres} Acres
                            </span>
                          </Typography> */}
                        </Stack>
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={8} xs={12}>
          <MapContainer />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchListing;
