import client from "Api";
import { baseApiUrl, propertyUrl } from "helpers/urls";
import { Dispatch } from "redux";
import { AlertActions, IAlertPayload } from "store/alerts/AlertInterface";
import { AlertType } from "store/alerts/AlertTypes";

import {
  BookingActions,
  IBookingDetails,
  IBookingExistingCard,
  IGetAreaActivityPrice,
  IGetGuestBooking,
} from "./BookingInterface";
import BookingTypes from "./BookingType";

export const getGuestBookings = (type: string) => {
  return async (dispatch: Dispatch<BookingActions | AlertActions>) => {
    try {
      dispatch({ type: BookingTypes.GET_GUEST_BOOKING_REQUEST });
      const response: IGetGuestBooking = await client.get(
        `${propertyUrl}owner/areaActivity/guest/getallbookings?type=${type}`,
      );
      dispatch({
        type: BookingTypes.GET_GUEST_BOOKING_SUCCESS,
        payload: { type, bookings: response.data || [] },
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BookingTypes.GET_GUEST_BOOKING_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const getAreaActivityPrice = (areaActivityID: string, state: string) => {
  return async (dispatch: Dispatch<BookingActions | AlertActions>) => {
    try {
      dispatch({ type: BookingTypes.GET_AREA_ACTIVITY_PRICE_REQUEST });
      const response: IGetAreaActivityPrice = await client.get(
        `${baseApiUrl}services/property/v1/owner/areaActivity/${areaActivityID}/price/${state}`,
      );
      dispatch({
        type: BookingTypes.GET_AREA_ACTIVITY_PRICE_SUCCESS,
        payload: response.data || {},
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BookingTypes.GET_AREA_ACTIVITY_PRICE_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const addBookingExistingCard = (
  payload: any,
  cardId: string,
  areaActivityId: string,
) => {
  return async (dispatch: Dispatch<BookingActions | AlertActions>) => {
    try {
      dispatch({ type: BookingTypes.ADD_BOOKING_EXISTING_CARD_REQUEST });
      const response: IBookingExistingCard = await client.post(
        `${baseApiUrl}services/property/v1/owner/areaActivity/${areaActivityId}/existingcardbooking/${cardId}`,
        payload,
      );
      dispatch({
        type: BookingTypes.ADD_BOOKING_EXISTING_CARD_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BookingTypes.ADD_BOOKING_EXISTING_CARD_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload as IAlertPayload,
      });
      return error;
    }
  };
};

export const addBookingUsingNewCard = (
  payload: any,
  areaActivityId: string,
) => {
  return async (dispatch: Dispatch<BookingActions | AlertActions>) => {
    try {
      dispatch({ type: BookingTypes.ADD_BOOKING_EXISTING_CARD_REQUEST });
      const response: IBookingExistingCard = await client.post(
        `${baseApiUrl}services/property/v1/owner/areaActivity/${areaActivityId}/newcardbooking`,
        payload,
      );
      dispatch({
        type: BookingTypes.ADD_BOOKING_EXISTING_CARD_SUCCESS,
        payload: response.data,
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BookingTypes.ADD_BOOKING_EXISTING_CARD_ERROR });
      const errorPayload: IAlertPayload = { message: error.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload as IAlertPayload,
      });
      return error;
    }
  };
};

export const getHostBookings = (type: string) => {
  return async (dispatch: Dispatch<BookingActions | AlertActions>) => {
    try {
      dispatch({ type: BookingTypes.GET_HOST_BOOKINGS_REQUEST });
      const response = await client.get(
        `${propertyUrl}owner/areaActivity/host/getallbookings?type=${type}`,
      );
      dispatch({
        type: BookingTypes.GET_HOST_BOOKINGS_SUCCESS,
        payload: response.data || [],
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BookingTypes.GET_HOST_BOOKINGS_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};

export const getBookingDetails = (bookingId: string) => {
  return async (dispatch: Dispatch<BookingActions | AlertActions>) => {
    try {
      dispatch({ type: BookingTypes.GET_BOOKING_DETAILS_REQUEST });
      const response = await client.get(
        `${baseApiUrl}/services/property/v1/owner/areaActivity/booking/${bookingId}`,
      );
      dispatch({
        type: BookingTypes.GET_BOOKING_DETAILS_SUCCESS,
        payload: response.data || ({} as IBookingDetails),
      });
      return response;
    } catch (error: any) {
      dispatch({ type: BookingTypes.GET_BOOKING_DETAILS_ERROR });
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: { message: error?.message },
      });
      return error;
    }
  };
};
