import client from "Api";
import { paymentUrl } from "helpers/urls";
import { Dispatch } from "redux";
import { AlertActions, IAlertPayload } from "store/alerts/AlertInterface";
import { AlertType } from "store/alerts/AlertTypes";
import { IResponseData } from "store/common/CommonInterface";

import {
  FinanceActions,
  IACHPaymentAccountDetails,
  IACHPaymentState,
} from "./FinanceInterface";
import FinanceTypes from "./FinanceTypes";

export const addACHPayment = (data: IACHPaymentState) => {
  return async (dispatch: Dispatch<FinanceActions | AlertActions>) => {
    try {
      dispatch({ type: FinanceTypes.ADD_ACH_PAYMENT_REQUEST });
      const response: IResponseData = await client.post(`${paymentUrl}ach`, {
        ...data,
      });
      dispatch({
        type: FinanceTypes.ADD_ACH_PAYMENT_SUCCESS,
        payload: response.data as IACHPaymentAccountDetails,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: FinanceTypes.ADD_ACH_PAYMENT_ERROR });
      return error;
    }
  };
};

export const getACHPayment = () => {
  return async (dispatch: Dispatch<FinanceActions | AlertActions>) => {
    try {
      dispatch({ type: FinanceTypes.GET_ACH_PAYMENT_REQUEST });
      const response: IResponseData = await client.get(`${paymentUrl}ach`);
      dispatch({
        type: FinanceTypes.GET_ACH_PAYMENT_SUCCESS,
        payload: (response.data && response.data?.length > 0
          ? response.data?.[0]
          : {}) as IACHPaymentAccountDetails,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: FinanceTypes.GET_ACH_PAYMENT_ERROR });
      return error;
    }
  };
};

export const updateACHPayment = (id: string, data: IACHPaymentState) => {
  return async (dispatch: Dispatch<FinanceActions | AlertActions>) => {
    try {
      dispatch({ type: FinanceTypes.UPDATE_ACH_PAYMENT_REQUEST });
      const response: IResponseData = await client.put(
        `${paymentUrl}ach/${id}`,
        { ...data },
      );
      dispatch({
        type: FinanceTypes.UPDATE_ACH_PAYMENT_SUCCESS,
        payload: response.data as IACHPaymentAccountDetails,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: FinanceTypes.UPDATE_ACH_PAYMENT_ERROR });
      return error;
    }
  };
};

export const deleteACHPayment = (id: string) => {
  return async (dispatch: Dispatch<FinanceActions | AlertActions>) => {
    try {
      dispatch({ type: FinanceTypes.DELETE_ACH_PAYMENT_REQUEST });
      const response: IResponseData = await client.delete(
        `${paymentUrl}ach/${id}`,
      );
      dispatch({ type: FinanceTypes.DELETE_ACH_PAYMENT_SUCCESS });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: FinanceTypes.DELETE_ACH_PAYMENT_ERROR });
      return error;
    }
  };
};
