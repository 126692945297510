import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

interface IProps {
  onClick: () => void;
}

function RenderEdit(props: IProps) {
  const { onClick } = props;
  return (
    <IconButton
      aria-label="edit"
      color="warning"
      size="small"
      onClick={onClick}
    >
      <EditIcon sx={{ fontSize: 18 }} />
    </IconButton>
  );
}

export default RenderEdit;
