import RoomIcon from "@mui/icons-material/Room";
import { Avatar, Box, Divider, useTheme } from "@mui/material";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import bbox from "@turf/bbox";
import CustomInputField from "components/TextField/CustomInputField";
import { MAPBOX_TOKEN, mapLayer } from "constants/constants";
import { LocationIcon, ParkingIcon } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import moment from "moment";
import { ElementRef, useEffect, useRef, useState } from "react";
import Map, {
  GeolocateControl,
  Layer,
  Marker,
  NavigationControl,
  Popup,
  Source,
} from "react-map-gl";
import ShowMoreText from "react-show-more-text";
import {
  IMarker,
  IProperty,
  MarkerTypes,
  PropertySteps,
  setEditingProperty,
} from "store";
import { IBookingDetails } from "store/booking/BookingInterface";
import { useAppDispatch } from "store/hooks";
import { getMapZoomPosition } from "utils/utils";
import { parse } from "wkt";

import RenderEdit from "./components/RenderEdit";

interface IProps {
  isEdit?: boolean;
  property: IProperty;
  bookingDetails?: string;
  setBookingDetails?: (bookingDetails: string) => void;
  showLocation?: boolean;
  propertyBookingDetails?: IBookingDetails;
  showRules?: boolean;
}
const { ENTRY, PARKING } = MarkerTypes;

function PropertyDetails(props: IProps) {
  const {
    property,
    isEdit,
    bookingDetails,
    setBookingDetails,
    showLocation,
    propertyBookingDetails,
    showRules,
  } = props;

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const mapRef = useRef<any>();
  const bookingDetailsRef = useRef<ElementRef<"span">>(null);

  const parkingMarkers = property.markers?.filter(x => x.type === PARKING);
  const entryMarkers = property.markers?.filter(x => x.type === ENTRY);

  const { navigateTo } = useNavigateStep();

  const isMobile = useIsMobile("midsm");

  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [showPropertyInfoWindow, setShowPropertyInfoWindow] = useState(false);
  const [selectedMarker, setSelectedMarker] = useState<IMarker>();
  const [showMapMarkers, setShowMapMarkers] = useState<boolean>(false);

  useEffect(() => {
    if (property) {
      setTimeout(() => {
        if (property?.outline && pointData) {
          const [minLng, minLat, maxLng, maxLat] = bbox(pointData);
          mapRef.current?.fitBounds(
            [
              [minLng, minLat],
              [maxLng, maxLat],
            ],
            { padding: 40, duration: 50 },
          );
        } else {
          if (property.lng && property.lat) {
            mapRef.current?.flyTo({
              center: [property.lng, property.lat],
              duration: 50,
              zoom: getMapZoomPosition(property.totalAcres),
            });
          }
        }
      }, 500);
    }
  }, [property, property.lng, property.lat]);

  useEffect(() => {
    if (property.markers) {
      const markers = [] as IMarker[];
      property.markers.forEach(marker => {
        const pointInfo = parse(marker?.point || "");
        const markerData: IMarker = {
          ...marker,
          latitude: pointInfo.coordinates[1],
          longitude: pointInfo.coordinates[0],
        };
        markers.push(markerData);
      });
      setMarkers(markers);
    }
  }, [property, property.markers]);

  useEffect(() => {
    if (isEdit && bookingDetailsRef?.current) {
      setTimeout(() => {
        bookingDetailsRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  }, [isEdit]);

  const pointData = {
    type: "Feature",
    geometry: parse(property?.outline || ""),
  };

  const toggleShowDescription = () => setDescriptionExpanded(v => !v);

  useEffect(() => {
    const currentDate = moment().startOf("day");
    const bookingStartDate = moment(
      propertyBookingDetails?.bookingStartDate,
    ).startOf("day");
    const diff = bookingStartDate.diff(currentDate, "days");

    if (diff < 3 && diff > -1) setShowMapMarkers(true);
  }, [propertyBookingDetails?.bookingStartDate]);

  const renderMarkers = () => {
    return (
      <>
        {markers.map((marker, index) => (
          <Marker
            key={marker.label}
            anchor="center"
            latitude={marker.latitude}
            longitude={marker.longitude}
            onClick={e => onMarkerClick(e, index)}
          >
            {marker.type === PARKING && (
              <SvgIcon
                color="secondary"
                style={{ backgroundColor: theme.palette.info.main }}
              >
                <ParkingIcon />
              </SvgIcon>
            )}
            {marker.type === ENTRY && (
              <SvgIcon color="info">
                <RoomIcon />
              </SvgIcon>
            )}
          </Marker>
        ))}
      </>
    );
  };

  const updatePropertyStep = (step: PropertySteps) => {
    dispatch(setEditingProperty(true));
    navigateTo(step);
  };

  const onMarkerClick = (e: any, index: number) => {
    e?.originalEvent?.preventDefault?.();
    e?.originalEvent?.stopPropagation?.();
    setSelectedMarker(markers[index]);
    setShowPropertyInfoWindow(true);
  };

  return (
    <Box>
      <Stack alignItems="center" direction="row" spacing={1}>
        <Typography variant="h2">{property.name}</Typography>
        {isEdit ? (
          <RenderEdit
            onClick={() => updatePropertyStep(PropertySteps.PROPERTY_DETAILS)}
          />
        ) : null}
      </Stack>

      <Stack
        alignItems="center"
        direction="row"
        flexWrap="wrap"
        justifyContent="space-between"
        spacing={2}
        whiteSpace="nowrap"
      >
        <Stack alignItems="center" direction="row" pt={1} spacing={2}>
          <Stack alignItems="center" direction="row" spacing={1}>
            <LocationIcon />
            <Typography lineHeight="17px" sx={{ display: "flex" }} variant="h5">
              <span
                style={{
                  maxWidth: isMobile ? "140px" : "200px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {property.city}
              </span>
              <span>{property.state}</span>
            </Typography>
          </Stack>
          <Typography fontSize={12}>•</Typography>
          <Typography lineHeight="17px" variant="h5">
            {property.totalAcres} Acres
          </Typography>
          {isEdit ? (
            <RenderEdit
              onClick={() => updatePropertyStep(PropertySteps.PROPERTY_DETAILS)}
            />
          ) : null}
        </Stack>
        {/* {!isEdit ? (
          <Stack alignItems="center" direction="row" pt={1} spacing={1}>
            <SvgIcon
              inheritViewBox
              component={ShareIcon}
              sx={{
                "& path": { fill: "rgba(0, 0, 0, 0.26)" },
                height: theme.spacing(1.75),
                width: theme.spacing(1.75),
              }}
            />
            <Typography
              lineHeight="17px"
              style={{
                borderBottom: "rgba(0, 0, 0, 0.26) 1px solid",
                color: "rgba(0, 0, 0, 0.26)",
              }}
              variant="h5"
            >
              Share
            </Typography>
          </Stack>
        ) : null} */}
      </Stack>

      <Divider style={{ margin: "24px 0 16px 0" }} />

      <Stack alignItems="center" direction="row" spacing={1}>
        <Avatar />
        <Stack alignItems="center" direction="row" spacing={1}>
          <Typography>Host </Typography>
          <Typography textTransform="capitalize">
            {property.contactOwnerName}
          </Typography>
        </Stack>
        {isEdit ? (
          <RenderEdit
            onClick={() => updatePropertyStep(PropertySteps.PROPERTY_DETAILS)}
          />
        ) : null}
      </Stack>

      <Divider style={{ margin: "24px 0" }} />

      {property.description ? (
        <>
          <Stack
            alignItems="flex-start"
            direction="row"
            justifyContent="space-between"
          >
            <ShowMoreText
              expanded={descriptionExpanded}
              lines={2}
              onClick={toggleShowDescription}
            >
              {property.description}
            </ShowMoreText>

            {isEdit ? (
              <RenderEdit
                onClick={() =>
                  updatePropertyStep(PropertySteps.PROPERTY_DETAILS)
                }
              />
            ) : null}
          </Stack>

          <Divider style={{ margin: "24px 0" }} />
        </>
      ) : null}

      {/* <Box>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography fontWeight="bold" variant="h7">
            Included with Main Activity
          </Typography>
          {isEdit ? (
            <RenderEdit
              onClick={() => navigateTo(PropertySteps.ACTIVITY_TYPE)}
            />
          ) : null}
        </Stack>

        <RenderActivities activities={["cycling", "hiking"]} />

        <Typography variant="caption">
          *This activity requires that you have personal insurance to
          participate.
        </Typography>
      </Box>

      <Divider style={{ margin: "24px 0" }} /> */}

      {/* <Box>
        <Typography fontWeight="bold" variant="h7">
          Included Amenities
        </Typography>

        <Grid container py={2} spacing={1}>
          {new Array(8).fill("Amenity").map((amenity, index) => {
            return (
              <Grid key={`${amenity}_${index}`} item xs={5}>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <AmenitiesIcon />
                  <Typography>{amenity}</Typography>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box> 

     <Divider style={{ margin: "24px 0" }} />*/}

      <Box>
        <Map
          ref={mapRef}
          initialViewState={{
            longitude: -98.4842,
            latitude: 39.0119,
            zoom: 6,
          }}
          mapboxAccessToken={MAPBOX_TOKEN}
          // mapStyle="mapbox://styles/mapbox/streets-v9?optimize=true"
          mapStyle="mapbox://styles/mapbox/satellite-streets-v11"
          style={{
            width: "100%",
            height: "300px",
          }}
        >
          <GeolocateControl position="bottom-right" />
          <NavigationControl position="bottom-right" />
          {property?.documentId && (
            <>
              {showLocation && showMapMarkers && (
                <>
                  {/* eslint-disable-next-line */}
                  {/* @ts-ignore */}
                  <Source data={pointData} type="geojson">
                    {/* eslint-disable-next-line */}
                    {/* @ts-ignore */}
                    <Layer {...mapLayer} />
                  </Source>
                </>
              )}
              {showLocation && showMapMarkers && renderMarkers()}

              {showPropertyInfoWindow && (
                <Popup
                  closeButton={true}
                  closeOnClick={false}
                  latitude={selectedMarker?.latitude || 0}
                  longitude={selectedMarker?.longitude || 0}
                  maxWidth="400px"
                  offset={10}
                  onClose={() => setShowPropertyInfoWindow(false)}
                >
                  <Stack
                    direction="row"
                    spacing={4}
                    sx={{
                      minHeight: "84px",
                      borderRadius: "15px",
                      padding: 0.8,
                      cursor: "pointer",
                    }}
                  >
                    <Stack justifyContent="center">
                      <Stack spacing={0.5}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 600,
                          }}
                        >
                          {selectedMarker?.label}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            lineHeight: "22px",
                            fontWeight: 600,
                          }}
                        >
                          {selectedMarker?.type === PARKING
                            ? `Parking Spots: ${selectedMarker.parkingSpots}`
                            : `Gate Code: ${selectedMarker?.gateCode}`}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            lineHeight: "22px",
                            fontWeight: 400,
                            wordWrap: "break-word",
                          }}
                        >
                          Additional Info: {selectedMarker?.notes}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Popup>
              )}
            </>
          )}
        </Map>
      </Box>

      {showLocation && showMapMarkers ? (
        <>
          <Box>
            <Stack alignItems="center" direction="row" mt={3} spacing={2}>
              <Typography fontWeight="bold" variant="h7">
                Entry
              </Typography>
              {isEdit ? (
                <RenderEdit
                  onClick={() => updatePropertyStep(PropertySteps.ENTRY_POINT)}
                />
              ) : null}
            </Stack>

            <Stack alignItems="center" direction="row" py={2} spacing={1}>
              <RoomIcon />
              <Typography>{entryMarkers?.length || 0} Entry Points</Typography>
            </Stack>
          </Box>
          <Box>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography fontWeight="bold" variant="h7">
                Parking
              </Typography>
              {isEdit ? (
                <RenderEdit
                  onClick={() =>
                    updatePropertyStep(PropertySteps.PARKING_POINT)
                  }
                />
              ) : null}
            </Stack>

            <Stack alignItems="center" direction="row" py={2} spacing={1}>
              <ParkingIcon />
              <Typography>
                {parkingMarkers?.length || 0} Parking Spots
              </Typography>
            </Stack>
          </Box>
        </>
      ) : null}

      {/* <Divider style={{ margin: "24px 0" }} /> */}
      {showRules && (
        <Box>
          <Stack alignItems="center" direction="row" mt={3} spacing={2}>
            <Typography fontWeight="bold" variant="h7">
              Property Rules
            </Typography>
            {isEdit ? (
              <RenderEdit
                onClick={() => updatePropertyStep(PropertySteps.PROPERTY_RULES)}
              />
            ) : null}
          </Stack>

          <Box component="ul">
            {property?.rules &&
              JSON.parse(property.rules).map((rule: string, index: number) => {
                return (
                  <Box key={`rule${index}`} component="li" pt={1}>
                    <Typography>{rule}</Typography>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}

      {/* <Box mt={3}>
          <Typography fontWeight="bold" variant="h7">
            Health and Safety
          </Typography>

          <Box mt={1}>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus,
              ante mauris aliquet auctor. Tellus, sollicitudin lorem neque,
              amet. Tempus, blandit tellus tellus donec cras condimentum
              sollicitudin.
            </Typography>
          </Box>
        </Box>

        <Box mt={3}>
          <Typography fontWeight="bold" variant="h7">
            Cancellation Policy
          </Typography>

          <Box mt={1}>
            <Typography>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Risus,
              ante mauris aliquet auctor. Tellus, sollicitudin lorem neque,
              amet. Tempus, blandit tellus tellus donec cras condimentum
              sollicitudin.
            </Typography>
          </Box>
        </Box>
      </Box> */}

      {!isEdit ? (
        <>
          <Divider style={{ margin: "24px 0" }} />
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            justifyContent="space-between"
            whiteSpace="nowrap"
          >
            <Stack
              alignItems="center"
              direction="row"
              mb={isMobile ? 2 : 0}
              spacing={1}
            >
              <Avatar />
              <Stack>
                <Typography sx={{ whiteSpace: "pre-wrap" }}>
                  {property.contactOwnerName}
                </Typography>
                <Typography variant="caption">1 activity</Typography>
              </Stack>
            </Stack>

            {/* <Button disabled fullWidth={isMobile} variant="outlined">
              Contact Host
            </Button> */}
          </Stack>

          <Divider style={{ margin: "24px 0" }} />
        </>
      ) : null}

      {isEdit ? (
        <>
          <Divider style={{ margin: "24px 0" }} />
          <Box>
            <Typography fontWeight="bold" variant="h7">
              Booking Details
            </Typography>

            <Typography component="div" variant="caption">
              Add any information to be sent to guest on booking of this
              activity
            </Typography>
            <Box ref={bookingDetailsRef} />
            <Box mt={1}>
              <CustomInputField
                multiline
                autoComplete="off"
                name="bookingDetails"
                rows={8}
                type="text"
                value={bookingDetails}
                onChange={e => setBookingDetails?.(e.target.value)}
              />
            </Box>
          </Box>
        </>
      ) : null}

      {/* <Box>
        <Typography fontWeight="bold" variant="h7">
          Other available activities on the property
        </Typography>

        <Grid container my={2} spacing={2}>
          {new Array(8).fill(0).map((_, index) => {
            return (
              <Grid key={`activity_${index}`} item xs={6}>
                <RenderOtherActivity />
              </Grid>
            );
          })}
        </Grid>
      </Box> */}
    </Box>
  );
}

export default PropertyDetails;
