import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import SecurityFields from "./SecurityFields";

function Security() {
  return (
    <>
      <Stack alignItems="start" justifyContent="center" spacing={2}>
        <Typography alignItems="center" component="div" variant="h2">
          Security
        </Typography>
        <Divider sx={{ width: "100%" }} />
        <SecurityFields />
      </Stack>
    </>
  );
}

export default Security;
