import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import theme from "theme";

interface ICustomSearchInputProps {
  searchText: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  // handleOnFocus: () => void;
  placeholder?: string;
}

function CustomSearchInput(props: ICustomSearchInputProps) {
  const { searchText, handleInputChange, placeholder } = props;

  return (
    <TextField
      hiddenLabel
      autoComplete="off"
      // variant="standard"
      InputProps={{
        type: "search",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="search"
              edge="end"
              style={{
                backgroundColor: theme.palette.secondary.main,
                marginRight: 1,
              }}
            >
              <SearchIcon style={{ color: "white" }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label=""
      placeholder={placeholder}
      style={{
        backgroundColor: "white",
        border: "none",
        width: "inherit",
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "30px",
        },
      }}
      value={searchText}
      onChange={handleInputChange}
      //   onFocus={handleOnFocus}
    />
  );
}

export default CustomSearchInput;
