const ErrorMessages = {
  billing: {
    cardAlias:
      "Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.",
    cardNumber: "Please enter a valid card number.",
    name: "Card Name must be between 2-30 characters, contain 2 letters, and may contain no special characters except hyphen (-) or apostrophe.",
    cardExpiryMonth: "Please select a month.",
    cardExpiryYear: "Please select a year.",
    address: "Address must be 5-32 characters.",
    addressEmpty: "Please enter Address.",
    city: "City must be 2-36 characters.",
    cityEmpty: "Please enter a City.",
    state: "Please enter a State.",
    zipcode: "Please enter a valid Zip code.",
  },
  property: {
    propertyName: "Name must be between 2-70 characters.",
    description: "3000 character max.",
    contactOwnerName: "Name must be between 2-70 characters.",
  },
  mailingAddress: {
    name: "Name must be between 2-70 characters.",
    address: "40 character max.",
    city: "40 character max.",
    cityEmpty: "Please enter a City.",
    state: "Please enter a State.",
    zip: "Please enter a valid Zip code.",
  },
  addPhoto: {
    title: "Title must be between 2-70 characters.",
    description: "Description must be between 2-300 characters.",
    fileSize: "The image file must be 100MB or less.",
    fileFormat:
      "File format is not supported. Please select one of the following file types: jpg, jpeg or png.",
  },
  directDepositForm: {
    name: "Name must be between 1-35 characters, contain 1 letter, and may contain no special characters except hyphen (-) or apostrophe.",
    routingNumber: "Invalid Routing Number.",
    accountNumber: "Invalid Account Number.",
    accountType: "Please select Account Type.",
  },
};

export default ErrorMessages;
