import valid from "card-validator";
import { NAME_REGEX, NAME_REGEX_2 } from "constants/constants";
import ErrorMessages from "constants/ErrorMessages";
import { CardFormType } from "store/billing/BillingTypes";
import * as yup from "yup";

import { validateFileSize } from "./validator";

export const defaultSchema = yup.object().shape({});
const { ADD } = CardFormType;
const propertyErrorMsg = {
  ...ErrorMessages.property,
  propertyAddress: { ...ErrorMessages.mailingAddress },
};

const addressValidationSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, propertyErrorMsg.propertyAddress.name)
    .max(70, propertyErrorMsg.propertyAddress.name)
    .required(propertyErrorMsg.propertyAddress.name),
  address: yup
    .string()
    .trim()
    .min(0, propertyErrorMsg.propertyAddress.address)
    .max(40, propertyErrorMsg.propertyAddress.address),
  city: yup
    .string()
    .trim()
    .min(2, propertyErrorMsg.propertyAddress.city)
    .max(40, propertyErrorMsg.propertyAddress.city)
    .required(propertyErrorMsg.propertyAddress.cityEmpty),
  state: yup.string().required(propertyErrorMsg.propertyAddress.state),
  zip: yup
    .string()
    .trim()
    .length(5, propertyErrorMsg.propertyAddress.zip)
    .required(propertyErrorMsg.propertyAddress.zip),
});

export const mailingAddressValidations = yup.object().shape({
  mailingAddress: addressValidationSchema,
});

export const propertyValidationSchema = yup.object().shape({
  propertyName: yup
    .string()
    .trim()
    .min(2, propertyErrorMsg.propertyName)
    .max(70, propertyErrorMsg.propertyName)
    .required(propertyErrorMsg.propertyName),
  description: yup
    .string()
    .trim()
    .min(0, propertyErrorMsg.description)
    .max(3000, propertyErrorMsg.description),
  contactOwnerName: yup
    .string()
    .trim()
    .min(2, propertyErrorMsg.contactOwnerName)
    .max(70, propertyErrorMsg.contactOwnerName)
    .required(propertyErrorMsg.contactOwnerName),
  propertyAddress: addressValidationSchema,
});

const billingErrorMsg = ErrorMessages.billing;
export const billingValidationSchema = (formType: number) =>
  yup.object().shape({
    cardAlias: yup
      .string()
      .trim()
      .min(1, billingErrorMsg.cardAlias)
      .matches(NAME_REGEX, billingErrorMsg.cardAlias)
      .required(billingErrorMsg.cardAlias),
    ...(formType === ADD && {
      cardNumber: yup
        .string()
        .test("card-validator", billingErrorMsg.cardNumber, value => {
          const numberValidation = valid.number(value);
          return numberValidation.isValid;
        })
        .required(billingErrorMsg.cardNumber),
    }),
    name: yup
      .string()
      .trim()
      .min(2, billingErrorMsg.name)
      .matches(NAME_REGEX_2, billingErrorMsg.name)
      .required(billingErrorMsg.name),
    cardExpiryMonth: yup.string().required(billingErrorMsg.cardExpiryMonth),
    cardExpiryYear: yup.string().required(billingErrorMsg.cardExpiryYear),
    address: yup
      .string()
      .trim()
      .min(5, billingErrorMsg.address)
      .required(billingErrorMsg.addressEmpty),
    city: yup
      .string()
      .trim()
      .min(2, billingErrorMsg.city)
      .required(billingErrorMsg.cityEmpty),
    state: yup.string().required(billingErrorMsg.state),
    zipcode: yup
      .string()
      .trim()
      .length(5, billingErrorMsg.zipcode)
      .required(billingErrorMsg.zipcode),
  });

const uploadPhotoErrorMsg = ErrorMessages.addPhoto;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
export const ActivityPhotoUploadSchema = () =>
  yup.object().shape({
    title: yup
      .string()
      .min(2, uploadPhotoErrorMsg.title)
      .required(uploadPhotoErrorMsg.title),
    description: yup
      .string()
      .min(2, uploadPhotoErrorMsg.description)
      .required(uploadPhotoErrorMsg.description),
    file: yup
      .mixed()
      .nullable()
      .required("Please upload a file")
      .test(
        "fileSize",
        uploadPhotoErrorMsg.fileSize,
        value =>
          !value || (value && validateFileSize((value as File).size, 100)),
        // (value) => !value || (value && value.size <= 1024 * 1024),
      )
      .test(
        "format",
        uploadPhotoErrorMsg.fileFormat,
        value =>
          !value || (value && SUPPORTED_FORMATS.includes((value as File).type)),
      ),
  });

export const propertyRulesValidations = yup.object().shape({
  rules: yup.array().of(yup.object().shape({ rule: yup.string().required() })),
});

const directDepositFormMsg = ErrorMessages.directDepositForm;
export const directDepositFormValidationSchema = yup.object().shape({
  name: yup
    .string()
    .required(directDepositFormMsg.name)
    .min(1, directDepositFormMsg.name)
    .matches(NAME_REGEX, directDepositFormMsg.name),
  routingNumber: yup
    .string()
    .required(directDepositFormMsg.routingNumber)
    .min(9, directDepositFormMsg.routingNumber),
  accountNumber: yup
    .string()
    .required(directDepositFormMsg.accountNumber)
    .min(8, directDepositFormMsg.accountNumber),
  accountType: yup.string().required(directDepositFormMsg.accountType),
});
