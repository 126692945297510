import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useFocus from "hooks/useFocus";
import React from "react";
import { updateUserAuthData } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

interface IHearAboutUsProps {
  handleNextStep: () => void;
}

function HearAboutUs({ handleNextStep }: IHearAboutUsProps) {
  const options = ["Web", "Social", "Referral", "Etc"];

  const dispatch = useAppDispatch();
  const { userAuthData } = useAppSelector(s => s.auth);

  const focusButtonRef = useFocus(Boolean(userAuthData.hearAboutUs.length));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateUserAuthData({
        ...userAuthData,
        hearAboutUs: (event.target as HTMLInputElement).value,
      }),
    );
  };

  const handleNext = () => {
    handleNextStep();
  };

  return (
    <Box>
      <Stack alignItems="center" justifyContent="center">
        <Typography component="h3" variant="h3">
          How did you hear about us?
        </Typography>
        <Box sx={{ mt: theme.spacing(3), mb: theme.spacing(4) }}>
          <FormControl fullWidth>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={userAuthData.hearAboutUs}
              onChange={handleChange}
            >
              {options.map(option => {
                return (
                  <FormControlLabel
                    key={option}
                    control={<Radio />}
                    label={
                      <Typography component="span" variant="h6">
                        {option}
                      </Typography>
                    }
                    value={option}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Box>
        <Button
          ref={focusButtonRef}
          fullWidth
          disabled={!(userAuthData.hearAboutUs.length > 0)}
          variant="contained"
          onClick={handleNext}
        >
          Next
        </Button>
      </Stack>
    </Box>
  );
}

export default HearAboutUs;
