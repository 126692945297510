import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/system/Stack";
import CustomInputField from "components/TextField/CustomInputField";
import CustomSelectField from "components/TextField/CustomSelectField";
import { ACCOUNT_TYPES } from "constants/constants";
import {
  addACHPayment,
  IACHPaymentState,
  IResponseData,
  updateACHPayment,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import {
  handleAllowOnlyNumber,
  handleAllowOnlyOneSpaceBetweenWordsAndAlphabets,
} from "utils/utils";

import useDirectDepositForm from "./useDirectDepositForm";

interface IProps {
  type?: string;
  onEditModalClose?: () => void;
}

function DirectDepositForm({ type, onEditModalClose }: IProps) {
  const dispatch = useAppDispatch();
  const { achPaymentAccountDetails, loading, isUpdateLoading } = useAppSelector(
    s => s.finance,
  );

  const initialState: IACHPaymentState = {
    name: "" || achPaymentAccountDetails.name,
    routingNumber: "" || achPaymentAccountDetails.routingNumber,
    accountNumber: "" || achPaymentAccountDetails.accountNumber,
    accountType: "" || achPaymentAccountDetails.accountType,
  };

  const onSubmit = async () => {
    const requestData: IACHPaymentState = {
      ...values,
    };
    if (type === "edit") {
      const response = (await dispatch(
        updateACHPayment(achPaymentAccountDetails.documentId, requestData),
      )) as IResponseData;
      if (!response.error) {
        onEditModalClose?.();
      }
    } else {
      await dispatch(addACHPayment(requestData));
    }
  };

  const formik = useDirectDepositForm(onSubmit, initialState);
  const {
    touched,
    values,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    isValid,
    dirty,
  } = formik;

  return (
    <Stack
      noValidate
      component="form"
      spacing={theme.spacing(3)}
      width="100%"
      onSubmit={handleSubmit}
    >
      <Stack spacing={theme.spacing(2.5)}>
        <CustomInputField
          fullWidth
          error={!!(touched.name && errors.name)}
          errormessage={touched.name ? errors.name : undefined}
          id="ach-name"
          inputProps={{ maxLength: 35 }}
          label="Name"
          name="name"
          type="text"
          value={values.name}
          onBlur={handleBlur}
          onChange={e =>
            handleChange("name")(
              handleAllowOnlyOneSpaceBetweenWordsAndAlphabets(e.target.value),
            )
          }
        />
        <CustomInputField
          fullWidth
          error={!!(touched.routingNumber && errors.routingNumber)}
          errormessage={
            touched.routingNumber ? errors.routingNumber : undefined
          }
          id="ach-routingNumber"
          inputProps={{ maxLength: 9 }}
          label="Routing #"
          name="routingNumber"
          type="text"
          value={values.routingNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleAllowOnlyNumber}
        />
        <CustomInputField
          fullWidth
          error={!!(touched.accountNumber && errors.accountNumber)}
          errormessage={
            touched.accountNumber ? errors.accountNumber : undefined
          }
          id="ach-accountNumber"
          inputProps={{ maxLength: 17 }}
          label="Account #"
          name="accountNumber"
          type="text"
          value={values.accountNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handleAllowOnlyNumber}
        />

        <CustomSelectField
          error={!!(touched.accountType && errors?.accountType)}
          errormessage={touched.accountType ? errors?.accountType : undefined}
          inputProps={{
            name: "accountType",
          }}
          label="Account Type"
          placeholder="Select Account Type"
          value={values.accountType}
          onBlur={handleBlur}
          onChange={handleChange}
        >
          <option disabled selected value="">
            Select Account Type
          </option>
          {ACCOUNT_TYPES.map(accountType => (
            <option key={accountType.value} value={accountType.value}>
              {accountType.label}
            </option>
          ))}
        </CustomSelectField>
      </Stack>
      <Stack spacing={theme.spacing(type === "edit" ? 2 : 3)}>
        {type !== "edit" && (
          <Typography align="center">
            By completing and submitting this form you are digitally signing and
            giving permission for Wing It to make transactions with your Banking
            Institution.
          </Typography>
        )}
        <LoadingButton
          disableRipple
          disableTouchRipple
          fullWidth
          disabled={!(isValid && dirty)}
          loading={loading || isUpdateLoading}
          size="large"
          type="submit"
          variant="contained"
        >
          {type === "edit" ? "Save" : "Submit"}
        </LoadingButton>
        {type === "edit" && (
          <Button
            disableRipple
            size="large"
            variant="outlined"
            onClick={onEditModalClose}
          >
            Cancel
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

export default DirectDepositForm;
