import { Action, combineReducers, Reducer } from "redux";

import AccountReducer from "./account/AccountReducer";
import AlertReducer from "./alerts/AlertReducer";
import AuthReducer from "./authentication/AuthenticationReducer";
import AuthenticationType from "./authentication/AuthenticationTypes";
import BillingReducer from "./billing/BillingReducer";
import BookingReducer from "./booking/BookingReducer";
import FinanceReducer from "./finance/FinanceReducer";
import ProfileReducer from "./profile/ProfileReducer";
import PropertyReducer from "./property/PropertyReducer";
import SearchReducer from "./search/SearchReducer";

const combinedReducer = combineReducers({
  auth: AuthReducer,
  account: AccountReducer,
  alert: AlertReducer,
  booking: BookingReducer,
  billing: BillingReducer,
  profile: ProfileReducer,
  property: PropertyReducer,
  search: SearchReducer,
  finance: FinanceReducer,
});

type AppState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer<AppState, Action> = (state, action) => {
  // action to clear application redux
  if (action.type === AuthenticationType.USER_LOGOUT) {
    // eslint-disable-next-line no-param-reassign
    state = {} as AppState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
