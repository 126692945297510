import { Reducer } from "redux";
import { IPlace } from "store/property/PropertyInterface";

import {
  ICurrentActivity,
  ISearchState,
  SearchActions,
} from "./SearchInterface";
import { SearchTypes } from "./SearchTypes";

const initialState: ISearchState = {
  loading: false,
  currentActivity: {} as ICurrentActivity,
  currentLocation: {} as IPlace,
  currentDate: [],
  activities: [],
  locationOptions: [],
  searchResults: [],
  mapProperties: [],
};

const SearchReducer: Reducer<ISearchState, SearchActions> = (
  state = initialState,
  action = {} as SearchActions,
) => {
  switch (action.type) {
    case SearchTypes.FETCH_ACTIVITIES_REQUEST: {
      return {
        ...state,
        activities: [],
        loading: true,
      };
    }
    case SearchTypes.FETCH_ACTIVITIES_REQUEST_SUCCESS: {
      return {
        ...state,
        activities: action.payload,
        loading: false,
      };
    }
    case SearchTypes.FETCH_ACTIVITIES_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case SearchTypes.FETCH_PROPERTIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case SearchTypes.FETCH_PROPERTIES_REQUEST_SUCCESS: {
      return {
        ...state,
        locationOptions: action.payload,
        loading: false,
      };
    }
    case SearchTypes.FETCH_PROPERTIES_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case SearchTypes.MAKE_SEARCH_REQUEST: {
      return {
        ...state,
        loading: true,
        searchResults: [],
        mapProperties: [],
      };
    }
    case SearchTypes.MAKE_SEARCH_REQUEST_SUCCESS: {
      return {
        ...state,
        searchResults: [...action.payload],
        mapProperties: [...action.payload],
        loading: false,
      };
    }
    case SearchTypes.MAKE_SEARCH_REQUEST_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case SearchTypes.SET_CURRENT_ACTIVITY: {
      return {
        ...state,
        currentActivity: action.payload,
      };
    }

    case SearchTypes.SET_CURRENT_LOCATION: {
      return {
        ...state,
        currentLocation: action.payload,
      };
    }

    case SearchTypes.SET_CURRENT_DATE: {
      return {
        ...state,
        currentDate: action.payload,
      };
    }

    case SearchTypes.FETCH_MAP_PROPERTIES_REQUEST: {
      return {
        ...state,
        loading: false,
      };
    }
    case SearchTypes.FETCH_MAP_PROPERTIES_SUCCESS: {
      const properties = [...state.mapProperties, ...action.payload];
      const uniqueProperties = [
        ...new Map(properties.map(m => [m.documentId, m])).values(),
      ];

      return {
        ...state,
        mapProperties: uniqueProperties,
        loading: false,
      };
    }
    case SearchTypes.FETCH_MAP_PROPERTIES_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default SearchReducer;
