import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CustomInputField from "components/TextField/CustomInputField";
import useNavigateStep from "hooks/useNavigateStep";
import { useEffect, useState } from "react";
import { alertError, PropertySteps, updatePropertyFields } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";

function PropertyRules() {
  const { navigateTo } = useNavigateStep();
  const dispatch = useAppDispatch();

  const { propertyInfo, isUpdating, isPropertyEditing } = useAppSelector(
    s => s.property,
  );

  const [rules, setRules] = useState([""]);

  const onSubmit = async () => {
    const filteredRules = rules.filter(x => x !== "");
    const propertyDetails = {
      rules: JSON.stringify(filteredRules),
      lastStep:
        parseInt(propertyInfo.lastStep, 10) > PropertySteps.PROPERTY_RULES
          ? propertyInfo.lastStep
          : PropertySteps.PROPERTY_RULES.toString(),
    };

    const response = await dispatch(
      updatePropertyFields(propertyDetails, propertyInfo?.documentId),
    );

    if (!response.error) {
      if (isPropertyEditing) {
        navigateTo(PropertySteps.REVIEW);
      } else {
        navigateTo(PropertySteps.ACTIVITY_COST);
      }
    }
  };

  useEffect(() => {
    if (propertyInfo) {
      setRules(propertyInfo.rules ? JSON.parse(propertyInfo.rules) : [""]);
    }
  }, [propertyInfo]);

  const addFormFields = () => {
    const index = rules.findIndex(x => x === "");
    if (index === -1) {
      setRules([...rules, ""]);
    } else {
      dispatch(
        alertError({
          message: "Please fill the added rules before adding a new rule",
        }),
      );
    }
  };

  const removeFormFields = (index: number) => {
    const newRules = [...rules];
    newRules.splice(index, 1);
    setRules(newRules);
  };

  const handleChange = (index: number, e: any) => {
    const newRules = [...rules];
    newRules[index] = e.target.value;
    setRules(newRules);
  };

  return (
    <>
      <RightMainComponentWrapper center={false}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ textAlign: "center", mb: theme.spacing(2) }}
        >
          <Typography
            component="div"
            sx={{
              fontWeight: 700,
              lineHeight: "36px",
              fontSize: "28px",
            }}
            variant="h6"
          >
            Property Rules
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
            }}
            variant={"h7"}
          >
            Please enter property rules with maximum of 70 characters per rule.
          </Typography>
        </Stack>
        <Box noValidate component="form" width={"100%"}>
          {rules.length > 0 ? (
            <>
              {rules.map((rule, index) => (
                <Stack
                  key={index}
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                >
                  <CustomInputField
                    autoComplete="off"
                    inputProps={{ maxLength: 70 }}
                    // label={`Rule ${index + 1}`}
                    name={`rules.${index}`}
                    type="text"
                    value={rule}
                    onChange={e => handleChange(index, e)}
                  />
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={() => removeFormFields(index)}
                  >
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <IconButton
                    aria-label="add"
                    color="primary"
                    onClick={() => addFormFields()}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Stack>
              ))}
            </>
          ) : (
            <Stack alignItems="center" justifyContent="center">
              <Button
                color="secondary"
                sx={{ width: "50%", mt: 5 }}
                variant="contained"
                onClick={() => addFormFields()}
              >
                Add New Rule
              </Button>
            </Stack>
          )}
        </Box>
      </RightMainComponentWrapper>
      <Footer
        handleNext={onSubmit}
        loading={isUpdating}
        primaryText={isPropertyEditing ? "Save" : "Next"}
      />
    </>
  );
}

export default PropertyRules;
