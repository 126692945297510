import Menu, { MenuProps } from "@mui/material/Menu";
import React from "react";
import theme from "theme";

interface ICustomMenuProps extends MenuProps {
  children: React.ReactNode;
  excludeMarginTop?: boolean;
}

function CustomMenu(props: ICustomMenuProps) {
  const {
    children,
    id,
    anchorEl,
    open,
    onClose,
    onClick,
    excludeMarginTop = false,
  } = props;
  return (
    <Menu
      disableAutoFocusItem
      disableScrollLock
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      id={id}
      open={open}
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: "4px",
          mt: 1.5,
          "&.MuiPaper-root": {
            border: `0.25px solid ${theme.palette.text.secondary}`,
            minWidth: "120px !important",
            ...(excludeMarginTop && { marginTop: 0 }),
          },
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
          },
          "& .MuiMenuItem-root": {
            fontWeight: 500,
            fontSize: "14px",
            lineHeight: "20px",
            px: theme.spacing(2),
            minHeight: "unset !important",
          },
          "& .MuiList-root": {
            padding: theme.spacing(0.75, 0),
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      onClick={onClick}
      onClose={onClose}
    >
      {children}
    </Menu>
  );
}

export default CustomMenu;
