import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { deleteACHPayment, IResponseData } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

interface IProps {
  onDeleteModalClose: () => void;
  onEditModalOpen: () => void;
}

function DeletePayment({ onDeleteModalClose, onEditModalOpen }: IProps) {
  const dispatch = useAppDispatch();
  const { achPaymentAccountDetails, loading, isUpdateLoading } = useAppSelector(
    s => s.finance,
  );

  const handleDeletePayment = async () => {
    const response = (await dispatch(
      deleteACHPayment(achPaymentAccountDetails.documentId),
    )) as IResponseData;
    if (!response.error) {
      onDeleteModalClose();
    }
  };

  return (
    <Stack spacing={theme.spacing(3)}>
      <Typography>
        Are you sure you want to delete this account? We will not be able to
        compensate you for your Property Bookings.
      </Typography>
      <Typography>
        If you would like to continue hosting on Wing It, please click{" "}
        <Typography
          component={Link}
          sx={{ cursor: "pointer", fontWeight: "bold" }}
          variant="body2"
          onClick={() => {
            onDeleteModalClose();
            onEditModalOpen();
          }}
        >
          EDIT
        </Typography>
        .
      </Typography>
      <Stack spacing={theme.spacing(2)}>
        <LoadingButton
          disableRipple
          disableTouchRipple
          fullWidth
          loading={loading || isUpdateLoading}
          size="large"
          variant="contained"
          onClick={handleDeletePayment}
        >
          Delete
        </LoadingButton>
        <Button
          disableRipple
          size="large"
          variant="outlined"
          onClick={onDeleteModalClose}
        >
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
}

export default DeletePayment;
