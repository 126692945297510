import Stack from "@mui/material/Stack";
import { US_STATES_CODES } from "constants/constants";
import { useIsMobile } from "hooks/useIsMobile";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import {
  clearFoundPropertyData,
  createProperty,
  getAddressDetailsByLatLong_MAPBOX,
  getAddressDetailsByPlace,
  IContextAddress,
  IPlace,
  IPropertyRequest,
  searchProperties,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";

import FoundProperty from "../FoundProperty";
import MapSearchInput from "./SearchInput";
import SearchResultsList from "./SearchResultsList";

interface ISearchContainerProps {
  mapRef: any;
}

const SearchContainer = forwardRef(({ mapRef }: ISearchContainerProps, ref) => {
  useImperativeHandle(ref, () => ({
    getCreatePropertyDetails(lat: number, long: number) {
      getCreatePropertyDetails_MAPBOX(lat, long);
    },
  }));

  const dispatch = useAppDispatch();

  const { propertyInfo } = useAppSelector(s => s.property);

  const [selectedPlace, setSelectedPlace] = useState<IPlace>();

  const [searchText, setSearchText] = useState<string>("");
  const [searchResults, setSearchResults] = useState<IPlace[]>([]);

  const [centerCoordinate, setCenterCoordinate] = useState([-98.4842, 39.0119]);

  const isMobile = useIsMobile("midsm");

  useEffect(() => {
    const debounceSearchFn = setTimeout(async () => {
      if (searchText.length >= 3 && !selectedPlace) {
        const response = await searchProperties(searchText);
        if (response) {
          setSearchResults(response);
        }
      }
    }, 200);

    return () => clearTimeout(debounceSearchFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;
    setSearchText(value);
  };

  const onSelectPlace = async (place: IPlace) => {
    setSelectedPlace(place);
    setSearchResults([]);
    await getCreatePropertyDetailsByPlace(place);
    setSearchText(place.place_name_en);
  };

  const getCreatePropertyDetailsByPlace = async (place: IPlace) => {
    const { address, city, state, zip, lat, long } =
      await fetchAddressDetailsByPlace(place.place_name_en);
    const payload: IPropertyRequest = {
      address1: address || "",
      address2: "",
      city,
      state,
      zip,
      lat: lat.toString(),
      lon: long.toString(),
      ip: "192.168.5.8",
    };
    dispatch(createProperty(payload));

    // mapRef.current?.flyTo({ center: [long, lat], duration: 500, zoom: 12 });
    setCenterCoordinate([long, lat]);
  };

  // const getCreatePropertyDetails = async (
  //   latitude: number,
  //   longitude: number,
  // ) => {
  //   const { address, city, state, zip, lat, long } = await getAddressDetails([
  //     longitude,
  //     latitude,
  //   ]);
  //   const payload: IPropertyRequest = {
  //     address1: address || "",
  //     address2: "",
  //     city,
  //     state,
  //     zip,
  //     lat: lat.toString(),
  //     lon: long.toString(),
  //     ip: "192.168.5.8",
  //   };
  //   dispatch(createProperty(payload));

  //   // mapRef.current?.flyTo({ center: [long, lat], duration: 500, zoom: 12 });
  //   setCenterCoordinate([long, lat]);
  // };

  // const getAddressDetails = async (coordinates: number[]) => {
  //   const response = await getAddressDetailsByLatLong(coordinates);
  //   const address = response?.results[0]?.locations[0];
  //   const city = address.adminArea5;
  //   const state = US_STATES_CODES[address.adminArea3];
  //   const zip = address.postalCode.split("-")[0];
  //   const lat = address.displayLatLng.lat;
  //   const long = address.displayLatLng.lng;
  //   const street = address.street;

  //   return {
  //     address: street,
  //     city,
  //     state,
  //     zip,
  //     lat,
  //     long,
  //   };
  // };
  const fetchAddressDetailsByPlace = async (place: string) => {
    const response = await getAddressDetailsByPlace(place);
    const address = response?.results[0]?.locations[0];
    const city = address.adminArea5;
    const state = US_STATES_CODES[address.adminArea3];
    const zip = address.postalCode.split("-")[0];
    const lat = address.displayLatLng.lat;
    const long = address.displayLatLng.lng;
    const street = address.street;

    return {
      address: street,
      city,
      state,
      zip,
      lat,
      long,
    };
  };

  const getCreatePropertyDetails_MAPBOX = async (lat: number, long: number) => {
    const { address, city, state, zip } = await getAddressDetails_MAPBOX([
      long,
      lat,
    ]);
    const payload: IPropertyRequest = {
      address1: address || "",
      address2: "",
      city,
      state,
      zip,
      lat: lat.toString(),
      lon: long.toString(),
      ip: "192.168.5.8",
    };
    dispatch(createProperty(payload));

    // mapRef.current?.flyTo({ center: [long, lat], duration: 500, zoom: 12 });
    setCenterCoordinate([long, lat]);
  };

  const getAddressDetails_MAPBOX = async (coordinates: number[]) => {
    const response = await getAddressDetailsByLatLong_MAPBOX(coordinates);
    const reversedData = response?.features[0];
    const address = reversedData?.properties.address;
    let city = "";
    let state = "";
    let zip = "";
    reversedData?.context?.map((item: IContextAddress) => {
      if (item.id.includes("postcode")) {
        zip = item.text;
        return item;
      }
      if (item.id.includes("place")) {
        city = item.text;
        return item;
      }
      if (item.id.includes("region")) {
        state = item.text;
        return item;
      }
      return item;
    });
    return {
      address,
      city,
      state,
      zip,
    };
  };

  const onRemoveFoundProperty = () => {
    setSelectedPlace(undefined);
    dispatch(clearFoundPropertyData());
    setSearchText("");
  };
  return (
    <>
      <Stack
        style={{
          position: "absolute",
          left: isMobile ? "2.5%" : "25%",
          top: "5%",
          width: isMobile ? "95%" : "50%",
          justifyContent: "center",
        }}
      >
        <MapSearchInput
          handleInputChange={handleInputChange}
          handleOnFocus={() => {
            setSelectedPlace(undefined);
          }}
          searchText={searchText}
        />
        <SearchResultsList
          mapSelectedArea={undefined}
          places={searchResults}
          onSelectPlace={onSelectPlace}
        />
      </Stack>

      {propertyInfo?.documentId && (
        <Stack
          style={{
            position: "absolute",
            left: "25%",
            bottom: "5%",
            width: "50%",
            justifyContent: "center",
            overflow: "hidden",
            borderRadius: "10px",
            ...(isMobile && {
              borderRadius: "20px 20px 0 0",
              zIndex: 12,
              left: 0,
              bottom: 0,
              width: "100%",
            }),
          }}
        >
          <FoundProperty
            location={centerCoordinate}
            property={propertyInfo}
            onRemove={onRemoveFoundProperty}
          />
        </Stack>
      )}
    </>
  );
});

export default SearchContainer;
