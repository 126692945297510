import { FormikHelpers, useFormik } from "formik";
import {
  IAddPhoto,
  IMailingAddress,
  IPropertyDetailsState,
  IPropertyRulesState,
} from "store/property/PropertyInterface";

export const usePropertyForm = (
  onSubmit: (
    values: IPropertyDetailsState,
    formikHelpers: FormikHelpers<IPropertyDetailsState>,
  ) => void | Promise<unknown>,
  initialValues: IPropertyDetailsState,
  validationSchema: any,
) => {
  return useFormik<IPropertyDetailsState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useMailingForm = (
  onSubmit: (
    values: IMailingAddress,
    formikHelpers: FormikHelpers<IMailingAddress>,
  ) => void | Promise<unknown>,
  initialValues: IMailingAddress,
  validationSchema: any,
) => {
  return useFormik<IMailingAddress>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const useAddPhotoForm = (
  onSubmit: (
    values: IAddPhoto,
    formikHelpers: FormikHelpers<IAddPhoto>,
  ) => void | Promise<unknown>,
  initialValues: IAddPhoto,
  validationSchema: any,
) => {
  return useFormik<IAddPhoto>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};

export const usePropertyRulesForm = (
  onSubmit: (
    values: IPropertyRulesState,
    formikHelpers: FormikHelpers<IPropertyRulesState>,
  ) => void | Promise<unknown>,
  initialValues: IPropertyRulesState,
  validationSchema: any,
) => {
  return useFormik<IPropertyRulesState>({
    initialValues,
    validationSchema,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    onSubmit,
  });
};
