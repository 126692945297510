import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

function HRLine() {
  const hrLineStyle = {
    width: "75px",
    display: "block",
    height: "1px",
    backgroundColor: "#D9D9D9",
    margin: "0 8px",
  };

  return (
    <Stack alignItems="center" direction="row" justifyContent="center">
      <Box sx={hrLineStyle} />
      <Typography component="span" variant="subtitle1">
        or
      </Typography>
      <Box sx={hrLineStyle} />
    </Stack>
  );
}

export default HRLine;
