enum AccountTypes {
  GET_ACTIVITIES_REQUEST = "GET_ACTIVITIES_REQUEST",
  GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_ERROR = "GET_ACTIVITIES_ERROR",

  CHECK_EMAIL_REQUEST = "CHECK_EMAIL_REQUEST",
  CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS",
  CHECK_EMAIL_ERROR = "CHECK_EMAIL_ERROR",

  CHECK_PHONE_REQUEST = "CHECK_PHONE_REQUEST",
  CHECK_PHONE_SUCCESS = "CHECK_PHONE_SUCCESS",
  CHECK_PHONE_ERROR = "CHECK_PHONE_ERROR",

  UPDATE_USER_ACCOUNT_INFO_REQUEST = "UPDATE_USER_ACCOUNT_INFO_REQUEST",
  UPDATE_USER_ACCOUNT_INFO_SUCCESS = "UPDATE_USER_ACCOUNT_INFO_SUCCESS",
  UPDATE_USER_ACCOUNT_INFO_ERROR = "UPDATE_USER_ACCOUNT_INFO_ERROR",
}

export default AccountTypes;
