import client from "Api";
import { paymentUrl } from "helpers/urls";
import { Dispatch } from "react";
import { AlertActions, IAlertPayload } from "store/alerts/AlertInterface";
import { AlertType } from "store/alerts/AlertTypes";
import { IResponseData } from "store/common/CommonInterface";

import {
  BillingActions,
  ICardData,
  ICreateCardRequestData,
} from "./BillingInterface";
import BillingType from "./BillingTypes";

export const showCardForm = (
  showForm: boolean,
  formType: number | undefined,
) => {
  return async (dispatch: Dispatch<BillingActions>) => {
    dispatch({
      type: BillingType.SHOW_CARD_FORM,
      payload: { showForm, formType },
    });
  };
};

export const getCards = () => {
  return async (dispatch: Dispatch<BillingActions | AlertActions>) => {
    try {
      dispatch({ type: BillingType.GET_CARDS_REQUEST });
      const response: IResponseData = await client.get(
        `${paymentUrl}creditCards`,
      );
      dispatch({ type: BillingType.GET_CARDS_SUCCESS, payload: response.data });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: BillingType.GET_CARDS_ERROR });
      return error;
    }
  };
};

export const addCard = (cardData: ICreateCardRequestData) => {
  return async (dispatch: Dispatch<BillingActions | AlertActions>) => {
    try {
      dispatch({ type: BillingType.ADD_CARD_REQUEST });
      const response: IResponseData = await client.post(
        `${paymentUrl}creditCards`,
        {
          ...cardData,
        },
      );
      dispatch({
        type: BillingType.ADD_CARD_SUCCESS,
        payload: response.data as ICardData,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: BillingType.ADD_CARD_ERROR });
      return error;
    }
  };
};

export const setActiveEditCardIndex = (index: number) => {
  return async (dispatch: Dispatch<BillingActions>) => {
    dispatch({ type: BillingType.SET_EDIT_CARD_ACTIVE, payload: index });
  };
};

export const editCard = (cardData: ICreateCardRequestData, cardID: string) => {
  return async (dispatch: Dispatch<BillingActions | AlertActions>) => {
    try {
      dispatch({ type: BillingType.EDIT_CARD_REQUEST });
      const response: IResponseData = await client.put(
        `${paymentUrl}creditCards/${cardID}`,
        {
          ...cardData,
        },
      );
      dispatch({
        type: BillingType.EDIT_CARD_SUCCESS,
        payload: response.data as ICardData,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: BillingType.EDIT_CARD_ERROR });
      return error;
    }
  };
};

export const deleteCard = (cardID: string) => {
  return async (dispatch: Dispatch<BillingActions | AlertActions>) => {
    try {
      dispatch({ type: BillingType.DELETE_CARD_REQUEST });
      const response: IResponseData = await client.delete(
        `${paymentUrl}creditCards/${cardID}`,
      );
      dispatch({
        type: BillingType.DELETE_CARD_SUCCESS,
        payload: cardID,
      });
      return response;
    } catch (error: unknown) {
      const e = error as IResponseData;
      const errorPayload: IAlertPayload = { message: e?.message };
      dispatch({
        type: AlertType.ALERT_ERROR,
        payload: errorPayload,
      });
      dispatch({ type: BillingType.DELETE_CARD_ERROR });
      return error;
    }
  };
};

export const resetBillingState = () => {
  return async (dispatch: Dispatch<BillingActions>) => {
    dispatch({ type: BillingType.RESET_BILLING_STATE });
  };
};
