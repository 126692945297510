import { useNavigate } from "react-router-dom";
import { IResponseData, logout } from "store";
import { useAppDispatch } from "store/hooks";
import { clearSession } from "utils/cookies";
import { setCookieItem } from "utils/utils";

const useLogout = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const clearSession = async () => {
    const response = (await dispatch(logout())) as IResponseData;
    if (response && !response.error) {
      await clearSession();
      setCookieItem("userRole", "guest");
      navigate("/");
    }
  };

  return clearSession;
};

export default useLogout;
