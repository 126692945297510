import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import theme from "theme";

interface ILoaderProps {
  size?: number | string;
  withPadding?: boolean;
  customPaddingThemeSpacing?: number;
  position?: "start" | "center" | "end";
}

const POSITION_MAP: { [key: string]: string } = {
  start: "flex-start",
  center: "center",
  end: "flex-end",
};

function Loader(props: ILoaderProps) {
  const {
    customPaddingThemeSpacing,
    position = "center",
    withPadding = true,
    size,
  } = props;

  return (
    <Stack
      alignItems={POSITION_MAP[position]}
      boxSizing="border-box"
      height="100%"
      justifyContent="center"
      width="100%"
      {...(withPadding && {
        padding: theme.spacing(customPaddingThemeSpacing || 4),
      })}
    >
      <CircularProgress size={size} />
    </Stack>
  );
}

export default Loader;
