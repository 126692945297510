import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePickerIcon } from "helpers/images";
import moment from "moment";

interface ICustomDatePickerProps {
  DOB?: Date;
  errorMessage?: string;
  onChange: (newValue: Date | null) => void;
  disableFuture?: boolean;
  disabled?: boolean;
  label?: any;
}

function CustomDatePicker({
  label = "Date of Birth (MM/DD/YYYY)",
  DOB,
  onChange,
  errorMessage,
  disableFuture = false,
  disabled = false,
}: ICustomDatePickerProps) {
  return (
    <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
      <Typography variant="h5">{label}</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          reduceAnimations
          components={{
            OpenPickerIcon: DatePickerIcon,
          }}
          disabled={disabled}
          disableFuture={disableFuture}
          // label="Date of Birth (MM/DD/YYYY)"
          orientation="portrait"
          // renderInput={({ inputRef, inputProps, InputProps }) => {
          //   return (
          //     <Box
          //       sx={{
          //         display: "flex",
          //         alignItems: "center",
          //         padding: "13px 16px",
          //         backgroundColor: theme.palette.background.paper,
          //         borderRadius: "6px",
          //         border: `1px solid ${theme.palette.divider}`,
          //       }}
          //     >
          //       <input
          //         ref={inputRef}
          //         {...inputProps}
          //         placeholder="MM/DD/YYYY"
          //         style={{
          //           border: 0,
          //           outline: 0,
          //           padding: 0,
          //           fontSize: "14px",
          //           fontWeight: 500,
          //           lineHeight: "22px",
          //           color: theme.palette.text.primary,
          //           flexGrow: 1,
          //           letterSpacing: "0.5px",
          //         }}
          //         type="text"
          //       />
          //       {InputProps?.endAdornment}
          //     </Box>
          //   );
          // }}

          // @ts-ignore
          value={moment(DOB)}
          onChange={onChange}
        />
      </LocalizationProvider>
      {errorMessage ? (
        <Typography color="error" variant="caption">
          {errorMessage}
        </Typography>
      ) : null}
    </Stack>
  );
}

export default CustomDatePicker;
