import { Stack } from "@mui/material";
import Loader from "components/loader";
import useNavigateStep from "hooks/useNavigateStep";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { getPropertyDetails, PropertySteps } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";

import Property from "./components/Property";

type ReviewParams = {
  propertyId: string;
};

function PropertyEdit() {
  const { propertyId } = useParams<ReviewParams>();

  const { isLoading } = useAppSelector(s => s.property);

  const dispatch = useAppDispatch();

  const { navigateTo } = useNavigateStep();

  useEffect(() => {
    if (propertyId) {
      dispatch(getPropertyDetails(propertyId)).then(response => {
        if (response && !response.error) {
          if (parseInt(response.data.lastStep, 10) === PropertySteps.REVIEW) {
            navigateTo(PropertySteps.REVIEW);
          } else {
            navigateTo(parseInt(response.data.lastStep, 10) + 1);
          }
        }
      });
    }
  }, [dispatch, navigateTo, propertyId]);

  if (isLoading)
    return (
      <Stack alignItems="center" height={"100vh"} justifyContent="center">
        <Loader />
      </Stack>
    );

  return <Property />;
}

export default PropertyEdit;
