import Box from "@mui/material/Box";
import { useIsMobile } from "hooks/useIsMobile";
import { AuthFlowTypes, updateAuthStep } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import AuthHeader from "./AuthHeader";
import Activity from "./components/Activity";
import Email from "./components/Email";
import Guidelines from "./components/Guidelines";
import HearAboutUs from "./components/HearAboutUs";
import Information from "./components/Information";
import Password from "./components/Password";
import TermsAndConditions from "./components/Terms&Conditions";

const {
  EMAIL,
  PASSWORD,
  ACCOUNT_DETAILS,
  TNC,
  GUIDELINES,
  HEAR_ABOUT_US,
  ACTIVITY,
} = AuthFlowTypes;

function Auth() {
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile();

  const { authStep } = useAppSelector(s => s.auth);

  const handleNextStep = (s = "") => {
    switch (s) {
      case "step2":
        // if (
        //   [FlowTypes.MOBILE, FlowTypes.EMAIL].includes(
        //     userAuthData.flowType as FlowTypes,
        //   )
        // ) {
        //   dispatch(updateAuthStep(authStep + 4));
        // } else {
        dispatch(updateAuthStep(AuthFlowTypes.ACCOUNT_DETAILS));
        break;
      case "step3":
        dispatch(updateAuthStep(AuthFlowTypes.EMAIL));
        break;
      default:
        dispatch(updateAuthStep(authStep + 1));
    }
  };

  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "470px",
        maxWidth: "100%",
        backgroundColor: theme.palette.common.white,
      }}
    >
      <AuthHeader />
      <Box p={theme.spacing(3)}>
        {authStep === EMAIL && <Email handleNextStep={handleNextStep} />}
        {authStep === PASSWORD && <Password handleNextStep={handleNextStep} />}
        {authStep === ACCOUNT_DETAILS && (
          <Information handleNextStep={handleNextStep} />
        )}
        {authStep === TNC && (
          <TermsAndConditions handleNextStep={handleNextStep} />
        )}
        {authStep === GUIDELINES && <Guidelines />}
        {authStep === HEAR_ABOUT_US && (
          <HearAboutUs handleNextStep={handleNextStep} />
        )}
        {authStep === ACTIVITY && <Activity />}
      </Box>
    </Box>
  );
}

export default Auth;
