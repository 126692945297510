import { Grid, Stack } from "@mui/material";
import PropertyDetails from "components/PropertyDetails";
import PriceDetailsBox from "components/PropertyDetails/components/PriceDetailsBox";
import { useIsMobile } from "hooks/useIsMobile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PropertySteps,
  updateAreaActivity,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

import Footer from "../Footer";
import { RightMainComponentWrapper } from "../RightContainer";
import ImageGrid from "./../ImageGrid";

function Review() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isMobile = useIsMobile("midsm");

  const { propertyInfo, activityMediaArr, selectedAreaActivity } =
    useAppSelector(s => s.property);

  const [bookingDetails, setBookingDetails] = useState<string>();

  const onSubmit = async () => {
    if (propertyInfo.bookingDetails !== bookingDetails) {
      const response = await dispatch(
        updateAreaActivity(
          {
            bookingNotes: bookingDetails,
          },
          selectedAreaActivity?.documentId || "",
        ),
      );
      if (!response.error) {
        await dispatch(
          updatePropertyDetails(
            {
              lastStep:
                parseInt(propertyInfo.lastStep, 10) > PropertySteps.REVIEW
                  ? propertyInfo.lastStep
                  : PropertySteps.REVIEW.toString(),
            },
            propertyInfo?.documentId,
          ),
        );
        navigate("/host/listings");
      }
      return true;
    }

    navigate("/host/listings");
  };

  useEffect(() => {
    if (propertyInfo?.area?.[0]?.activities?.[0]?.areaActivityDate?.[0]) {
      setBookingDetails(propertyInfo.area[0].activities[0].bookingNotes);
    }
  }, [propertyInfo]);

  return (
    <>
      <RightMainComponentWrapper center={false}>
        <Stack
          spacing={theme.spacing(isMobile ? 3 : 4)}
          sx={{ boxSizing: "border-box" }}
          width="100%"
        >
          <ImageGrid isEdit activityMediaArr={activityMediaArr} />
          <Grid container spacing={{ xs: 0, md: 2 }}>
            <Grid item md={8} xs={12}>
              <PropertyDetails
                isEdit
                bookingDetails={bookingDetails}
                property={propertyInfo}
                setBookingDetails={setBookingDetails}
                showLocation={true}
                showRules={true}
              />
            </Grid>
            <Grid item md={4} pt={isMobile ? 3 : 0} xs={12}>
              <PriceDetailsBox
                isEdit
                hostReview={true}
                property={propertyInfo}
              />
            </Grid>
          </Grid>
        </Stack>
      </RightMainComponentWrapper>
      <Footer
        handleNext={onSubmit}
        primaryText={
          propertyInfo.bookingDetails !== bookingDetails ? "Update" : "Finish"
        }
        secondaryText="Back"
      />
    </>
  );
}

export default Review;
