const browserPath: any = {
  "/home": "Home",

  "search-listing": "Search Results",

  "/guest-booking": "Guest Booking",
  "/booking-review": "Booking Review",

  "/account/profile": "Profile",
  "/account/security": "Security",
  "/account/billing": "Billing",
  "/account/finance": "Finance",
  "/account": "Account",

  "/host/property/create": "Create Property",
  "/host/property/edit": "Edit Property",
  "/host/property/review": "Host Property Review",
  "/host/listings": "Host Listings",
  "/host/booking-review": "Host Booking Review",
  "/host": "Host Dashboard",
  "/reset-password": "Reset Password",
  "/property": "Property Info",
  "/": "Wing It",
};

export default browserPath;
