function IFramePOC() {
  return (
    <div style={{ height: "100vh" }}>
      <iframe
        height="100%"
        src="https://wingit.us/mastering-turkey-calling-everything-you-need-to-know/"
        style={{ height: "100%", width: "100%" }}
        width="100%"
      />
    </div>
  );
}

export default IFramePOC;
