import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import {
  checkEmail,
  IResponseData,
  ResponseCodes,
  sendEmail,
  updateEmailValidationFlags,
  updateUserAuthData,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { validateEmail } from "utils/validator";

import CustomInputField from "../../../components/TextField/CustomInputField";

interface IEmailProps {
  handleNextStep: (s?: string) => void;
}

interface IErrorState {
  resendMail: boolean;
  message: string;
}

const INITIAL_ERROR: IErrorState = { resendMail: false, message: "" };

function Email({ handleNextStep }: IEmailProps) {
  const dispatch = useAppDispatch();

  const { emailVerified, emailExists } = useAppSelector(s => s.account);
  const { loading: getProfileDetailsLoading } = useAppSelector(s => s.profile);
  const { loading, userAuthData, emailValidationFlags } = useAppSelector(
    s => s.auth,
  );
  const { email } = userAuthData;

  const {
    sendEmailForSignUp,
    sendEmailForVerify,
    sendVerifyEmailForPasswordSetup,
  } = emailValidationFlags;

  const [error, setError] = useState(INITIAL_ERROR);

  useEffect(() => {
    if ((!sendEmailForSignUp || !sendEmailForVerify) && error.resendMail) {
      setError(INITIAL_ERROR);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmailForVerify, sendEmailForSignUp]);

  const isFormValid = () => {
    return validateEmail(email);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value.length === 0 && error.resendMail) {
      setError(INITIAL_ERROR);
    }
    dispatch(updateUserAuthData({ ...userAuthData, [name]: value as string }));
  };

  const sendMail = async (exists: boolean, isEmailVerified: boolean) => {
    const response = (await sendEmail(email)) as IResponseData;
    if (response && !response.error) {
      if (error) setError({ resendMail: false, message: "" });
      if (!exists && !isEmailVerified) {
        emailValidationFlags.sendEmailForSignUp = true;
      } else {
        emailValidationFlags.sendEmailForVerify = true;
      }
      updateEmailValidationFlags(emailValidationFlags);
    } else if (response?.status === ResponseCodes.BAD_REQUEST) {
      setError({ resendMail: true, message: response.message });
    }
  };

  const handleCheckEmail = async () => {
    const response = await dispatch(checkEmail(email));
    if (response && !response?.error) {
      const { emailExists, isEmailVerified } = response.data;
      if (emailExists && isEmailVerified) {
        handleNextStep();
      } else {
        sendMail(emailExists, isEmailVerified);
      }
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isFormValid()) {
      e.preventDefault();
      handleCheckEmail();
    }
  };

  return (
    <Box>
      <Stack alignItems="center" direction="column" justifyContent="center">
        {sendEmailForSignUp ||
        sendEmailForVerify ||
        sendVerifyEmailForPasswordSetup ? (
          <Stack
            alignItems="center"
            direction="column"
            justifyContent="center"
            p={theme.spacing(3)}
            spacing={theme.spacing(3)}
          >
            <Typography component="p" sx={{ textAlign: "center" }} variant="h2">
              {sendEmailForSignUp &&
                "Please check your email for instructions to complete creating your account."}
              {sendEmailForVerify &&
                "We see you have an account. Please check your email to complete your sign in."}
              {sendVerifyEmailForPasswordSetup &&
                `We see that your email is un-verified. Please go to ${email} to verify your email address before we can proceed in creating a password for your account. This will ensure your email is correct and secure.`}
            </Typography>
            {error.resendMail ? (
              <Typography
                color={theme.palette.error.main}
                my={theme.spacing(2)}
                variant="caption"
              >
                {error.message}
              </Typography>
            ) : null}
            <Typography
              variant="body2"
              onClick={() => sendMail(emailExists, emailVerified)}
            >
              I did not receive an email.
            </Typography>
          </Stack>
        ) : (
          <>
            <Typography gutterBottom component="h3" variant="h3">
              Sign In / Sign Up
            </Typography>
            <Typography component="span" variant="h6">
              Enter your email below
            </Typography>
            <Box sx={{ my: theme.spacing(3), width: "100%" }}>
              <CustomInputField
                autoComplete="off"
                id="auth-email"
                label="Email"
                name="email"
                type="email"
                value={email}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
              />
            </Box>
            {error.resendMail ? (
              <Typography
                color={theme.palette.error.main}
                mb={theme.spacing(2)}
                variant="caption"
              >
                {error.message}
              </Typography>
            ) : null}
            <LoadingButton
              fullWidth
              disabled={!isFormValid()}
              loading={loading || getProfileDetailsLoading}
              sx={{ mb: theme.spacing(3) }}
              variant="contained"
              onClick={handleCheckEmail}
            >
              Next
            </LoadingButton>
          </>
        )}
      </Stack>
    </Box>
  );
}

export default Email;
