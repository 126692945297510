import { Box, Divider, Stack, Typography } from "@mui/material";
import { UsersIcon } from "helpers/images";
import useNavigateStep from "hooks/useNavigateStep";
import moment from "moment";
import { useEffect, useState } from "react";
import { IProperty, PropertySteps, setEditingProperty } from "store";
import { getAreaActivityPrice } from "store/booking/BookingAction";
import { IBookingDetails } from "store/booking/BookingInterface";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getFormattedDateRange } from "utils/utils";

import RenderEdit from "./RenderEdit";

interface IProps {
  isEdit?: boolean;
  property: IProperty;
  bookingDetails?: IBookingDetails;
  isBookingReview?: boolean;
  hostReview?: boolean;
}

function PriceDetailsBox(props: IProps) {
  const dispatch = useAppDispatch();
  const { property, isEdit, bookingDetails, isBookingReview, hostReview } =
    props;
  const activityArea = property.area?.[0].activities?.[0];
  const activityDates = activityArea?.areaActivityDate?.[0];
  const { navigateTo } = useNavigateStep();

  const updatePropertyStep = (step: PropertySteps) => {
    dispatch(setEditingProperty(true));
    navigateTo(step);
  };
  const [bookingDays, setBookingDays] = useState(0);
  const [initialBookingAmount, setInitialBookingAmount] = useState(0);

  const [finalBookingAmount, setFinalBookingAmount] = useState(0);
  const { areaActivityPrice } = useAppSelector(s => s.booking);
  const { price, wingitFee, taxes } = areaActivityPrice;

  useEffect(() => {
    if (bookingDetails) {
      const startDate = moment(bookingDetails?.bookingStartDate);
      const endDate = moment(bookingDetails?.bookingEndDate);
      setBookingDays(endDate.diff(startDate, "days") + 1);
    }
  }, [bookingDetails]);

  useEffect(() => {
    if (property?.area) {
      dispatch(
        getAreaActivityPrice(
          property.area[0].activities[0].documentId,
          property.state,
        ),
      );
    }
  }, [dispatch, property]);

  useEffect(() => {
    if (price) {
      const bookingAmount = bookingDays * price;
      setInitialBookingAmount(bookingAmount);
      const finalAmount =
        parseFloat(bookingAmount.toFixed(2)) +
        bookingDays * parseFloat(wingitFee.toFixed(2)) +
        bookingDays * parseFloat(taxes.toFixed(2));
      setFinalBookingAmount(
        parseFloat(parseFloat(finalAmount.toString()).toFixed(2)),
      );
    }
  }, [bookingDays, price, taxes, wingitFee]);

  return (
    <Box border="#898989 1px solid" borderRadius={5} px={3} py={2}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
      >
        <Typography>{isBookingReview ? "When" : "Availability"}</Typography>
        {isEdit ? (
          <RenderEdit
            onClick={() => updatePropertyStep(PropertySteps.ACTIVITY_DATES)}
          />
        ) : null}
      </Stack>

      <Box mt={1}>
        <Typography fontWeight="bold">
          {isBookingReview
            ? getFormattedDateRange(
                bookingDetails?.bookingStartDate?.toString() || "",
                bookingDetails?.bookingEndDate?.toString() || "",
              )
            : getFormattedDateRange(
                activityDates?.startDate?.toString() || "",
                activityDates?.endDate?.toString() || "",
              )}
        </Typography>
      </Box>

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>Activity</Typography>

        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography fontWeight="bold">{activityArea?.name}</Typography>
          {isEdit ? (
            <RenderEdit
              onClick={() => updatePropertyStep(PropertySteps.ACTIVITY_TYPE)}
            />
          ) : null}
        </Stack>
      </Stack>

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          <UsersIcon />
          <Typography>
            {isBookingReview ? "Guest Count" : "Guest Capacity"}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography fontWeight="bold">
            {isBookingReview
              ? bookingDetails?.participantCount
              : activityArea?.maxParticipants}
          </Typography>
          {isEdit ? (
            <RenderEdit
              onClick={() => updatePropertyStep(PropertySteps.ACTIVITY_COST)}
            />
          ) : null}
        </Stack>
      </Stack>

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        mt={2}
      >
        <Typography>Cost per day</Typography>

        <Stack alignItems="center" direction="row" spacing={2}>
          <Typography fontWeight="bold">
            ${activityArea?.originalPrice?.toFixed(2)}
          </Typography>
          {isEdit ? (
            <RenderEdit
              onClick={() => updatePropertyStep(PropertySteps.ACTIVITY_COST)}
            />
          ) : null}
        </Stack>
      </Stack>

      {hostReview && (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>Listing fee 15%</Typography>

            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography fontWeight="bold">
                ${activityArea?.markUpPrice?.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>Total per day</Typography>

            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography fontWeight="bold">
                ${activityArea?.price?.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </>
      )}

      {isBookingReview && (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>Number of days</Typography>

            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography fontWeight="bold">{bookingDays}</Typography>
            </Stack>
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>Amount</Typography>
            <Typography fontWeight="bold">
              ${initialBookingAmount.toFixed(2)}
            </Typography>
          </Stack>
        </>
      )}

      <Divider style={{ marginTop: 20 }} />

      {isBookingReview && (
        <>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>Wing It fee</Typography>
            <Typography fontWeight="bold">
              ${(parseFloat(wingitFee?.toFixed(2)) * bookingDays)?.toFixed(2)}
            </Typography>
          </Stack>

          {/* <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>
              Taxes (${taxes?.toFixed(2)} * {bookingDays})
            </Typography>
            <Typography fontWeight="bold">
              ${(parseFloat(taxes?.toFixed(2)) * bookingDays)?.toFixed(2)}
            </Typography>
          </Stack> */}
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            mt={2}
          >
            <Typography>Total</Typography>
            <Typography fontWeight="bold">
              ${finalBookingAmount.toFixed(2)}
            </Typography>
          </Stack>
        </>
      )}
    </Box>
  );
}

export default PriceDetailsBox;
