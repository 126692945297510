import { Reducer } from "redux";

import {
  FinanceActions,
  IACHPaymentAccountDetails,
  IFinanceState,
} from "./FinanceInterface";
import FinanceTypes from "./FinanceTypes";

const initialState: IFinanceState = {
  loading: false,
  isUpdateLoading: false,

  achPaymentAccountDetails: {} as IACHPaymentAccountDetails,
};

const FinanceReducer: Reducer<IFinanceState, FinanceActions> = (
  state = initialState,
  action = {} as FinanceActions,
) => {
  switch (action.type) {
    case FinanceTypes.ADD_ACH_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FinanceTypes.ADD_ACH_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        achPaymentAccountDetails: { ...action.payload },
      };
    }
    case FinanceTypes.ADD_ACH_PAYMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case FinanceTypes.GET_ACH_PAYMENT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case FinanceTypes.GET_ACH_PAYMENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        achPaymentAccountDetails: { ...action.payload },
      };
    }
    case FinanceTypes.GET_ACH_PAYMENT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case FinanceTypes.UPDATE_ACH_PAYMENT_REQUEST: {
      return {
        ...state,
        isUpdateLoading: true,
      };
    }
    case FinanceTypes.UPDATE_ACH_PAYMENT_SUCCESS: {
      return {
        ...state,
        isUpdateLoading: false,
        achPaymentAccountDetails: { ...action.payload },
      };
    }
    case FinanceTypes.UPDATE_ACH_PAYMENT_ERROR: {
      return {
        ...state,
        isUpdateLoading: false,
      };
    }
    case FinanceTypes.DELETE_ACH_PAYMENT_REQUEST: {
      return {
        ...state,
        isUpdateLoading: true,
      };
    }
    case FinanceTypes.DELETE_ACH_PAYMENT_SUCCESS: {
      return {
        ...state,
        isUpdateLoading: false,
        achPaymentAccountDetails: {} as IACHPaymentAccountDetails,
      };
    }
    case FinanceTypes.DELETE_ACH_PAYMENT_ERROR: {
      return {
        ...state,
        isUpdateLoading: false,
      };
    }
    default:
      return state;
  }
};

export default FinanceReducer;
