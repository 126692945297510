/* eslint-disable @typescript-eslint/no-explicit-any */

import { baseUIUrl } from "helpers/urls";
import moment from "moment";

// Utility Functions
export const getCookieItem = (cName: string) => {
  const match = document.cookie.match(new RegExp(`(^| )${cName}=([^;]+)`));
  if (match) return match[2];
  return "";
};

export const removeCookieItem = (cName: string) => {
  document.cookie = `${cName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const getTokenExpiry = () => {
  return new Date(2147483647 * 1000).toUTCString();
};

export const setCookieItem = (
  cName: string,
  cValue: string,
  expDays = true,
) => {
  const cookieDetails = `${cName}=${cValue};`;
  if (expDays) {
    const expires = `expires=${getTokenExpiry()};`;
    document.cookie = `${cookieDetails} ${expires} path=/`;
  } else {
    document.cookie = `${cookieDetails} path=/`;
  }
};

export const handleMaskToUSNumber = (value: string): string => {
  if (value) {
    const n = value.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    let maskedNumber = "";
    if (n) {
      maskedNumber = !n[2]
        ? n[1]
        : `(${n[1]}) ${n[2]}${n[3] ? `-${n[3]}` : ""}`;
    }
    return maskedNumber;
  }
  return value;
};

export const handleFormatToUSNumber = (number: string) => {
  const num = number.match(/(\d{3})(\d{3})(\d{4})/);
  const numberFormatted = num ? `(${num[1]}) ${num[2]}-${num[3]}` : "";
  return numberFormatted;
};

export const unmaskUSNumber = (number: string) => {
  return number.replace(/[()\s-]/g, "");
};

export const isNumberKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
  const keyPressed = parseInt(e.key, 10);
  const isNumber = keyPressed >= 0 && keyPressed <= 9;
  if (!isNumber) e.preventDefault();
};

export const getRoundedCanvas = (sourceCanvas: any) => {
  const canvas = document.createElement("canvas");
  const context: any = canvas.getContext("2d");
  const { width } = sourceCanvas;
  const { height } = sourceCanvas;

  canvas.width = width;
  canvas.height = height;
  context.imageSmoothingEnabled = true;
  context.drawImage(sourceCanvas, 0, 0, width, height);
  context.globalCompositeOperation = "destination-in";
  context.beginPath();
  context.arc(
    width / 2,
    height / 2,
    Math.min(width, height) / 2,
    0,
    2 * Math.PI,
    true,
  );
  context.fill();
  return canvas;
};

export const dataURItoBlob = (dataURI: string) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    // byteString = Buffer.from(dataURI.split(",")[1], "base64").toString();
    byteString = window.atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const getPhoneNumberWithoutCountryCode = (phoneNumber: string) => {
  if (phoneNumber.startsWith("+91")) {
    return phoneNumber.substring(3);
  }
  if (phoneNumber.startsWith("+1")) {
    return phoneNumber.substring(2);
  }
  return phoneNumber;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const redirectToMobileApp = () => {
  const app = window.location.href.replace(baseUIUrl, "wingit:/");

  if (/Android|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    window.location = app as unknown as Location;
  }
};

export const handleRestrictSpaces = (
  value: string,
  customRegexValidation?: RegExp,
) => {
  return value !== " "
    ? value.replace(customRegexValidation as RegExp, "").replace(/\s{2,}/g, " ")
    : value.trim();
};

// export function getFormattedDateRange(
//   selectDate: ISelectDate[],
//   placeholder?: string,
// ) {
//   if (!selectDate?.[0].startDate) return placeholder || "";

//   const startDate = moment(selectDate?.[0].startDate);
//   const endDate = moment(selectDate?.[0].endDate);

//   if (startDate.isSame(endDate, "d")) {
//     return startDate.format("MMM D, YYYY");
//   }

//   if (startDate.isSame(endDate, "M")) {
//     return `${startDate.format("MMM D")} - ${endDate.format("D, YYYY")}`;
//   }

//   if (startDate.isSame(endDate, "y")) {
//     return `${startDate.format("MMM D")} - ${endDate.format("MMM D, YYYY")}`;
//   }

//   return `${startDate.format("MMM D, YYYY")} - ${endDate.format(
//     "MMM D, YYYY",
//   )}`;
// }

export function getFormattedDateRange(
  sDate: Date | string | undefined,
  eDate: Date | string | undefined,
) {
  if (!sDate) {
    return "";
  }
  const startDate = moment(sDate);
  const endDate = moment(eDate);

  if (startDate.isSame(endDate, "d") || eDate === undefined) {
    return startDate.format("MMM D, YYYY");
  }

  if (startDate.isSame(endDate, "M")) {
    return `${startDate.format("MMM D")} - ${endDate.format("D, YYYY")}`;
  }

  if (startDate.isSame(endDate, "y")) {
    return `${startDate.format("MMM D")} - ${endDate.format("MMM D, YYYY")}`;
  }

  return `${startDate.format("MMM D, YYYY")} - ${endDate.format(
    "MMM D, YYYY",
  )}`;
}

export const handleAllowOnlyNumber = (
  event: React.KeyboardEvent<HTMLInputElement>,
) => {
  isNumberKey(event);
};
export const handleAllowOnlyOneSpaceBetweenWordsAndAlphabets = (
  value: string,
) => {
  return value !== " "
    ? value
        .replace(/[0-9]/, "")
        .replace(/\s{2,}/g, " ")
        .replace(/^\s/g, "")
    : value.trim();
};

export const getMapZoomPosition = (area: number) => {
  if (area < 50) {
    return 16;
  } else if (area < 150) {
    return 15;
  } else if (area < 300) {
    return 14;
  } else if (area < 800) {
    return 11;
  } else {
    return 12;
  }
};

export const formatPrice = (price: number | string) => {
  price = price.toString();
  const dec = price.split(".")[1];
  if (dec == undefined) return price;
  else return Number(price).toFixed(2);
};
