import { Reducer } from "redux";

import { BillingActions, IBillingState, ICardData } from "./BillingInterface";
import BillingType from "./BillingTypes";

const initialState: IBillingState = {
  loading: false,
  isDeleteCardLoading: false,
  showForm: false,
  formType: undefined,
  cards: [] as ICardData[],
  activeEditCardIndex: 0,
  defaultCardIndex: 0,
};

const BillingReducer: Reducer<IBillingState, BillingActions> = (
  state = initialState,
  action = {} as BillingActions,
) => {
  switch (action.type) {
    case BillingType.GET_CARDS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BillingType.GET_CARDS_SUCCESS: {
      const index = action.payload.findIndex(
        (card: ICardData) => card.isDefault,
      );
      return {
        ...state,
        cards: [...action.payload],
        defaultCardIndex: index !== -1 ? index : 0,
        loading: false,
      };
    }
    case BillingType.GET_CARDS_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BillingType.SHOW_CARD_FORM: {
      const { showForm, formType } = action.payload;
      return {
        ...state,
        showForm,
        formType,
      };
    }
    case BillingType.ADD_CARD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BillingType.ADD_CARD_SUCCESS: {
      const cards = [...state.cards];
      if (action.payload.isDefault) {
        if (cards.length > 0) {
          cards[state.defaultCardIndex].isDefault = false;
          cards.sort(
            (a, b) =>
              new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
          );
        }
        cards.unshift(action.payload);
      } else {
        cards.splice(1, 0, action.payload);
      }

      return {
        ...state,
        loading: false,
        cards,
      };
    }
    case BillingType.ADD_CARD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BillingType.SET_EDIT_CARD_ACTIVE: {
      return {
        ...state,
        activeEditCardIndex: action.payload,
      };
    }
    case BillingType.EDIT_CARD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case BillingType.EDIT_CARD_SUCCESS: {
      const cards = [...state.cards];
      const selectedIndex = cards.findIndex(
        (card: ICardData) => card.documentId === action.payload.documentId,
      );

      if (action.payload.isDefault) {
        cards[state.defaultCardIndex].isDefault = false;
        if (selectedIndex !== -1) {
          cards.splice(selectedIndex, 1);
          cards.sort(
            (a, b) =>
              new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf(),
          );
          cards.unshift(action.payload);
        }
      } else if (selectedIndex !== -1) cards[selectedIndex] = action.payload;

      return {
        ...state,
        loading: false,
        cards,
      };
    }
    case BillingType.EDIT_CARD_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case BillingType.DELETE_CARD_REQUEST: {
      return {
        ...state,
        isDeleteCardLoading: true,
      };
    }
    case BillingType.DELETE_CARD_SUCCESS: {
      const cards = [...state.cards];
      const selectedIndex = cards.findIndex(
        (card: ICardData) => card.documentId === action.payload,
      );
      cards.splice(selectedIndex, 1);

      return {
        ...state,
        isDeleteCardLoading: false,
        cards,
      };
    }
    case BillingType.DELETE_CARD_ERROR: {
      return {
        ...state,
        isDeleteCardLoading: false,
      };
    }
    case BillingType.RESET_BILLING_STATE: {
      return {
        ...state,
        showForm: false,
        formType: undefined,
        cards: [] as ICardData[],
        activeEditCardIndex: 0,
      };
    }
    default:
      return state;
  }
};

export default BillingReducer;
