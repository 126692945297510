import React from "react";

interface IErrorState {
  hasError: boolean;
}
interface IErrorProps {
  children: JSX.Element;
}

export default class ErrorBoundary extends React.Component<
  IErrorProps,
  IErrorState
> {
  constructor(props: IErrorProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <h3>Something went wrong.</h3>;
    }
    return children;
  }
}
