import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useIsMobile } from "hooks/useIsMobile";
import React from "react";
import { deleteCard, IResponseData, showCardForm } from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";

interface IProps {
  onClose: () => void;
}

function ModalText({ text, ismobile }: { text: string; ismobile: number }) {
  return (
    <Typography
      component="span"
      sx={{ px: theme.spacing(ismobile ? 0 : 6) }}
      variant="h6"
    >
      {text}
    </Typography>
  );
}

function ModalButton({ onClose }: { onClose: () => void }) {
  return (
    <Button
      disableRipple
      disableTouchRipple
      fullWidth
      sx={{
        backgroundColor: theme.palette.text.primary,
        color: theme.palette.common.white,
        "&:hover": {
          backgroundColor: theme.palette.text.primary,
          color: theme.palette.common.white,
        },
      }}
      variant="outlined"
      onClick={onClose}
    >
      Got it!
    </Button>
  );
}

function RemoveCardModal({ onClose }: IProps) {
  const dispatch = useAppDispatch();
  const { isDeleteCardLoading, cards, activeEditCardIndex } = useAppSelector(
    s => s.billing,
  );
  const card = cards[activeEditCardIndex];
  const isMobile = useIsMobile();

  const handleDeleteCard = async () => {
    const response = (await dispatch(
      deleteCard(cards[activeEditCardIndex].documentId),
    )) as IResponseData;
    if (response && typeof response.error !== "undefined" && !response.error) {
      onClose?.();
      dispatch(showCardForm(false, undefined));
    }
  };

  return (
    <Box
      sx={{
        width: "inherit",
        height: "100%",
        mt: theme.spacing(isMobile ? 2 : 0),
      }}
    >
      <Stack
        alignItems="center"
        height="inherit"
        justifyContent="space-between"
        spacing={theme.spacing(3)}
        width="inherit"
      >
        {cards.length === 1 && (
          <>
            <ModalText
              ismobile={+isMobile}
              text="Your account must have a card on file."
            />
            <ModalButton onClose={onClose} />
          </>
        )}
        {cards.length > 1 && card?.isDefault && (
          <>
            <ModalText
              ismobile={+isMobile}
              text="This card is set to default. In order to remove this card, please assign a new default card."
            />
            <ModalButton onClose={onClose} />
          </>
        )}
        {cards.length !== 1 && !card?.isDefault && (
          <>
            <ModalText
              ismobile={+isMobile}
              text="Are you sure you want to remove this credit card from your
              account?"
            />
            <Stack
              direction={isMobile ? "column-reverse" : "column"}
              spacing={theme.spacing(3)}
              width="100%"
            >
              <LoadingButton
                disableRipple
                disableTouchRipple
                fullWidth
                loading={isDeleteCardLoading}
                sx={{
                  backgroundColor: theme.palette.text.primary,
                  color: theme.palette.common.white,
                  "&:hover": {
                    backgroundColor: theme.palette.text.primary,
                    color: theme.palette.common.white,
                  },
                }}
                variant="outlined"
                onClick={handleDeleteCard}
              >
                Remove card
              </LoadingButton>
              {isMobile && (
                <Button
                  disableRipple
                  disableTouchRipple
                  fullWidth
                  sx={{
                    borderColor: theme.palette.text.primary,
                    "&:hover": {
                      backgroundColor: theme.palette.text.primary,
                      color: theme.palette.common.white,
                    },
                  }}
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </Button>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Box>
  );
}

export default RemoveCardModal;
