import { Divider, SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { AddIcon } from "helpers/images";
import { useIsMobile } from "hooks/useIsMobile";
import useNavigateStep from "hooks/useNavigateStep";
import moment from "moment";
import { useEffect } from "react";
import {
  deleteSelectedActivityDate,
  PropertySteps,
  setEditActiveIndex,
  showDateRangeCalender,
  updatePropertyDetails,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { scrollToTop } from "utils/utils";

import Footer from "../../Footer";
import { RightMainComponentWrapper } from "../../RightContainer";

function ListDateRange() {
  const dispatch = useAppDispatch();
  const { navigateTo } = useNavigateStep();
  const isMobile = useIsMobile("midsm");
  const {
    activityDates,
    editActive,
    isPropertyEditing,
    selectedAreaActivity,
    propertyInfo,
  } = useAppSelector(s => s.property);
  useEffect(() => {
    scrollToTop();
  }, [activityDates?.length]);

  const handleShowDateRangeCalender = () => {
    dispatch(showDateRangeCalender(true));
  };

  const handleNext = async () => {
    if (isPropertyEditing) {
      navigateTo(PropertySteps.REVIEW);
    } else {
      await dispatch(
        updatePropertyDetails(
          {
            lastStep:
              parseInt(propertyInfo.lastStep, 10) > PropertySteps.ACTIVITY_DATES
                ? propertyInfo.lastStep
                : PropertySteps.ACTIVITY_DATES.toString(),
          },
          propertyInfo?.documentId,
        ),
      );
      navigateTo(PropertySteps.ACTIVITY_PHOTOS);
    }
  };

  const handleDelete = (dateId: string) => {
    dispatch(
      deleteSelectedActivityDate(
        dateId,
        selectedAreaActivity?.documentId || "",
      ),
    );
  };

  const handleEdit = (index: number) => {
    if (!editActive) {
      dispatch(setEditActiveIndex(true, index));
      handleShowDateRangeCalender();
    }
  };

  const dateBoxStyles = {
    height: "20px",
    border: "1px solid #898989",
    borderRadius: "4px",
    padding: "5px",
    fontSize: "13px",
    textAlign: "center",
    whiteSpace: "nowrap",
  };

  return (
    <>
      <RightMainComponentWrapper center={false}>
        <Stack
          spacing={theme.spacing(2)}
          sx={{
            boxSizing: "border-box",
            mt: theme.spacing(isMobile ? 0 : 5),
            px: theme.spacing(isMobile ? 0 : 3),
          }}
          width="100%"
        >
          <Stack spacing={theme.spacing(2)}>
            <Typography
              component="div"
              sx={{ fontWeight: 700 }}
              variant="subtitle3"
            >
              Available Dates
            </Typography>
            <Divider />
            <>
              <Stack spacing={theme.spacing(2)}>
                {activityDates.map((date, index) => (
                  <Stack key={date.name} spacing={theme.spacing(2)}>
                    <Typography
                      component="div"
                      sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                      variant="h6"
                    >
                      {date.name}
                    </Typography>
                    <Stack
                      alignItems="center"
                      direction="row"
                      justifyContent="space-between"
                      spacing={theme.spacing(isMobile ? 3 : 5)}
                    >
                      <Stack
                        direction="row"
                        flexGrow={1}
                        spacing={theme.spacing(isMobile ? 2 : 3)}
                        width="100%"
                      >
                        <Box sx={dateBoxStyles} width="50%">
                          {moment(date?.startDate).format("ll")}
                        </Box>
                        <Box sx={dateBoxStyles} width="50%">
                          {moment(date?.endDate).format("ll")}
                        </Box>
                      </Stack>
                      <Stack
                        direction="row"
                        spacing={theme.spacing(isMobile ? 2 : 3)}
                      >
                        <Typography
                          className="text-ul-offset_1"
                          component="div"
                          variant="h6"
                          onClick={() => handleEdit(index)}
                        >
                          Edit
                        </Typography>
                        <Typography
                          className="text-ul-offset_1"
                          component="div"
                          variant="h6"
                          onClick={() => handleDelete(date.documentId!)}
                        >
                          Delete
                        </Typography>
                      </Stack>
                    </Stack>
                    <Divider />
                  </Stack>
                ))}
              </Stack>
              <Stack
                alignItems="center"
                className="text-ul-offset_1_hover"
                direction="row"
                justifyContent="flex-start"
                spacing={theme.spacing(1)}
                sx={{
                  cursor: "pointer",
                  marginTop:
                    activityDates.length > 0
                      ? "auto"
                      : `${theme.spacing(0)} !important`,
                }}
                onClick={handleShowDateRangeCalender}
              >
                <SvgIcon
                  inheritViewBox
                  component={AddIcon}
                  sx={{
                    height: "10px",
                    width: "10px",
                    stroke: theme.palette.text.secondary,
                  }}
                />
                <Typography
                  component="div"
                  sx={{ color: theme.palette.text.secondary }}
                  variant="h6"
                >
                  New
                </Typography>
              </Stack>
              <Divider />
            </>
          </Stack>
        </Stack>
      </RightMainComponentWrapper>
      <Footer
        disabled={!(activityDates.length > 0)}
        handleNext={handleNext}
        primaryText={isPropertyEditing ? "Save" : "Next"}
        secondaryText="Back"
      />
    </>
  );
}

export default ListDateRange;
