import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";
import theme from "theme";

interface ICustomInputFieldProps extends InputBaseProps {
  label?: string | ReactNode;
  errormessage?: string;
}

const CustomInputField = styled((props: ICustomInputFieldProps) => (
  <Stack direction="column" spacing={0.5} sx={{ width: "100%" }}>
    <Typography
      color={props.error ? theme.palette.error.main : ""}
      component="span"
      variant="h5"
    >
      {props.label}
    </Typography>
    <InputBase {...props} />
    {props.error ? (
      <Typography color={theme.palette.error.main} variant="caption">
        {props.errormessage}
      </Typography>
    ) : null}
  </Stack>
))(({ endAdornment, theme, error }) => ({
  "&.MuiInputBase-root": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "22px",
    padding: endAdornment ? "13px 10px 13px 16px" : "13px 16px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "6px",
    border: `1px solid ${theme.palette.divider}`,
    "& .MuiInputBase-input": {
      padding: 0,
    },
    "& .MuiInputAdornment-root": {
      "& .MuiButtonBase-root": {
        padding: "10px 8px",
      },
    },
  },
  "&.Mui-error": {
    border: `1px solid ${theme.palette.error.main}`,
  },
  "&.Mui-focused": {
    border: !error ? `1px solid ${theme.palette.text.primary}` : "",
  },
  "&.MuiInputBase-disabled": {
    backgroundColor: "#e9ecef",
    opacity: 0.65,
  },
}));

export default CustomInputField;
