/* eslint-disable no-unsafe-optional-chaining */
// eslint-disable-next-line import/no-extraneous-dependencies
import "cropperjs/dist/cropper.css";

import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ModalContainer from "components/Modal/ModalContainer";
import { useEffect, useRef, useState } from "react";
import { Cropper } from "react-cropper";
import theme from "theme";

interface IImageData {
  minZoom: number;
  zoom: number;
  isReady: boolean;
}

type ICropperProps = {
  title: string;
  imageType: string;
  previewImage: string;
  image?: string;
  initialAspectRatio: any;
  aspectRatio: any;
  enableZoom: boolean;
  header: boolean;
  footer: boolean;
  handleCancelUploadImage: () => void;
  selectFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleApply: (cropperData: any, minZoom: number, zoom: number) => void;
  loading?: boolean;
  croppedImageData?: any;
  styles?: any;
};

function ImageCropper(props: ICropperProps) {
  const {
    title,
    imageType,
    previewImage,
    image,
    initialAspectRatio,
    aspectRatio,
    enableZoom,
    header,
    footer,
    handleCancelUploadImage,
    selectFile,
    handleApply,
    loading,
    croppedImageData,
    styles = {},
  } = props;
  const cropperRef = useRef<HTMLImageElement>(null);
  const [imageData, setImageData] = useState<IImageData>({
    minZoom: 0,
    zoom: 0,
    isReady: false,
  });

  const handleRange = (event: any) => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.zoomTo(event.target.value / 10);
    setImageData({ ...imageData, zoom: event.target.value });
  };

  const apply = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    handleApply(cropper, imageData.minZoom, imageData.zoom);
  };

  useEffect(() => {
    setImageData({ ...imageData, isReady: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewImage]);

  useEffect(() => {
    if (imageData.isReady) {
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      if (previewImage) {
        const data = cropper?.getImageData();
        const zoomValue = Math.floor(
          (Math.floor((data?.width / data?.naturalWidth) * 10) +
            (Math.floor((data?.width / data?.naturalWidth) * 10) + 10)) /
            2,
        );
        cropper.zoomTo(zoomValue / 10);
        setImageData({
          ...imageData,
          minZoom: Math.floor((data?.width / data?.naturalWidth) * 10),
          zoom: zoomValue,
        });
      } else if (image) {
        cropper.setData(croppedImageData?.data);
        cropper.setCropBoxData(croppedImageData?.cropBoxData);
        cropper.setCanvasData(croppedImageData?.canvasData);
        setImageData({
          ...imageData,
          zoom: croppedImageData?.zoom,
          minZoom: croppedImageData?.minZoom,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageData.isReady]);

  const handleZoom = (zoomValue: number) => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    cropper.zoomTo(zoomValue / 10);
    setImageData({ ...imageData, zoom: zoomValue });
  };

  return (
    <>
      <ModalContainer open={header}>
        <Grid container direction="column">
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            sx={{ height: "60px", mx: 1.5 }}
          >
            <Typography component="h5" sx={{ fontSize: "16px" }} variant="h3">
              {title}
            </Typography>
            <Box>
              <IconButton onClick={handleCancelUploadImage}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Stack>
          <Cropper
            ref={cropperRef}
            cropBoxResizable
            modal
            aspectRatio={aspectRatio}
            autoCropArea={1}
            background={false}
            center={false}
            className={imageType === "profile" ? "rounded" : "rectangle"}
            cropBoxMovable={imageType === "profile"}
            crossOrigin="anonymous"
            dragMode="move"
            guides={false}
            initialAspectRatio={initialAspectRatio}
            minCropBoxHeight={100}
            minCropBoxWidth={100}
            ready={() => {
              setImageData({ ...imageData, isReady: true });
            }}
            restore={false}
            src={previewImage || image}
            style={styles}
            toggleDragModeOnDblclick={false}
            viewMode={1}
            zoomOnTouch={false}
            zoomOnWheel={false}
            autoCrop
            // Cropper js options
            checkCrossOrigin={false}
          />
          {enableZoom && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              spacing={2}
              sx={{ mb: 1, height: "60px" }}
            >
              <IconButton
                disabled={
                  !imageData.isReady || imageData.zoom <= imageData.minZoom
                }
                onClick={() => {
                  // eslint-disable-next-line no-plusplus
                  handleZoom(--imageData.zoom);
                }}
              >
                <ZoomOutIcon aria-label="Zoom-Out" fontSize="medium" />
              </IconButton>
              <Slider
                aria-label="zoom"
                max={imageData.minZoom + 10}
                min={imageData.minZoom}
                value={imageData.zoom}
                onChange={handleRange}
              />
              <IconButton
                disabled={
                  !imageData.isReady || imageData.zoom >= imageData.minZoom + 10
                }
                onClick={() => {
                  // eslint-disable-next-line no-plusplus
                  handleZoom(++imageData.zoom);
                }}
              >
                <ZoomInIcon aria-label="Zoom-Out" fontSize="medium" />
              </IconButton>
            </Stack>
          )}
          {footer && (
            <>
              <Divider sx={{ width: "100%" }} />
              <Stack
                alignItems="center"
                className="border-top height-76"
                direction="row"
                justifyContent="space-between"
                sx={{ p: 1.5 }}
              >
                <Button
                  sx={{ px: theme.spacing(3.25), py: theme.spacing(1.5) }}
                  onClick={handleCancelUploadImage}
                >
                  Cancel
                </Button>
                <Stack alignItems="center" direction="row" spacing={2}>
                  <Button
                    sx={{
                      px: theme.spacing(3.25),
                      py: theme.spacing(1),
                    }}
                    variant="outlined"
                  >
                    <label
                      htmlFor="btn-upload-cover"
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        accept=".png,.jpeg,.jpg"
                        className="d-none"
                        disabled={loading}
                        id="btn-upload-cover"
                        name="profile-avatar"
                        style={{ display: "none" }}
                        type="file"
                        onChange={selectFile}
                        onClick={(fileEvent: any) => {
                          // eslint-disable-next-line no-param-reassign
                          fileEvent.target.value = null;
                        }}
                      />
                      Change Image
                    </label>
                  </Button>
                  <LoadingButton
                    disabled={loading || !imageData.isReady}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    sx={{
                      px: theme.spacing(5),
                      py: theme.spacing(1),
                    }}
                    variant="contained"
                    onClick={apply}
                  >
                    Apply
                  </LoadingButton>
                </Stack>
              </Stack>
            </>
          )}
        </Grid>
      </ModalContainer>
    </>
  );
}

export default ImageCropper;
