import { Reducer } from "redux";

import {
  IProfileState,
  IUserProfileData,
  ProfileActions,
} from "./ProfileInterface";
import ProfileType from "./ProfileTypes";

const initialState: IProfileState = {
  loading: false,
  userProfileData: {} as IUserProfileData,
  isProfileImageUploading: false,
};

const ProfileReducer: Reducer<IProfileState, ProfileActions> = (
  state = initialState,
  action = {} as ProfileActions,
) => {
  switch (action.type) {
    case ProfileType.FETCH_USER_PROFILE_INFO_REQUEST: {
      return {
        ...state,
        userProfileData: {} as IUserProfileData,
        loading: true,
      };
    }
    case ProfileType.FETCH_USER_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        userProfileData: action.payload,
      };
    }
    case ProfileType.FETCH_USER_PROFILE_INFO_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ProfileType.UPDATE_USER_PROFILE_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ProfileType.UPDATE_USER_PROFILE_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        userProfileData: { ...state.userProfileData, ...action.payload },
      };
    }
    case ProfileType.UPDATE_USER_PROFILE_INFO_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ProfileType.UPDATE_USER_EMERGENCY_CONTACT_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case ProfileType.UPDATE_USER_EMERGENCY_CONTACT_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        userProfileData: {
          ...state.userProfileData,
          emergencyContacts: [...[], action.payload],
        },
      };
    }
    case ProfileType.UPDATE_USER_EMERGENCY_CONTACT_INFO_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ProfileType.UPLOAD_PROFILE_AVATAR_REQUEST: {
      return {
        ...state,
        isProfileImageUploading: true,
      };
    }
    case ProfileType.UPLOAD_PROFILE_AVATAR_SUCCESS: {
      return {
        ...state,
        isProfileImageUploading: false,
        userProfileData: {
          ...state.userProfileData,
          originalImageReadUrl: action.payload.originalImageReadUrl,
          croppedImageReadUrl: action.payload.croppedImageReadUrl,
          croppedImageDetails: action.payload.croppedImageDetails,
        },
      };
    }
    case ProfileType.UPLOAD_PROFILE_AVATAR_ERROR: {
      return {
        ...state,
        isProfileImageUploading: false,
      };
    }
    case ProfileType.UPDATE_USER_PROFILE_STORE_DATA: {
      return {
        ...state,
        userProfileData: action.payload,
      };
    }
    case ProfileType.REMOVE_SOCIAL_ACCOUNT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case ProfileType.REMOVE_SOCIAL_ACCOUNT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    case ProfileType.ADD_SOCIAL_ACCOUNT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case ProfileType.ADD_SOCIAL_ACCOUNT_ERROR: {
      return {
        ...state,
        loading: false,
      };
    }
    default:
      return state;
  }
};

export default ProfileReducer;
