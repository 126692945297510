import { Box, Breakpoint } from "@mui/material";
import ModalContainer from "components/Modal/ModalContainer";
import { useIsMobile } from "hooks/useIsMobile";
import React, { useEffect } from "react";
import theme from "theme";

import BottomDrawer from "./CustomBottomDrawer";

interface IProps {
  openDrawer: boolean;
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode | JSX.Element;
  breakpoint?: Breakpoint;
  header?: React.ReactNode | JSX.Element;
}

function TogglePopupComponent(props: IProps) {
  const {
    openDrawer,
    setDrawer,
    openModal,
    setModal,
    children,
    header = null,
    breakpoint = "sm",
  } = props;

  const isMobile = useIsMobile(breakpoint);

  useEffect(() => {
    if (isMobile && openModal) {
      setDrawer(true);
      setModal(false);
    } else if (!isMobile && openDrawer) {
      setDrawer(false);
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      {isMobile ? (
        <BottomDrawer
          withPuller
          open={openDrawer}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
        >
          {children}
        </BottomDrawer>
      ) : (
        <ModalContainer open={openModal}>
          <Box
            sx={{
              width: "470px",
              maxWidth: "100%",
              backgroundColor: theme.palette.common.white,
            }}
          >
            {header}
            <Box p={theme.spacing(3)}>{children}</Box>
          </Box>
        </ModalContainer>
      )}
    </>
  );
}

export default TogglePopupComponent;
