import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const authRoutes = [
  "/activate-account",
  "/forgot-password",
  "/forgot-password-success",
  "/login",
  "/reset-password",
  "/signup",
  "/verification-mail",
  "/activate-account",
  "users/exists",
  "verify/email",
  "verify/emailToken",
  "resetPassword",
];

export default function useAuthRouteCheck() {
  const location = useLocation();

  const isAuthRoute = useMemo(() => {
    return authRoutes.some(r => location.pathname.includes(r));
  }, [location.pathname]);

  return isAuthRoute;
}
