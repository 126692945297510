import { Box, Typography } from "@mui/material";
import ModalContainer from "components/Modal/ModalContainer";
import theme from "theme";

interface IBookingModalSuccessProps {
  open: boolean;
  modalStatusChange: () => void;
}

const TermsAndConditionsModal = ({
  open,
  modalStatusChange,
}: IBookingModalSuccessProps) => {
  return (
    <ModalContainer
      showCloseIcon
      fullWidth={true}
      handleClose={() => {
        modalStatusChange();
      }}
      maxWidth="lg"
      open={open}
      showHeader={true}
    >
      <Box
        sx={{
          maxWidth: "100%",
          backgroundColor: theme.palette.common.white,
        }}
      >
        <Box p={theme.spacing(3)}>
          <Box>
            <Typography
              component={"p"}
              sx={{
                marginBottom: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              TERMS AND CONDITIONS OF SERVICE
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              These Terms are a binding legal agreement between you and Wing It
              that govern your use of the Wing It Platform. The Wing It Platform
              offers an online venue that enables Users to publish, offer, and
              search for Activities. To use the Platform, you must register an
              account to access and use the features available on the Wing It
              Platform, and you must keep your information accurate and up to
              date. As the provider of the Wing It Platform, Wing It does not
              own, control, offer or manage any Activity Listings. Wing It is
              not a party to the contracts entered into directly between
              Landowners and Members, nor is Wing It a real estate broker,
              travel agency, or insurer.
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Wing It is not acting as an agent in any capacity for any User
              (Landowner, Member or otherwise), except as specified in the
              Payment Terms. Our collection and use of personal information in
              connection with your access to and use of the Wing It Platform is
              described in our Privacy Policy.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "24px",
              }}
            >
              PLEASE READ THESE TERMS CAREFULLY BEFORE YOU OPEN AN ACCOUNT ON
              THE WING IT PLATFORM. BY ACCESSING OR USING THE WING IT PLATFORM,
              YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THESE TERMS. THESE
              TERMS REMAIN IN EFFECT UNTIL EITHER YOU OR WE TERMINATE THESE
              TERMS PURSUANT TO THE TERMS. SECTION 17 OF THESE TERMS CONTAINS AN
              ARBITRATION AGREEMENT AND CLASS ACTION WAIVER THAT APPLY TO ALL
              CLAIMS BROUGHT AGAINST WING IT IN THE UNITED STATES. PLEASE READ
              THEM CAREFULLY.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Users alone are responsible for identifying, understanding, and
              complying with all laws, rules and regulations that apply to their
              Listings and Activities (as defined below). If you have questions
              about how local laws apply to your Listing(s) and Activities on
              Wing It, you should always seek legal guidance.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              This Agreement describes the terms and conditions applicable to
              the services available through our Platform and details your
              responsibilities, thereby limiting the liability of WingIt and its
              third-party suppliers or providers.
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              BY ACCESSING OR USING THE PLATFORM, OR CREATING A LISTING THROUGH
              THE PLATFORM, YOU ARE ACCEPTING THESE TERMS, AND YOU REPRESENT AND
              WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER
              INTO THESE TERMS (INCLUDING THE INSURANCE POLICY SET FORTH IN
              SECTION 3.7 BELOW). YOU MAY NOT ACCESS OR USE THE PLATFORM OR
              ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD. IF YOU DO
              NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS
              AND/OR USE THE PLATFORM OR CREATE A BOOKING THROUGH THE PLATFORM.
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              FOR MEMBERS ADDITIONAL LISTING ACTIVITY TERMS AND CONDITIONS MAY
              APPLY TO YOUR ACTIVITY, BASED ON THE INDIVIDUAL ACTIVITY AND ANY
              LANDOWNER RULES AND CONDITIONS..
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              These Terms may be revised at any time for any reason, and Wing It
              may provide you notice of these changes by any reasonable means,
              including by providing notice through the Platform. If you do not
              agree to the revised Terms, you may not access or use the
              Platform.
            </Typography>
            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              BY ACCEPTING THESE TERMS, YOU ARE AGREEING TO THE MANDATORY
              ARBITRATION AND CLASS ACTION WAIVER CONTAINED BELOW.
            </Typography>

            <Typography
              component={"h3"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              The Wing It Platform
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.1</strong>&nbsp;<strong>Overview</strong>. The Wing It
              Platform is an online marketplace that enables Landowners to
              publish Activities on the Wing It Platform and to communicate and
              transact directly with Members who are seeking to reserve
              Activities.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.2</strong>&nbsp;<strong>Wing It’s Role</strong>.&nbsp;
              Wing It does not own, create, sell, resell, provide, control,
              manage, offer, deliver, or supply any Activities or
              Activity&nbsp;Listings. Landowners alone are responsible for their
              Activity&nbsp;Listings. When Members make or accept a reservation,
              they are entering into a contract directly with the Landowner.
              Wing It is not, and does not become, a party to or otherwise
              participate in any contractual relationship between the Member and
              Landowner. Wing It is not an insurer. Wing It is not acting as an
              agent for any Member or Landowner. By using the Wing It Platform,
              you agree that any legal remedy or liability that you seek to
              obtain for actions or omissions of a Member or Landowner will be
              limited to a claim against that particular Member or Landowner who
              caused you harm. You agree not to attempt to impose liability on
              or seek any legal remedy from Wing It with respect to such actions
              or omissions.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.4</strong>&nbsp;<strong>No Guarantee by Wing It</strong>
              .&nbsp; Wing It has no control over and does not guarantee (i) the
              existence, quality, safety, suitability, or legality of
              any&nbsp;Activity Listings or Activities, (ii) the truth or
              accuracy of any Activity Listings or other Content, or (iii) the
              performance or conduct of any Member or Landowner. Wing It does
              not endorse any Member, Landowner, Activity Listing, or Activity.
              You should exercise due diligence and care when deciding whether
              to reserve an Activity Listing or communicating and interacting
              with other Members and Landowners.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.5</strong>&nbsp;<strong>Limited Relationship</strong>
              .&nbsp; Your relationship with Wing It is that of an independent
              individual or entity and not an employee, agent, joint venturer,
              or partner of Wing It. Wing It does not, and shall not be deemed
              to, direct or control you generally or in your performance under
              these Terms. You acknowledge and agree that you have complete
              discretion whether to list an Activity, reserve an Activity, or
              otherwise engage in other business or employment activities.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.6</strong>&nbsp;<strong>Internet Availability</strong>
              .&nbsp; Due to the unpredictability of the Internet, Wing It
              cannot guarantee the continuous and uninterrupted availability and
              accessibility of the Wing It Platform. Wing It may restrict the
              availability of the Wing It Platform to perform maintenance
              measures, ensure the proper or improved functioning of the Wing It
              Platform, or for the security and integrity of Wing It’s servers.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.7</strong>&nbsp;<strong>Age Verification</strong>.&nbsp;
              In order to access and use the Wing It Platform and register an
              account on Wing It, you must be an individual at least 18 years
              old or a duly organized, validly existing business or other legal
              entity that is in good standing under the laws of the state and
              country in which such business or legal entity is established and
              able to enter into binding contracts.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.8</strong>&nbsp;<strong>Account Registration</strong>
              .&nbsp; You must register an account to use the Wing It Platform.
              If you are registering an account for a business or other legal
              entity, you represent and warrant that you have the authority to
              legally bind such entity and grant Wing It all permissions and
              licenses provided in these Terms. You must provide accurate,
              current, and complete information during the account registration
              process. You may not register more than one (1) account.&nbsp;The
              various types of Member accounts can be found on Wing It’s
              membership page.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>1.8</strong>&nbsp;<strong>NO ENDORSEMENT</strong>
              Wing IT does not make any representations about, confirm, or
              endorse any Member or Landowner, or the purported identity or
              background of the Member or Landowner or Activity. By using the
              Wing It Platform, you agree that any legal remedy or liability
              that you seek to obtain for actions or omissions of other Members,
              Landowners, or other third parties will be limited to a claim
              against the particular Member(s), Landowner(s), or other third
              parties who caused you harm. You agree not to attempt to impose
              liability on or seek any legal remedy from WingIt with respect to
              such actions or omissions. We encourage Landowners and Members to
              communicate directly with each other regarding any Bookings or
              Listings. This limitation shall not apply to any claim by a
              Landowner against WingIt regarding the remittance of payments
              received from a Member by WingIt on behalf of a Landowner, which
              instead shall be subject to the limitations described in the
              section below entitled Limitation of Liability.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Member Responsibilities, Assumption of Risk, and Reserving
              Activities
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2.1&nbsp;</strong>
              <strong>Member Responsibilities</strong>. As a Member you are
              responsible and liable for your own acts and omissions and are
              also responsible for the acts and omissions of anyone you invite
              to join or provide access to the Activity. Before and during an
              Activity you must at all times adhere to the Landowner’s
              instructions and all applicable laws. As a Member, you are
              responsible for ensuring that you have all appropriate permits,
              licenses, and tags. If you are reserving for an additional Member
              who is a minor or if you bring a minor to an Activity, you must be
              legally authorized to act on behalf of the minor and you are
              solely responsible for the supervision of that minor.&nbsp;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2.2&nbsp;</strong>
              <strong>Member Assumption of Risk</strong>.&nbsp;&nbsp;
              <strong>
                As a Member, you acknowledge that many activities carry inherent
                risks and agree that, to the maximum extent permitted by
                applicable law, you assume the entire risk arising out of your
                access to and use of the Wing It Platform and any Content,
                including your engagements of any Activity, or any other
                interaction you have with other Members whether in person or
                online. This means it is your responsibility to investigate an
                Activity to determine whether it is suitable for you. Certain
                Activities may carry risk of illness, bodily injury, disability,
                or death, and you freely and willfully assume those risks by
                choosing to participate in those Activities. You agree that Wing
                It will not be responsible for any liability incurred as a
                result of your interaction with other Members or in Activities.
                You will not exceed any limitations set forth in the Listing.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>2.3</strong>&nbsp;<strong>Reserving Activities</strong>.
              When you reserve an Activity, you agree to pay the price as
              determined between you and the Landowner. You also agree that
              Landowner’s may charge and collect any&nbsp;security
              deposit&nbsp;identified during checkout. When you receive the
              reservation confirmation, a contract for Activities (sometimes
              called a reservation in these Terms) is formed directly between
              you and the Landowner. The cancellation policy and any other
              rules, standards, policies, or requirements identified in the
              Activity Listing or during checkout form part of your contract
              with the Landowner. An Activity reservation is a limited license
              to enter, occupy, and use the Activity. The Landowner retains the
              right to re-enter the property where the Activity is taking place
              during your use of the applicable property, to the extent: (i) it
              is reasonably necessary, (ii) permitted by your contract with the
              Landowner, and (iii) consistent with applicable law. If you stay
              past the usage period as determined by you and the Landowner, the
              Landowner has the right to make you leave in a manner consistent
              with applicable law, including by imposing reasonable overstay
              penalties. You may not exceed the maximum number of allowed
              Members.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Landowner Responsibilities, Assumption of Risk, and Other
              Landowner Terms
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.1</strong>&nbsp;
              <strong>Landowner Responsibilities</strong>. As a Landowner you
              are responsible and liable for your own acts and omissions and
              also responsible for the acts and omissions of anyone you allow to
              participate in providing your Activities. You are responsible for
              setting the price and establishing rules and requirements for the
              Activity Listing. You must describe any and all fees and charges
              in the Activity Listing description and may not collect any
              additional fees or charges outside the Wing It Platform. You shall
              not encourage Members to create third-party accounts, submit
              reviews, provide their contact information, or take other actions
              outside the Wing It Platform.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.2</strong>&nbsp;
              <strong>Landowner Assumption of Risk</strong>.&nbsp;
              <strong>
                As a Landowner, you acknowledge that your Activities carry
                inherent risks and agree that you assume the entire risk arising
                out of your access to and use of the Wing It Platform, offering
                your Activities, or any interaction you have with other Members
                whether in person or online. You agree that you have had the
                opportunity to investigate the Wing It Platform and any laws,
                rules, regulations, or obligations that may be applicable to
                your Activity Listings or Activities and that you are not
                relying upon any statement of law made by Wing It.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.3&nbsp;</strong>
              <strong>Contracting with Members</strong>. When you accept a
              reservation request, or receive a reservation confirmation through
              the Wing It Platform, you are entering into a contract directly
              with the Member, and are responsible for delivering your Activity
              under the terms and at the price specified in your Activity
              Listing. You also agree to pay all applicable Taxes for each
              reservation.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.4&nbsp;</strong>
              <strong>Creating and Managing an Activity Listing</strong>. Each
              Activity Listing must include complete and accurate information
              about your Activity including the description, location, calendar
              availability, the price, other charges like security
              deposits,&nbsp;and any rules or requirements that apply to your
              Members or Activity Listing. Each Activity Listing must disclose
              any deficiencies, restrictions, and requirements that apply to the
              Activity Listing. You are responsible for keeping your Activity
              Listing information and content up-to-date and accurate at all
              times.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.5</strong>&nbsp;<strong>Ownership</strong>.&nbsp; When
              creating an Activity Listing, as a Landowner you represent and
              warrant that you are the owner of the property, or have the right
              to list the property on the Wing It Platform.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.6&nbsp;</strong>
              <strong>Legal Obligations</strong>. As a Landowner, you are
              responsible for understanding and complying with any laws, rules,
              regulations, and contracts with third parties that apply to your
              Activity Listing. Information we provide, if any, regarding legal
              requirements is for informational purposes only. You should
              independently confirm your obligations. You are responsible for
              handling and using personal data of Members and others in
              compliance with applicable privacy laws and these Terms. When
              listing an Activity you must, where applicable, fully educate and
              inform Members about (i) any risks inherent to the Activity, (ii)
              any requirements for participation including, but not limited to,
              minimum age, skill level, and level of fitness, and (iii) anything
              else the Member(s) need to know to safely participate in the
              Activity.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>3.7</strong>&nbsp;<strong>Insuarance</strong>.&nbsp; Wing
              It provides general liability insurance for Landowners. We give
              you extra insurance when you list with us. Wing It recommends that
              you insure your property and discuss appropriate coverage with
              your advisors.
              <p>
                Annual Fee: There’s a $325 insurance fee each year for
                landowners. Usually, the money from guest bookings will cover
                this. If not, we’ll send you a bill for the difference at the
                end of the year.
              </p>
              <p>
                How it works: The insurance kicks in when you get your first
                booking that’s paid for. Anything that happens before that isn’t
                covered by this. We are not your insurance agent or broker.
              </p>
              <p>
                Our Limits: Our insurance has its limits. We’re here to help,
                but we can’t cover everything. You are fully responsible for
                paying for any damages not covered by our insurance, as well as
                fees, expense, liens and fines arising out of your use or
                occupation of the Listed Property. Wing It’s liability insurance
                provided does not and shall not apply to any motor vehicle or
                automobile coverage. Please contact support@wingit.us for any
                further questions.
              </p>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Cancellations, Refunds, and Reservation Modifications
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.1&nbsp;</strong>
              <strong>Cancellations and Refunds</strong>. Members may cancel a
              confirmed reservation at any time pursuant to the Activity
              Listing’s cancellation policy as set by the Landowner. If a
              Landowner cancels a reservation, then the Member will receive a
              full refund for the applicable reservation. Regardless of any
              Landowner’s listed cancellation policy, if a Member cancels a
              reservation within forty-eight (48) hours from the time of the
              reservation confirmation, the Member will be given a full refund.
              Unless otherwise set forth by the Landowner in the Activity
              Listing, if a Member cancels a reservation more than forty-eight
              (48) hours from the time of the reservation confirmation, then the
              Member will not receive a refund.&nbsp;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>4.2&nbsp;</strong>
              <strong>Reservation Modifications</strong>. Members and
              Landowner’s are responsible for any Reservation Modifications they
              agree to make, and agree to pay any additional amounts, fees, or
              taxes associated with any Reservation Modification.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Taxes
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              As a Landowner, you are solely responsible for determining and
              fulfilling your obligations under applicable laws to report,
              collect, remit, or include in the Activity Listing fee any
              applicable Taxes.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Wing It Platform Rules
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>6.1&nbsp;</strong>
              <strong>Rules</strong>. You must follow these rules and must not
              help or induce others to break or circumvent these rules:
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (a) Do not lie, misrepresent something or someone, or pretend to
              be someone else;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (b) Do not scrape, hack, reverse engineer, compromise or impair
              the Wing It Platform;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (c) Do not use bots, crawlers, scrapers, or other automated means
              to access or collect data or other content from or otherwise
              interact with the Wing It Platform;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (d) Do not hack, avoid, remove, impair, or otherwise attempt to
              circumvent any security or technological measure used to protect
              the Wing It Platform or Content;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (e) Do not decipher, decompile, disassemble, or reverse engineer
              any of the software or hardware used to provide the Wing It
              Platform;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (f) Do not take any action that could damage or adversely affect
              the performance or proper functioning of the Wing It Platform;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (g) Users may only use another User’s personal information as
              necessary to facilitate a transaction using the Wing It Platform
              as authorized by these Terms;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (h) Do not use the Wing It Platform, our messaging tools, or
              Users’ personal information to send commercial messages without
              the recipient’s express consent;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (i) Users may use Content made available through the Wing It
              Platform solely as necessary to enable the User’s use of the Wing
              It Platform as a Member or Landowner;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (j) Do not use Content unless granted permission from the Content
              owner or the use is authorized by us in these Terms or another
              agreement the User has with us;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (k) Do not request, make, or accept a reservation or any payment
              outside of the Wing It Platform to avoid paying fees, taxes or for
              any other reason;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (l) Do not require or encourage Users to open an account, leave a
              review, complete a survey, or otherwise interact, with a third
              party website, application or service before, during or after a
              reservation, unless authorized by Wing It;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (m) Do not engage in any practices that are intended to manipulate
              our search algorithm.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (n) Do not use, copy, display, mirror or frame the Wing It
              Platform, any Content, any Wing It branding, or any page layout or
              design without our consent; and
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (o) Honor legal obligations, including but not limited to:
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (i) Understand and follow the laws that apply, including privacy,
              data protection, and export laws;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (ii) If a you provide us with someone else’s personal information,
              then you: (i) must do so in compliance with applicable law, (ii)
              must be authorized to do so, and (iii) authorize us to process
              that information under our Privacy Policy;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (iii) Read and follow our Terms;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (iv) Do not organize or facilitate unauthorized parties or events.
              You are responsible and liable for any party or event during your
              reservation;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (v) Do not use the name, logo, branding, or trademarks of Wing It
              or others without permission; and
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (vi) Do not use or register any domain name, social media handle,
              trade name, trademark, branding, logo, or other source identifier
              that may be confused with Wing It branding.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>6.2&nbsp;</strong>
              <strong>Reporting Violations</strong>. If you believe that a User,
              Activity Listing, or Content poses an imminent risk of harm to a
              person or property, you should immediately contact local
              authorities before contacting Wing It. If you report an issue to
              local authorities, Wing It may request a copy of that report.
              Except as required by law, you agree that we are not obligated to
              take action in response to any report.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>6.3&nbsp;</strong>
              <strong>Copyright Notifications</strong>. If you believe that
              Content on the Wing It Platform infringes copyrights, then please
              notify us at the address listed in Section 16 of these Terms.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Reviews
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              After each Activity concludes, Members and Landowners will have an
              opportunity to submit a star rating and review each other. Reviews
              reflect the opinions of the Users and do not reflect the opinion
              of Wing It. Reviews are not verified by Wing It for accuracy and
              may be incorrect or misleading. Users are prohibited from
              manipulating the review system, which includes, but is not limited
              to, instructing a third-party to write a positive or negative
              review about another User.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Content
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Wing It may, at its sole discretion, enable Users to provide
              Content. By providing Content, in whatever form and through
              whatever means, you grant Wing It a non-exclusive, worldwide,
              royalty-free, irrevocable, perpetual, sub-licensable and
              transferable license to copy, modify, prepare derivative works of,
              distribute, publish and otherwise exploit, that Content, without
              limitation. If Content includes personal information, our Privacy
              Policy describes how we use that personal information. Where Wing
              It pays for the creation of Content or facilitates its creation,
              Wing It may own that Content, in which case supplemental terms or
              disclosures will say that. You are solely responsible for all
              Content that you provide and warrant that you either own it or are
              authorized to grant Wing It the rights described in these Terms.
              You are responsible and liable if any of your Content violates or
              infringes the intellectual property or privacy rights of any third
              party. You agree that Wing It may make available services or
              automated tools to translate Content and that your Content may be
              translated using such services or tools. Wing It does not
              guarantee the accuracy or quality of translations and Users are
              responsible for confirming the accuracy of such translations.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Termination, Suspension and other Measures
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>9.1&nbsp;</strong>
              <strong>Termination</strong>. You may terminate these Terms at any
              time by sending us&nbsp;an email&nbsp;or by deleting your account.
              Wing It may terminate these Terms and your account for any reason
              by giving you 30 days’ notice via email or using any other contact
              information you have provided. Wing It may also terminate these
              Terms immediately and without notice and stop providing access to
              the Wing It Platform if you breach these Terms, you violate our
              Policies, you violate applicable laws, or we reasonably believe
              termination is necessary to protect Wing It, its Users, or third
              parties. If your account has been inactive for more than two
              years, we may terminate your account without prior notice.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>9.2&nbsp;</strong>
              <strong>Member Violations</strong>. If you (i) breach these Terms,
              our&nbsp;policies, or our&nbsp;standards, (ii) you violate
              applicable laws, regulations, or third-party rights, (iii) Wing It
              believes it is reasonably necessary to protect Wing It, its Users,
              or third parties, or (iv) you are found to have caused damage to a
              Landowner’s property as discussed below in Section 10; Wing It
              may, with or without prior notice:
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (a) suspend or limit your access to or use of the Wing It Platform
              and/or your account;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (b) suspend or remove Activity Listings, Reviews, or other
              Content;
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (c) cancel pending or confirmed reservations; or
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "10px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              (d) suspend or revoke any special status associated with your
              account.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              For minor violations or where otherwise appropriate as Wing It
              determines in its sole discretion, you will be given notice of any
              intended measure by Wing It and an opportunity to resolve the
              issue. If a reservation is cancelled under this Section, the
              amount paid to the Landowner will be reduced by the amount we
              refund or otherwise provide to the Member, and by any other costs
              we incur as a result of the cancellation.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>9.3&nbsp;</strong>
              <strong>Legal Mandates</strong>. Wing It may take any action it
              determines is reasonably necessary to comply with applicable law,
              or the order or request of a court, law enforcement, or other
              administrative agency or governmental body, including the measures
              described above in Section 9.2.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>9.4&nbsp;</strong>
              <strong>Effect of Termination</strong>. If you are a Landowner and
              terminate your Wing It account, any confirmed reservation(s) will
              be automatically cancelled and the Member will receive a full
              refund. If you are a Member and terminate your account, any
              confirmed reservation(s) will be automatically cancelled and any
              refund will depend upon the terms of the reservation’s
              cancellation policy. When these Terms have been terminated, you
              are not entitled to a restoration of your account or any of your
              Content. If your access to or use of the Wing It Platform has been
              limited, or your Wing It account has been suspended, or these
              Terms have been terminated by us, you may not register a new
              account or access or use the Wing It Platform through an account
              of another User.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>9.5&nbsp;</strong>
              <strong>Survival</strong>. Parts of these Terms that by their
              nature survive termination, will survive termination of these
              Terms.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Resolving Complaints and Damage Claims
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              As a Member, you are responsible for leaving the Landowner’s
              property in the condition it was in when you arrived. As a
              Landowner, if a Member causes damage to your property, you may
              make a claim for compensation through the Resolution Center. The
              Member will be given an opportunity to respond to the Landowner’s
              claim.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Disclaimer of Warranties
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              We provide the Wing It Platform and all Content “as is” without
              warranty of any kind and we disclaim all warranties, whether
              express or implied. Any references to a Member or Activity Listing
              being “verified” (or similar language) indicate only that the
              Member or Activity Listing or Wing It has completed a relevant
              verification or identification process and nothing else. The
              disclaimers in these Terms apply to the maximum extent permitted
              by law. If you have statutory rights or warranties we cannot
              disclaim, the duration of any such statutorily required rights or
              warranties, will be limited to the maximum extent permitted by
              law.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              Limitations on Liability
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Neither Wing It (including its affiliates and personnel) nor any
              other party involved in creating, producing, or delivering the
              Wing It Platform or any Content will be liable for any incidental,
              special, exemplary or consequential damages, including lost
              profits, loss of data or loss of goodwill, service interruption,
              computer damage or system failure or the cost of substitute
              products or services, or for any damages for personal or bodily
              injury or emotional distress arising out of or in connection with
              (i) these Terms, (ii) the use of or inability to use the Wing It
              Platform or any Content, (iii) any communications, interactions or
              meetings you may have with someone you interact or meet with
              through, or as a result of, your use of the Wing It Platform, or
              (iv) publishing or reservation of a Activity Listing, including
              the provision or use of Activities, whether based on warranty,
              contract, tort (including negligence), product liability or any
              other legal theory, and whether or not Wing It has been informed
              of the possibility of such damage, even if a limited remedy set
              out in these Terms is found to have failed of its essential
              purpose.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Except for our obligation to transmit payments to Landowners under
              these Terms, in no event will Wing It’s aggregate liability for
              any claim or dispute arising out of or in connection with these
              Terms, your interaction with any Users, or your use of or
              inability to use the Wing It Platform, any Content, or any
              Activity, exceed: (A) to Members, the amount you paid as a Member
              during the 12-month period prior to the event giving rise to the
              liability, (B) to Landowners, the amount paid to you as a
              Landowner in the 12-month period prior to the event giving rise to
              the liability, or (C) to anyone else, one hundred U.S. dollars
              (US$100).
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              These limitations of liability and damages are fundamental
              elements of these Terms between you and Wing It. If applicable law
              does not allow the limitations of liability set out in these
              Terms, the above limitations may not apply to you.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>Indemnification</strong>. To the maximum extent permitted
              by applicable law, you agree to release, defend (at Wing It’s
              option), indemnify, and hold Wing It (including other affiliates
              and their personnel) harmless from and against any claims,
              liabilities, damages, losses, and expenses, including, without
              limitation, reasonable legal and accounting fees, arising out of
              or in any way connected with: (i) your breach of these Terms
              (including any supplemental or additional terms that apply to a
              product or feature), (ii) your improper use of the Wing It
              Platform, (iii) your interaction with any User, or use of an
              Activity, including without limitation any injuries, losses or
              damages (whether compensatory, direct, incidental, consequential
              or otherwise) of any kind arising in connection with or as a
              result of such interaction, stay, participation or use, (iv) your
              failure, or our failure at your direction, to accurately report,
              collect or remit Taxes, or (v) your breach of any laws,
              regulations or third party rights such as intellectual property or
              privacy rights.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>Modification</strong>. Wing It may modify these Terms at
              any time in our sole discretion. When we make material changes to
              these Terms, we will post the revised Terms on the Wing It
              Platform and update the “Last Updated” date at the top of these
              Terms. We will also provide Members with notice of any material
              changes by email at least 30 days before the date they become
              effective. If you disagree with the revised Terms, you may
              terminate these Terms immediately as provided in these Terms. If
              you do not terminate these Terms before the date the revised Terms
              become effective, then your continued access to or use of the Wing
              It Platform will constitute acceptance of the revised Terms.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>Governing Law and Venue</strong>. If you reside or have
              your place of establishment in the United States, then these Terms
              will be interpreted in accordance with the laws of the State of
              Kansas and the United States of America, without regard to
              conflict-of-law provisions. Judicial proceedings (other than small
              claims actions) that are excluded from the arbitration agreement
              in Section 17 must be brought in state or federal court in Douglas
              County, Kansas, unless we both agree to some other location. You
              and we both consent to venue and personal jurisdiction in Douglas
              County, Kansas.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>Wing It Entity</strong>. Wing It, LLC, a Kansas limited
              liability company, is the Wing It entity. You may contact Wing It,
              LLC at: Wingit, LLC 706 Massachusetts, Lawrence KS 66044
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>Dispute Resolution and Arbitration Agreement</strong>.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.1&nbsp;</strong>
              <strong>Application</strong>. This Arbitration Agreement only
              applies to you if your country of residence or establishment is
              the United States. If your country of residence or establishment
              is not the United States, and you nevertheless attempt to bring
              any legal claim against Wing It in the United States, this
              Arbitration Agreement will apply for determination of the
              threshold issue of whether this Section 17 applies to you, and all
              other threshold determinations, including residency,
              arbitrability, venue, and applicable law.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.2&nbsp;</strong>
              <strong>Overview of Dispute Resolution Process</strong>. These
              Terms provide for a two-part process for individuals to whom this
              Section 17 applies: (1) an informal negotiation directly with Wing
              It’s customer service team (described in paragraph 17.3, below),
              and if necessary (2) a binding arbitration administered by the
              AAA. You and Wing It each retain the right to seek relief in small
              claims court as an alternative to arbitration.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.3&nbsp;</strong>
              <strong>
                Mandatory Pre-Arbitration Dispute Resolution and Notification
              </strong>
              . At least 30 days prior to initiating an arbitration, you and
              Wing It each agree to notify the other party of the dispute in
              writing and attempt in good faith to negotiate an informal
              resolution. You must send your notice of dispute to Wing It by
              mailing it the address listed in Section 16 of these Terms. Wing
              It will send its notice of dispute to the email address associated
              with your Wing It account. A notice of dispute must include: the
              party’s name and preferred contact information, a brief
              description of the dispute, and the relief sought. If the parties
              are unable to resolve the dispute within the 30-day period, only
              then may either party commence arbitration by filing a written
              Demand for Arbitration (available at www.adr.org) with the AAA and
              providing a copy to the other party as specified in the AAA
              Rules(available at www.adr.org).
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.4&nbsp;</strong>
              <strong>Agreement to Arbitrate</strong>.&nbsp;
              <strong>You and Wing It mutually agree that&nbsp;</strong>
              <strong>Disputes</strong>
              <strong>
                &nbsp;will be settled by binding individual arbitration (the “
              </strong>
              <strong>Arbitration Agreement</strong>
              <strong>
                ”). If there is a dispute about whether this Arbitration
                Agreement can be enforced or applies to our Dispute, you and
                Wing It agree that the arbitrator will decide that issue.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.5&nbsp;</strong>
              <strong>Exceptions to Arbitration Agreement</strong>. You and Wing
              It each agree that the following causes of action and/or claims
              for relief are exceptions to the Arbitration Agreement and will be
              brought in a judicial proceeding in a court of competent
              jurisdiction (as defined by Section 21): (i) any claim or cause of
              action alleging actual or threatened infringement,
              misappropriation or violation of a party’s copyrights, trademarks,
              trade secrets, patents, or other intellectual property rights;
              (ii) any claim or cause of action seeking emergency injunctive
              relief based on exigent circumstances (e.g., imminent danger or
              commission of a crime, hacking, cyber-attack); or (iii) a request
              for the remedy of public injunctive relief. You and Wing It agree
              that the remedy of public injunctive relief will proceed after the
              arbitration of all arbitrable claims, remedies, or causes of
              action, and will be stayed pending the outcome of the arbitration
              pursuant to section 3 of the Federal Arbitration Act.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.6&nbsp;</strong>
              <strong>Arbitration Rules and Governing Law</strong>. This
              Arbitration Agreement evidences a transaction in interstate
              commerce and the Federal Arbitration Act governs all substantive
              and procedural interpretation and enforcement of this provision.
              The arbitration will be administered by AAA in accordance with the
              AAA Rules then in effect, except as modified here. The AAA Rules
              are available at&nbsp;
              <a href="https://www.adr.org/" rel="noreferrer" target="_blank">
                www.adr.org
              </a>
              . In order to initiate arbitration, a completed written demand
              (available at&nbsp;
              <a href="https://www.adr.org/" rel="noreferrer" target="_blank">
                www.adr.org
              </a>
              ) must be filed with the AAA and provided to the other party, as
              specified in the AAA rules.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.7&nbsp;</strong>
              <strong>
                Modification to AAA Rules – Arbitration Hearing/Location
              </strong>
              . Wing It agrees that any required arbitration hearing may be
              conducted, at your option: (a) in the U.S. county where you
              reside; (b) in Douglas County, Kansas; (c) via phone or video
              conference; or (d) if all parties agree, by solely the submission
              of documents to the arbitrator.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.8&nbsp;</strong>
              <strong>
                Modification of AAA Rules – Attorney’s Fees and Costs
              </strong>
              . Your arbitration fees and your share of arbitrator compensation
              shall be governed by the AAA Rules and, where appropriate, limited
              by the AAA Consumer Rules. If such costs are determined by the
              arbitrator to be excessive, Wing It will pay all arbitration fees
              and expenses. Either party may make a request that the arbitrator
              award attorneys’ fees and costs upon proving that the other party
              has asserted a claim, cross-claim or defense that is groundless in
              fact or law, brought in bad faith or for the purpose of
              harassment, or is otherwise frivolous, as allowed by applicable
              law and the AAA Rules.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.9&nbsp;</strong>
              <strong>Arbitrator’s Decision</strong>. The arbitrator’s decision
              will include the essential findings and conclusions upon which the
              arbitrator based the award. Judgment on the arbitration award may
              be entered in any court with proper jurisdiction. The arbitrator
              may award any relief allowed by law or the AAA Rules, but
              declaratory or injunctive relief may be awarded only on an
              individual basis and only to the extent necessary to provide
              relief warranted by the claimant’s individual claim.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.10&nbsp;</strong>
              <strong>Jury Trial Waiver</strong>.&nbsp;
              <strong>
                You and Wing It acknowledge and agree that we are each waiving
                the right to a trial by jury as to all arbitrable Disputes.
              </strong>
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.11&nbsp;</strong>
              <strong>No Class Actions or Representative Proceedings</strong>.
              You and Wing It acknowledge and agree that, to the fullest extent
              permitted by law, both parties each waive the right to participate
              as a plaintiff or class member in any purported class action
              lawsuit, class-wide arbitration, private attorney general action,
              or any other representative or consolidated proceeding. Unless we
              agree in writing, the arbitrator may not consolidate more than one
              party’s claims and may not otherwise preside over any form of any
              class or representative proceeding. If there is a final judicial
              determination that applicable law precludes enforcement of the
              waiver contained in this paragraph as to any claim, cause of
              action or requested remedy, then that claim, cause of action or
              requested remedy, and only that claim, cause of action or
              requested remedy, will be severed from this agreement to arbitrate
              and will be brought in a court of competent jurisdiction. In the
              event that a claim, cause of action or requested remedy is severed
              pursuant to this paragraph, then you and we agree that the claims,
              causes of action or requested remedies that are not subject to
              arbitration will be stayed until all arbitrable claims, causes of
              action and requested remedies are resolved by the arbitrator.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.12&nbsp;</strong>
              <strong>Severability</strong>. Except as provided in Section
              17.11, in the event that any portion of this Arbitration Agreement
              is deemed illegal or unenforceable, such provision will be severed
              and the remainder of the Arbitration Agreement will be given full
              force and effect.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.13&nbsp;</strong>
              <strong>Changes to Agreement to Arbitrate</strong>. If Wing It
              changes this Section 17 after the date you last accepted these
              Terms (or accepted any subsequent changes to these Terms), you may
              reject that change by sending us written notice (including by
              email) within 30 days of the date the change is effective.
              Rejecting a new change, however, does not revoke or alter your
              prior consent to any earlier agreements to arbitrate any Dispute
              between you and Wing It (or your prior consent to any subsequent
              changes thereto), which will remain in effect and enforceable as
              to any Dispute between you and Wing It.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>17.14&nbsp;</strong>
              <strong>Survival</strong>. Except as provided in Section 17.12 and
              subject to Section 9.5, this Section 17 will survive any
              termination of these Terms and will continue to apply even if you
              stop using the Wing It Platform or terminate your Wing It account.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>Miscellaneous</strong>.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.1&nbsp;</strong>
              <strong>Other Terms Incorporated by Reference</strong>. Our other
              supplemental policies and terms linked to in these Terms apply to
              your use of the Wing It Platform, are incorporated by reference,
              and form part of these Terms with Wing It.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.2&nbsp;</strong>
              <strong>Interpreting these Terms</strong>. Except as they may be
              supplemented by additional terms, conditions, policies,
              guidelines, standards, and in-product disclosures, these Terms
              (including those items incorporated by reference) constitute the
              entire agreement between Wing It and you pertaining to your access
              to or use of the Wing It Platform and supersede any and all prior
              oral or written understandings or agreements between Wing It and
              you. These Terms do not and are not intended to confer any rights
              or remedies upon anyone other than you and Wing It. If any
              provision of these Terms is held to be invalid or unenforceable,
              except as otherwise indicated in Section 17.11 above, such
              provision will be struck and will not affect the validity and
              enforceability of the remaining provisions. Where the word “will”
              is used in these Terms it connotes an obligation with the same
              meaning as “shall.”
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.3&nbsp;</strong>
              <strong>No Waiver</strong>. Wing It’s failure to enforce any right
              or provision in these Terms will not constitute a waiver of such
              right or provision unless acknowledged and agreed to by us in
              writing. Except as expressly set forth in these Terms, the
              exercise by either party of any of its remedies under these Terms
              will be without prejudice to its other remedies under these Terms
              or otherwise permitted under law.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.4&nbsp;</strong>
              <strong>Assignment</strong>. You may not assign, transfer or
              delegate these Terms or your rights and obligations hereunder
              without Wing It’s prior written consent. Wing It may without
              restriction assign, transfer or delegate these Terms and any
              rights and obligations hereunder, at its sole discretion, with 30
              days’ prior notice.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.5&nbsp;</strong>
              <strong>Notice</strong>. Unless specified otherwise, any notices
              or other communications to Members permitted or required under
              these Terms, will be provided electronically and given by Wing It
              via email, Wing It Platform notification, messaging service, or
              any other contact method we enable and you provide.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.6&nbsp;</strong>
              <strong>Third-Party Services</strong>. The Wing It Platform may
              contain Third-Party Services that are subject to different terms
              and privacy practices. Wing It is not responsible or liable for
              any aspect of such Third-Party Services and links to such
              Third-Party Services are not an endorsement.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.7&nbsp;</strong>
              <strong>Apple Terms</strong>. If you access or download our
              application from the Apple App Store, you agree to&nbsp;
              <a
                href="https://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/"
                rel="noreferrer"
                target="_blank"
              >
                Apple’s Licensed Application End User License Agreement
              </a>
              .
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.8&nbsp;</strong>
              <strong>Wing It Platform Content</strong>. Content made available
              through the Wing It Platform may be protected by copyright,
              trademark, and/or other laws of the United States and other
              countries. You acknowledge that all intellectual property rights
              for that Content are the exclusive property of Wing It and/or its
              licensors and agree that you will not remove, alter or obscure any
              copyright, trademark, service mark or other proprietary rights
              notices. You may not use, copy, adapt, modify, prepare derivative
              works of, distribute, license, sell, transfer, publicly display,
              publicly perform, transmit, broadcast or otherwise exploit any
              Content accessed through the Wing It Platform except to the extent
              you are the legal owner of that Content or as expressly permitted
              in these Terms. Subject to your compliance with these Terms, Wing
              It grants you a limited, non-exclusive, non-sublicensable,
              revocable, non-transferable license to (i) download and use the
              Application on your personal device(s); and (ii) access and view
              the Content made available on or through the Wing It Platform and
              accessible to you, solely for your personal and non-commercial
              use.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.9&nbsp;</strong>
              <strong>Force Majeure</strong>. Wing It shall not be liable for
              any delay or failure to perform resulting from causes outside its
              reasonable control, including, but not limited to, acts of God,
              natural disasters, war, terrorism, riots, embargoes, acts of civil
              or military authorities, fire, floods, accidents, pandemics,
              epidemics or disease, strikes or shortages of transportation
              facilities, fuel, energy, labor or materials.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              <strong>18.10&nbsp;</strong>
              <strong>Emails and SMS</strong>. You will receive administrative
              communications from us using the email address or other contact
              information you provide for your Wing It account. Enrollment in
              additional email subscription programs will not affect the
              frequency of these administrative emails, though you should expect
              to receive additional emails specific to the program(s) to which
              you have subscribed. You may also receive promotional emails from
              us. No fee is charged for these promotional emails, but
              third-party data rates could apply. You can control whether you
              receive promotional emails using the notification preferences in
              your account settings. Please note that you will not be able to
              take advantage of certain promotions if you disable certain
              communication settings or do not have an Wing It account.
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "24px",
              }}
            >
              SCHEDULE A – GLOSSARY
            </Typography>

            <Typography
              component={"p"}
              sx={{
                marginTop: "15px",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Capitalized terms used in herein shall have the meanings set forth
              in this Section A:
            </Typography>

            <ol>
              <li>
                &nbsp;“<strong>AAA</strong>” means the American Arbitration
                Association.
              </li>
              <li>
                &nbsp;“<strong>AAA Rules</strong>” means the Consumer
                Arbitration Rules and/or other AAA arbitration rules determined
                to be applicable by the AAA.
              </li>
              <li>
                &nbsp;“<strong>Activity</strong>” or “
                <strong>Activities</strong>” means a property for hunting,
                fishing, camping, and other outdoor activities offered by a
                Landowner to a Member.
              </li>
              <li>
                &nbsp;“<strong>Activity</strong>&nbsp;<strong>Listing</strong>”
                means a Landowner’s offering of an Activity to Members.
              </li>
              <li>
                &nbsp;“<strong>Arbitration Agreement</strong>” means the
                definition as found in Section 23.4 of the Terms.
              </li>
              <li>
                &nbsp;“<strong>Content</strong>” means feedback, text, photos,
                audio, video, information, and other content.
              </li>
              <li>
                &nbsp;“<strong>Disputes</strong>” means any dispute, claim, or
                controversy arising out of or relating to these Terms or the
                applicability, breach, termination, validity, enforcement, or
                interpretation thereof, or any use of the Wing It Platform,
                Activities, or any Content.
              </li>
              <li>
                &nbsp;“<strong>Landowner</strong>” or “
                <strong>Landowners</strong>” means those Users that publish and
                offer Activities for use by Members.
              </li>
              <li>
                &nbsp;“<strong>Member</strong>” or “<strong>Members</strong>”
                means those Users who enroll in the Wing It Platform
                subscription service and search for, reserve, and/or use an
                Activity.
              </li>
              <li>
                &nbsp;“<strong>Payment Terms</strong>” means those terms as
                specified in the Payments Terms of Service.
              </li>
              <li>
                &nbsp;“<strong>Reservation Modifications</strong>” means
                modifications made to any reservation by either (a) Landowners
                or Members on the Wing It Platform or (b) Wing It customer
                service on behalf of the Member.&nbsp;&nbsp;
              </li>
              <li>
                &nbsp;“<strong>Taxes</strong>” means any applicable VAT or other
                indirect sales taxes, occupancy taxes, tourist, income, or other
                taxes.
              </li>
              <li>
                &nbsp;“<strong>Terms</strong>” means these Terms and Conditions
                of Service.
              </li>
              <li>
                &nbsp;“<strong>Third-Party Services</strong>” means links to
                third-party websites, applications, services, or resources.
              </li>
              <li>
                &nbsp;“<strong>User</strong>” or “<strong>Users</strong>” means
                the users of the Wing It Platform which includes Landowners and
                Members.
              </li>
              <li>
                &nbsp;“<strong>Wing It</strong>,” “<strong>we</strong>,” “
                <strong>us</strong>,” or “<strong>our</strong>” refers to the
                Wing It entity set forth in Section 22 of these Terms.
              </li>
              <li>
                &nbsp;“<strong>Wing It Platform</strong>” means the websites,
                applications, and other offerings from Wing It.
              </li>
              <li>
                &nbsp;“<strong>You</strong>” means the entity or person agreeing
                to these terms.
              </li>
            </ol>
          </Box>
        </Box>
      </Box>
    </ModalContainer>
  );
};

export default TermsAndConditionsModal;
