import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import Badge from "@mui/material/Badge";
import BadgeAvatar from "components/avatar/BadgeAvatar";
import { ImageType } from "store";

interface IImageBadgeProps {
  handleEditImage: () => void;
  handleDeleteImage?: () => void;
  showDelete?: boolean;
  imageSrc: string;
  imageType: string;
  changeImage?: boolean;
  showEdit?: boolean;
  selectFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  width?: string;
  height?: string;
  marginRight?: string;
  marginTop?: string;
  borderRadius?: string;
}

export default function ImageBadgeComponent(props: IImageBadgeProps) {
  const {
    handleDeleteImage,
    handleEditImage,
    imageSrc,
    imageType,
    showDelete = false,
    changeImage = false,
    showEdit = true,
    selectFile,
    width,
    height,
    marginRight,
    marginTop,
    borderRadius,
  } = props;

  const { ACTIVITY_IMAGE } = ImageType;

  const boxDimention = {
    [ACTIVITY_IMAGE]: { height: "auto", width: undefined },
  };

  return (
    <Badge
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      badgeContent={
        <>
          {showDelete && (
            <BadgeAvatar onClick={handleDeleteImage}>
              <DeleteIcon fontSize="small" />
            </BadgeAvatar>
          )}
        </>
      }
      sx={{
        height: height ?? boxDimention[imageType as ImageType]?.height,
        width: width ?? boxDimention[imageType as ImageType]?.width ?? "100%",
        marginRight: marginRight,
        marginTop: marginTop,
      }}
    >
      <img
        alt={imageType}
        height="100%"
        src={imageSrc}
        style={{
          border: "1px solid lightgrey",
          borderRadius: borderRadius ?? "10px",
          objectFit: "cover",
        }}
        width="100%"
      />
      {showEdit ? (
        changeImage ? (
          <BadgeAvatar showSecondBadge>
            <label
              htmlFor={imageType}
              style={{
                width: boxDimention[imageType as ImageType]?.width ?? "100%",
                height: boxDimention[imageType as ImageType]?.height,
              }}
            >
              <input
                accept=".png,.jpeg,.jpg,.webp"
                id={imageType}
                name={imageType}
                style={{ display: "none" }}
                type="file"
                onChange={selectFile}
                onClick={(e: any) => {
                  e.target.value = null;
                }}
              />
              <AddPhotoAlternateIcon fontSize="small" />
            </label>
          </BadgeAvatar>
        ) : (
          <BadgeAvatar showSecondBadge onClick={handleEditImage}>
            <AddPhotoAlternateIcon fontSize="small" />
          </BadgeAvatar>
        )
      ) : (
        <></>
      )}
    </Badge>
  );
}
