import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { clearPropertyData } from "store";
import { UserRole } from "store/common/CommonInterface";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import { getCookieItem } from "utils/utils";

import Bookings from "./Bookings";

function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userAuth } = useAppSelector(s => s.auth);

  const isLoggedIn = useMemo(() => {
    const isHost = getCookieItem("userRole") === UserRole.HOST;
    return userAuth && isHost;
  }, [userAuth]);

  const handleCreateNew = () => {
    dispatch(clearPropertyData());
    navigate("/host/property/create");
  };

  return (
    <Stack alignItems="center" justifyContent="center">
      {isLoggedIn ? (
        <Stack sx={{ width: "100%", my: 5 }}>
          <Box
            component="div"
            sx={{
              backgroundColor: theme.palette.secondary.main,
              padding: "2rem 0rem",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <Typography
              sx={{
                border: "2.5px solid white",
                backgroundColor: theme.palette.secondary.main,
                padding: "1rem 1rem",
                cursor: "pointer",
                color: "white",
                fontSize: "16px",
                borderRadius: 2,
              }}
              variant="h6"
              onClick={handleCreateNew}
            >
              Create New Listing
            </Typography>
          </Box>
          <Bookings />
        </Stack>
      ) : (
        <Typography
          component="div"
          sx={{
            fontWeight: "bold",
            my: 5,
            fontSize: "16px",
          }}
          variant="h6"
        >
          Please switch to host anything
        </Typography>
      )}
    </Stack>
  );
}

export default Home;
