import { Button, IconButton, SvgIcon } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import theme from "theme";

import { ReactComponent as BackIcon } from "../../assets/back_icon.svg";
import { ReactComponent as CloseIcon } from "../../assets/close_icon.svg";
import { ReactComponent as WingItLogo } from "../../assets/wingit_logo.svg";

export interface IModalHeaderProps {
  showCloseIcon?: boolean;
  showBackIcon?: boolean;
  handleBack?: () => void;
  handleClose?: () => void;
  title?: string;
}

function ModalHeader({
  showCloseIcon = false,
  showBackIcon = false,
  handleBack,
  handleClose,
  title = "",
}: IModalHeaderProps) {
  return (
    <>
      <DialogTitle>
        <Box
          px={theme.spacing(3)}
          sx={{
            height: "60px",
            borderBottom: `1px solid ${theme.palette.text.secondary}`,
            position: "relative",
          }}
        >
          {showCloseIcon && (
            <Box sx={{ position: "absolute", right: "24px", top: "12px" }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          {showBackIcon && (
            <Box sx={{ position: "absolute", left: "24px", top: "10px" }}>
              <IconButton
                aria-label="Back Button"
                size="large"
                sx={{ mb: 1, mt: 1 }}
                onClick={handleBack}
              >
                <BackIcon />
              </IconButton>
            </Box>
          )}
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="center"
            sx={{ height: "100%" }}
          >
            <Box mx={theme.spacing(3)}>
              {title ? (
                <Typography variant="subtitle1" whiteSpace="nowrap">
                  {title}
                </Typography>
              ) : (
                <SvgIcon
                  inheritViewBox
                  component={WingItLogo}
                  sx={{ height: "24px", width: "90px" }}
                />
              )}
            </Box>
          </Stack>
        </Box>
      </DialogTitle>
    </>
  );
}

export default ModalHeader;
