import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import ModalContainer from "components/Modal/ModalContainer";
import ModalHeader from "components/Modal/ModalHeader";
import PasswordRules from "components/PasswordRules/PasswordRules";
import CustomInputField from "components/TextField/CustomInputField";
import CustomInputHeader from "components/TextField/CustomInputHeader";
import CustomMobileInputField from "components/TextField/CustomMobileInputField";
import { defaultPasswordRules } from "constants/constants";
import Email from "pages/Auth/components/Email";
import { useEffect, useState } from "react";
import {
  checkEmailExistsToUpdate,
  ICheckEmailExistsData,
  IPasswordRule,
  IResponseData,
  ISecurityEditableData,
  updateEmail,
  updateUserPassword,
  updateUserProfileInfo,
} from "store";
import { useAppDispatch, useAppSelector } from "store/hooks";
import theme from "theme";
import {
  getPhoneNumberWithoutCountryCode,
  handleMaskToUSNumber,
  unmaskUSNumber,
} from "utils/utils";
import {
  validateEmail,
  validatePassword,
  validatePasswordRules,
  validatePhoneNumber,
} from "utils/validator";

function SecurityFields() {
  const dispatch = useAppDispatch();

  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [errorMsgs, setErrorMsgs] = useState({
    phoneNumber: "",
    email: "",
    password: "",
  });

  const [passwordRules, setPasswordRules] = useState(defaultPasswordRules);

  const { loading, userProfileData } = useAppSelector(s => s.profile);
  const { phone, email } = userProfileData;

  const [securityEditableData, setSecurityEditableData] =
    useState<ISecurityEditableData>({
      phone: phone || "",
      email: email || "",
      password: "",
    });

  useEffect(() => {
    setSecurityEditableData({
      phone: phone || "",
      email: email || "",
      password: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfileData]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    const tempValue = value;

    const trimValue: string = tempValue !== " " ? tempValue : tempValue.trim();
    setSecurityEditableData({ ...securityEditableData, [name]: trimValue });
    setErrorMsgs({ ...errorMsgs, [name]: "" });
  };

  const handleEmailValidation = (email: string) => {
    let emailError = "";
    if (email && email.trim()) {
      const isEmailValid = validateEmail(email);
      if (!isEmailValid) {
        emailError = "Please enter valid email address";
      }
    } else {
      emailError = "Email is required";
    }
    return emailError;
  };
  const handlePhoneNumberValidation = (phoneNumber: string) => {
    let phoneError = "";
    if (phoneNumber && phoneNumber.trim()) {
      const isPhoneValid = validatePhoneNumber(phoneNumber);
      if (!isPhoneValid) {
        phoneError = "Please enter valid phone number";
      }
    } else {
      phoneError = "Phone number is required";
    }
    return phoneError;
  };

  // const handlePasswordValidation = () => {
  //   if (!validatePassword(securityEditableData.password)) {
  //     return "Password doesn't match the requirements";
  //   }
  //   return "";
  // };

  const handleVerifyPhoneNumber = async () => {
    const unMaskedNumber = unmaskUSNumber(securityEditableData.phone);

    if (phone === unMaskedNumber) {
      setIsEditingPhoneNumber(false);
    } else {
      const errMsg = handlePhoneNumberValidation(unMaskedNumber);
      setErrorMsgs({ ...errorMsgs, phoneNumber: errMsg });
      if (!errMsg) {
        dispatch(
          updateUserProfileInfo({
            phone: unMaskedNumber,
          }),
        );
        setIsEditingPhoneNumber(false);
      }
    }
  };

  const handleUpdatePassword = async () => {
    if (validatePassword(securityEditableData.password)) {
      await dispatch(updateUserPassword(securityEditableData.password));
      setPasswordRules(defaultPasswordRules);
      setIsEditingPassword(false);
      setSecurityEditableData({
        ...securityEditableData,
        password: "",
      });
      setShowPassword(false);
    }
  };

  const handlePasswordKeyup = async () => {
    const rules: IPasswordRule[] = validatePasswordRules(
      securityEditableData.password,
    );
    setPasswordRules(rules);
  };

  const handleUpdateEmailAccount = async () => {
    dispatch(
      updateUserProfileInfo({
        email: securityEditableData.email,
      }),
    );
    const response = (await updateEmail(
      securityEditableData.email,
    )) as IResponseData;
    if (response && !response.error) {
      setShowEmailConfirmation(false);
      setIsEditingEmail(false);
    }
  };

  const handleVerifyEmail = async () => {
    if (email === securityEditableData.email) {
      setIsEditingEmail(false);
    } else {
      const errMsg = handleEmailValidation(securityEditableData.email);
      setErrorMsgs({ ...errorMsgs, email: errMsg });
      if (!errMsg) {
        const response = (await checkEmailExistsToUpdate(
          securityEditableData.email,
        )) as IResponseData;
        if (response && !response?.error) {
          const { emailExists } = response.data as ICheckEmailExistsData;
          if (!emailExists) {
            setShowEmailConfirmation(true);
          } else {
            setErrorMsgs({
              ...errorMsgs,
              email: "Email already exists, please try with another email.",
            });
          }
        }
      }
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOnChange = (value: string) => {
    setSecurityEditableData({ ...securityEditableData, phone: value });
    setErrorMsgs({
      ...errorMsgs,
      phoneNumber: "",
    });
  };

  return (
    <>
      <CustomInputHeader
        closeEditing={() => {
          setSecurityEditableData({
            ...securityEditableData,
            password: "",
          });
          setIsEditingPassword(false);
          setErrorMsgs({ ...errorMsgs, password: "" });
          setPasswordRules(defaultPasswordRules);
          setShowPassword(false);
        }}
        isEditing={isEditingPassword}
        label="Password"
        saveEditValue={() => {
          // setIsEditingName(false);
          handleUpdatePassword();
        }}
        toEdit={() => {
          setIsEditingPassword(true);
        }}
      />
      {isEditingPassword ? (
        <>
          <CustomInputField
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePassword}>
                  {showPassword ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
            // error={errorMsgs.password.length > 0}
            // errormessage={errorMsgs.password}
            inputProps={{ maxLength: 35 }}
            label="New Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={securityEditableData.password}
            // onBlur={() => {
            //   const errMsg = handlePasswordValidation();
            //   setErrorMsgs({ ...errorMsgs, password: errMsg });
            // }}
            onChange={handleInputChange}
            onKeyUp={handlePasswordKeyup}
          />
          <Box sx={{ mb: theme.spacing(3), width: "100%" }}>
            <PasswordRules rules={passwordRules} />
          </Box>
        </>
      ) : (
        <>
          <Typography component="span" variant="h5">
            ********
          </Typography>
        </>
      )}
      <Divider sx={{ width: "100%" }} />
      <CustomInputHeader
        closeEditing={() => {
          setSecurityEditableData({
            ...securityEditableData,
            phone: phone || "",
          });
          setIsEditingPhoneNumber(false);
          setErrorMsgs({ ...errorMsgs, phoneNumber: "" });
        }}
        isEditing={isEditingPhoneNumber}
        label="Phone"
        saveEditValue={handleVerifyPhoneNumber}
        toEdit={setIsEditingPhoneNumber}
      />
      {isEditingPhoneNumber ? (
        <CustomMobileInputField
          autoComplete="off"
          error={errorMsgs.phoneNumber.length > 0}
          errormessage={errorMsgs.phoneNumber}
          inputProps={{ maxLength: 14 }}
          name="phone"
          placeholder="(xxx) xxx-xxxx"
          value={securityEditableData.phone}
          onBlur={() => {
            const errMsg = handlePhoneNumberValidation(
              unmaskUSNumber(securityEditableData.phone),
            );
            setErrorMsgs({ ...errorMsgs, phoneNumber: errMsg });
          }}
          onChange={handleOnChange}
        />
      ) : (
        <>
          <Typography component="span" variant="h5">
            {handleMaskToUSNumber(
              getPhoneNumberWithoutCountryCode(phone || ""),
            )}
          </Typography>
        </>
      )}

      <Divider sx={{ width: "100%" }} />
      <CustomInputHeader
        closeEditing={() => {
          setSecurityEditableData({
            ...securityEditableData,
            email: email || "",
          });
          setIsEditingEmail(false);
          setErrorMsgs({ ...errorMsgs, email: "" });
        }}
        isEditing={isEditingEmail}
        label="Email"
        saveEditValue={handleVerifyEmail}
        toEdit={setIsEditingEmail}
      />
      {isEditingEmail ? (
        <CustomInputField
          autoComplete="off"
          error={errorMsgs.email.length > 0}
          errormessage={errorMsgs.email}
          label=""
          name="email"
          type="email"
          value={securityEditableData.email}
          onBlur={() => {
            const errMsg = handleEmailValidation(securityEditableData.email);
            setErrorMsgs({ ...errorMsgs, email: errMsg });
          }}
          onChange={event => handleInputChange(event)}
        />
      ) : (
        <Typography component="span" variant="h5">
          {email}
        </Typography>
      )}

      {showVerifyEmail && (
        <>
          <ModalContainer open={showVerifyEmail}>
            <Box
              sx={{
                width: "470px",
                maxWidth: "100%",
                backgroundColor: theme.palette.common.white,
              }}
            >
              <ModalHeader
                showCloseIcon
                handleClose={() => setShowVerifyEmail(false)}
              />
              <Box p={theme.spacing(3)}>
                <Email handleNextStep={() => setShowVerifyEmail(false)} />
              </Box>
            </Box>
          </ModalContainer>
        </>
      )}
      {showEmailConfirmation && (
        <>
          <ModalContainer open={showEmailConfirmation}>
            <Box
              sx={{
                width: "470px",
                maxWidth: "100%",
                backgroundColor: theme.palette.common.white,
              }}
            >
              <ModalHeader
                showCloseIcon
                handleClose={() => setShowEmailConfirmation(false)}
              />
              <Box p={theme.spacing(3)}>
                <Stack
                  alignItems="center"
                  direction="column"
                  justifyContent="center"
                >
                  <Typography
                    component="p"
                    sx={{ textAlign: "center" }}
                    variant="h2"
                  >
                    {`We now need to verify your new email address before it can be saved to your account, this is to make sure you receive timely communications and your account is secure. Please click the verification link we've sent to ${securityEditableData.email}.`}
                  </Typography>
                  <Typography
                    component="p"
                    sx={{ textAlign: "center" }}
                    variant="h2"
                  >
                    Note: Wing It will not allow you to Sign In with this
                    address, or receive communications until it has been
                    verified.
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="flex-end"
                    marginTop={theme.spacing(3)}
                    width="100%"
                  >
                    <Button
                      sx={{
                        paddingLeft: theme.spacing(3),
                        paddingRight: theme.spacing(3),
                      }}
                      variant="text"
                      onClick={() => setShowEmailConfirmation(false)}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={loading}
                      sx={{
                        paddingLeft: theme.spacing(3),
                        paddingRight: theme.spacing(3),
                      }}
                      variant="contained"
                      onClick={handleUpdateEmailAccount}
                    >
                      Confirm
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </ModalContainer>
        </>
      )}
    </>
  );
}

export default SecurityFields;
