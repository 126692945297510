import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import theme from "theme";

interface IMapSearchInputProps {
  searchText: string;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleOnFocus: () => void;
}

function MapSearchInput({
  searchText,
  handleInputChange,
  handleOnFocus,
}: IMapSearchInputProps) {
  return (
    <TextField
      hiddenLabel
      autoComplete="off"
      // variant="standard"
      InputProps={{
        type: "search",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="search"
              edge="end"
              style={{
                backgroundColor: theme.palette.secondary.main,
                marginRight: 1,
              }}
            >
              <SearchIcon style={{ color: "white" }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label=""
      placeholder="Search Location"
      style={{
        backgroundColor: "white",
        borderRadius: "30px",
        border: "none",
      }}
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          border: "none",
        },
      }}
      value={searchText}
      onChange={handleInputChange}
      onFocus={handleOnFocus}
    />
  );
}

export default MapSearchInput;
