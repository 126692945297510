import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import theme from "theme";

interface IStepperInputProps {
  label: string;
  value: number;
  name: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onClick: (type: string) => void;
  direction?: string;
}

function StepperInput({
  label,
  value,
  onChange,
  name,
  onClick,
  direction = "row",
}: IStepperInputProps) {
  return (
    <Stack
      alignItems={direction === "row" ? "center" : "start"}
      direction={direction === "row" ? "row" : "column"}
      justifyContent="space-between"
      spacing={2}
      sx={{ mt: theme.spacing(2), width: "100%" }}
    >
      <Typography component="span" variant="h5">
        {label}
      </Typography>
      <Stack alignItems="center" direction="row" spacing={1}>
        <RemoveCircleOutlineIcon
          className="cursor-pointer"
          style={{ fontSize: "30px" }}
          onClick={() => onClick("minus")}
        />
        <TextField
          autoComplete="off"
          InputProps={{ style: { padding: "0px !important" } }}
          name={name}
          sx={{ width: 100 }}
          type="number"
          value={value}
          variant="outlined"
          onChange={onChange}
        />
        <AddCircleOutlineIcon
          className="cursor-pointer"
          style={{ fontSize: "30px" }}
          onClick={() => onClick("plus")}
        />
      </Stack>
    </Stack>
  );
}

export default StepperInput;
