import { IPasswordRule, UserRole } from "store/common/CommonInterface";
import { PropertySteps } from "store/property/PropertyTypes";

export const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
export const MAP_QUEST_TOKEN = process.env.REACT_APP_MAP_QUEST_TOKEN;

export const NAME_REGEX = /^[a-zA-Z][a-zA-Z' -]*$/;
export const OWNER_NAME_REGEX = /^[a-zA-Z][a-zA-Z'& -]*$/;
export const NAME_REGEX_2 = /^[a-zA-Z]{2}[a-zA-Z' -]*$/;
export const ZIPCODE_REGEX = /^\d{5}(-\d{4})?$/;
export const ONLY_ALPHABETS_SPACES = /[^a-zA-Z\s]/gi;

export interface IMenu {
  label: string;
  path: string;
  roles: string[];
  showDivider?: boolean;
  switchRole?: boolean;
  mode?: string[];
  externalLink?: boolean;
}

const { GUEST, HOST } = UserRole;
export const userMenu: IMenu[] = [
  {
    label: "My Account",
    path: "/account/profile",
    roles: [GUEST, HOST],
    showDivider: true,
  },
  // {
  //   label: "Settings",
  //   path: "/settings",
  //   roles: [HOST],
  //   showDivider: true,
  // },
  {
    label: "Switch to Host",
    path: "",
    roles: [GUEST],
    showDivider: true,
    switchRole: true,
  },
  {
    label: "Help",
    path: "https://support.wingit.us/hc/en-us/requests/new",
    roles: [GUEST, HOST],
    showDivider: false,
    externalLink: true,
  },
  {
    label: "Switch to Guest",
    path: "",
    roles: [HOST],
    showDivider: false,
    switchRole: true,
  },
];

export const NAVIGATION_MENU: IMenu[] = [
  {
    label: "Dashboard",
    path: "/host",
    roles: [HOST],
  },
  // {
  //   label: "Inbox",
  //   path: "",
  //   roles: [GUEST, HOST],
  // },
  // {
  //   label: "Trips",
  //   path: "",
  //   roles: [GUEST],
  // },
  {
    label: "Listings",
    path: "/host/listings",
    roles: [HOST],
  },
  {
    label: "Bookings",
    path: "/guest-booking",
    roles: [GUEST],
  },
];

export const sideMenuItems: IMenu[] = [
  {
    label: "Profile",
    path: "profile",
    roles: [GUEST, HOST],
    mode: ["web", "mobile"],
  },
  {
    label: "Security",
    path: "security",
    roles: [GUEST, HOST],
    mode: ["web", "mobile"],
  },
  {
    label: "Billing",
    path: "billing",
    roles: [GUEST],
    mode: ["web", "mobile"],
  },
  {
    label: "Finance",
    path: "finance",
    roles: [HOST],
    mode: ["web", "mobile"],
  },
  // {
  //   label: "Notifications",
  //   path: "",
  //   roles: [GUEST, HOST],
  //   mode: ["web", "mobile"],
  // },
  // {
  //   label: "App Settings",
  //   path: "",
  //   roles: [GUEST, HOST],
  //   mode: ["web", "mobile"],
  // },
  // {
  //   label: "Affiliate",
  //   path: "",
  //   roles: [GUEST, HOST],
  //   mode: ["web", "mobile"],
  // },
  {
    label: "Help",
    path: "https://support.wingit.us/hc/en-us/requests/new",
    roles: [GUEST, HOST],
    mode: ["mobile"],
    externalLink: true,
  },
];

export const defaultPasswordRules: IPasswordRule[] = [
  {
    rule: "At least 8 characters",
    valid: false,
  },
  {
    rule: "At least 1 lowercase letter",
    valid: false,
  },
  {
    rule: "At least 1 uppercase letter",
    valid: false,
  },
  {
    rule: "At least 1 number",
    valid: false,
  },
  {
    rule: "At least 1 special character",
    valid: false,
  },
];

export const US_STATES: string[] = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Federated States of Micronesia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Marshall Islands",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Palau",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Island",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const US_STATES_CODES: any = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa", //
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FM: "Federated States of Micronesia", ///
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam", //
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands", //
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands", //
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Palau",
  PS: "Pennsylvania", //
  PR: "Puerto Rico", //
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VAI: "Virgin Island", //
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};

interface IStepTitleData {
  title: string;
  subTitle: string;
  addon?: string;
}

type IStepTitle = {
  [key: number]: IStepTitleData;
};
const {
  ACTIVITY_PHOTOS,
  ENTRY_POINT,
  FIND_YOUR_PROPERTY,
  MAILING_ADDRESS,
  PROPERTY_DETAILS,
  ACTIVITY_TYPE,
  ACTIVITY_DETAILS,
  ACTIVITY_DATES,
  GET_STARTED_STEP1,
  GET_STARTED_STEP2,
  PARKING_POINT,
  EXCLUSION_AREA,
  ACTIVITY_COST,
  PROPERTY_RULES,
} = PropertySteps;

export const propertyTitles: IStepTitle = {
  [ACTIVITY_PHOTOS]: {
    title: "Activity Photos",
    subTitle:
      "Add any inspirational photos from your property to show guests what they can expect.",
  },
  [FIND_YOUR_PROPERTY]: {
    title: "Find your property",
    subTitle:
      "Search for your Address, County, City, State, or locate on map then tap on your property to add.",
  },
  [GET_STARTED_STEP1]: {
    title: `Let's get started`,
    subTitle: "",
  },
  [GET_STARTED_STEP2]: {
    title: `Well done! <br/> Your property has been added`,
    subTitle: "",
  },
  [MAILING_ADDRESS]: {
    title: "Mailing address",
    subTitle:
      "Choose your mailing address; we will send you a welcome package and signs with other information.",
  },
  [ACTIVITY_TYPE]: {
    title: "Activity Type",
    subTitle:
      "Search or select your Activity Type that you want to offer on your property.",
    addon:
      "Please select an Activity Type, each activity type will show as a separate listing. If you have an activity type that is not listed please contact Customer Service.",
  },
  [ACTIVITY_DETAILS]: {
    title: "Activity Details",
    subTitle:
      "Search or select your Activity Details that you want to offer on your property.",
    addon:
      "Each Activity Type is allowed 1 Detail and is a single listing. You can come back and create as many Activity Types as you like - if you have an Activity Type Detail that is not listed please contact Customer Service.",
  },
  [ACTIVITY_DATES]: {
    title: "Activity Dates",
    subTitle: "Set availability date ranges for booking your property.",
  },
  [PROPERTY_DETAILS]: {
    title: "Property Details",
    subTitle: "",
  },
  [ENTRY_POINT]: {
    title: "Entry point(s)",
    subTitle:
      "Tap on the map to add an entry point. When you are done adding all the entries, click next.",
  },
  [PARKING_POINT]: {
    title: "Parking",
    subTitle:
      "Tap on the map to add any available locations. When you are done adding all the entries, click next.",
  },
  [EXCLUSION_AREA]: {
    title: "Exclusion Area",
    subTitle:
      "Draw a square or circle around the areas you don't want any guests to access (Eg. house, barn, etc.) You can zoom in on the map to see a closer view.",
  },
  [ACTIVITY_COST]: {
    title: "Activity Cost",
    subTitle: "Set price for Activity and maximum number of guests.",
  },
  [PROPERTY_RULES]: {
    title: "Property Rules",
    subTitle:
      "Add Rules specific to your Property's Activity. You will have the opportunity to customized these rules for each Activity on the Property.",
  },
};

export const mapLayer = {
  id: "lineLayer",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "orange",
    "line-width": 2,
  },
  source: "data",
  type: "line",
};

export const ACCOUNT_TYPES = [
  {
    label: "Checking",
    value: "checking",
  },
  {
    label: "Saving",
    value: "saving",
  },
];

export const REF_NAV_ITEMS = [
  { label: "About", link: "https://resources.wingit.us/about/" },
  { label: "Resources", link: "https://resources.wingit.us/resources/" },
  { label: "Guests", link: "https://resources.wingit.us/guests/" },
  { label: "Hosts", link: "https://resources.wingit.us/landowners/" },
  { label: "Contact Us", link: "https://resources.wingit.us/contact/" },
];
