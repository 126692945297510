export enum PropertyType {
  UPDATE_PROPERTY_STEP = "UPDATE_PROPERTY_STEP",

  CREATE_PROPERTY_REQUEST = "CREATE_PROPERTY_REQUEST",
  CREATE_PROPERTY_SUCCESS = "CREATE_PROPERTY_SUCCESS",
  CREATE_PROPERTY_ERROR = "CREATE_PROPERTY_ERROR",

  CLEAR_PROPERTY_DATA = "CLEAR_PROPERTY_DATA",

  CLEAR_FOUND_PROPERTY_DATA = "CLEAR_FOUND_PROPERTY_DATA",

  CLAIM_PROPERTY_REQUEST = "CLAIM_PROPERTY_REQUEST",
  CLAIM_PROPERTY_SUCCESS = "CLAIM_PROPERTY_SUCCESS",
  CLAIM_PROPERTY_ERROR = "CLAIM_PROPERTY_ERROR",

  UPDATE_PROPERTY_INFO = "UPDATE_PROPERTY_INFO",

  UPDATE_PROPERTY_REQUEST = "UPDATE_PROPERTY_REQUEST",
  UPDATE_PROPERTY_SUCCESS = "UPDATE_PROPERTY_SUCCESS",
  UPDATE_PROPERTY_ERROR = "UPDATE_PROPERTY_ERROR",

  MANAGE_MARKERS_REQUEST = "MANAGE_MARKERS_REQUEST",
  MANAGE_MARKERS_SUCCESS = "MANAGE_MARKERS_SUCCESS",
  MANAGE_MARKERS_ERROR = "MANAGE_MARKERS_ERROR",

  GET_ALL_PROPERTIES_REQUEST = "GET_ALL_PROPERTIES_REQUEST",
  GET_ALL_PROPERTIES_SUCCESS = "GET_ALL_PROPERTIES_SUCCESS",
  GET_ALL_PROPERTIES_ERROR = "GET_ALL_PROPERTIES_ERROR",

  GET_PROPERTY_DETAILS_REQUEST = "GET_PROPERTY_DETAILS_REQUEST",
  GET_PROPERTY_DETAILS_SUCCESS = "GET_PROPERTY_DETAILS_SUCCESS",
  GET_PROPERTY_DETAILS_ERROR = "GET_PROPERTY_DETAILS_ERROR",

  SET_ACTIVITY_TYPE = "SET_ACTIVITY_TYPE",
  SET_ACTIVITY_DETAIL = "SET_ACTIVITY_DETAIL",

  SHOW_DATE_RANGE_CALENDER = "SHOW_DATE_RANGE_CALENDER",
  // ADD_ACTIVITY_DATE = "ADD_ACTIVITY_DATE",
  SET_EDIT_ACTIVE_INDEX = "SET_EDIT_ACTIVE_INDEX",
  // EDIT_ACTIVITY_DATE = "EDIT_ACTIVITY_DATE",
  // DELETE_ACTIVITY_DATE = "DELETE_ACTIVITY_DATE",

  CREATE_PROPERTY_ACTIVITY_REQUEST = "CREATE_PROPERTY_ACTIVITY_REQUEST",
  CREATE_PROPERTY_ACTIVITY_SUCCESS = "CREATE_PROPERTY_ACTIVITY_SUCCESS",
  CREATE_PROPERTY_ACTIVITY_ERROR = "CREATE_PROPERTY_ACTIVITY_ERROR",

  GET_ACTIVITIES_REQUEST = "GET_ACTIVITIES_REQUEST",
  GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS",
  GET_ACTIVITIES_ERROR = "GET_ACTIVITIES_ERROR",

  ADD_ACTIVITY_DATE_REQUEST = "ADD_ACTIVITY_DATE_REQUEST",
  ADD_ACTIVITY_DATE_SUCCESS = "ADD_ACTIVITY_DATE_SUCCESS",
  ADD_ACTIVITY_DATE_ERROR = "ADD_ACTIVITY_DATE_ERROR",

  EDIT_ACTIVITY_DATE_REQUEST = "EDIT_ACTIVITY_DATE_REQUEST",
  EDIT_ACTIVITY_DATE_SUCCESS = "EDIT_ACTIVITY_DATE_SUCCESS",
  EDIT_ACTIVITY_DATE_ERROR = "EDIT_ACTIVITY_DATE_ERROR",

  DELETE_ACTIVITY_DATE_REQUEST = "DELETE_ACTIVITY_DATE_REQUEST",
  DELETE_ACTIVITY_DATE_SUCCESS = "DELETE_ACTIVITY_DATE_SUCCESS",
  DELETE_ACTIVITY_DATE_ERROR = "DELETE_ACTIVITY_DATE_ERROR",

  GET_ACTIVITY_DETAILS_REQUEST = "GET_ACTIVITY_DETAILS_REQUEST",
  GET_ACTIVITY_DETAILS_SUCCESS = "GET_ACTIVITY_DETAILS_SUCCESS",
  GET_ACTIVITY_DETAILS_ERROR = "GET_ACTIVITY_DETAILS_ERROR",

  ADD_ACTIVITY_IMAGE_REQUEST = "ADD_ACTIVITY_IMAGE_REQUEST",
  ADD_ACTIVITY_IMAGE_SUCCESS = "ADD_ACTIVITY_IMAGE_SUCCESS",
  ADD_ACTIVITY_IMAGE_ERROR = "ADD_ACTIVITY_IMAGE_ERROR",

  EDIT_ACTIVITY_IMAGE_REQUEST = "EDIT_ACTIVITY_IMAGE_REQUEST",
  EDIT_ACTIVITY_IMAGE_SUCCESS = "EDIT_ACTIVITY_IMAGE_SUCCESS",
  EDIT_ACTIVITY_IMAGE_ERROR = "EDIT_ACTIVITY_IMAGE_ERROR",

  DELETE_ACTIVITY_IMAGE_REQUEST = "DELETE_ACTIVITY_IMAGE_REQUEST",
  DELETE_ACTIVITY_IMAGE_SUCCESS = "DELETE_ACTIVITY_IMAGE_SUCCESS",
  DELETE_ACTIVITY_IMAGE_ERROR = "DELETE_ACTIVITY_IMAGE_ERROR",

  UPDATE_AREA_ACTIVITY_REQUEST = "UPDATE_AREA_ACTIVITY_REQUEST",
  UPDATE_AREA_ACTIVITY_SUCCESS = "UPDATE_AREA_ACTIVITY_SUCCESS",
  UPDATE_AREA_ACTIVITY_ERROR = "UPDATE_AREA_ACTIVITY_ERROR",

  SET_EDITING_PROPERTY = "SET_EDITING_PROPERTY",

  UPDATE_PROPERTY_FIELDS_REQUEST = "UPDATE_PROPERTY_FIELDS_REQUEST",
  UPDATE_PROPERTY_FIELDS_SUCCESS = "UPDATE_PROPERTY_FIELDS_SUCCESS",
  UPDATE_PROPERTY_FIELDS_ERROR = "UPDATE_PROPERTY_FIELDS_ERROR",

  GET_PROPERTY_AVAILABLE_DATES_REQUEST = "GET_PROPERTY_AVAILABLE_DATES_REQUEST",
  GET_PROPERTY_AVAILABLE_DATES_SUCCESS = "GET_PROPERTY_AVAILABLE_DATES_SUCCESS",
  GET_PROPERTY_AVAILABLE_DATES_ERROR = "GET_PROPERTY_AVAILABLE_DATES_ERROR",

  UPDATE_PROPERTY_STATUS_REQUEST = "UPDATE_PROPERTY_STATUS_REQUEST",
  UPDATE_PROPERTY_STATUS_SUCCESS = "UPDATE_PROPERTY_STATUS_SUCCESS",
  UPDATE_PROPERTY_STATUS_ERROR = "UPDATE_PROPERTY_STATUS_ERROR",
}

export enum PropertySteps {
  // Step - 1 Start
  INITIAL,
  FIND_YOUR_PROPERTY,
  PROPERTY_DETAILS,
  // Step - 2 Start
  ACTIVITY_TYPE,
  PROPERTY_RULES,
  // ACTIVITY_DETAILS,
  ACTIVITY_COST,
  ENTRY_POINT,
  PARKING_POINT,
  // EXCLUSION_AREA,
  ACTIVITY_DATES,
  ACTIVITY_PHOTOS,
  REVIEW,
  // Step - 3 Start
  GET_STARTED_STEP1,

  GET_STARTED_STEP2, // This is for Step 2: Property Details -> Get Started
  MAILING_ADDRESS,

  GET_STARTED_STEP3, // This is for Step 3: Add Activities -> Get Started

  ACTIVITY_DETAILS,
  EXCLUSION_AREA,
}

export enum MarkerTypes {
  ENTRY = "ENTRY_POINT",
  PARKING = "PARKING",
}
